import styled from "styled-components";

export const Styles = styled.div`
  .add-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 260px;
    background-color: ${({ colors }) => colors.color.addBox};
    /* margin-top: 20px; */
    border-radius: 5px;
    cursor: pointer;
  }
  .add-box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .add-box-content p {
    margin-top: 20px;
  }
  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: ${({ colors }) => colors.color.secondary};
  }
  .plus-icon {
    height: 35px;
    width: 35px;
    filter: brightness(0) invert(1);
  }
  img {
    border-radius: 5px;
  }
  button {
    border: none;
    background-color: none !important;
  }
`;
