import React, { useState } from "react";
import InputField from "../../components/common/InputField";
import Button from "../../components/common/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { openErrorMessage } from "../../store/slices/toastSlice";
import { useAuthContext } from "../../features/authentication";
const validationSchema = Yup.object({
  otp: Yup.string().required("otp is required"),
});
const initialValues = {
  otp: "",
};
export const AccountVerificationForm = ({ user, onSuccess }) => {
  const [serverError, setServerError] = useState(null);
  const { verifyOTP } = useAuthContext();
  const dispatch = useDispatch();
  const {
    errors,
    values,
    handleChange,
    isSubmitting,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await verifyOTP({
          code: values.otp + "",
          userId: user._id,
        });
        onSuccess(response.data.user);
      } catch (error) {
        dispatch(openErrorMessage(error.response.data.error));
        setServerError(error.response.data.error);
      }
    },
  });
  return (
    <>
      <div
        className="d-flex flex-column justify-content-between mb-4"
        style={{ minHeight: "130px" }}
      >
        <div style={{ width: 200 }}>
          <img
            className="img-fluid"
            src={"/logo-horizontal.png"}
            alt={"Logo"}
          />
        </div>
        <div className="d-flex flex-column align-items-start justify-content-start">
          <h2 className="text-xl font-semibold">Verify your account</h2>
        </div>
      </div>
      <div>
        <div>
          {serverError ? (
            <div className="alert alert-danger mb-4 " role="alert">
              {serverError}
            </div>
          ) : (
            <div className="alert alert-primary mb-2 " role="alert">
              We've sent a verification code to your email address
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <InputField
              className="signup-input mt-1"
              type="number"
              placeholder="Enter 4 digits OTP"
              name="otp"
              max="9999"
              min="0000"
              id="otp"
              value={values.otp}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.otp}
              autoComplete="off"
            />
            <Button
              type="submit"
              className="continue"
              children={
                isSubmitting ? (
                  <div
                    className="spinner-border spinner-border-sm and spinner-grow-sm"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  <p style={{ color: "white" }}>Submit</p>
                )
              }
            />
          </form>
        </div>
      </div>
    </>
  );
};
