import React from "react";
import styled from "styled-components";

import { colors } from "../../../utils/Colors";

const GigPlaceholder = ({ placeholders }) => {
  return (
    <>
      {placeholders.map((_, i) => {
        return (
          <GigPlaceholderStyled key={i} colors={colors}>
            <div className="d-flex align-items-center">
              <div className="profile-place"></div>
              <div className="profile-name"></div>
            </div>
            <div className="desc-place mb-5"></div>
          </GigPlaceholderStyled>
        );
      })}
    </>
  );
};

const GigPlaceholderStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 280px;
  height: 260px;
  background-color: ${({ colors }) => colors.color.veryLightGrey};
  opacity: 0.5;
  border-radius: 10px;
  overflow: hidden;
  .profile-place {
    background-color: ${({ colors }) => colors.color.darkGrey};
    border-radius: 50%;
    opacity: 0.3;
    margin: 10px 20px;
    width: 40px;
    height: 40px;
  }
  .profile-name {
    background-color: ${({ colors }) => colors.color.darkGrey};
    opacity: 0.3;
    width: 100px;
    height: 10px;
  }
  .desc-place {
    background-color: ${({ colors }) => colors.color.darkGrey};
    opacity: 0.3;
    width: 180px;
    height: 20px;
    margin: 0 0 10px 20px;
  }
`;

export default GigPlaceholder;
