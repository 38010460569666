import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";
import RoutesPath from "../../utils/Paths";

export const getJobs = createAsyncThunk("jobs/getJobs", async () => {
  try {
    const response = await axios.get("/jobs");
    return response.data.jobs;
  } catch (error) {
    return error;
  }
});
export const getJobById = createAsyncThunk(
  "jobs/getJobById",
  /**
   * @param {Object} data
   * @param {string} data.id
   */
  async (data) => {
    try {
      const response = await axios.get("/jobs/details?jobId=" + data.id);
      return {
        ...response.data.details,
        createdBy: {
          ...response.data.details.createdBy,
          jobsStats: {
            ...response.data.stats,
          },
        },
      };
    } catch (error) {
      return error;
    }
  }
);

export const filterJobs = createAsyncThunk(
  "jobs/filterJobs",
  /**
   * @param {Object} data
   * @param {string} data.category
   * @param {string} data.searchText
   * @param {string} data.sortBy
   * @param {string} data.workType
   * @param {string} data.minPrice
   * @param {string} data.maxPrice
   * @param {string} data.subcategory
   * @param {string} data.userId
   * @returns {Promise}
   */
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const {
        category,
        searchText,
        sortBy,
        workType,
        minPrice,
        maxPrice,
        subcategory,
        userId,
      } = data;

      const filters = {
        category: category || "",
        searchText: searchText || "",
        sortBy: sortBy || "",
        workType: workType || "",
        minPrice: minPrice || "",
        maxPrice: maxPrice || "",
        subcategory: subcategory || "",
        userId: userId || "",
      };

      let queryString = "";
      for (let key in filters) {
        queryString = queryString + `${key}=${filters[key]}&`;
      }

      const response = await axios.get(`/jobs/query?${queryString}`);
      return response.data.jobs;
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  }
);

export const postJob = createAsyncThunk(
  "jobs/postJobs",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { job, navigate } = data;
      let images = [];

      for (let i = 0; i < job.attachments.length; i++) {
        const formData1 = new FormData();
        formData1.append("attachment", job.attachments[i]);
        const response = await axios.post("attachments/one", formData1);
        images.push({
          publicId: response.data.attachment.publicId,
          imgUrl: response.data.attachment.url,
        });
      }

      const reqData = {
        attachments: images,
        category: job.category._id,
        subcategory: job.subcategory._id,
        workType: job.workType,
        deadline: job.deadline,
        budgetMax: job.budgetMax,
        budgetMin: job.budgetMin,
        description: job.description,
        location: job.location,
      };

      const response = await axios.post("/jobs", reqData);
      navigate(RoutesPath.manageJobs);
      dispatch(openSuccessMessage(response.data.message));
      return response;
    } catch (error) {
      dispatch(openErrorMessage(error.response.data.error));
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const updateJob = createAsyncThunk(
  "jobs/updateJob",
  async (user, { rejectWithValue, dispatch }) => {
    try {
      const { data, navigate } = user;
      let images = [];
      for (let i = 0; i < data.attachments.length; i++) {
        const formData = new FormData();
        if (!("imgUrl" in data.attachments[i])) {
          formData.append("attachment", data.attachments[i]);
          const response = await axios.post("attachments/one", formData);
          images.push({
            publicId: response.data.attachment.publicId,
            imgUrl: response.data.attachment.url,
          });
        }
        if ("imgUrl" in data.attachments[i]) {
          images.push(data.attachments[i]);
        }
      }

      const reqData = {
        _id: data._id,
        attachments: images,
        category: data.category._id,
        subcategory: data.subcategory._id,
        workType: data.workType,
        deadline: data.deadline,
        budgetMax: data.budgetMax,
        budgetMin: data.budgetMin,
        description: data.description,
        location: data.location,
      };
      const response = await axios.put("/jobs", reqData);
      navigate(RoutesPath.manageJobs);
      dispatch(openSuccessMessage("Updated successfully"));
      return response.data;
    } catch (error) {
      dispatch(openErrorMessage("Sorry! Something went wrong"));
      rejectWithValue(error);
    }
  }
);

export const getMyJobs = createAsyncThunk(
  "jobs/getMyJobs",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get("/jobs/myJobs");
      let jobs = response.data.jobs;
      return jobs;
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const deleteMyJob = createAsyncThunk(
  "jobs/deleteMyJob",
  /**
   *
   * @param {object} data
   * @param {string} data._id
   * @returns
   */
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { _id } = data;
      const response = await axios.delete(`/jobs/${_id}`);
      dispatch(openSuccessMessage(response.data.message));
      return {
        message: response.data.message,
        _id: _id,
      };
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  }
);

export const getJobOffers = createAsyncThunk(
  "jobs/getjobOffers",
  /**
   *
   * @param {object} data
   * @param {string} data._id
   * @returns
   */
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/jobs/offers/${data._id}`);
      return response.data.offers;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const rejectJobOffer = createAsyncThunk(
  "jobs/rejectJobOffer",
  /**
   *
   * @param {object} data
   * @property {string} data._id
   * @returns
   */
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { _id } = data;
      const response = await axios.put(`jobs/offers/${_id}/reject`);
      dispatch(openSuccessMessage(response.data.message));
      return {
        message: response.data.message,
        _id: _id,
      };
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  }
);

export const jobsByPaginate = createAsyncThunk(
  "jobs/jobs-paginate",
  /**
   * @param {Object} data
   * @param {number} data.page
   * @param {number} data.limit
   * @param {string} data.category
   * @param {string} data.searchText
   * @param {string} data.sortBy
   * @param {string} data.workType
   * @param {string} data.minPrice
   * @param {string} data.maxPrice
   * @param {string} data.subcategory
   * @param {string} data.userId
   * @returns {Promise}
   */
  async (data = {}, { rejectWithValue, dispatch }) => {
    try {
      const {
        page,
        limit,
        category,
        searchText,
        sortBy,
        workType,
        minPrice,
        maxPrice,
        subcategory,
        userId,
      } = data;

      const filters = {
        page: page || 1,
        limit: limit || 15,
        category: category || "",
        searchText: searchText || "",
        sortBy: sortBy || "",
        workType: workType || "",
        minPrice: minPrice || "",
        maxPrice: maxPrice || "",
        subcategory: subcategory || "",
        userId: userId || "",
      };

      let queryString = "";
      for (let key in filters) {
        queryString = queryString + `${key}=${filters[key]}&`;
      }

      const response = await axios.get(`/jobs/query/results?${queryString}`);
      return {
        page: filters.page,
        limit: filters.limit,
        jobs: response.data.jobs,
      };
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  }
);
