import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import axios1 from "axios";
import { setCurrentSection } from "../slices/jobsSlice";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

export const pauseJob = createAsyncThunk(
  "jobs/pauseJob",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { id } = data;

      const response = await axios.put(`/jobs/${id}/actions/pause`);
      dispatch(setCurrentSection(3));
      dispatch(openSuccessMessage(response.data.message));
      return {
        message: response.data.message,
        id: id,
      };
    } catch (error) {
      dispatch(openErrorMessage("Sorry! Something went wrong"));
      return rejectWithValue(error);
    }
  }
);
export const resumeJob = createAsyncThunk(
  "jobs/resumeJob",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { id, navigate } = data;

      const response = await axios.put(`/jobs/${id}/actions/active`);
      dispatch(setCurrentSection(2));
      // navigate("/");
      dispatch(openSuccessMessage("Job Resumed Successfully"));
      return {
        message: response.data.message,
        id: id,
      };
    } catch (error) {
      dispatch(openErrorMessage("Sorry! Something went wrong"));
      return rejectWithValue(error);
    }
  }
);
