import React, { useState } from "react";

const Avatar = ({ name, imgSrc, width=32 , height = 32 }) => {
  const secondChar = name?.split(" ")[1] || "";
  const [shouldFallback, setFallback] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#eee",
        width,
        height,
        borderRadius: "100%",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
      }}
    >
      {!imgSrc || shouldFallback ? (
        name ? <p style ={{fontSize:width >=  50? "1.5rem":"1rem"}}>{(name[0] + (secondChar ? secondChar[0] : "")).toUpperCase()}</p>:null
      ) : (
        <img
          src={imgSrc}
          alt={name}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "100%",
          }}
          onError={() => {
            setFallback(true);
          }}
        />
      )}
    </div>
  );
};

export default Avatar;
