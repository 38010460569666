import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  .card-list {
    flex: 5;
  }
  .credit-cards {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
    // box-shadow: inset 3px 3px 6px #00000029;
  }
  .credit-cards::-webkit-scrollbar {
    width: 5px !important;
  }
  .credit-cards::-webkit-scrollbar-track {
    background-color: ${({ colors }) => colors.color.lightGrey};
  }
  .credit-cards::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.color.secondary};
    opacity: 0.7;
  }
  .offer-info {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
    padding: 10px;
  }
  .payment-form {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
    padding: 10px;
  }
  h5 {
    font-size: 16px;
  }
  .payment-label {
    font-size: 14px;
    color: ${({ colors }) => colors.color.darkGrey};
    margin-bottom: 3px;
  }
  .methods {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .methods-card {
    display: flex;
    justify-content: center;
    height: 55px;
    padding: 7px 0;
  }
  .methods-card img {
    width: 50px;
  }
  .selected {
    border: 1px solid ${({ colors }) => colors.color.secondary};
    border-radius: 5px;
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }
  .offer-detail-img {
    margin: 0 auto;
    width: 100px;
  }
  .offer-detail-img img {
    width: 100px;
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;

    .card-list {
      margin: 20px 0 0 20px;
      border-radius: 5px;
    }
    // .credit-cards {
    //   padding: 10px 50px;
    // }
    .offer-info {
      flex: 3;
      margin: 20px 20px 0 0;
      padding: 20px;
      max-height: 400px;
    }
    .payment-form {
      flex: 5;
      margin-top: 20px;
      padding: 20px;
    }
    h5 {
      font-size: 18px;
    }
    .methods {
      gap: 20px;
    }
  }
  @media only screen and (min-width: 1200px) {
    // .credit-cards {
    //   padding: 10px 100px;
    // }
  }
`;
