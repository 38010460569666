import { signIn } from "./sign-in";
import { signUp } from "./sign-up";
import { confirmAccount } from "./confirm-account";
import { changePassword } from "./change-password";
import { verifyOTP } from "./verify-otp";
import axios from "../../../api/axios";
import { becomeSeller } from "./become-seller";
export const authAPI = {
  signIn,
  signUp,
  confirmAccount,
  changePassword,
  verifyOTP,
  becomeSeller,
  logout: async () => {
    return await axios.get("/users/sign-out");
  },
  /**
   * 
   * @param {Object} payload
   * @param {String} payload.type
   * @param {String} payload.id
   * @returns {Promise<{status:string}>}
   */
  makeFavourite: async (payload) => {
    return await axios.post("/users/make-fav", payload);
  },

  changeProfilePic:async (profileImg) =>{
    const formData = new FormData()
    formData.append("profileImg",profileImg)
    return await axios.put("/users/profile-img", formData);
  },

  updateProfile:async (payload) =>{
    return await axios.post("/users/profile", payload);
  },

};
