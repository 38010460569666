import {
  useUserDialogs,
  useUserDialogsSlice,
} from "./queries/use-user-dialogs";

import { useIncomingMessage } from "./subscribers/use-incoming-message";
import { useReadMessageListener } from "./subscribers/use-read-message-listener";
import { useDeliveredMessageListener } from "./subscribers/use-delivered-message-listener";
import {
  useReadMessageStatus,
  useReadMessageStatusSlice,
} from "./mutations/use-read-message";
import {
  useSendMessage,
  useSendMessageSlice,
} from "./mutations/use-send-message";
import {
  useDialogMessages,
  useDialogMessagesReducer,
} from "./queries/use-dialog-messages";
export * from "./services";
export const quickbloxReducers = {
  [useUserDialogsSlice.reducerName]: useUserDialogsSlice.reducer,
  [useSendMessageSlice.reducerName]: useSendMessageSlice.reducer,
  [useDialogMessagesReducer.reducerName]: useDialogMessagesReducer.reducer,
  [useReadMessageStatusSlice.reducerName]: useReadMessageStatusSlice.reducer,
};
export const quickbloxActions = {
  ...useSendMessageSlice.actions,
  ...useUserDialogsSlice.actions,
  ...useDialogMessagesReducer.actions,
  ...useReadMessageStatusSlice.actions,
};
export {
  useDialogMessages,
  useUserDialogs,
  useIncomingMessage,
  useSendMessage,
  useReadMessageListener,
  useReadMessageStatus,
  useDeliveredMessageListener,
};
