import styled from "styled-components";

export const StyledButton = styled.div`
  button:disabled,
  button[disabled] {
    background-color: ${({ colors }) => colors.color.darkGrey};
    color: ${({ colors }) => colors.color.white};
    opacity: 0.5;
  }
  .footer-btn {
    background-color: ${({ colors }) => colors.color.secondary};
    border: none;
    border-radius: 10px;
    color: ${({ colors }) => colors.color.white};
    padding: 0 10px;
    margin-right: 5px;
    font-size: 13px;
    cursor: pointer;
  }
  .search-btn {
  }
  .apply-btn {
    width: 100%;
    outline: none;
    border: none;
    padding: 5px 10px;
    color: white;
    background-color: ${({ colors }) => colors.color.secondary};
    border-radius: 5px;
  }
  .seller-btn {
    border: none;
    display: block;
    outline: none;
    background-color: ${({ colors }) => colors.color.secondary};
    color: white;
    padding: 10px 0;
    width: 100%;
    border-radius: 5px;
    margin: 15px 0;
  }
  .contact-btn {
    border: none;
    display: block;
    outline: none;
    border: 1px solid ${({ colors }) => colors.color.secondary};
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .send-offer-btn {
    padding: 8px 20px;
    background-color: ${({ colors }) => colors.color.secondary};
    border: none;
    color: ${({ colors }) => colors.color.white};
    border-radius: 5px;
  }
  .submit-job-btn {
    padding: 7px 10px;
    background-color: ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    width: 115px;
  }
  .profile-contact-btn {
    width: 100%;
    text-align: center;
    padding: 10px;
    color: ${({ colors }) => colors.color.white};
    background-color: ${({ colors }) => colors.color.secondary};
    border: none;
    border-radius: 5px;
    font-size: 18px;
  }
  .cancel-btn {
    background-color: ${({ colors }) => colors.color.white};
    color: ${({ colors }) => colors.color.black};
    cursor: pointer;
    padding: 9px 10px;
    border-radius: 5px;
    border: 1px solid ${({ colors }) => colors.color.secondary};
  }
  .continue {
    background-color: ${({ colors }) => colors.color.secondary};
    padding: 8px 3px;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    color: #fff;
    margin-top: 15px;
    width: 100%;
  }
  .save-changes-btn {
    padding: 8px 20px;
    border: none;
    background-color: ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
    border-radius: 5px;
    margin: 30px 0;
  }
  .go-online {
    background-color: ${({ colors }) => colors.color.secondary};
    border: 1px solid ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
    padding: 5px 10px;
    border-radius: 5px;
  }
  .go-offline {
    border: 1px solid ${({ colors }) => colors.color.darkGrey};
    padding: 5px 10px;
    border-radius: 5px;
  }
  .add-payment-card-btn {
    padding: 5px 15px;
    border: 1px solid ${({ colors }) => colors.color.secondary};
    background-color: ${({ colors }) => colors.color.white};
    border-radius: 5px;
    min-width: 91.69px;
    margin-left: 10px;
  }
  .accept-btn {
    padding: 2px 10px;
    background-color: ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
    border-radius: 5px;
    border: none;
    font-family: "NN_Regular";
  }
  .complete-btn {
    min-width: 150px;
  }
  .reject-btn {
    padding: 2px 10px;
    background-color: ${({ colors }) => colors.color.red};
    color: ${({ colors }) => colors.color.white};
    border-radius: 5px;
    border: none;
    font-family: "NN_Regular";
    min-width: 65px;
  }
  .reject-order-btn {
    min-width: 77px;
  }
  .withdraw-btn {
    width: 100%;
    border: none;
    padding: 3px 5px;
    border-radius: 5px;
    color: ${({ colors }) => colors.color.white};
    background-color: ${({ colors }) => colors.color.secondary};
  }
  .withdraw {
    min-width: 94px;
  }
  .withdraw:disabled,
  .withdraw[disabled] {
    background-color: ${({ colors }) => colors.color.darkGrey};
    color: ${({ colors }) => colors.color.white};
    opacity: 0.5;
  }
  .resume-btn:disabled,
  .resume-btn[disabled] {
    background-color: ${({ colors }) => colors.color.darkGrey};
    color: ${({ colors }) => colors.color.white};
    opacity: 0.5;
  }
  .add-bank-btn {
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    color: ${({ colors }) => colors.color.white};
    background-color: ${({ colors }) => colors.color.secondary};
    min-width: 182px;
  }
`;
