import React, { useEffect } from "react";
import { Styles } from "./gigDetail";
import { colors } from "../../../utils/Colors";
import Footer from "../../../components/common/Footer";
import Header from "../../../components/common/Header";
import UserInfo, {
  UserInfoPlaceholder,
} from "../../../components/Gigs/UserInfo";
import GigInfo, { GigInfoPlaceholder } from "../../../components/Gigs/GigInfo";
import Sellerinfo from "../../../components/Gigs/SellerInfo";
import GigDetailCarousel from "../../../components/Gigs/GigDetailCarousel";

import { BsChevronRight } from "react-icons/bs";
import { useLocation, useParams } from "react-router-dom";
import { FcAdvertising } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { getGigDetail } from "../../../store/thunks/gigsThunk";
import GigReviews from "./GigReviews";
import GigAppleMap from "./GigAppleMap";
/**
 * @typedef {import("../../../types/server").GigDetailData} GigDetailData
 */
const GigDetail = () => {
  const { state: initalData } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const { gigDetail, loading: gigDetailLoading } = useSelector(
    (state) => state.gigs
  );
  const gigId = params.gigId;
  /** @type {GigDetailData | undefined} */
  const data = gigDetail || initalData;
  const initalLoading = initalData ? false : gigDetailLoading;
  console.log(data);

  useEffect(() => {
    dispatch(getGigDetail({ gigId: gigId }));
  }, [dispatch, gigId]);

  return (
    <Styles colors={colors}>
      <Header />
      <div className="container gig-detail">
        <div className="row">
          <div className="col col-12 col-lg-7">
            <div className="d-flex gap-2 align-items-center text-muted text-sm mb-2">
              {initalLoading ? (
                <>
                  <p className="w-25">
                    <span class="placeholder placeholder-grey w-100"></span>
                  </p>
                  <BsChevronRight size={8} />
                  <p className="w-25">
                    <span class="placeholder placeholder-grey w-100"></span>
                  </p>
                </>
              ) : (
                <>
                  <p className="text-capitalize">{data.category?.name}</p>
                  <BsChevronRight size={8} />
                  <p className="text-capitalize">{data.subcategory?.name}</p>
                </>
              )}
            </div>
            <div className="gig-title">
              {initalLoading ? (
                <h2 className="placeholder-glow w-75" style={{ height: 24 }}>
                  <span class="placeholder placeholder-grey w-100"></span>
                </h2>
              ) : (
                <h2>{data.title}</h2>
              )}
            </div>
            <div className="main-info">
              {initalLoading ? (
                <UserInfoPlaceholder />
              ) : (
                <UserInfo {...data.createdBy} />
              )}
              <hr />
              <div className="d-flex align-items-center w-90">
                {initalLoading ? (
                  <p className="w-25">
                    <span class="placeholder placeholder-grey w-100"></span>
                  </p>
                ) : (
                  <>
                    <div>
                      <FcAdvertising className="speaker-icon" />
                    </div>
                    <p className="text-capitalize">{data.workType}</p>
                  </>
                )}
              </div>
              {initalLoading ? (
                <GigInfoPlaceholder />
              ) : (
                <GigInfo
                  priceFrom={data.priceFrom}
                  priceTo={data.priceTo}
                  workDaysFrom={data.workDaysFrom}
                  workDaysTo={data.workDaysTo}
                  requirements={data.requirements}
                  createdBy={data.createdBy}
                />
              )}
            </div>
          </div>
          <div className="col col-12 col-lg-5">
            <div className="gallery">
              {initalLoading ? null : (
                <GigDetailCarousel photo={data.attachments} />
              )}
            </div>
          </div>
        </div>
        <div className="row mt-md-3 mt-lg-4">
          <div className="col col-12 col-md-5 col-lg-5">
            <div className="about-section">
              {initalLoading ? (
                <div>
                  <h3 className="placeholder-glow w-50" style={{ height: 24 }}>
                    <span className="placeholder placeholder-grey w-100"></span>
                  </h3>
                  <p aria-hidden="true" className="placeholder-glow w-100">
                    <span class="placeholder placeholder-grey w-100 col-6"></span>
                  </p>
                </div>
              ) : (
                <div>
                  <h3>
                    About This <span>Gig</span>
                  </h3>
                  <p>{data.description}</p>
                </div>
              )}
            </div>
          </div>
          <div className="col col-12 col-md-7 col-lg-7">
            {initalLoading ? (
              <div className="placeholder-glow w-100" style={{ height: 400 }}>
                <span class="placeholder placeholder-grey w-100 h-100"></span>
              </div>
            ) : (
              <GigAppleMap {...data} />
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            {initalLoading ? null : <Sellerinfo {...data.createdBy} />}
          </div>
          <GigReviews gigId={gigId} className="col-12 my-5" />
        </div>
      </div>
      <Footer />
    </Styles>
  );
};

export default GigDetail;
