import React from "react";

import { Styles } from "./inputImage1";
import { colors } from "../../../utils/Colors";

import { TiPlus } from "react-icons/ti";

const InputImage1 = ({ text }) => {
  return (
    <Styles colors={colors}>
      <div className="add-box">
        <div className="add-box-content">
          <div className="plus">
            <TiPlus className="plus-icon" />
          </div>
          {text ? <p>{text}</p> : null}
        </div>
      </div>
    </Styles>
  );
};
export default InputImage1;
