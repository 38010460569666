import { createSlice } from "@reduxjs/toolkit";
import { getReviews,createReviewFromBuyer,createReviewFromSeller } from "../thunks";


const handlePending = (key) => (state) => {
  state[key].loading = true;
};
const handleRejected = (key) => (state, action) => {
  state[key].loading = false;
  state[key].error = action.payload;
};
const handleFulfilled = (key) => (state, action) => {
  state[key].loading = false;
  state[key].data = action.payload;
};

const initialState = {
  getReviews: {
    data:undefined,
    loading: true,
    error: "",  
  },
  createReviewFromBuyer: {
    data:undefined,
    loading: false,
    error: "",  
  },
  createReviewFromSeller: {
    data:undefined,
    loading: false,
    error: "",  
  },

};

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviews.pending, handlePending("getReviews"))
      .addCase(getReviews.fulfilled, handleFulfilled("getReviews"))
      .addCase(getReviews.rejected, handleRejected("getReviews"))
      .addCase(createReviewFromSeller.pending, handlePending("createReviewFromSeller"))
      .addCase(createReviewFromSeller.fulfilled, handleFulfilled("createReviewFromSeller"))
      .addCase(createReviewFromSeller.rejected, handleRejected("createReviewFromSeller"))
      .addCase(createReviewFromBuyer.pending, handlePending("createReviewFromBuyer"))
      .addCase(createReviewFromBuyer.fulfilled, handleFulfilled("createReviewFromBuyer"))
      .addCase(createReviewFromBuyer.rejected, handleRejected("createReviewFromBuyer"));

  },
});

export default reviewsSlice.reducer;
