import React, {  Suspense,  } from "react";

import { Styles } from "./manageJobs";
import { colors } from "../../utils/Colors";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import PausedJobs from "../../components/Jobs/PausedJobs";

import { useDispatch, useSelector } from "react-redux";
import { setCurrentSection } from "../../store/slices/jobsSlice";
import ActiveJobs from "../../components/Jobs/ActiveJobs";


const ManageJobs = () => {
  const dispatch = useDispatch();
  const { currentSection } = useSelector(
    (state) => state.jobs
  );

  return (
    <Styles colors={colors}>
      <Header />
      <section className="container manage-orders">
        <h2>
          Manage <span className="falcon-clr">Jobs</span>
        </h2>
        <div className="status-bar">
          <div
            className={
              currentSection === 1
                ? "priority-order status-bar-sphere"
                : currentSection === 2
                ? "active-order status-bar-sphere"
                : currentSection === 3
                ? "pause-order status-bar-sphere"
                : currentSection === 4
                ? "pending-order status-bar-sphere"
                : "unapprove-order status-bar-sphere"
            }
          ></div>
        </div>
        <ul className="manage-status-list">
          <li
            className="manage-status"
            onClick={() => dispatch(setCurrentSection(2))}
          >
            ACTIVE
          </li>
          <li
            className="manage-status"
            onClick={() => dispatch(setCurrentSection(3))}
          >
            PAUSE
          </li>
        </ul>
        {currentSection === 2 ? (
          <ActiveJobs />
        ) : (
          currentSection === 3 && (
            <Suspense fallback={<div>Loading...</div>}>
              <PausedJobs />
            </Suspense>
          )
        )}
      </section>
      <Footer />
    </Styles>
  );
};

export default ManageJobs;
