import React from "react";

import { AiTwotoneStar } from "react-icons/ai";

const AboutSeller = () => {
  return (
    <div className="about-seller p-3">
      <div className="d-flex justify-content-center">
        <div>
          <div className=" avatar">
            <img alt = {"profilePhoto"} src="https://fiverr-res.cloudinary.com/t_profile_original,q_auto,f_auto/attachments/profile/photo/1d943221ab48c8a0be3f0c6312d3a1f7-45459661627970312924/JPEG_20210803_115830_2365722574100175430.jpg" />
          </div>
          <h4>Quoterze</h4>
          <div
            className="d-flex align-items-center justify-content-center gap-1"
            style={{ marginRight: "10px" }}
          >
            <AiTwotoneStar className="star-icon" />
            <p style={{ color: "gold", fontSize: "20px" }}>4.9</p>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div className="d-flex justify-content-between mb-2">
          <h6>Average response rate</h6>
          <div
            className="d-flex align-items-center justify-content-between gap-2"
            style={{ width: "120px" }}
          >
            <div className="progress" style={{ width: "80px" }}>
              <div
                className="progress-bar bg-info"
                role="progressbar"
                style={{ width: "100%" }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p style={{ width: "50px" }}>100%</p>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <h6>Inbox response time</h6>
          <div>
            <p>4 hrs</p>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <h6>Order response rate</h6>
          <div
            className="d-flex align-items-center justify-content-between gap-2"
            style={{ width: "120px" }}
          >
            <div className="progress" style={{ width: "80px" }}>
              <div
                className="progress-bar bg-info"
                role="progressbar"
                style={{ width: "100%" }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p style={{ width: "50px" }}>100%</p>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <h6>Delivered on time</h6>
          <div
            className="d-flex align-items-center justify-content-between gap-2"
            style={{ width: "120px" }}
          >
            <div className="progress" style={{ width: "80px" }}>
              <div
                className="progress-bar bg-danger"
                role="progressbar"
                style={{ width: "80%" }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p style={{ width: "50px" }}>80%</p>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <h6>Order completion</h6>
          <div
            className="d-flex align-items-center justify-content-between gap-2"
            style={{ width: "120px" }}
          >
            <div className="progress" style={{ width: "80px" }}>
              <div
                className="progress-bar bg-danger"
                role="progressbar"
                style={{ width: "60%" }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p style={{ width: "50px" }}>60%</p>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-2 grey-clr">
          <h6>Earned in November</h6>
          <p style={{ width: "40px" }}>$ 0</p>
        </div>
      </div>
    </div>
  );
};

export default AboutSeller;
