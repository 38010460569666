import React from "react";

import styled from "styled-components";

import { AiTwotoneStar } from "react-icons/ai";
import { useField } from "formik";
import { colors } from "../../utils/Colors";

/**
 * @typedef RatingFieldProps
 * @property {string} name
 */
const RatingField = (props) => {
  const [field, , helpers] = useField(props);
  const currentRating = field.value || 0;
  const maxRating = 5;
  return (
    <RatingFieldStyled colors={colors} className="mb-0 d-flex gap-4">
      <label>{"Rating "}</label>
      <div className = "d-flex align-items-center">
        {[...Array(maxRating)].map((_, index) => {
          const ratingValue = index + 1;
          return (
            <AiTwotoneStar
              style={{ fill: ratingValue <= currentRating ? "gold" : "gray" }}
              className="star-icon"
              key={index}
              onClick={() => helpers.setValue(ratingValue)}
            />
          );
        })}
      </div>
    </RatingFieldStyled>
  );
};

const RatingFieldStyled = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 5px;
  }
  .star-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    
  }
  .star-icon:hover {
    fill: gold !important;
  }
`;

export default RatingField;
