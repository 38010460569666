import React, { useState } from "react";

import { Styles } from "./gigCreation";
import { colors } from "../../../utils/Colors";
import Header from "../../../components/common/Header";
import Footer from "../../../components/common/Footer";
import { createGig } from "../../../store/thunks/gigsThunk";
import UnderConstruct from "../../../assets/images/gig-creation.svg";
import ModalLocation from "../../../components/Seller/ModalLocation";
import GigCreationForm from "../../../components/Seller/GigCreationForm";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const GigCreation = () => {
  const [formNo, setFormNo] = useState(1);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleForm = (data) => {
    setValues({ ...values, ...data });
    setFormNo(formNo <= 4 ? formNo + 1 : formNo);
  };

  const handleSubmit = (data) => {
    dispatch(createGig({ data: { ...values, ...data }, navigate }));
  };
  return (
    <Styles colors={colors}>
      <Header />
      <div className="gig-creation">
        <div className="gig-creation-data">
          <GigCreationForm
            handleSubmit={handleSubmit}
            handleForm={handleForm}
            formNo={formNo}
            setFormNo={setFormNo}
          />
        </div>
        <div className="gig-creation-img">
          <img src={UnderConstruct} alt="under-construction" />
        </div>
      </div>
      <ModalLocation />
      <Footer />
    </Styles>
  );
};

export default GigCreation;
