import React from "react";

import { Styles } from "./categories";
import { colors } from "../../../utils/Colors";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { BsFillCaretDownFill } from "react-icons/bs";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useSelector } from "react-redux";

function Categories() {
  const navigate= useNavigate()
  const { categories, loading } = useSelector((state) => state.categories);

  if (loading) {
    return <CategoriesPlaceholder />;
  }

  return (
    <Styles colors={colors} className="categories-slider-ref swiper-slider-overrides">
      <Swiper
        slidesPerView={"auto"}
        navigation={true}
        modules={[Navigation]}
        spaceBetween={16}
        style={{padding:"0px 40px"}}
      >
        {categories.map((category, index) => {
          return (
            <SwiperSlide style={{ maxWidth: "max-content" }} key={category._id}>
              <UncontrolledDropdown>
                <DropdownToggle
                  className="d-flex pt-2 pb-2 justify-content-center align-items-center w-100"
                  data-toggle="dropdown"
                  tag="span"
                >
                  <p className="dropdown-trigger text-capitalize grey-clr d-flex justify-content-center gap-5 align-items-center px-3">
                    {category.name}
                    <BsFillCaretDownFill
                      style={{
                        width: "15px",
                        height: "15px",
                        color: colors.color.secondary,
                        marginTop: "3px",
                        opacity: ".6",
                      }}
                    />
                  </p>
                </DropdownToggle>
                <DropdownMenu
                  className="p-0"
                  style={{ minWidth: "280px" }}
                  container=".categories-slider-ref"
                >
                  {category.subcategories.map((subcategory, index) => {
                    return (
                      <DropdownItem
                        
                        style={{ color: "GrayText", weight: "bold" }}
                        key={subcategory._id}
                        className="dropdown-item w-100 p-2 text-capitalize"
                        onClick = {() => {
                          navigate(`/gigs/${category.name}/${subcategory.name}`)
                        }}
                      >
                        {subcategory.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Styles>
  );
}

const CategoriesPlaceholder = () => {
  return (
    <div
      className="d-flex align-items-center ps-2 pe-2 gap-3"
      style={{ height: "56.9px" }}
    >
      <div className={`d-flex gap-2`}>
        <div
          className="placeholder-glow rounded"
          style={{ height: 40, width: 280 }}
        >
          <span className="placeholder placeholder-grey w-100 h-100 rounded"></span>
        </div>
      </div>
      <div className={`d-flex gap-2`}>
        <div
          className="placeholder-glow rounded"
          style={{ height: 40, width: 280 }}
        >
          <span className="placeholder placeholder-grey w-100 h-100 rounded"></span>
        </div>
      </div>
      <div className={`d-flex gap-2`}>
        <div
          className="placeholder-glow rounded"
          style={{ height: 40, width: 280 }}
        >
          <span className="placeholder placeholder-grey w-100 h-100 rounded"></span>
        </div>
      </div>
      <div className={`d-flex gap-2`}>
        <div
          className="placeholder-glow rounded"
          style={{ height: 40, width: 280 }}
        >
          <span className="placeholder placeholder-grey w-100 h-100 rounded"></span>
        </div>
      </div>
      <div className={`d-flex gap-2`}>
        <div
          className="placeholder-glow rounded"
          style={{ height: 40, width: 280 }}
        >
          <span className="placeholder placeholder-grey w-100 h-100 rounded"></span>
        </div>
      </div>
    </div>
  );
};
export default Categories;
