import { useCallback, useEffect } from "react";
import { useQBContext } from "../../context/quickblox-provider";
import { useDialogMessagesState } from "../queries/use-dialog-messages";
import { useAuthContext } from "../../../authentication";

/**
 *
 * @typedef {object} Message
 * @property {string} body
 * @property {null | number} delay
 * @property {string} dialog_id
 * @property {object} extension
 * @property {number} extension.date_sent
 * @property {string} extension.message_id
 * @property {number} extension.dialog_id
 * @property {number} extension.save_to_history
 * @property {string} id
 * @property {number} markable
 * @property {null | number} recipient_id
 * @property {string} type
 */

export const useDeliveredMessageListener = () => {
  const { user } = useAuthContext();
  const { QBApp } = useQBContext();
  const chatsMessagesState = useDialogMessagesState();

  const onDeliveredStatusListener = useCallback(
    (messageId, dialogId, userId) => {
      console.log("delivered To", messageId, dialogId, userId);

      // Update the message delivered status in the list of all loaded messages
      chatsMessagesState.setStateByParams({ dialogId }, (prevState) => {
        if (!prevState) return prevState;
        return {
          ...prevState,
          data: prevState.data?.map((page) => {
            return page.map((message) =>
              message._id === messageId
                ? {
                    ...message,
                    delivered_ids: [...message.delivered_ids, userId],
                  }
                : message
            );
          }),
        };
      });
    },
    [chatsMessagesState]
  );

  useEffect(() => {
    if (!user) return;
    QBApp.chat.onDeliveredStatusListener = (messageId, dialogId, userId) =>
      onDeliveredStatusListener(messageId, dialogId, userId);
    return () => {
      QBApp.chat.onDeliveredStatusListener = null;
    };
  }, [QBApp.chat, onDeliveredStatusListener, user]);

  return {
    messages: [],
  };
};
