import { createContext, useContext, useState } from "react";
import {
  useDeliveredMessageListener,
  useIncomingMessage,
  useReadMessageListener,
} from "../api";
const QBListenersContext = createContext({
  newMessage: null,
  setNewMessage:() => {}
});

export function QBListenersContextProvider({ children }) {
  const [newMessage, setNewMessage] = useState(null);
  useIncomingMessage({
    onNewMessage: (message) => {
      setNewMessage(message);
    },
  });
  useReadMessageListener();
  useDeliveredMessageListener();
  return (
    <QBListenersContext.Provider value={{ newMessage,setNewMessage }}>
      {children}
    </QBListenersContext.Provider>
  );
}

export function useQBListenersContext() {
  return useContext(QBListenersContext);
}
