import React from "react";
// import { CgSoftwareDownload } from "react-icons/cg";
import styled from "styled-components";
/**
 * @typedef {object} Props
 * @property {string} previewUrl
 */
const AttachmentCard = (/** @type {Props} */ { previewUrl }) => {
  return (
    <AttachmentCardStyle href = {previewUrl} target = "_blank" rel="noreferrer">
      {/* <div>
        <a href={previewUrl} download>
          <CgSoftwareDownload size={20} />
        </a>
      </div> */}
      <img src={previewUrl} alt={previewUrl} />
    </AttachmentCardStyle>
  );
};
const AttachmentCardStyle = styled.a`
  width: 180px;
  height: 200px;
  display:block;
  padding: 0px 12px;
  border-radius: 8px;
  border: 1px solid #74C0FF;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: #EDFBFF;
  cursor:pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  div {
    display: flex;
    justify-content: flex-end;
    padding-top:4px;
    padding-bottom:4px;
  }
  a {
    fontweight: 600;
    text-decoration: none;
    color: #009BF9;
    border-radius: 50%;
    padding: 4px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #009BF9;
      color: white;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius:8px;
    border-top-right-radius:8px;
  }
`;
export default AttachmentCard;
