import styled from "styled-components";
export const JobDetailStyles = styled.div`
  margin: 40px auto;
  min-height: 50vh;
  h3,
  h3 span {
    font-family: "NN_SemiBold";
  }
  .heading {
    font-family: "NN_SemiBold";
  }
  .regular {
    font-family: "NN_Regular";
  }
  .map {
    height: 250px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  .vertical-hr {
    min-height: 100%;
    border-right: 1px solid ${({ colors }) => colors.color.lightGrey};
    margin: 0 20px 12px 20px;
  }

  .offers-section {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    margin-top: 20px;
    cursor: pointer;
  }
  .offers-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    place-items: center;
    grid-gap: 30px;
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-left: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-right: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px 10px;
  }

  .see-more {
    color: ${({ colors }) => colors.color.blue};
    font-size: 12px;
    cursor: pointer;
  }
  .accept-btn {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
  }
  .reject-btn {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: ${({ colors }) => colors.color.danger};
    color: ${({ colors }) => colors.color.white};
    width: 72px;
  }

  .create-offer {
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-left: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-right: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
  }
  .form {
    background-color: ${({ colors }) =>
      colors.color.lightBackground} !important;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    max-width: 440px;
  }
  .select {
    width: 100%;
    max-width: 400px;
  }
  textarea {
    width: 100%;
    max-width: 400px;
    height: 150px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
    outline: none;
    padding: 10px;
  }
  .bid {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    background: ${({ colors }) => colors.color.white} !important;
    border-radius: 5px;
    max-width: 400px;
  }
  .bid input {
    border: none;
    padding: 8px 10px !important;
  }
  .note {
    color: ${({ colors }) => colors.color.darkGrey} !important;
    font-size: 12px;
  }

  @media only screen and (min-width: 587px) {
    .offers-list {
      padding: 30px 40px;
    }
    .create-offer {
      padding-bottom: 40px;
    }
  }

  @media only screen and (min-width: 992px) {
    .job-detail {
      max-width: 1100px !important;
    }
    .offer-content {
      margin-right: 55px;
    }
  }
`;
