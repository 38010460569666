import { createSlice } from "@reduxjs/toolkit";
import { getInvoices } from "../thunks";


const handlePending_ = (key) => (state,action) => {
  state[key].loading = true;
  state[key].error = null;
  state[key].data = undefined;

}
const handleFulfilled_ = (key) => (state,action) => {
  state[key].loading = false;
  state[key].error = null;
  state[key].data = action.payload;
}
const handleRejected_ = (key) => (state,action) => {
  state[key].loading = false;
  state[key].error = action.payload;
}

const initialState = {
  getInvoices: {
    loading: false,
    error: "",
    data: undefined,
  }
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  extraReducers: (builder) => {
    builder
      // Trying to convert everything to the new format
      .addCase(getInvoices.pending, handlePending_("getInvoices"))
      .addCase(getInvoices.fulfilled, handleFulfilled_("getInvoices"))
      .addCase(getInvoices.rejected, handleRejected_("getInvoices"))
  },
});

export default invoiceSlice.reducer;
