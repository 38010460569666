import { useField } from "formik";
import React from "react";

/**
 * @typedef TextareaFieldProps
 * @property {string | undefined} label
 * @property {string} name
 */
const TextareaField = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <div className="d-flex flex-column gap-2">
      {!!(label) && <label>{label}</label>}
      <textarea
        className="form-control"
        placeholder="Leave a comment here"
        style={{ height: "150px" }}
        {...field}
      />
    </div>
  );
};

export default TextareaField;
