import React from "react";
import useImperativeComponent from "../../hooks/common/useImperativeComponent";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../components/common/Button";
import { useDispatch } from "react-redux";
import TextareaField from "../../components/common/TextareaField";
import RatingField from "../../components/common/RatingField";
import { Form, Formik } from "formik";
import { createReviewFromBuyer } from "../../store/thunks";
import { isFulfilled } from "@reduxjs/toolkit";

/**
 * @typedef CreateReviewModalProps
 * @property {string} gigId
 * @property {string} orderId
 */
const useCreateReviewModal = ({ gigId, orderId }) => {
  // const { loading } = useSelector(
  //   (state) => state.reviews.createReviewFromBuyer
  // );
  const dispatch = useDispatch();
  const { componentNode, openComponent } = useImperativeComponent(
    (isOpen, close) => {
      return (
        <Modal isOpen={isOpen} toggle={close}>
          <ModalHeader toggle={close}>Write a Review</ModalHeader>
          <Formik
            initialValues={{
              rating: 0,
              comment: "",
            }}
            onSubmit={async (values) => {
              const action = await dispatch(
                createReviewFromBuyer({
                  ...values,
                  gigId: gigId,
                  orderId: orderId,
                })
              );
              if (isFulfilled(action)) {
                close();
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <ModalBody className="d-flex flex-column gap-4">
                  <RatingField name="rating" />
                  <TextareaField name="comment" label={<p>{"Comment"}</p>} />
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="go-offline px-3 py-1"
                    onClick={close}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="reject-btn px-3 py-1 reject-order-btn"
                    type="submit"
                  >
                    {isSubmitting ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      );
    }
  );
  return {
    reviewModal: componentNode,
    openReviewModal: openComponent,
  };
};

export default useCreateReviewModal;
