import React, { useEffect } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { createJobOffer } from "../../store/thunks/offersThunk";
import Error from "../common/Error";
import InputField from "../common/InputField";
import Select from "react-select";
import Button from "../common/Button";
import { isFulfilled } from "@reduxjs/toolkit";
import RoutesPath from "../../utils/Paths";
import { useAuthContext } from "../../features/authentication";
import { getMyGigs } from "../../store/thunks/gigsThunk";
import useGetConfigurations from "../../hooks/api/useGetConfigurations";

const SendOfferSchema = Yup.object({
  coverLetter: Yup.string().required("Cover Letter is Required"),
  selectedGig: Yup.object().required("Gig is Required"),
  price: Yup.number().required("Price is Required"),
});

/**
 * @typedef {object} Props
 * @property {string} jobId
 * @property {{budgetMax: number, budgetMin: number}} offer
 */
const CreateOfferForm = (
  /** @type {Props} **/ { jobId, offer: { budgetMax, budgetMin } }
) => {
  const { user } = useAuthContext();
  const userId = user._id;
  const { data: gigs, loading } = useSelector((state) => state.gigs.getMyGigs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const configurations =
    useGetConfigurations();
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      coverLetter: "",
      selectedGig: "",
      price: "",
      attachments: [],
    },
    validationSchema: SendOfferSchema,
    onSubmit: async (values) => {
      const action = await dispatch(
        createJobOffer({
          attachments: values.attachments,
          coverLetter: values.coverLetter,
          selectedGig: values.selectedGig.id,
          price: values.price,
          jobId: jobId,
          uid: userId,
        })
      );
      if (isFulfilled(action)) {
        navigate(RoutesPath.sentOffers);
      }
    },
  });

  useEffect(() => {
    dispatch(getMyGigs());
  }, [dispatch]);
  return (
    <div>
      <div className="offers-section">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Send Offer</h5>
          <FiChevronDown />
        </div>
      </div>
      <div className="create-offer d-flex justify-content-center">
        <form className="form" onSubmit={handleSubmit}>
          <h5 className="text-center heading">Create Offer</h5>
          <div className="mb-3">
            <label className="d-block regular" htmlFor="gig">
              Select Gig
            </label>
            <Select
              className="select"
              id="selectedGig"
              name="selectedGig"
              options={gigs?.map((gig) => ({
                label: gig.title,
                id: gig._id,
              }))}
              isLoading={loading}
              getOptionLabel={(option) => (
                <div className="text-capitalize">{option.label}</div>
              )}
              getOptionValue={(option) => option.id}
              onChange={(selectedOption) =>
                setFieldValue("selectedGig", selectedOption)
              }
              value={values.selectedGig}
            />
            {errors.selectedGig && touched.selectedGig && (
              <Error text={errors.selectedGig} />
            )}
          </div>
          <div className="mb-3">
            <label className="d-block regular" htmlFor="coverLetter">
              {"Cover Letter"}
            </label>
            <textarea
              placeholder="I can..."
              name="coverLetter"
              id="coverLetter"
              value={values.coverLetter}
              onChange={handleChange}
              autoComplete="off"
            />
            {errors.coverLetter && touched.coverLetter && (
              <Error text={errors.coverLetter} />
            )}
          </div>
          <div className="mb-3">
            <label className="d-block regular" htmlFor="">
              Bid your Price{" "}
              <span className="note">
                (clients budget is ${budgetMin} - ${budgetMax})
              </span>
            </label>
            <div className="bid d-flex align-items-center px-2 py-1 ">
              <p className="heading" style={{ minWidth: "25px" }}>
                $ -{" "}
              </p>
              <InputField
                className="budget-input"
                type="number"
                name="price"
                id="price"
                value={values.price}
                onChange={handleChange}
                autoComplete="off"
                onBlur={handleBlur}
              />
            </div>
            {values?.price && (
              <p className="text-sm mt-1 mb-2">
                {"Amount you will recieve after service fees - "}
                <strong>
                  {configurations.loading
                    ? "Loading..."
                    : `$${(
                        values.price -
                        ((values.price * configurations.data.sellerTax) / 100)
                      ).toFixed(2)}`}
                </strong>
              </p>
            )}
            {errors.price && touched.price && <Error text={errors.price} />}
          </div>
          {/* Apparently attachments are not allowed for this creating offer... Synced with Mobile-App */}
          {/* <div className="mb-4">
            <label className="d-block regular mb-1" htmlFor="">
              Attach Files <span className="note">(optional)</span>
            </label>
            <input
              type="file"
              id="attachments"
              name="attachments"
              onChange={handleChange}
              multiple
            />
          </div> */}
          <div className="d-flex justify-content-center">
            <Button type="submit" className="accept-btn" disabled={loading}>
              {isSubmitting ? (
                <div
                  className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              ) : (
                "Send"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOfferForm;
