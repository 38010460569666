const Content = () => (
  <>
    <h1>Terms of Service</h1>
    <p>Updated on January 31, 2024</p>
    <p>
      It is important to understand that you should carefully read and review
      the terms of service presented here before using the platform, whether
      it's the website or app.
    </p>
    <p>
      By accepting the terms of service of GigFalcon ("the Platform"), you agree
      to comply
    </p>
    <p>
      with and be bound by the following terms and conditions. You also certify
      that you are at least 18 years of age and legally allowed to hire someone
      for the required services
    </p>
    <p>
      If you do not agree with these terms, you should refrain from using any of
      the services provided through the website, app, or any other platform.
      It's essential to review and understand these terms before proceeding.
    </p>
    <br></br>
    <h4>Description of Service</h4>
    <p>
      GigFalcon is a technology platform that connects buyers or companies with
      sellers or companies providing professional services. These services may
      include but are not limited to handyman work, plumbing, electrical
      services, mechanical services, IT support, tutoring, commercial
      repairs/maintenances and more.
    </p>
    <br></br>
    <h4>Liability</h4>
    <p>
      GigFalcon will not be held liable for any damages, losses, or disputes
      arising from{" "}
      <span>
        negligence or incompetency of any individual or company hired through
        the platform.
      </span>
    </p>
    <p />
    <p>
      Users of the platform agree to comply with all laws and regulations
      governed by the State of Oklahoma. Users are strictly prohibited from
      engaging in any trades or transactions that are not legal according to the
      laws and regulations of their jurisdiction. This includes but is not
      limited to the buying, selling, or exchange of items or services that are
      illegal or prohibited by law.
    </p>
    <br></br>
    <h4>Background Checks</h4>
    <p>
      GigFalcon does not perform background or criminal checks on anyone using
      the platform. It is the sole responsibility of the buyer to conduct their
      own due diligence and vetting procedures before hiring any service
      provider.
    </p>
    <br></br>
    <h4>Funds Availability</h4>
    <p>
      Payment will be received from the buyer once order has been created and
      agreed by both parties and will stay with GigFalcon. The release of funds
      will be initiated upon receiving final confirmation from the buyer of the
      work completed and accepted, indicating satisfaction with the goods or
      services provided. After the completion of a
    </p>
    <p />
    <p />
    <p>
      work order, funds will be placed on hold for a period of 7-14 days with
      GigFalcon, dependent on the account health and status of the Seller
      account. This hold period is designed to allow both parties ample time to
      address and resolve any concerns or issues that may arise
    </p>
    <p>
      If an order is canceled (for any reason), the funds paid will be either
      returned to the Buyer’s account or on Buyer’s account Credit Balance.
    </p>
    <br></br>
    <h4>Account Deactivation</h4>
    <p>
      GigFalcon reserves the right to deactivate any user account at its
      discretion, without prior notice, should there be a violation of the terms
      and conditions or any fraudulent activity detected.
    </p>
    <br></br>
    <h4>Scope of Responsibility</h4>
    <p>
      All users acknowledge that GigFalcon is a technology company and does not
      perform the actual services. GigFalcon will not be responsible for the
      quality or delivery of services provided by the individuals or companies
      listed on the platform.
    </p>
    <br></br>
    <h4>Permits and Licenses</h4>
    <p>
      Buyers are responsible for ensuring that any required permits, licenses,
      or legal requirements are obtained before the commencement of any work.
      GigFalcon will not be responsible for any legal fees, fines, or
      liabilities incurred due to the absence of necessary permits or licenses.
    </p>
    <br></br>
    <h4>Proprietary Rights</h4>
    <p>
      All content and materials provided on our platform are the proprietary
      property of GigFalcon and are protected by intellectual property laws.
      Users are prohibited from using, copying, distributing, or creating
      derivative works from any content or materials on the platform without
      prior written consent from GigFalcon. This includes but is not limited to
      trademarks, logos, text, images, videos, and any other proprietary
      information. Unauthorized use of proprietary materials may result in legal
      action.
    </p>
    <br></br>
    <h4>Intellectual Property Rights</h4>
    <p>
      All intellectual property rights related to the services and content
      provided on our platform, including but not limited to trademarks,
      copyrights, patents, and trade secrets, are the property of GigFalcon or
      its licensors. Users are prohibited from using, copying, modifying,
      distributing, or creating derivative works from any of the intellectual
      property rights belonging to GigFalcon without prior written consent. Any
      unauthorized use of intellectual property may result in legal action.
    </p>
    <br></br>
    <h4>Disclaimer of Warranties</h4>
    <p />
    <p />
    <p>
      Users acknowledge that GigFalcon does not offer warranties or insurance
      for the services provided by independent contractors on the platform. The
      quality and outcome of the work performed by service providers are not
      guaranteed by GigFalcon. Users are responsible for vetting and selecting
      service providers and should secure any necessary insurance or warranties
      directly from the service provider. GigFalcon disclaims any liability for
      the quality, outcome, or performance of the services rendered by service
      providers.
    </p>
    <p>
      By using the platform, all users agree to indemnify and hold GigFalcon
      harmless from any claims, damages, liabilities, and expenses arising from
      their use of the platform and engagement with service providers.
    </p>
    <p>
      Your use of the site, its content, and any services or items obtained
      through the website is at your own risk. The site, its content, and any
      services or items obtained through the website are provided on an 'as-is'
      and 'as-available' basis, without any warranties of any kind, either
      express or implied. Neither GigFalcon nor any person associated with
      GigFalcon makes any warranty or representation regarding the completeness,
      security, reliability, quality, accuracy, or availability of the site.
    </p>
    <p>
      The foregoing does not affect any warranties that cannot be excluded or
      limited under applicable law.
    </p>
    <br></br>
    <h4>Disputes and Cancellations</h4>
    <p>
      We encourage our Buyers and Sellers to try and settle conflicts amongst
      themselves. If for any reason this fails users can contact GigFalcon at
      support@gigfalcon.com Customer Support department for assistance.
    </p>
    <br></br>
    <h4>Limitation on Liability</h4>
    <p />
    <p>
      In no event will GigFalcon, its affiliates or their licensors, service
      providers, employees, agents, officers or directors be liable for damages
      of any kind, under any legal theory, arising out of or in connection with
      your use, or inability to use, the site, any websites linked to it, any
      content on the website or such other websites or any services or items
      obtained through the site or such other websites, including any direct,
      indirect, special, incidental, consequential or punitive damages,
      including but not limited to, personal injury, pain and suffering,
      emotional distress, loss of revenue, loss of profits, loss of business or
      anticipated savings, loss of use, loss of goodwill, loss of data, and
      whether caused by tort (including negligence), breach of contract or
      otherwise, even if foreseeable.
    </p>
    <p>
      In accepting liability as the limited authorized payment collection agent
      of seller, GigFalcon and its affiliates assume no liability for any acts
      or omissions of seller.
    </p>
    <p>
      The foregoing does not affect any liability which cannot be excluded or
      limited under applicable law.
    </p>
    <p>
      The term “affiliate” referred to herein, is an entity that, directly or
      indirectly, controls, or is under the control of, or is under common
      control with GigFalcon, where control means having more than fifty percent
      (50%) voting stock or other ownership interest or the majority of voting
      rights of such entity.
    </p>
  </>
);
export default Content;
