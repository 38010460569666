import React from "react";

/**
 * @typedef {object} Props
 * @property {number | undefined} size
 * @property {string | undefined} className
 */
const RectanglePlaceholder = (/** @type {Props} */ { size = 50, className= "" }) => {
  return (
    <div className={`d-flex gap-2 ${className}`}>
      <div
        className="placeholder-glow rounded"
        style={{ height: size, width: size }}
      >
        <span className="placeholder placeholder-grey w-100 h-100 rounded"></span>
      </div>
    </div>
  );
};

export default RectanglePlaceholder;
