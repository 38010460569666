import styled from "styled-components";

export const Styles = styled.div`
  .seller-section h3 {
    font-size: 24px;
  }
  .seller-section h3 span {
    color: ${({ colors }) => colors.color.secondary};
  }

  .seller-details {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 10px;
    width: 100%;
    margin-top: 20px;
  }
  .seller-desrciption {
    font-size: 14px;
  }

  @media only screen and (min-width: 768px) {
    .seller-details {
      width: 500px;
    }
    .seller-info {
      width: 40%;
    }
  }
`;
