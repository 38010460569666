import { useCallback, useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";

export const useInfiniteQueryReduxState = ({ actions, reducerName }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => {
    return state[reducerName];
  });
  console.log(state);
  const getStateById = useCallback(
    (id) => {
      const stateId = typeof id === "string" ? id : JSON.stringify(id);
      return state[stateId];
    },
    [state]
  );

  const setStateById = useCallback(
    (id, setState) => {
      const stateId = typeof id === "string" ? id : JSON.stringify(id);
      dispatch(
        actions.setStateForId({
          id: stateId,
          setState,
        })
      );
    },
    [dispatch, actions]
  );

  return { state, getStateById, setStateById };
};
export const useInfiniteQueryRedux = ({
  id,
  fetch,
  initalPageParams,
  getNextPageParams,
  actions,
  reducerName,
}) => {
  const uniqueId = useMemo(() => JSON.stringify(id), [id]);
  // const getNextPageParamsRef = useRef(getNextPageParams);
  const { state, setStateById } = useInfiniteQueryReduxState({
    actions,
    reducerName,
  });
  // This is making it calls only for first time
  /**
   * @type {{status:"loading"|"success"|"error",isFetchingNextPage:boolean,error?:any,data?:any}}
   */
  const stateWithDefault = state[uniqueId] || {
    data: undefined,
    pageParams: undefined,
    status: "loading",
    isFetchingNextPage: false,
    error: undefined,
  };

  const setState = useCallback(
    (setState) => {
      const id = JSON.parse(uniqueId);
      setStateById(id, setState);
    },
    [uniqueId, setStateById]
  );

  const fetchByPageNo = useCallback(
    async (pageParams) => {
      const id = JSON.parse(uniqueId);
      const response = await fetch(id, pageParams);
      return {
        data: response,
        pageParams,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uniqueId]
  );

  const fetchNextPage = async () => {
    const prevState = state[uniqueId] || {
      data: undefined,
      pageParams: undefined,
      status: "loading",
      isFetchingNextPage: false,
      error: undefined,
    };
    if (prevState.status === "loading") return;
    if (prevState.data.length === 0) return;
    if (prevState.pageParams.length === 0) return;
    const nextPageParams = getNextPageParams(
      prevState.data,
      prevState.data[prevState.data.length - 1],
      prevState.pageParams[prevState.pageParams.length - 1]
    );
    if (!nextPageParams) return;
    try {
      setState((state) => ({
        ...state,
        isFetchingNextPage: true,
      }));
      const response = await fetchByPageNo(nextPageParams);
      setState((state) => ({
        ...state,
        data: state.data ? [...state.data, response.data] : [response.data],
        pageParams: state.pageParams
          ? [...state.pageParams, response.pageParams]
          : [response.pageParams],
        error: null,
        isFetchingNextPage: false,
      }));
    } catch (e) {
      setState((state) => ({
        ...state,
        error: e,
        isFetchingNextPage: false,
      }));
    }
  };

  useEffect(() => {
    const initalFetch = async () => {
      try {
        setState((state) => ({
          status: "loading",
          data: undefined,
          error: undefined,
          pageParams: undefined,
          isFetchingNextPage: false,
        }));
        const response = await fetchByPageNo(initalPageParams);
        setState((state) => ({
          status: "success",
          data: [response.data],
          error: undefined,
          pageParams: [response.pageParams],
          isFetchingNextPage: false,
        }));
      } catch (e) {
        console.log(e);
        setState((state) => ({
          status: "error",
          data: undefined,
          error: e,
          pageParams: undefined,
          isFetchingNextPage: false,
        }));
      }
    };
    initalFetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId]);

  return {
    ...stateWithDefault,
    setState,
    fetchNextPage,
  };
};
