import React, { useRef } from "react";

const FileUploadTrigger = React.forwardRef(({ RenderComponent,outerClassName = "", ...props }, ref) => {
  const inputRef = useRef(null);
  return (
    <div className={outerClassName}>
      <RenderComponent
        onClick={() => {
          if (!inputRef.current) return;
          inputRef.current.click();
        }}
      />
      <input
        type="file"
        className="d-none"
        accept="image/*"
        ref={inputEl => {
          inputRef.current = inputEl;
          ref && (ref.current = inputEl);
        }}
        {...props}
      />
    </div>
  );
});

export default FileUploadTrigger;
