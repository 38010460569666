import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useJobsSelector from "../../store/hooks/useJobsSelector";
import { filterJobs, jobsByPaginate } from "../../store/thunks/jobsThunk";
import useJobsSelectorByPagination from "../../store/hooks/useJobsSelectorByPagination";
import SearchFilter from "../../components/common/SearchFilter";
import DropdownFilter from "../../components/common/DropdownFilter";
import RangeFilter from "../../components/common/RangeFilter";
import { VscDebugRestart } from "react-icons/vsc";
import { colors } from "../../utils/Colors";
import JobCard from "../../components/Jobs/JobCard";
import styled from "styled-components";
import GigPlaceholder from "../../components/common/Placeholders/GigPlaceholder";
const SearchParamsKeys = {
  query: "query",
  sortBy: "sortBy",
  workType: "workType",
  minBudgetLimit: "min-budgetLimit",
  maxBudgetLimit: "max-budgetLimit",
};

const JobsContainer = () => {
  const dispatch = useDispatch();
  const scrollViewRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: jobsData,
    loading,
    hasMore,
    page,
    limit,
  } = useJobsSelectorByPagination();

  const handleScroll = useCallback(() => {
    if (!hasMore) {
      return;
    }
    const params = {
      ...{
        searchText: searchParams.get(SearchParamsKeys["query"]),
        sortBy: searchParams.get(SearchParamsKeys["sortBy"]),
        workType: searchParams.get(SearchParamsKeys["workType"]),
        minPrice: searchParams.get(SearchParamsKeys["minBudgetLimit"]),
        maxPrice: searchParams.get(SearchParamsKeys["maxBudgetLimit"]),
      },
      page: page + 1,
      limit,
    };
    if (isMobile) {
      const threshold = document.body.offsetHeight * 0.8;
      if (window.innerHeight + window.scrollY >= threshold && !loading) {
        dispatch(jobsByPaginate(params));
      }
    } else {
      if (scrollViewRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollViewRef.current;
        const threshold = scrollHeight * 0.9;
        if (scrollTop + clientHeight >= threshold && !loading) {
          dispatch(jobsByPaginate(params));
        }
      }
    }
  }, [hasMore, searchParams, page, limit, isMobile, loading, dispatch]);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    if (scrollViewRef.current) {
      scrollViewRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);

      if (scrollViewRef.current) {
        scrollViewRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isMobile, handleScroll]);

  useEffect(() => {
    dispatch(
      jobsByPaginate({
        ...{
          searchText: searchParams.get(SearchParamsKeys["query"]),
          sortBy: searchParams.get(SearchParamsKeys["sortBy"]),
          workType: searchParams.get(SearchParamsKeys["workType"]),
          minPrice: searchParams.get(SearchParamsKeys["minBudgetLimit"]),
          maxPrice: searchParams.get(SearchParamsKeys["maxBudgetLimit"]),
        },
        page: 1,
      })
    );
  }, [searchParams, dispatch]);

  return (
    <div className="list" ref={scrollViewRef}>
      <div
        className="home-dropdown d-flex flex-column gap-2"
        style={{ maxWidth: 540, margin: "16px auto" }}
      >
        <div className="list-header">
          <h2 className="gigs-title">
            Find The{" "}
            <span>
              Jobs
              <br />
            </span>{" "}
            You Need
          </h2>
        </div>
        <div className="d-flex flex-column justify-content-center gap-4">
          <SearchFilter paramId={SearchParamsKeys["query"]} />
          <div className="d-flex gap-2 justify-content-between align-items-center w-100">
            <DropdownFilter
              paramId={SearchParamsKeys["sortBy"]}
              options={SortingOptions}
            >
              {"Sort By"}
            </DropdownFilter>
            <DropdownFilter
              paramId={SearchParamsKeys["workType"]}
              options={WorkingTypeOptions}
            >
              {"Work Type"}
            </DropdownFilter>
            <RangeFilter
              maxParamId={SearchParamsKeys["maxBudgetLimit"]}
              minParamId={SearchParamsKeys["minBudgetLimit"]}
            >
              {"Budget"}
            </RangeFilter>
            <VscDebugRestart
              style={{
                cursor: "pointer",
                width: "25px",
                height: "25px",
                color: colors.color.secondary,
              }}
              onClick={(e) => {
                setSearchParams((prev) => {
                  prev.delete("query");
                  prev.delete("sortBy");
                  prev.delete("workType");
                  prev.delete("budgetLimit");
                  prev.delete("min-budgetLimit");
                  prev.delete("max-budgetLimit");
                  return prev;
                });
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      </div>
      <StyledJobsList>
        {!!jobsData?.length &&
          jobsData?.map((job) => {
            return <JobCard key={job._id} {...job} />;
          })}
        {loading ? (
          <GigPlaceholder placeholders={[1, 2, 3, 4, 5, 6]} />
        ) : (
          !loading &&
          !jobsData?.length && (
            <div
              className="d-flex align-items-center justify-content-center mt-5"
              style={{ color: colors.color.darkGrey }}
            >
              <p>Sorry ... No Jobs Found</p>
            </div>
          )
        )}
      </StyledJobsList>
    </div>
  );
};

const SortingOptions = [
  {
    value: "high-low",
    label: "Price (high to low)",
  },
  {
    value: "low-high",
    label: "Price (low to high)",
  },
];
const WorkingTypeOptions = [
  { value: "on-site", label: "Onsite" },
  { value: "online", label: "Online" },
];
const StyledJobsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0;
`;
export default JobsContainer;
