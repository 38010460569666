import styled from "styled-components";

export const Styles = styled.div`
  .signin {
    padding: 0 15px;
    height: 100vh;
  }
  .person-section {
    display: none;
  }
  form {
    display: flex;
    flex-direction: column;
  }

  p {
    text-align: center;
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .agree {
    font-size: 11px;
    padding: 15px 0;
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
  }
  button {
    padding: 8px 3px;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    color: #fff;
    width: 100%;
  }
  input {
    height: 35px;
    outline: none;
    border: 1.6px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 7px;
    padding: 15px 10px;
    font-size: 12px;
  }
  .github-btn {
    background-color: ${({ colors }) => colors.color.facebook};
  }
  .google-btn {
    background-color: ${({ colors }) => colors.color.google};
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 3px 3px;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
    width: 100%;
    cursor: pointer;
  }
  .google-text {
    color: ${({ colors }) => colors.color.white};
    text-align: center;
    font-size: 17px !important;
    margin-left: -3px;
  }
  .google-icon-wrapper {
    background-color: ${({ colors }) => colors.color.white};
    border-radius: 2px;
    padding: 7px;
  }
  .google-icon {
    width: 25px;
    height: 25px;
  }
  .continue {
    background-color: ${({ colors }) => colors.color.secondary};
  }
  .member {
    color: ${({ colors }) => colors.color.black};
    margin-top: 15px;
  }
  span {
    color: ${({ colors }) => colors.color.secondary};
    cursor: pointer;
  }
  .forget-password {
    text-align: end;
    font-size: 11px !important;
    color: ${({ colors }) => colors.color.google};
    margin: 5px;
    cursor: pointer;
  }
  .forget-password:hover {
    text-decoration: underline;
  }
  h2 {
    font-family: "MS_SemiBold";
  }

  @media only screen and (min-width: 375px) {
    p:last-child {
      font-size: 12.8px;
    }
    button {
      font-size: 15px;
    }
  }
  @media only screen and (min-width: 768px) {
    .signin {
      display: flex;
      padding-right: 20px;
      padding-left: 0;
    }
    .person-section {
      display: block;
      width: 350px;
      flex: 1;
    }
    @keyframes slideInLeft {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
    .person-section img {
      height: 100vh;
      animation: 1s ease-out 0s 1 slideInLeft;
    }
  }
  @media only screen and (min-height: 1024px) {
    .person-section {
      display: none;
    }
  }
  @media only screen and (min-width: 1024px) {
    .signin {
      display: flex;
      padding: 0 50px;
    }
    .person-section {
      display: block;
      flex: 1;
    }
    .person-section img {
      height: 100vh;
    }
  }
`;
