import React from "react";

import Details from "./Details";
import Attachments from "./Attachments";
import Requirements from "./Requirments";
import Description from "./Description";
import { colors } from "../../../utils/Colors";
import styled from "styled-components";

const GigCreationForm = ({
  title = (
    <>
      {" "}
      Create a <span className="falcon-clr">Gig</span>
    </>
  ),
  gigValues,
  handleSubmit,
  handleForm,
  formNo,
  setFormNo,
}) => {
  return (
    <GigFormStyle colors={colors}>
      <h3>{title}</h3>
      <div>
        <div className="page1">
          <div className={formNo === 1 ? "d-block" : "d-none"}>
            <Details
              gigValues={gigValues}
              formNo={formNo}
              setFormNo={setFormNo}
              handleFormData={(data) => handleForm(data)}
            />
          </div>
          <div className={formNo === 2 ? "d-block" : "d-none"}>
            <Description
              gigValues={gigValues}
              formNo={formNo}
              setFormNo={setFormNo}
              handleFormData={(data) => handleForm(data)}
              x
            />
          </div>
          <div className={formNo === 3 ? "d-block" : "d-none"}>
            <Requirements
              gigValues={gigValues}
              formNo={formNo}
              setFormNo={setFormNo}
              handleFormData={(data) => handleForm(data)}
            />
          </div>
          <div className={formNo === 4 ? "d-block" : "d-none"}>
            <Attachments
              gigValues={gigValues}
              formNo={formNo}
              setFormNo={setFormNo}
              handleFormData={(data) => handleSubmit(data)}
            />
          </div>
        </div>
      </div>
    </GigFormStyle>
  );
};

const GigFormStyle = styled.div`
  .page1 {
    background-color: ${({ colors }) => colors.color.lightBackground};
    height: 100%;
    min-height: 600px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
    padding: 10px 20px;
  }
  .page1 label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
  }
  form {
    height: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .gig-title-input {
    width: 100%;
    font-size: 18px;
  }
  .description {
    margin-bottom: 20px;
  }
  textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    border: none;
    outline: none;
    resize: vertical;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
    margin-bottom: 0 !important;
  }
  // .selectors {
  //   // margin-bottom: 20px;
  // }
  .select {
    width: 200px;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .note {
    color: ${({ colors }) => colors.color.darkGrey};
    font-size: 14px;
  }
  .btns {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  .save-btn {
    border: none;
    background-color: ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 80px;
  }
  .location-wrapper {
    margin-bottom: 40px;
  }
  .location {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    background-color: ${({ colors }) => colors.color.white};
    color: ${({ colors }) => colors.color.darkGrey};
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
    cursor: pointer;
  }
  .working {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }
  .add-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    width: 220px;
    background-color: ${({ colors }) => colors.color.addBox};
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: ${({ colors }) => colors.color.secondary};
  }
  .plus-icon {
    height: 35px;
    width: 35px;
    filter: brightness(0) invert(1);
  }
  .input-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  .file-img {
    border-radius: 5px;
    margin-top: 20px;
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }

  @media only screen and (min-width: 481px) {
    .working {
      flex-direction: row;
    }
  }
`;
export default GigCreationForm
