import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import gigsReducer from "./slices/gigsSlice";
import locationReducer from "./slices/locationSlice";
import JobsReducer from "./slices/jobsSlice";
import categoryReducer from "./slices/categorySlice";
import reviewsReducer from "./slices/reviewsSlice";
import profileReducer from "./slices/profileSlice";
import paymentReducer from "./slices/paymentSlice";
import ordersReducer from "./slices/ordersSlice";
import earningsReducer from "./slices/earningsSlice";
import offersReducer from "./slices/offersSlice";
import toastReducer from "./slices/toastSlice";
import invoiceReducer from "./slices/invoiceSlice";
import notificationsReducer from "./slices/notificationsSlice";
import homeFiltersReducer from "./slices/homeFiltersSlice";
import {quickbloxReducers} from '../features/quickblox'

const store = configureStore({
  reducer: {
    auth: authReducer,
    gigs: gigsReducer,
    loc: locationReducer,
    jobs: JobsReducer,
    categories: categoryReducer,
    reviews: reviewsReducer,
    profile: profileReducer,
    payment: paymentReducer,
    orders: ordersReducer,
    earnings: earningsReducer,
    offers: offersReducer,
    notifications: notificationsReducer,
    toast: toastReducer,
    invoice: invoiceReducer,
    homeFilters:homeFiltersReducer,
    ...quickbloxReducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
