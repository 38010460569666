import React, { useState, useEffect } from "react";

import { Styles } from "./aboutProfile";
import { colors } from "../../../utils/Colors";
import { getCountryFromLatLng } from "../../../services";
// import { formatMessageTime } from "../../../utils/helperFunctions";

import { useAuthContext } from "../../../features/authentication";
import { formatMessageTime } from "../../../utils/helperFunctions";
import { ContactButton } from "../../../features/quickblox";
import Avatar from "../../../components/common/Avatar";
import { Badge } from "reactstrap";
/**
 *
 * @typedef Props
 * @property {string} role
 * @property {string} img
 * @property {string} qbId
 * @property {import("../../../types/server").location} location
 * @property {string} username
 * @property {number} rating
 * @property {number | undefined} responseTime
 * @property {boolean} online
 * @property {string} createdAt
 * @property {string | undefined} lastDelivery
 * @property {string | undefined} sellerLevel
 * @property {number | undefined} ordersCompleted
 * @property {boolean} buyer
 * @property {string} userId
 * @property {boolean | undefined} isProVerified
 */
const AboutProfile = (
  /** @type Props */ {
    role,
    img,
    qbId,
    location,
    username,
    rating,
    responseTime,
    online,
    createdAt,
    lastDelivery,
    sellerLevel,
    ordersCompleted,
    userId,
    isProVerified,
    buyer = false,
  }
) => {
  const [addressLocation, setAddressFromLocation] = useState("");
  const { isAuthenticated, user } = useAuthContext();
  const status = online ? "Online" : "Offline";
  const createdOn = formatMessageTime(createdAt);
  const avgResponseTime =
    responseTime > 1 ? `${responseTime} Hours` : `${responseTime} Hour`;
  const isOwnerProfile =
    isAuthenticated && typeof user != "undefined" && user._id === userId;
  useEffect(() => {
    const profileLocation = async () => {
      try {
        const country = await getCountryFromLatLng(
          location?.latitude,
          location?.longitude
        );
        setAddressFromLocation(country);
      } catch (e) {
        console.log(e);
      }
    };
    profileLocation();
  }, [location]);

  return (
    <Styles colors={colors}>
      <div className="about-profile">
        <div className="about-info">
          <div className={online ? " status" : "offline status"}>
            <li>{status}</li>
          </div>
          <div className="about-info-lower-section">
            <Avatar imgSrc={img} name={username} width={100} height={100} />
            <div className="d-flex gap-2 align-items-center">
              <p className="profile-name">{username}</p>
              {isProVerified ? (
                <Badge>{"Pro"}</Badge>
              ) : sellerLevel ? (
                <p className="text-md text-muted mb-1">
                  {sellerLevel + " Seller"}
                </p>
              ) : null}
            </div>
            <div className="profile-bio">
              {rating > 0 && (
                <rating rating={rating} ordersCompleted={ordersCompleted} />
              )}
            </div>
            {isAuthenticated ? (
              typeof user !== "undefined" &&
              !isOwnerProfile && (
                <ContactButton
                  recipientName={username}
                  recipentId={parseInt(qbId) || null}
                  label="Contact Me"
                  className="w-100"
                />
              )
            ) : (
              <button
                type="button"
                className="btn btn-lg btn-primary mt-4 w-100"
                onClick={() => {
                  alert("please Login First");
                }}
              >
                {"Contact Me"}
              </button>
            )}
          </div>
        </div>
        <hr />
        <div className="profile-info">
          <div className="profile-info-detail">
            <p className="question">From</p>
            <p className="answer">{addressLocation}</p>
          </div>
          {avgResponseTime > 0 && (
            <div className="profile-info-detail">
              <p className="question">Average Resonse Time</p>
              <p className="answer">{avgResponseTime}</p>
            </div>
          )}
          <div className="profile-info-detail">
            <p className="question">Member Since</p>
            <p className="answer">{createdOn}</p>
          </div>
          {lastDelivery && (
            <div className="profile-info-detail">
              <p className="question">Last Delivery</p>
              <p className="answer">{lastDelivery}</p>
            </div>
          )}
          {role === "seller" && (
            <div className="profile-info-detail">
              <p className="question">Seller Level</p>
              <p className="answer">{sellerLevel}</p>
            </div>
          )}
        </div>
      </div>
    </Styles>
  );
};
export const AboutProfilePlaceholder = () => {
  return (
    <Styles colors={colors}>
      <div className="about-profile">
        <div className="about-info">
          <div
            className="placeholder-glow rounded-pill w-25"
            style={{ height: "20px" }}
          >
            <span class="placeholder placeholder-grey w-100 h-100 rounded-pill"></span>
          </div>
          <div className="about-info-lower-section">
            <div
              className="placeholder-glow rounded-circle"
              style={{ height: "100px", width: "100px" }}
            >
              <span class="placeholder placeholder-grey w-100 h-100 rounded-circle"></span>
            </div>
            <p className="placeholder-glow w-25">
              <span class="placeholder placeholder-grey w-100"></span>
            </p>
            <div className="w-100 mt-4">
              <div
                className="placeholder-glow w-100 rounded-2"
                style={{ height: "40px" }}
              >
                <span class="placeholder placeholder-grey w-100 h-100 rounded-2"></span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="profile-info">
          <div className="profile-info-detail">
            <p className="placeholder-glow w-25">
              <span class="placeholder placeholder-grey w-100"></span>
            </p>
            <p className="placeholder-glow w-50">
              <span class="placeholder placeholder-grey w-100"></span>
            </p>
          </div>
          <div className="profile-info-detail">
            <p className="placeholder-glow w-25">
              <span class="placeholder placeholder-grey w-100"></span>
            </p>
            <p className="placeholder-glow w-50">
              <span class="placeholder placeholder-grey w-100"></span>
            </p>
          </div>
          <div className="profile-info-detail">
            <p className="placeholder-glow w-25">
              <span class="placeholder placeholder-grey w-100"></span>
            </p>
            <p className="placeholder-glow w-50">
              <span class="placeholder placeholder-grey w-100"></span>
            </p>
          </div>
          <div className="profile-info-detail">
            <p className="placeholder-glow w-25">
              <span class="placeholder placeholder-grey w-100"></span>
            </p>
            <p className="placeholder-glow w-50">
              <span class="placeholder placeholder-grey w-100"></span>
            </p>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default AboutProfile;
