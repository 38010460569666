import styled from "styled-components";

export const Styles = styled.div`
  .gig-detail {
    padding: 20px;
  }
  .category {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .category p {
    color: #446ee7;
    font-size: 14px;
  }
  .chevron {
    margin: 0 10px;
  }
  .gig-title {
    padding-bottom: 15px;
  }
  .gig-title h2 {
    font-size: 25px;
    margin: 0;
  }

  .gallery {
    border-radius: 5px;
    overflow: hidden;
  }
  .about-section {
    margin: 20px 0;
  }
  .about-section h3 {
    font-size: 24px;
    font-weight: bolder;
  }
  .about-section h3 span {
    color: ${({ colors }) => colors.color.secondary};
  }
  .speaker-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  h3,
  h3 span {
    font-family: "MS_SemiBold";
  }

  @media only screen and (min-width: 768px) {
    .detail-section {
      height: 300px;
    }
    .gallery {
      width: 100%;
      margin-top: 70px;
    }
    .about-section p {
      line-height: 1.8;
      text-align: justify;
    }
    .main-info {
      width: 90%;
    }
  }

`;
