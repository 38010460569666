
import { useState } from 'react'

/**
 * A function that takes a result of a variable type and returns nothing.
 * This will close our component and return to the caller of `openComponent`.
 *
 * @callback CloseComponent
 * @param {any} result - The result value to be passed back to the caller.
 * @returns {void}
 */

/**
 * A function that builds the UI for a component.
 * It takes the close function as a parameter and returns a `ReactNode`
 * that we can display.
 *
 * @callback ComponentFactory
 * @param {boolean} isOpen - Indicates whether the component is open or not.
 * @param {CloseComponent} close - The close function to be called when the component needs to be closed.
 * @returns {ReactNode} The UI for the component.
 */

/**
 * A custom hook that provides imperative component functionality.
 *
 * @param {ComponentFactory} componentFactory - The factory function that builds the component UI.
 * @returns {{componentNode: ReactNode, openComponent: () => Promise<any>}} The component node and the function to open the component.
 */
function useImperativeComponent(componentFactory) {
    const [componentNode, setComponentNode] = useState(null)

    function openComponent() {
        return new Promise(resolve => {
            function close(value) {
                resolve(value)
                setComponentNode(componentFactory(false, close))
            }
            setComponentNode(componentFactory(true, close))
        })
    }

    return {
        componentNode,
        openComponent
    }
}
export default useImperativeComponent
