import React, { useState, useRef } from "react";

import InputField from "../../common/InputField";
import { Styles } from "./postJobForm";
import { colors } from "../../../utils/Colors";
import { PostJobSchema } from "../../../utils/PostJobSchema";
import { postJob } from "../../../store/thunks";
import Button from "../../common/Button";
import Error from "../../common/Error";
import PicturePicker from "../../common/PicturePicker";

import Select from "react-select";
import { useFormik } from "formik";
import { FaDollarSign } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppleAutoComplete from "../../../components/common/AppleAutoComplete";

const work = [
  { value: "on-site", label: "On-Site" },
  { value: "online", label: "Online" },
];

const handleWork = (workType) => {
  if (workType === "on-site") {
    return { value: "on-site", label: "On-Site" };
  }
  if (workType === "online") {
    return { value: "online", label: "Online" };
  }
};

const delivery = [];

const makeDay = (num) => {
  let day = "Day";
  if (num > 1) day = "Days";
  return { value: num, label: `${num} ${day}` };
};
for (let i = 1; i <= 30; i++) {
  delivery.push(makeDay(i));
}

const PostJobForm = ({ onSubmit, jobValues, addressLabel = "" }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [subCategoryList, setsubCategoryList] = useState([]);
  const [coordinates, setCoordinates] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const autoCompleteRef = useRef();
  const { categories } = useSelector((state) => state.categories);
  const { loading } = useSelector((state) => state.jobs);

  const handleSelectChange = (selected) => {
    setFieldValue("category", {
      name: selected.name,
      img: selected.img,
      _id: selected._id,
    });
    setFieldValue("subcategory", "");
    setsubCategoryList(selected.subcategories);
    selected.subcategories ? setSelectedOption(true) : setSelectedOption(false);
  };

  const handleImageUpload = (file, index) => {
    const images = [...values.attachments];
    for (let i = 0; i <= 2; i++) {
      if (i === index) {
        images[i] = file;
      }
    }
    setFieldValue(
      "attachments",
      images.filter((image) => image)
    );
  };

  const onSelectPlace = (location) => {
    const {
      coordinate: { latitude, longitude },
      description,
    } = location.value;
    setCoordinates({
      latitude,
      longitude,
    });
    setFieldValue("location", {
      latitude,
      longitude,
      address: description,
    });
  };

  const initialValues = {
    category: jobValues?.category || "",
    subcategory: jobValues?.subcategory || "",
    workType: jobValues?.workType || "",
    deadline: jobValues?.deadline || "",
    budgetMax: jobValues?.budgetMax || "",
    budgetMin: jobValues?.budgetMin || "",
    description: jobValues?.description || "",
    attachments: jobValues?.attachments || [],
    location: jobValues?.location || {},
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: PostJobSchema,
    onSubmit: (values, action) => {
      if (jobValues) {
        onSubmit(values);
      } else {
        dispatch(postJob({ job: values, navigate }));
        action.resetForm();
      }
    },
  });

  return (
    <Styles colors={colors}>
      <form className="service-looking" onSubmit={handleSubmit}>
        <label className="service-looking-question">
          What service you are looking to purchase. Please be as detailed as
          possible.
        </label>
        <div className="text-field">
          <textarea
            placeholder="I am looking for..."
            name="description"
            id="description"
            value={values.description}
            onChange={handleChange}
            autoComplete="off"
          ></textarea>
          <p>{values.description.length}/600</p>
        </div>
        {errors.description && touched.description && (
          <Error text={errors.description} />
        )}
        <hr />
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-wrap gap-4">
            <div style={{ flex: 1 }}>
              <label htmlFor="category" className="select-label">
                Choose Category
              </label>
              <Select
                id="category"
                name="category"
                className="select"
                placeholder="Select Category"
                options={categories}
                getOptionLabel={(option) => {
                  return <div className="text-capitalize">{option.name}</div>;
                }}
                getOptionValue={(option) => option}
                onChange={handleSelectChange}
                value={values.category}
              />
              {errors.category && touched.category && (
                <Error text={errors.category} />
              )}
            </div>
            <div style={{ flex: 1 }}>
              <label htmlFor="subcategory" className="select-label">
                Choose Subcategory
              </label>
              <Select
                id="subcategory"
                name="subcategory"
                className="select"
                placeholder="Select Subcat..."
                options={subCategoryList}
                getOptionLabel={(option) => {
                  return <div className="text-capitalize">{option.name}</div>;
                }}
                getOptionValue={(option) => option}
                isDisabled={jobValues ? false : !selectedOption}
                onChange={(value) => {
                  setFieldValue("subcategory", value);
                }}
                value={values.subcategory}
              />
              {errors.subcategory && touched.subcategory && (
                <Error text={errors.subcategory} />
              )}
            </div>
          </div>
          <div className="d-flex flex-wrap gap-4">
            <div style={{ flex: 1 }}>
              <label htmlFor="workType" className="select-label">
                Choose workType
              </label>
              <Select
                id="workType"
                name="workType"
                className="select"
                onChange={(selectedOption) => {
                  setFieldValue("workType", selectedOption.value);
                }}
                options={work}
                getOptionLabel={(option) => {
                  return <div>{option.label}</div>;
                }}
                getOptionValue={(option) => option}
                value={handleWork(values.workType)}
              />
              {errors.workType && touched.workType && (
                <Error text={errors.workType} />
              )}
            </div>
            <div style={{ flex: 1 }}>
              <label htmlFor="duration" className="select-label">
                Delievery Time
              </label>
              <Select
                id="deadline"
                name="deadline"
                className="select"
                onChange={(selectedOption) =>
                  setFieldValue("deadline", selectedOption.value)
                }
                options={delivery}
                getOptionLabel={(option) => {
                  return <div>{option.label}</div>;
                }}
                getOptionValue={(option) => option}
                value={makeDay(values.deadline)}
              />
              {errors.deadline && touched.deadline && (
                <Error text={errors.deadline} />
              )}
            </div>
          </div>
          <div>
            <label className="select-label">Location</label>
            <AppleAutoComplete
              style={{ width: "100%", borderRadius: 4 }}
              onSelect={onSelectPlace}
              limitCountries="US"
              placeholder={values.location?.address}
            />
            <p className="text-sm text-muted mt-1">
              {"Please enter a valid US address."}
            </p>
            {errors.location && touched.location && (
              <Error text={errors.location} />
            )}
          </div>
        </div>
        <div className="budget">
          <label>Budget</label>
          <div className="budget-box">
            <div>
              <div className="budget-min-max">
                <p>
                  Min - <FaDollarSign />
                </p>
                <InputField
                  className="budget-input"
                  type="number"
                  name="budgetMin"
                  id="budgetMin"
                  value={values.budgetMin}
                  onChange={handleChange}
                  autoComplete="off"
                  onBlur={handleBlur}
                />
              </div>
              {errors.budgetMin && touched.budgetMin && (
                <Error text={errors.budgetMin} />
              )}
            </div>

            <div>
              <div className="budget-min-max">
                <p>
                  Max - <FaDollarSign />
                </p>
                <InputField
                  className="budget-input"
                  type="number"
                  name="budgetMax"
                  id="budgetMax"
                  value={values.budgetMax}
                  onChange={handleChange}
                  autoComplete="off"
                  onBlur={handleBlur}
                />
              </div>
              {errors.budgetMax && touched.budgetMax && (
                <Error text={errors.budgetMax} />
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="attachment-box">
          <label>Add Attachment</label>

          <div className="add">
            {[1, 2, 3].map((picker, index) => {
              let image = values.attachments[index];
              if (image?.imgUrl) {
                image = values.attachments[index].imgUrl;
              }
              return (
                <PicturePicker
                  key={index}
                  index={index}
                  setImage={handleImageUpload}
                  image={image}
                />
              );
            })}
          </div>
          {errors.attachments && touched.attachments && (
            <Error text={errors.attachments} />
          )}
        </div>
        <div className="submit-job">
          <Button
            children={
              loading ? (
                <div
                  className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              ) : (
                "Submit Job"
              )
            }
            className="submit-job-btn"
            type="submit"
            disabled={loading}
          />
        </div>
      </form>
    </Styles>
  );
};

export default PostJobForm;
