import styled from "styled-components";

export const Styles = styled.div`
  .become-seller-page {
    min-height: 50vh;
    padding: 10px;
    margin: 20px auto 40px auto;
  }
  h3,
  h3 span {
    font-family: "NN_SemiBold";
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .heading {
    font-family: "NN_SemiBold";
  }
  .regular {
    font-family: "NN_Regular";
  }
  .light {
    font-family: "NN_Light";
  }
  .font-small {
    font-size: 14px;
  }
  .font-large {
    font-size: 18px;
  }
  .become-wrapper {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
    border-radius: 5px;
  }
  .username {
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
  }
  .profile-pic-wrapper {
    width: 120px;
    height: 120px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 50%;
    margin: 10px auto;
  }
  .add-profile-pic {
    width: 55px;
    height: 55px;
  }
  textarea {
    border: none;
    outline: none;
    width: 100%;
    height: 100px;
  }
  textarea::placeholder {
    font-size: 14px;
    font-family: "NN_Regular";
  }
  .select {
    max-width: 400px;
  }

  @media only screen and (min-width: 768px) {
    .become-seller-page {
      padding: 10px 40px;
    }
  }
  @media only screen and (min-width: 992px) {
    .become-seller-page {
      max-width: 1100px !important;
    }
  }
`;
