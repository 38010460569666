import React from "react";
import { useAuthContext } from "../../../features/authentication";
import { useNavigate } from "react-router-dom";

const LogoutButton = (props) => {
  const { logOut } = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  return (
    <p
      onClick={async (e) => {
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();
        try {
          await logOut();
        }catch(e){

        }
        navigate("/sign-in");
        setLoading(false);
      }}
      {...props}
    >
      {loading ? (
        <div className="d-flex gap-2">
          <div
            className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
            role="status"
          >
            <span className="sr-only"></span>
          </div>
          <p>{"Logging out..."}</p>
        </div>
      ) : (
        "Logout"
      )}
    </p>
  );
};

export default LogoutButton;
