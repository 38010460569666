import * as QB from "quickblox/quickblox";
export const prepareQBSDK = (QBConfig) => {
    // check if we have installed SDK
    if (window.QB === undefined) {
      if (QB !== undefined) {
        window.QB = QB;
      } else {
        let QBLib = require("quickblox/quickblox.min");
        window.QB = QBLib;
      }
    }
  
    const APPLICATION_ID = QBConfig.credentials.appId;
    const AUTH_KEY = QBConfig.credentials.authKey;
    const AUTH_SECRET = QBConfig.credentials.authSecret;
    const ACCOUNT_KEY = QBConfig.credentials.accountKey;
    const CONFIG = QBConfig.appConfig;
  
    QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);
    return QB;
  };