import React, { useState, useEffect } from "react";
import Marker from "../../Map/Marker";
import BlueFalcon from "../../../assets/icons/BlueIcon.png";
import RedFalcon from "../../../assets/icons/redIcon.png";
import styled from "styled-components";
import { BiCurrentLocation } from "react-icons/bi";
import GoogleMapReact from "google-map-react";
import useGigsSelector from "../../../store/hooks/useGigsSelector";
import useJobsSelector from "../../../store/hooks/useJobsSelector";
import { colors } from "../../../utils/Colors";
import { useNavigate } from "react-router-dom";
import RoutesPath from "../../../utils/Paths";
import MapBlueMarker from "../../common/MapBlueMarker";
import useHomeFilters from "../../../store/hooks/useHomeFilters";
import useCurrentLocation from "../../../hooks/common/useCurrentLocation";

const DEFAULT_MAP_ZOOM = 13;

const HomeMapView = () => {
  const navigate = useNavigate();
  const currentLocation = useCurrentLocation();
  const { filters, handleUpdatingFilters } = useHomeFilters();
  const { gigs } = useGigsSelector();
  const { jobsData } = useJobsSelector();
  const [coordinates, setCoordinates] = useState({
    center: {
      lat: filters.geometryFilter.data.lat,
      lng: filters.geometryFilter.data.lng,
    },
    zoom: DEFAULT_MAP_ZOOM,
  });
  const isMapCoordinatesEqualToCurrentLocation =
    Math.abs(coordinates.center.lat - currentLocation?.lat) < 0.001 &&
    Math.abs(coordinates.center.lng - currentLocation?.lng) < 0.001;

  useEffect(() => {
    setCoordinates({
      center: {
        lat: filters.geometryFilter.data.lat,
        lng: filters.geometryFilter.data.lng,
      },
      zoom: DEFAULT_MAP_ZOOM,
    });
  }, [filters.geometryFilter]);
  return (
    <StyledMapView>
      <div className="map position-relative">
        <GoogleMapReact
          className="postion-relative"
          zoom={coordinates?.zoom}
          center={coordinates?.center}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAP_KEY,
            libraries: ["places"],
          }}
          onChange={(e) => {
            setCoordinates({
              center: {
                lat: e.center.lat,
                lng: e.center.lng,
              },
              zoom: e.zoom,
            });
          }}
          yesIWantToUseGoogleMapApiInternals
        >
          <MapBlueMarker
            lat={currentLocation?.lat ?? 0}
            lng={currentLocation?.lng ?? 0}
          />
          {(filters.sectionFilter === "jobs" ||
            filters.sectionFilter === "all") &&
            jobsData.length &&
            jobsData?.map((item, index) => (
              <Marker
                key={index}
                imageSrc={RedFalcon}
                name={item.createdBy.username}
                profilePic={""}
                description={item.description}
                openModal={coordinates.zoom >= 13}
                onHoverModalClick={() => {
                  navigate(`${RoutesPath.manageJobs}/${item._id}/detail`, {
                    state: item,
                  });
                }}
                onAvatarClick={() => {
                  navigate(`/profile/${item.createdBy._id}`);
                }}
                footer={
                  <div className="d-flex justify-content-between">
                    <p>
                      {"Price: "}
                      <span className="text-danger">{`$${item.budgetMin} - $${item.budgetMax}`}</span>
                    </p>
                    <p>
                      {item.createdBy.rating ? "Rating: " : ""}
                      <span style={{ color: colors.color.pending }}>{`${
                        item.createdBy.rating || "New Buyer"
                      }`}</span>
                    </p>
                  </div>
                }
                lat={item.location.latitude}
                lng={item.location.longitude}
              />
            ))}

          {(filters.sectionFilter === "gigs" ||
            filters.sectionFilter === "all") &&
            gigs?.map((item, index) => {
              return (
                <Marker
                  key={index}
                  imageSrc={BlueFalcon}
                  name={item.createdBy.username}
                  profilePic={item.createdBy.profileImg.imgUrl}
                  description={item.description}
                  openModal={coordinates.zoom >= 13}
                  onHoverModalClick={() => {
                    navigate(`/gig/${item._id}/detail`, {
                      state: item,
                    });
                  }}
                  onAvatarClick={() => {
                    navigate(`/profile/${item.createdBy._id}`);
                  }}
                  footer={
                    <div className="d-flex justify-content-between">
                      <p>
                        {"Price: "}
                        <span className="text-danger">{`$${item.priceFrom} - $${item.priceTo}`}</span>
                      </p>
                      <p>
                        {"Rating: "}
                        <span style={{ color: colors.color.pending }}>{`${
                          item.rating || "Rookie"
                        }`}</span>
                      </p>
                    </div>
                  }
                  lat={item.latitude}
                  lng={item.longitude}
                />
              );
            })}
        </GoogleMapReact>
        <button
          className="google-location-btn position-absolute"
          style={{
            padding: "10px",
            backgroundColor: "white",
            border: "none",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
            width: "40px",
            height: "40px",
            borderRadius: "2px",
            right: "10px",
            bottom: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            window.navigator.geolocation.getCurrentPosition((position) => {
              handleUpdatingFilters({
                geometryFilter: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                },
              });
            });
          }}
        >
          <BiCurrentLocation
            style={{
              width: "16px",
              height: "16px",
              marginTop: "-3px",
              color: isMapCoordinatesEqualToCurrentLocation ? "blue" : "gray",
            }}
          />
        </button>
      </div>
    </StyledMapView>
  );
};

/**
 * @typedef {Object} Coordinates
 * @property {Object} center
 * @property {number} center.lat
 * @property {number} center.lng
 * @property {number} zoom
 *
 *
 * @typedef Props
 * @property {Coordinates} coordinates
 * @property {(newCoordinate:Coordinates) => void} setCoordinates
 */

const StyledMapView = styled.div`
  .map {
    display: block;
    height: 80vh;
    width: 100vw;
  }
  @media only screen and (min-width: 769px) {
    .map {
      display: block;
      height: 80vh;
      width: 50vw;
    }
  }
`;

export default HomeMapView;
