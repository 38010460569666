import React, { useState, useRef } from "react";

import { Styles } from "./becomeSeller";
import { colors } from "../../../utils/Colors";
import Error from "../../../components/common/Error";
import Header from "../../../components/common/Header";
import Footer from "../../../components/common/Footer";
import AddProfile from "../../../assets/icons/addProfile.png";
import { languages } from "../../../utils/helperFunctions";

import Select from "react-select";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Button from "../../../components/common/Button";
import { BecomeSellerSchema } from "../../../utils/PostJobSchema";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../features/authentication";

const BecomeSeller = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [subCategoryList, setsubCategoryList] = useState([]);
  const [image, setImage] = useState(null);
  const fileRef = useRef(null);
  const { categories } = useSelector((state) => state.categories);
  const { becomeSeller, user } = useAuthContext();
  const navigate = useNavigate();

  const username = user?.username;
  const email = user?.email;

  const handleSelectChange = (selected) => {
    setFieldValue("category", {
      name: selected.name,
      img: selected.img,
      _id: selected._id,
    });
    setFieldValue("subcategories", "");
    setsubCategoryList(selected.subcategories);
    selected.subcategories ? setSelectedOption(true) : setSelectedOption(false);
  };

  const initialValues = {
    category: null,
    subcategories: null,
    description: "",
    languages: [
      {
        code: "en",
        name: "English",
        nativeName: "English",
      },
    ],
    profileImg: null,
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: BecomeSellerSchema,
    onSubmit: async (values, action) => {
      values.languages = values.languages.map((lang) => lang.code);
      values.category = values.category._id;
      values.subcategories = values.subcategories.map((subcat) => subcat._id);
      await becomeSeller(values);
      navigate("/");
    },
  });
  console.log(values);

  return (
    <>
      <Header />
      <Styles colors={colors}>
        <div className="become-seller-page">
          <form onSubmit={handleSubmit}>
            <h3 className="mb-3">
              <span className="falcon-clr">Seller</span> Profile
            </h3>
            <div className="d-flex flex-column flex-sm-row flex-wrap gap-3 w-100">
              <div className="become-wrapper" style={{ flex: "3" }}>
                <h5 className="heading mb-3">
                  <span className="falcon-clr heading ">Personal</span>{" "}
                  Information
                </h5>
                <div>
                  <div className="mb-3">
                    <p className="light font-small grey-clr mb-1">Username</p>
                    <p className="font-large username pb-1">{username}</p>
                  </div>
                  <div>
                    <p className="light font-small grey-clr mb-1">Email</p>
                    <p className="font-large username pb-1">{email}</p>
                  </div>
                </div>
              </div>
              <div className="become-wrapper" style={{ flex: "2" }}>
                <h5 className="heading mb-3">
                  <span className="falcon-clr heading ">Profile</span> Picture
                </h5>
                <div className="d-flex align-items-center">
                  <input
                    hidden
                    ref={fileRef}
                    accept="image/*"
                    type="file"
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        setImage(e.target.files[0]);
                        setFieldValue("profileImg", e.target.files[0]);
                      } else {
                        setImage(null);
                        setFieldValue("profileImg", null);
                      }
                    }}
                  />
                  {image ? (
                    <img
                      className="profile-pic-wrapper d-flex align-items-center justify-content-center"
                      src={URL.createObjectURL(image)}
                      alt="Preview"
                      width="120"
                      height="120"
                      style={{ objectFit: "cover", cursor: "pointer" }}
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    />
                  ) : (
                    <div
                      className="profile-pic-wrapper d-flex align-items-center justify-content-center"
                      onClick={() => {
                        fileRef.current.click();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="add-profile-pic"
                        src={AddProfile}
                        alt={"profilePic"}
                      />
                    </div>
                  )}
                </div>
                <div style={{ height: "20px" }}>
                  {errors.profileImg && touched.profileImg && (
                    <Error text={errors.profileImg} />
                  )}
                </div>
              </div>
              <div className="become-wrapper" style={{ flex: "2" }}>
                <h5 className="heading mb-3">
                  <span className="falcon-clr heading ">Technical</span>{" "}
                  Information
                </h5>

                <div className="mb-2">
                  <label className="font-small regular mb-1">
                    Skill Category
                  </label>
                  <Select
                    id="category"
                    name="category"
                    className="select"
                    placeholder="Select Category"
                    options={categories}
                    getOptionLabel={(option) => {
                      return (
                        <div className="text-capitalize">{option.name}</div>
                      );
                    }}
                    getOptionValue={(option) => option}
                    onChange={handleSelectChange}
                    value={values.category}
                  />
                  {errors.category && touched.category && (
                    <Error text={errors.category} />
                  )}
                </div>
                <div>
                  <label className="font-small regular mb-1">
                    Subcategories
                  </label>
                  <Select
                    id="subcategories"
                    name="subcategories"
                    className="select"
                    placeholder="Select Subcat..."
                    isMulti
                    options={subCategoryList}
                    getOptionLabel={(option) => {
                      return (
                        <div className="text-capitalize">{option.name}</div>
                      );
                    }}
                    getOptionValue={(option) => option}
                    isDisabled={!selectedOption}
                    onChange={(value) => {
                      setFieldValue("subcategories", value);
                    }}
                    value={values.subcategories}
                  />
                  {errors.subcategories && touched.subcategories && (
                    <Error text={errors.subcategories} />
                  )}
                </div>
              </div>
            </div>
            <div className="become-wrapper mt-3">
              <h5 className="heading">Description</h5>
              <textarea
                id="description"
                name="description"
                placeholder="write about yourself here..."
                onChange={handleChange}
                value={values.description}
              ></textarea>
              <div className="d-flex justify-content-between align-items-center">
                <div style={{ height: "20px" }}>
                  {errors.description && touched.description && (
                    <Error text={errors.description} />
                  )}
                </div>
                <p className="heading font-small">
                  {values.description.length} /600
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <Button
                children={
                  isSubmitting ? (
                    <div
                      className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    "Submit"
                  )
                }
                type="submit"
                disabled={isSubmitting}
                className="accept-btn complete-btn"
              />
            </div>
          </form>
        </div>
      </Styles>
      <Footer />
    </>
  );
};

export default BecomeSeller;
