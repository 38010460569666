import React, { useState, useEffect } from "react";
import { colors } from "../../utils/Colors";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import InputField from "../../components/common/InputField";
import Button from "../../components/common/Button";
import Error from "../../components/common/Error";
import styled from "styled-components";
import { FaCamera } from "react-icons/fa";
import * as Yup from "yup";
import { Formik } from "formik";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import { useAuthContext } from "../../features/authentication";
import AvatarPlaceholderImage from "../../assets/images/avatar-placeholder.png";
import FileUploadTrigger from "../../components/common/FileUploadTrigger";
import { geocodeByPlaceId } from "react-places-autocomplete";
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, "Minimum length 3")
    .required("Name is required")
    .label("name"),
  lastname: Yup.string()
    .trim()
    .required("Lastname is required")
    .min(3, "Minimum length 3")
    .label("lastname"),
  location: Yup.object().required(),
});

const Settings = () => {
  const [initalGeocodeFromLatLng, setInitalGeocodeFromLatLng] =
    useState(undefined);
  const { user, updateProfile } = useAuthContext();
  console.log(user)
  const [updatingImage, setUpdatingImage] = useState(false)
  useEffect(() => {
    const getInitalGeocode = async () => {
      const { latitude, longitude } = user.location;
      try {
        Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
        const response = await Geocode.fromLatLng(latitude, longitude);
        console.log(response);
        const AddressFirst = response.results.pop()        
        const AddressSecond = response.results.pop()        
        const AddressThird = response.results.pop()
        if (!AddressThird) {
          if (!AddressSecond) {
            if (!AddressFirst){
              throw new Error('Address not found')
            } else {
              // Found Address One but not address second and third
              setInitalGeocodeFromLatLng({
                value: AddressFirst,
                label: AddressFirst.formatted_address,
              });        
            }
          } else {
            // Found Address Second and others but not address third
            setInitalGeocodeFromLatLng({
              value: AddressSecond,
              label: AddressSecond.formatted_address,
            });        
          }
        } else {
          // Found Address Third and maybe others
          setInitalGeocodeFromLatLng({
            value: AddressThird,
            label: AddressThird.formatted_address,
          });        
        }
      } catch (e) {
        setInitalGeocodeFromLatLng(null);
        console.error(e);
      }
    };
    if (user?.location) {
      getInitalGeocode();
    }
  }, [user]);

  return (
    <SettingsStyled colors={colors}>
      <Header />
      <section
        className="container d-flex flex-column gap-5 mx-auto"
        style={{ maxWidth: "500px" }}
      >
        <div>
          <div className="d-flex flex-column gap-3">
            <div
              className="rounded-circle d-flex justify-content-center align-items-center position-relative overflow-hidden"
              style={{ width: "120px", height: "120px" }}
            >
              {updatingImage ? (
                <div className="w-100 h-100 d-flex justify-content-center align-items-center bg-light">
                  <div
                    className="spinner-border"
                    style={{ color: "gray" }}
                    role="status"
                  ></div>
                </div>
              ) : (
                <>
                  <img
                    src={user.profileImg.imgUrl || AvatarPlaceholderImage}
                    alt={"Profile"}
                    className="w-100 h-100 rounded-circle"
                  />
                  <FileUploadTrigger
                    outerClassName="bg-black d-flex justify-content-center align-items-center position-absolute bottom-0 w-100 h-25"
                    RenderComponent={({ onClick }) => (
                      <div
                        className="bg-black d-flex justify-content-center align-items-center opacity-75"
                        style={{ cursor: "pointer" }}
                        onClick={onClick}
                      >
                        <FaCamera style={{ color: "white" }} />
                      </div>
                    )}
                    onChange={async (e) => {
                      const files = e.target.files;
                      const image = files[0];
                      setUpdatingImage(true)
                      await updateProfile({ profileImg: image });
                      setUpdatingImage(false)
                    }}
                  />
                </>
              )}
            </div>
            <div>
              <h5
                style={{
                  color: colors.color.secondary,
                  margin: 0,
                }}
              >
                {user?.username || "Account"}
              </h5>
              <p style={{ fontSize: "12px", color: "gray" }}>{user?.email}</p>
            </div>
          </div>
        </div>
        <div>
          <h6>Need to update your public profile?</h6>
          <hr className="mt-0" />
          <div className="setting-fields">
            {initalGeocodeFromLatLng !== undefined ? (
              <Formik
                initialValues={{
                  name: user?.name,
                  lastname: user?.lastname,
                  location: initalGeocodeFromLatLng,
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  const payload = { ...values };
                  if (values.location?.value?.place_id) {
                    const response = await geocodeByPlaceId(
                      values.location?.value?.place_id
                    );
                    console.log(response);
                    const {
                      geometry: {
                        location: { lat, lng },
                      },
                    } = response[0];
                    payload.location = {
                      latitude: lat(),
                      longitude: lng(),
                    };
                  }
                  await updateProfile(payload);
                }}
                enableReinitialize={true}
              >
                {({
                  handleChange,
                  handleSubmit,
                  errors,
                  values,
                  handleBlur,
                  touched,
                  setFieldValue,
                  isSubmitting,
                  dirty,
                }) => {
                  return (
                    <div>
                      <InputField
                        className="setting-input mb-2"
                        name="name"
                        label="First Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // disabled={}
                      />
                      {errors.name && touched.name && (
                        <Error text={errors.name} />
                      )}
                      <InputField
                        className="setting-input mb-2"
                        name="lastname"
                        label="Last Name"
                        value={values.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // disabled={}
                      />
                      {errors.lastname && touched.lastname && (
                        <Error text={errors.lastname} />
                      )}
                      <div>
                        <label>Location</label>
                        <GooglePlacesAutocomplete
                          apiKey={process.env.REACT_APP_MAP_KEY}
                          fetchDetails={true}
                          name="location"
                          
                          selectProps={{
                            value: values.location,
                            onChange: (value) => {
                              setFieldValue("location", value);
                            },
                            isClearable:true,
                            // onFocus:() => {
                            //   setFieldValue("location", null);
                            // }
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button
                          type="submit"
                          disabled={isSubmitting || !dirty}
                          styles={{
                            width: "154px",
                          }}
                          children={
                            isSubmitting ? (
                              <div
                                className="spinner-border spinner-border-sm and spinner-grow-sm"
                                role="status"
                              >
                                <span className="sr-only"></span>
                              </div>
                            ) : (
                              "Save Changes"
                            )
                          }
                          className="save-changes-btn"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <p>{"Loading..."}</p>
            )}
          </div>
          {/* <hr /> */}
          {/* <div className="d-flex flex-column flex-lg-row gap-3 m-4">
            <div>
              <h5>ACCOUNT DEACTIVATION</h5>
            </div>
            <div>
              <h6>What happen when you deactivate your account?</h6>
              <ul>
                <li>Your Profile & gigs won't be shown on falcon anymore.</li>
                <li>Active orders will be cancel.</li>
                <li>You won't be able to re-activate your gigs.</li>
              </ul>
            </div>
          </div>
          <div className="mx-3">
            <h6>
              Please <span className="falcon-clr">withdraw your revenues</span>
              <br /> before deactivating your account!
            </h6>
          </div> */}
        </div>
        {/* <div className={setting == 2 ? "settings-balance-content" : "d-none"}>
          <h3>Your Account Balance</h3>
          <p className="account-subheading">
            When available we use your funds and credits as your primary payment
            method for new orders.
          </p>
          <div className="setting-balance-box">
            <div className="d-flex justify-content-between gap-2">
              <h5 className="mb-0 p-3 falcon-balance">FALCON BALANCE</h5>
              <h5 className="mb-0 p-3">TOTAL $ 17.00</h5>
            </div>
            <hr className="m-0" />
            <div className="p-3 d-flex justify-content-between gap-2">
              <h5>Your Earnings</h5>
              <h5>$ 17.00</h5>
            </div>
            <hr className="m-0" />
            <div className="p-3 d-flex justify-content-between gap-2">
              <div>
                <h5>Your Reimbursements</h5>
                <p className="grey-clr">
                  Funds that were credit back to your account for canceled
                  orders.
                </p>
              </div>
              <h5>$ 17.00</h5>
            </div>
          </div>
          <p className="mt-4 grey-clr" style={{ fontSize: "12px" }}>
            Please note it may take a few minutes to update new Falcon Credits
            in your account balance.
          </p>
        </div> */}
      </section>
      <Footer />
    </SettingsStyled>
  );
};

const SettingsStyled = styled.div`
  // .settings {
  //   margin: 60px auto;
  // }
  // .settings-headings {
  //   display: flex;
  //   gap: 20px;
  // }
  // .settings-headings h5 {
  //   padding: 5px 20px;
  //   border: 1px solid ${({ colors }) => colors.color.darkGrey};
  //   border-radius: 20px;
  //   cursor: pointer;
  // }
  // .settings-active-header {
  //   padding: 5px 20px;
  //   border: 1px solid ${({ colors }) => colors.color.secondary} !important;
  //   color: ${({ colors }) => colors.color.secondary};
  //   border-radius: 20px;
  // }
  // .settings-account-content {
  //   border: 1px solid ${({ colors }) => colors.color.lightGrey};
  // }
  // .settings-account-content h6 {
  //   color: ${({ colors }) => colors.color.darkGrey};
  //   padding: 10px;
  //   margin-bottom: 0;
  // }
  // .setting-fields {
  //   padding: 0 10px;
  // }
  // .online-status {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   gap: 20px;
  //   border: 1px solid ${({ colors }) => colors.color.lightGrey};
  //   padding: 5px 10px;
  // }
  // // .online-status button {
  // //   padding: 5px 10px;
  // //   border-radius: 5px;
  // // }
  // .online-text span {
  //   color: ${({ colors }) => colors.color.secondary};
  // }
  // .falcon-clr {
  //   color: ${({ colors }) => colors.color.secondary};
  // }
  // .settings-balance-content {
  //   border: 1px solid ${({ colors }) => colors.color.lightGrey};
  //   padding: 20px 40px;
  // }
  // .account-subheading {
  //   color: ${({ colors }) => colors.color.darkGrey};
  // }
  // .setting-balance-box {
  //   border: 1px solid ${({ colors }) => colors.color.lightGrey};
  // }
  // .falcon-balance {
  //   flex: 3;
  //   border-right: 1px solid ${({ colors }) => colors.color.lightGrey};
  // }
  // .grey-clr {
  //   color: ${({ colors }) => colors.color.darkGrey};
  // }
  // .setting-balance-box h5 {
  //   font-size: 16px;
  // }

  // @media only screen and (min-width: 768px) {
  //   .setting-balance-box h5 {
  //     font-size: 20px;
  //   }
  // }

  // @media only screen and (min-width: 768px) {
  //   .settings {
  //     display: flex;
  //     padding: 0 40px;
  //   }
  //   .settings-headings {
  //     flex-direction: column;
  //     //padding: 20px 40px 0 0;
  //   }
  //   .settings-account-content {
  //     flex: 1;
  //   }
  //   .settings-headings h5 {
  //     padding: 5px 60px;
  //   }
  // }

  // @media only screen and (min-width: 992px) {
  //   .settings {
  //     max-width: 1100px !important;
  //     padding: 0 20;
  //   }
  //   .settings-headings h5 {
  //     padding: 5px 100px;
  //   }
  // }
`;

export default Settings;
