import styled from "styled-components";
export const OrderDetailStyledDiv = styled.div`
  .order-details-page {
    min-height: 50vh;
    padding: 10px;
    margin: 40px auto;
  }
  h3,
  span {
    font-family: "NN_Bold";
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .textarea {
    width: 100%;
    height: 150px;
    padding: 5px;
    border: none;
    outline: none;
    resize: vertical;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
  }
  .order-wrapper {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
    border-radius: 10px;
  }
  .order-revisions-wrapper {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
    border-radius: 10px;
  }
  .seller-name {
    font-family: "NN_Bold";
    font-size: 18px;
  }
  .seller-level {
    font-family: "NN_Light";
    color: ${({ colors }) => colors.color.darkGrey};
    margin-top: -5px;
  }
  .avatar {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }
  .avatar img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  h6,
  h4 {
    font-family: "NN_Bold";
  }
  .order-headings {
    font-family: "NN_Bold";
    margin-bottom: 10px !important;
  }
  .order-headings span {
    font-family: "NN_Regular";
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .order-img {
    width: 145px;
    min-width: 145px;
    // width: 100%;
    height: 101px;
    border-radius: 10px;
    overflow: hidden;
  }
  .order-img img {
    width: 145px;
    // width: 100%;
    height: 101px;
    object-fit: cover;
  }
  .job-detail {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .revision {
    padding: 20px 0;
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
  }
  .revision:last-child {
    border-bottom: none !important;
  }

  @media only screen and (min-width: 420px) {
    .job-detail {
      flex-direction: row;
    }
    .seller-name {
      font-size: 20px;
    }
  }

  @media only screen and (min-width: 768px) {
    .order-details-page {
      padding: 10px 40px;
    }
    .order-wrapper {
      padding: 40px;
      border-radius: 20px;
    }
    .order-revisions-wrapper {
      padding: 20px 40px;
    }
  }
  @media only screen and (min-width: 992px) {
    .order-details-page {
      max-width: 1100px !important;
    }
  }
`;