import styled from "styled-components";

export const Styles = styled.div`
  .earnings-page {
    min-height: 50vh;
    padding: 10px;
    margin: 20px auto;
  }
  h5,
  h3,
  h3 span,
  .dark {
    font-family: "NN_SemiBold";
  }
  .light {
    font-family: "NN_Light";
  }
  hr {
    margin-top: 0;
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .earnings-wrapper {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
    border-radius: 10px;
  }
  .earnings-head-section {
  }
  .earnings-graph {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
    border-radius: 10px;
    flex: 3;
  }
  .earnings-balance {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
    border-radius: 10px;
    flex: 1;
  }
  .earnings-foot-section {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    margin-top: 20px;
    cursor: pointer;
  }
  .dollar {
    color: rgb(255, 193, 7);
    margin-top: 10px;
  }
  .total-income {
    font-family: "MS_Bold";
    font-size: 28px;
    margin-left: 10px;
    margin-top: -10px;
  }
  .earnings-foot-content {
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-left: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-right: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 10px 20px;
  }

  @media only screen and (min-width: 768px) {
    .earnings-page {
      padding: 10px 40px;
    }
  }
  @media only screen and (min-width: 992px) {
    .earnings-page {
      max-width: 1100px !important;
    }
  }
`;
