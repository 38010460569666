import React from "react";

import Button from "../../../common/Button";
import Error from "../../../common/Error";
import PicturePicker from "../../../common/PicturePicker";
import { AttachmentSchema } from "../../../../utils/PostJobSchema";

import { useFormik } from "formik";
import useGigsSelector from "../../../../store/hooks/useGigsSelector";

const Attachments = ({ formNo, setFormNo, handleFormData, gigValues }) => {
  const { loading } = useGigsSelector();

  const initialValues = {
    attachments: gigValues?.attachments || [],
  };

  const handleImageUpload = (file, index) => {
    const images = [...values.attachments];
    for (let i = 0; i <= 2; i++) {
      if (i === index) {
        images[i] = file;
      }
    }

    const filteredImages = images.filter((image) => image !== undefined);
    setFieldValue("attachments", filteredImages);
  };

  const { values, errors, setFieldValue, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema: AttachmentSchema,
    onSubmit: (values) => {
      handleFormData({ ...values });
    },
  });

  return (
    <form
      className="h-100 d-flex flex-column justify-content-between"
      onSubmit={handleSubmit}
    >
      <div className="add d-flex flex-wrap justify-content-center gap-4">
        {[1, 2, 3].map((picker, index) => {
          let image = values.attachments[index];
          if (image?.imgUrl) {
            image = values.attachments[index].imgUrl;
          }
          return (
            <PicturePicker
              key={index}
              index={index}
              setImage={handleImageUpload}
              image={image}
            />
          );
        })}
      </div>
      {errors.attachments && touched.attachments && (
        <Error text={errors.attachments}></Error>
      )}

      <div className="btns">
        <Button
          type="button"
          children="Back"
          className={formNo === 1 ? "d-none" : "cancel-btn"}
          onClick={() =>
            setFormNo(formNo > 1 && formNo < 5 ? formNo - 1 : formNo - 2)
          }
        />
        <div></div>
        <Button
          children={
            formNo === 4 ? (
              loading ? (
                <div
                  className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )
            ) : (
              "Next"
            )
          }
          className="save-btn"
          type="submit"
          disabled={loading}
        />
      </div>
    </form>
  );
};

export default Attachments;
