import React, { useEffect } from "react";
import { Styles } from "./favourites";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import GigCard4 from "../../components/Gigs/GigCard4";
import { useDispatch } from "react-redux";
import { getGigs } from "../../store/thunks/gigsThunk";
import { useAuthContext } from "../../features/authentication";
import useGigsSelector from "../../store/hooks/useGigsSelector";

const Favourites = () => {
  const dispatch = useDispatch();
  const { loading, gigs } = useGigsSelector();
  const {user, toggleFavourites} = useAuthContext()

  const handleRemoveFavGig = (id) => {
    toggleFavourites({ type: "gig", id });
  };
  
  useEffect(() => {
    dispatch(getGigs());
  }, [dispatch]);


  return (
    <Styles>
      <Header />
      <div className="container mt-5">
        <h3 className="fav-heading mb-3">Favorites</h3>
        <div>
          {user?.favGigs?.length > 0 && !loading ? (
            <div className="gigs-list w-100">
              {gigs?.map((card) => {
                return user?.favGigs?.map((favId) => {
                  return (
                    card._id === favId && (
                      <GigCard4
                        gig={card}
                        key={card._id}
                        removeFav={handleRemoveFavGig}
                      />
                    )
                  );
                });
              })}
            </div>
          ) : loading ? (
            <div className="d-flex align-items-center justify-content-center w-100">
              <p className="text-center p-5 grey-clr heading">Loading...</p>
            </div>
          ) : (
            !loading &&
            !user?.favGigs?.length > 0 && (
              <div className="d-flex align-items-center justify-content-center w-100">
                <p className="text-center p-5 grey-clr heading">
                  No Favorite Gig to show
                </p>
              </div>
            )
          )}
        </div>
      </div>
      <Footer />
    </Styles>
  );
};

export default Favourites;
