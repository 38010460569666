import styled from "styled-components";

export const Styles = styled.div`
  .gig-review {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    max-width: 1000px;
  }
  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .country {
    color: ${({ colors }) => colors.color.darkGrey};
    font-size: 14px;
  }
  .review-name {
    font-size: 18px;
  }
  .star-icon {
    fill: gold;
    width: 20px;
    height: 20px;
  }
`;
