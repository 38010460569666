import { useEffect } from "react";
import { useState } from "react";
import axios from "../../api/axios";

const useGetConfigurations = () => {
  const [state, setState] = useState({
    /**
     * @type {undefined | {{buyerTax: number, sellerTax: number, supportEmail: string, _id: string}}}
     */
    data: undefined,
    loading: true,
    error: undefined,
  });
  useEffect(() => {
    const fetchConfig = async () => {
      setState({ data: undefined, loading: true, error: undefined });
      try {
        const response = await axios.get(`/admin/settings`);
        setState({ data: response.data.settings, loading: false, error: undefined });
      } catch (e) {
        setState({ data: undefined, loading: false, error: e });
        console.log(e);
      }
    };
    fetchConfig()
  }, []);
  return state
};

export default useGetConfigurations;
