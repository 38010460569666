import { createContext, useContext } from "react";
import QBConfig from "../config";
import { prepareQBSDK } from "../api/services/init";

const QBApp = prepareQBSDK(QBConfig);
const QBContext = createContext({
  QBApp,
});

export function QBContextProvider({ children }) {
  return (
    <QBContext.Provider
      value={{
        QBApp,
      }}
    >
      {children}
    </QBContext.Provider>
  );
}

export function useQBContext(props) {
  return useContext(QBContext);
}
