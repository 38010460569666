import React from "react";

import { colors } from "../../../utils/Colors";
import { Styles } from "./descriptionProfile";

/**
 * @typedef {Object} Props
 * @property {string} description
 * @property {string[]} languages
 * @property {string} categoryName
 * @property {string[]} subcategory
 */
const DescriptionProfile = (
  /** @type {Props}**/ { categoryName, description, languages, subcategory }
) => {
  return (
    <Styles colors={colors}>
      <div className="description-profile">
        <div className="description">
          <h6 className="heading">Description</h6>
          <p className="answer">{description}</p>
        </div>
        <hr />
        <div className="langauges">
          <h6 className="heading">Languages</h6>
          <div>
            {languages?.map((lan, idx) => {
              return (
                <p className="answer " key={idx}>
                  {lan === "en" && "English"}
                </p>
              );
            })}
          </div>
        </div>
        <hr />
        <div className="skills">
          <h6 className="heading">Skills</h6>
          <div>
            <p className="answer text-capitalize">{categoryName}</p>
            {subcategory?.map((subcat, idx) => {
              return (
                <p className="answer text-capitalize" key={idx}>
                  {subcat.name}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </Styles>
  );
};
export const DescriptionProfilePlaceholder = () => {
  return (
    <Styles colors={colors}>
      <div className="description-profile">
        <div className="description">
          <h6 className="heading">
            <span class="placeholder placeholder-grey w-50"></span>
          </h6>
          <p className="answer">
            <span class="placeholder placeholder-grey w-25"></span>
          </p>
        </div>
        <hr />
        <div className="langauges">
          <h6 className="heading">
            <span class="placeholder placeholder-grey w-50"></span>
          </h6>
          <div className="d-flex gap-1 w-100">
            <span class="placeholder placeholder-grey w-25"></span>
            <span class="placeholder placeholder-grey w-25"></span>
          </div>
        </div>
        <hr />
        <div className="skills">
          <h6 className="heading">
            <span class="placeholder placeholder-grey w-50"></span>
          </h6>
          <div>
            <p className="answer text-capitalize">
              {<span class="placeholder placeholder-grey w-25"></span>}
            </p>
            <div className="d-flex gap-1 w-100">
              <span class="placeholder placeholder-grey w-25"></span>
              <span class="placeholder placeholder-grey w-25"></span>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default DescriptionProfile;
