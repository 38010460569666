import styled from "styled-components";

export const Styles = styled.div`
  @keyframes animateSearch {
    from {
      width: 0px;
    }
    to {
      width: 100%;
    }
  }

  width: 100%;

  .input-field {
    outline: none;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 7px;
    padding: 15px 10px;
    font-size: 16px;
    width: 100%;
  }
  .signup-input {
    height: 35px;
    margin-top: 15px;
  }
  .signup-input::-webkit-outer-spin-button,
  .signup-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .signup-input[type="number"] {
    -moz-appearance: textfield;
  }
  .footer-input {
    border: none;
    padding: 0 10px;
    font-size: 16px;
  }
  .budget-input {
    border: none;
    font-size: 16px;
    width: 120px;
    padding: 9px;
  }
  .setting-input {
    padding: 10px 10px;
  }
  .message-send-input {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .location-search-input {
    border-radius: 20px;
    padding: 5px 20px;
    animation: animateSearch 0.5s ease-in-out;
  }
  .input-field::-webkit-outer-spin-button,
  .input-field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .home-gig-search {
    padding: 7px 10px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px 0 0 5px;
  }
  .payment-input {
    padding: 10px;
    width: 100%;
  }
  .gig-creation-input {
    padding: 8px 10px;
  }

  @media only screen and (min-width: 375px) {
    .signup-input {
      height: 44px;
    }
  }
`;
