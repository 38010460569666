import React, { useEffect } from "react";
import "./index.css";
import IndexRoutes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { getCategories } from "./store/thunks/categoryThunk";
import { useDispatch } from "react-redux";
import Toast from "./components/common/Toast";
import { AuthContextProvider } from "./features/authentication";
import { MapkitProvider } from "react-mapkit";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <>
      <MapkitProvider
        tokenOrCallback={`${process.env.REACT_APP_BACKEND_URL}/v1/apple-mapkit/token`}
      >
        <Toast />
        <AuthContextProvider>
          <IndexRoutes />
        </AuthContextProvider>
      </MapkitProvider>
    </>
  );
};
export default App;
