import React, { useEffect, useState } from "react";

import { Styles } from "../PostJob/postJob";
import { colors } from "../../../utils/Colors";
import Header from "../../../components/common/Header";
import Footer from "../../../components/common/Footer";
import PostJobForm from "../../../components/Jobs/PostJobForm";
import Search from "../../../assets/images/postjob-img.svg";
import { updateJob } from "../../../store/thunks/jobsThunk";

import Geocode from "react-geocode";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const UpdateJob = () => {
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jobData = useLocation();
  const job = jobData.state;

  const handlePostJob = (values) => {
    let data = {
      ...values,
      _id: job._id,
    };
    dispatch(updateJob({ data, navigate }));
  };

  useEffect(() => {
    if (job) {
      Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
      Geocode.fromLatLng(job.location.latitude, job.location.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddress(response.results[0].formatted_address);
        },
        (error) => {
        }
      );
    }
  }, [job]);


  return (
    <Styles colors={colors}>
      <Header />
      <div className="post-job-section">
        <section>
          <h3>Edit Your Job</h3>
          <PostJobForm
            onSubmit={handlePostJob}
            jobValues={job}
            addressLabel={address}
          />
        </section>
        <div className="image-section">
          <div className="search">
            <img src={Search} alt="search image" />
          </div>
        </div>
      </div>
      <Footer />
    </Styles>
  );
};

export default UpdateJob;
