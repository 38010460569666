import styled from "styled-components";

export const Styles = styled.div`
  .manage-gigs-page {
    min-height: 50vh;
    padding: 10px;
    margin: 40px auto;
  }
  h3,
  h3 span {
    font-family: "NN_Bold";
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .heading {
    font-family: "NN_SemiBold";
  }

  .gigs-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    place-items: center;
    grid-gap: 25px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  @media only screen and (min-width: 768px) {
    .manage-gigs-page {
      padding: 10px 40px;
    }
  }
  @media only screen and (min-width: 992px) {
    .manage-gigs-page {
      max-width: 1100px !important;
    }
  }
`;
