import React, { useEffect } from "react";

import { formatMessageTime } from "../../../utils/helperFunctions";
import { getNotifications } from "../../../store/thunks/notificationsThunk";
import styled from "styled-components";
import { FaRegBell } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../../../features/authentication";
import { Link } from "react-router-dom";
import { colors } from "../../../utils/Colors";

const Notifications = () => {
  const dispatch = useDispatch();
  const { loading, error, notifications } = useSelector(
    (state) => state.notifications
  );
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    isAuthenticated && dispatch(getNotifications());
  }, [isAuthenticated, dispatch]);
  return (
    (<div className="dropdown">
      <FaRegBell
        className=" falcon-clr bell-icon dropdown-toggle"
        role="button"
        style={{ cursor: "pointer" }}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      />
      <ul
        className="notification-dropdown dropdown-menu mt-1 home-dropdown overflow-auto"
        style={{ minWidth: "400px" }}
      >
        <h5
          style={{ fontFamily: "MS_SemiBold", fontSize: "17px" }}
          className="m-0 p-2 border-bottom"
        >
          Notifications
        </h5>
        {error ? (
          <li
            className="d-flex align-items-center justify-content-center"
            style={{ height: "268px" }}
          >
            {error}
          </li>
        ) : loading ? (
          <li
            className="d-flex align-items-center justify-content-center"
            style={{ height: "268px" }}
          >
            Loading...
          </li>
        ) : !notifications?.length > 0 ? (
          <li
            className="d-flex align-items-center justify-content-center"
            style={{ height: "268px" }}
          >
            No Notification to show
          </li>
        ) : (
          notifications?.map((note) => {
            const subjectUrl =
              note?.subject === "offer"
                ? "/incoming-offers"
                : note?.subject === "buyer-order"
                ? `/order/${note.subjectId}/detail`
                : note?.subject === "seller-order"
                ? `/order/${note.subjectId}/seller/detail`
                : "";
            return (
              <StyledNotification colors={colors} key={note?._id}>
                <Link to={`${subjectUrl}`} className="text-body p-2 d-block border-bottom">
                  <div className="d-flex justify-content-between align-items-center gap-2">
                    <span className="text-capitalize">{note?.subject}</span>
                    <span className="grey-clr" style={{ fontSize: "10px" }}>
                      {formatMessageTime(note?.createdAt)}
                    </span>
                  </div>
                  <span style={{ fontSize: "12px" }}>{note?.title}</span>
                </Link>
              </StyledNotification>
            );
          })
        )}
      </ul>
    </div>)
  );
};
const StyledNotification = styled.li`
  :hover {
    background-color: #f5f5f5;
  }
`;
export default Notifications;
