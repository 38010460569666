import React from "react";
import Button from "../common/Button";
import useImperativeComponent from "../../hooks/common/useImperativeComponent";
import CreateCustomOfferModal from "./CreateCustomOfferModal";
import { useAuthContext } from "../../features/authentication";
/**
 * @typedef {Object} Props
 * @property {string} buyerId
 */
const CreateCustomOfferButton = (/** @type {Props} **/ { buyerId }) => {
  const { user } = useAuthContext();
  const { componentNode, openComponent } = useImperativeComponent(
    (isOpen, close) => (
      <CreateCustomOfferModal
        buyerId={buyerId}
        isOpen={isOpen}
        onClose={close}
      />
    )
  );
  if (user?.role !== "seller") {
    return null;
  }
  return (
    <>
      {componentNode}
      <Button className={"btn btn-dark"} onClick={openComponent}>
        {"Create an Offer"}
      </Button>
    </>
  );
};

export default CreateCustomOfferButton;
