const RoutesPath = {
  home: "/",
  error: "*",
  settings: "/settings",
  favourites: "/favourites",
  inbox: "/inbox",

  becomeSeller: "/become-seller",
  buyerOrders: "/buyer-orders",
  incomingOffers: "/incoming-offers",

  sentOffers: "/sent-offers",
  manageGigs: "/manage-gigs",
  gigDetail: "/gig/:gigId/detail",
  gigCreation: "/create-gig",
  updateGig: "update-gig/:gigId",

  sellerOrders: "/seller-orders",

  payments: "/payments",
  earnings: "/earnings",

  signIn: "/sign-in",
  signUp: "/sign-up",
  forgetPassword: "/forget-password",
  profile: "/profile/:profileId",

  manageJobs: "/jobs",
  jobDetail: "/jobs/:jobId/detail",
  postJob: "/post-job",
  updateJob: "/jobs/:job/update",

  orderDetail: "/order/:orderId/detail",
  sellerOrderDetail: "/order/:orderId/seller/detail",

  viewGigs: "/gigs/:category/:subcategory",

  legalTOS: "/legal/terms-of-service",
  legalPP: "/legal/privacy-policy",
  legalPTOS: "/legal/payment-terms-of-service",
  legalNDP: "/legal/non-discrimination-policy",
};
export default RoutesPath;
