import React from "react";
import { FaEnvelope } from "react-icons/fa";
import { useUserDialogs } from "../../api";
import { format } from "date-fns";
import Avatar from "../../../../components/common/Avatar";
import styled from "styled-components";
import { Link } from "react-router-dom";

const MessagesDialog = ({ dialogs, status }) => {
  if (status === "loading") {
    return (
      <>
        <h5
          className="m-0 pb-2 pt-2 ps-3 pe-3 border-bottom"
          style={{ fontFamily: "MS_SemiBold", fontSize: "17px" }}
        >
          Inbox
        </h5>
        <div className="flex-fill">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "268px" }}
          >
            Loading...
          </div>
        </div>
      </>
    );
  }
  if (status === "error") {
    return (
      <>
        <h5
          className="m-0 pb-2 pt-2 ps-3 pe-3 border-bottom"
          style={{ fontFamily: "MS_SemiBold", fontSize: "17px" }}
        >
          Inbox
        </h5>
        <div className="flex-fill">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "268px" }}
          >
            Something went wrong loading messages.
          </div>
        </div>
      </>
    );
  }
  if (dialogs.length <= 0) {
    return (
      <>
        <h5
          className="m-0 pb-2 pt-2 ps-3 pe-3 border-bottom"
          style={{ fontFamily: "MS_SemiBold", fontSize: "17px" }}
        >
          Inbox
        </h5>
        <div className="flex-fill">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "268px" }}
          >
            No Messages to show
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <h5
        className="m-0 pb-2 pt-2 ps-3 pe-3 border-bottom"
        style={{ fontFamily: "MS_SemiBold", fontSize: "17px" }}
      >
        Inbox
      </h5>
      <div className="flex-fill">
        {dialogs.map((dialog) => (
          <Link to={`/inbox?dialogId=${dialog._id}`} style={{ color: "black" }} key = {dialog._id}>
            <StyledHover key={dialog?._id}>
              <div className="d-flex gap-3 align-items-center pt-2 pb-2 ps-3 pe-3 border-bottom">
                <Avatar name={dialog.opponent.full_name || ""} />
                <div className="d-flex flex-column gap-2 flex-fill">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <p className="" style={{ fontSize: "14px" }}>
                      {dialog.opponent.full_name || dialog.opponent.email}
                    </p>
                    {dialog?.last_message_date_sent && (
                      <span className="grey-clr" style={{ fontSize: "10px" }}>
                        {format(dialog?.last_message_date_sent, "hh:mm a")}
                      </span>
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    {dialog.last_message && (
                      <p
                        className=""
                        style={{
                          fontSize: "0.8rem",
                          color:
                            dialog?.unread_messages_count > 0
                              ? "black"
                              : "#6c757d",
                          fontWeight:
                            dialog?.unread_messages_count > 0
                              ? "bolder"
                              : "normal",
                        }}
                      >
                        {dialog.last_message}
                      </p>
                    )}
                    {dialog?.unread_messages_count > 0 && (
                      <span
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          fontSize: 8,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "100%",
                          width: 16,
                          height: 16,
                          fontWeight: 600,
                        }}
                      >
                        {dialog?.unread_messages_count}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </StyledHover>
          </Link>
        ))}
      </div>
    </>
  );
};

const MessagesDialogTrigger = () => {
  const { dialogs, status } = useUserDialogs();
  const newMessagesCount = dialogs?.reduce(
    (prev, dialog) => prev + dialog.unread_messages_count,
    0
  );
  return (
    <div className="dropdown position-relative">
      <FaEnvelope
        className="falcon-clr bell-icon dropdown-toggle"
        role="button"
        style={{ cursor: "pointer" }}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      />
      {newMessagesCount ? (
        <span
          style={{
            backgroundColor: "red",
            color: "white",
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "100%",
            width: 20,
            height: 20,
            fontWeight: 600,
            position: "absolute",
            top: -8,
            right: -16,
          }}
        >
          {newMessagesCount}
        </span>
      ) : null}

      <div
        className="notification-dropdown dropdown-menu home-dropdown p-0"
        style={{ minWidth: "400px" }}
      >
        <div className="d-flex flex-column h-100">
          <MessagesDialog dialogs={dialogs} status={status} />
          <div className="border-top">
            <Link to="/inbox">
              <StyledHover className="p-2 d-flex align-items-center justify-content-center">
                {"See All Messages"}
              </StyledHover>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledHover = styled.div`
  :hover {
    background-color: #e6e6e6;
    cursor: pointer;
  }
`;
export default MessagesDialogTrigger;
