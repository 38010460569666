import axios from "../../api/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getInvoices = createAsyncThunk(
  "invoices/getInvoices",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get("/invoices");
      return response.data.invoices;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
