import styled from "styled-components";

export const Styles = styled.div`
  .seller-dashboard {
    padding: 20px;
    /* display: flex; */
  }
  .about-seller-wrapper {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
  }
  .about-seller {
    padding: 10px;
    /* order: 2; */
  }
  .seller-desc {
    /* order: 1; */
  }
  .dash-box {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 10px;
  }
  .avatar {
    border-radius: 50%;
    width: 100px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .avatar img {
    width: 100%;
  }
  .star-icon {
    fill: gold;
    width: 25px;
    height: 25px;
  }
  .progress-bar-low {
    width: 60px;
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }
  .speaker {
    border-radius: 50%;
    width: 100px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .speaker img {
    width: 100%;
  }
  .share-gigs-btn {
    border: 2px solid ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.secondary};
    background-color: ${({ colors }) => colors.color.white};
    padding: 10px 20px;
    border-radius: 5px;
  }

  @media only screen and (min-width: 576px) {
    .notice-text {
      padding: 0 80px;
    }
  }

  @media only screen and (min-width: 768px) {
  }

  @media only screen and (min-width: 992px) {
    .seller-desc {
      margin-top: 0 !important;
    }
  } ;
`;
