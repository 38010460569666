import React from "react";
import Avatar from "./Avatar";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import styled from "styled-components";

/**
 * @typedef {object} Props
 * @property {string} username
 * @property {string} rating
 * @property {string} profileImg
 * @property {string} userId
 * @property {'small' | 'medium' | 'large' | undefined} size
 * @property {boolean | undefined} hideRating
 *
 */
const AvatarRatingCard = (
  /** @type {Props} */ {
    profileImg,
    rating,
    userId,
    username,
    size = "medium",
    hideRating = false,
  }
) => {
  const sizeMap = {
    small: 26,
    medium: 40,
    large: 50,
  };
  return (
    <div
      className={`d-flex gap-1 align-items-center ${
        size === "small" ? "align-items-center" : ""
      }`}
    >
      <Avatar
        imgSrc={profileImg}
        name={username}
        width={sizeMap[size]}
        height={sizeMap[size]}
      />
      <AvatarRatingCardStyle
        style={{
          fontWeight: 500,
          fontSize: "16px",
        }}
        className={`d-flex overflow-hidden align-items-center ${
          size === "small" ? "gap-2 text-muted" : "flex-column"
        }`}
      >
        <Link
          className="text-truncate username-txt"
          to={`/profile/${userId}`}
          style={{ color: "black" }}
        >
          {username}
        </Link>
        {hideRating ? null : (
          <Rating
            rating={rating}
            size={size === "small" ? "compact" : "full"}
          />
        )}
      </AvatarRatingCardStyle>
    </div>
  );
};

const AvatarRatingCardStyle = styled.div`
  margin-top: -4px;
  &:hover {
    .username-txt {
      text-decoration: underline !important;
    }
  }
  .username-txt {
    color: #005b46;
    margin-left: 3px;
  }
`;
export default AvatarRatingCard;
