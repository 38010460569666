import styled from "styled-components";

export const Styles = styled.div`
  .post-job-section {
    margin: 20px 0;
    padding: 20px 0;
  }
  h3 {
    font-size: 23px;
    margin: 0 20px 20px 20px;
    font-family: "MS_SemiBold";
  }

  .image-section {
    display: none;
  }

  @media only screen and (min-width: 1024px) {
    .post-job-section {
      display: flex;
      padding: 20px;
    }
    .service-looking {
      width: 100%;
    }
    .image-section {
      display: block;
    }
    .search {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .image-section img {
      width: 70%;
    }
  }
`;
