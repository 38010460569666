import React from "react";

import Error from "../../../common/Error";
import InputField from "../../../common/InputField";
import Button from "../../../common/Button";

import { useFormik } from "formik";
import { DescriptionSchema } from "../../../../utils/PostJobSchema";

const Description = ({ formNo, setFormNo, handleFormData, gigValues }) => {
  const initialValues = {
    description: gigValues?.description || "",
    priceFrom: gigValues?.priceFrom || "",
    priceTo: gigValues?.priceTo || "",
    workDaysTo: gigValues?.workDaysTo || "",
    workDaysFrom: gigValues?.workDaysFrom || "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: DescriptionSchema,
      onSubmit: (values) => {
        handleFormData({ ...values });
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Add Description About Your Service</label>
        <div className="description">
          <textarea
            name="description"
            id="description"
            value={values.description}
            onChange={handleChange}
            autoComplete="off"
            onBlur={handleBlur}
            placeholder="Hello I am working in the field for..."
          ></textarea>
          {errors.description && touched.description ? (
            <Error text={errors.description}></Error>
          ) : (
            <Error text="" />
          )}
        </div>

        <div className="working">
          <div>
            <label>Price Min</label>
            <InputField
              className="gig-creation-input"
              type="number"
              name="priceFrom"
              id="priceFrom"
              value={values.priceFrom}
              onChange={handleChange}
              autoComplete="off"
              onBlur={handleBlur}
            />
            {errors.priceFrom && touched.priceFrom ? (
              <Error text={errors.priceFrom}></Error>
            ) : (
              <Error text="" />
            )}
          </div>
          <div>
            <label>Price Max</label>
            <InputField
              className="gig-creation-input"
              type="number"
              name="priceTo"
              id="priceTo"
              value={values.priceTo}
              onChange={handleChange}
              autoComplete="off"
              onBlur={handleBlur}
            />
            {errors.priceTo && touched.priceTo ? (
              <Error text={errors.priceTo}></Error>
            ) : (
              <Error text="" />
            )}
          </div>
        </div>
        <div className="working">
          <div>
            <label>Days Min</label>
            <InputField
              className="gig-creation-input"
              type="number"
              name="workDaysFrom"
              id="workDaysFrom"
              value={values.workDaysFrom}
              onChange={handleChange}
              autoComplete="off"
              onBlur={handleBlur}
            />
            {errors.workDaysFrom && touched.workDaysFrom ? (
              <Error text={errors.workDaysFrom}></Error>
            ) : (
              <Error text="" />
            )}
          </div>
          <div>
            <label>Days Max</label>
            <InputField
              className="gig-creation-input"
              type="number"
              name="workDaysTo"
              id="workDaysTo"
              value={values.workDaysTo}
              onChange={handleChange}
              autoComplete="off"
              onBlur={handleBlur}
            />
            {errors.workDaysTo && touched.workDaysTo ? (
              <Error text={errors.workDaysTo}></Error>
            ) : (
              <Error text="" />
            )}
          </div>
        </div>
      </div>
      <div className="btns">
        <Button
          type="button"
          children="Back"
          className={formNo === 1 ? "d-none" : "cancel-btn"}
          onClick={() =>
            setFormNo(formNo > 1 && formNo < 5 ? formNo - 1 : formNo - 2)
          }
        />
        <div></div>
        <Button
          children={formNo === 4 ? "Submit" : "Next"}
          className="save-btn"
          type="submit"
        />
      </div>
    </form>
  );
};

export default Description;
