import React, { useEffect } from "react";

import { Styles } from "./profile";
import { getReviews } from "../../../store/thunks/reviewThunk";
import { filterGigs } from "../../../store/thunks/gigsThunk";
import {
  getBuyerProfile,
  getProfileData,
} from "../../../store/thunks/profileThunk";

import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../../../features/authentication";
import useGigsSelector from "../../../store/hooks/useGigsSelector";
import DescriptionProfile, {
  DescriptionProfilePlaceholder,
} from "../DesriptionProfile";
import Header from "../../../components/common/Header";
import Footer from "../../../components/common/Footer";
import { colors } from "../../../utils/Colors";
import AboutProfile, { AboutProfilePlaceholder } from "../AboutProfile";
import GigPlaceholder from "../../../components/common/Placeholders/GigPlaceholder";
import GigCard from "../../../components/Gigs/GigCard";
import ReviewPlaceholder from "../../../components/common/Placeholders/ReviewPlaceholder";
import GigReview from "../../../components/Gigs/GigReview";

/**
 *
 * @typedef {import("../../../types/server").UserProfileData | undefined} ProfileInfoLocationState
 *
 */
const Profile = () => {
  const { user } = useAuthContext();
  const params = useParams();
  const isMyProfile = Boolean(user?._id === params?.profileId);
  const locationData = useLocation();
  const dispatch = useDispatch();
  const { data: userData, loading: userDataLoading } = useSelector((state) =>
    isMyProfile ? state.profile.getUserProfile : state.profile.getBuyerProfile
  );
  const { gigs: userGigs, loading: gigsLoading } = useGigsSelector();
  const { data: userReviews, loading: userReviewsLoading } = useSelector(
    (state) => state.reviews.getReviews
  );
  const initalData = /** @type {ProfileInfoLocationState} **/ (
    locationData?.state
  );
  /**
   * @type {import("../../../types/server").UserProfileData | undefined}
   */
  const data = userData || initalData;
  const userId = params.profileId;
  const initalLoading = initalData ? false : userDataLoading;
  useEffect(() => {
    if (userId) {
      dispatch(getReviews({ createdTo: userId }));
      if (isMyProfile) {
        dispatch(filterGigs({ userId }));
        dispatch(getProfileData({ userId }));
      } else {
        dispatch(getBuyerProfile({ userId }));
      }
    }
  }, [dispatch, isMyProfile, userId]);

  return (
    <Styles colors={colors}>
      <Header />
      <div className="container">
        <div className="row">
          <div className="profile mt-3 col-sm-12 col-lg-10 m-auto">
            <div className="about-profile-container col-sm-12 col-md-5 ">
              {initalLoading ? (
                <AboutProfilePlaceholder />
              ) : data ? (
                <AboutProfile
                  img={data?.profileImg?.imgUrl}
                  username={data?.username}
                  rating={data?.rating}
                  responseTime={data?.sellerStats?.averageResponseTimeHours}
                  online={data?.isOnline}
                  lastDelivery={data?.sellerStats?.lastDeliveryDate}
                  createdAt={data?.createdAt}
                  sellerLevel={data?.sellerStats?.sellerLevel}
                  isProVerified={data?.sellerStats?.isProVerified}
                  ordersCompleted={data?.sellerStats?.completedOrders}
                  location={data?.location}
                  qbId={data?.qbId}
                  role={data?.role}
                  userId={data?._id}
                />
              ) : (
                <div>{"Error in loading user data"}</div>
              )}
            </div>
            {isMyProfile && (
              <div className="description-profile-container col-sm-12 col-md-7">
                {initalLoading ? (
                  <DescriptionProfilePlaceholder />
                ) : data && data.sellerStats ? (
                  <DescriptionProfile
                    description={data.sellerStats?.description}
                    languages={data.sellerStats?.languages}
                    category={data.sellerStats?.category?.name}
                    subcategory={data.sellerStats?.subcategory}
                  />
                ) : (
                  <div>{"Error in loading user data"}</div>
                )}
              </div>
            )}
          </div>
          <div className="row mt-3">
            {isMyProfile && (
              <div className="profile-gigs col-12 col-lg-10 m-auto">
                {initalLoading ? (
                  <p className="w-25" style={{ height: 20 }}>
                    <span class="placeholder placeholder-grey w-100"></span>
                  </p>
                ) : (
                  <h3 className="text-capitalize">
                    {data?.username} <span>Gigs</span>
                  </h3>
                )}
                <div className="profile-gigs-list gap-3">
                  {gigsLoading ? (
                    <GigPlaceholder placeholders={[1, 2]} />
                  ) : userGigs?.length > 0 && !gigsLoading ? (
                    userGigs?.map((gig, index) => {
                      return index < 3 && <GigCard key={index} {...gig} />;
                    })
                  ) : (
                    <p className="no-to-show">No Gigs to Show</p>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div className="profile-gigs col-12 col-lg-10 m-auto mb-5">
                <div>
                  <h3>
                    <span>Reviews:</span>
                  </h3>
                  {userReviewsLoading ? (
                    <ReviewPlaceholder placeholders={[1, 2, 3]} />
                  ) : userReviews?.length ? (
                    <>
                      {userReviews?.map((review, index) => {
                        return (
                          index < 3 && <GigReview {...review} key={index} />
                        );
                      })}
                    </>
                  ) : (
                    <p className="text-muted">No Reviews to show</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Styles>
  );
};

export default Profile;
