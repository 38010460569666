import React, { useState } from "react";
import { Styles } from "./gigCard4";
import { colors } from "../../../utils/Colors";
import Rating from "../../common/Rating";
import { FiHeart } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
// import { deleteMyGig } from "../../../store/thunks/gigsThunk";

const GigCard4 = ({ gig, removeFav }) => {
  const [isFav, setIsFav] = useState(true);
  const navigate = useNavigate();

  // const handleDeleteGig = (gig) => {
  //   dispatch(deleteMyGig(gig));
  // };

  const bgImg = gig?.attachments?.[0].imgUrl;
  const id = gig?._id;
  const data = gig;
  const profileImg = gig?.createdBy?.profileImg?.imgUrl;
  const sellerLevel = gig?.createdBy?.sellerStats?.sellerLevel;
  const username = gig?.createdBy?.username;
  const title = gig?.title;
  const rating = gig?.rating;
  const ordersCompleted = gig?.completedOrdersCount;
  const priceFrom = gig?.priceFrom;
  const priceTo = gig?.priceTo;

  return (
    <Styles colors={colors}>
      <div className="gig-card">
        <div className="gig-img">
          <img className="bg-img" src={bgImg} alt="Background Image" />
          <div
            className="options d-flex align-items-center justify-content-center"
            onClick={() => {
              removeFav(gig._id);
              setIsFav((isFav) => !isFav);

            }}
          >
            <FiHeart
              className={isFav == true ? "filled fav-icon" : "fav-icon"}
            />
          </div>
          {rating > 0 && (
            <div className="gig-rating">
              <Rating rating={rating} ordersCompleted={ordersCompleted} />
            </div>
          )}
        </div>
        <div className="gig-content">
          <p
            className="gig-title"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/gig/${id}/detail`, {
                state: data,
              });
            }}
          >
            {title}
          </p>
          <p className="gig-price">
            Price:{" "}
            <span>
              ${priceFrom} - ${priceTo}
            </span>
          </p>
        </div>
        {/* {onConfirm && (
          <SweetAlert
            danger
            title={"Are you Sure you want to delete this Job ?"}
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            showCancel
            focusCancelBtn
            onConfirm={() => {
              setOnConfirm(false);
              handleDeleteGig(data);
            }}
            onCancel={() => setOnConfirm(false)}
          ></SweetAlert>
        )} */}
      </div>
    </Styles>
  );
};

export default GigCard4;
