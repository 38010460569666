import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import store from "./store/configure";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.querySelector("#root"));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleOAuthProvider>
);
