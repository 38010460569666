import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
export const useMutationReduxState = ({ actions, reducerName }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => {
    return state[reducerName];
  });
  
  const setStateById = useCallback(
    (id, setState) => {
      const stateId = JSON.stringify(id);
      dispatch(
        actions.setStateForId({
          id:stateId,
          setState,
        })
      );
    },
    [dispatch, actions]
  );

  return { state, setStateById };
};
export const useMutationRedux = ({ id, mutationFn, actions, reducerName }) => {
  const uniqueId = useMemo(() => JSON.stringify(id), [id]);
  const { state, setStateById } = useMutationReduxState({
    actions,
    reducerName,
  });
  /**
   * @type {{status:"pending"|"success"|"error"|"idle",error?:any,data?:any}}
   */
  const stateWithDefault = state[uniqueId] || {
    data: undefined,
    status:'idle',
    error:undefined
  }  
  const setState = useCallback(setState => {
    const id = JSON.parse(uniqueId)
    setStateById(id, setState)
  }, [uniqueId, setStateById])

  const handleMutation = useCallback(async (params) => {
    setState((state) => ({ ...state, status: "pending" }));
    try {
      const response = await mutationFn(params);
      setState((state) => ({
        ...state,
        data: response,
        error: null,
        status: "success",
      }));
      return response
    } catch (e) {
      setState((state) => ({ ...state, status: "error", error: e }));
    }
    return undefined
  }, [mutationFn, setState]);

  return {
    ...stateWithDefault,
    setState,
    mutate: handleMutation,
  };
};
