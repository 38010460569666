import React, { useEffect } from "react";

import Button from "../../common/Button";
import { formatMessageTime } from "../../../utils/helperFunctions";
import { resumeJob } from "../../../store/thunks/jobActionsThunk";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMyJobs } from "../../../store/thunks";

const PausedJobs = () => {
  const { myJobs, loading } = useSelector((state) => state.jobs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closedJobs = myJobs?.filter((job) => {
    if (job.status === "closed") {
      return true;
    }
    return false;
  });


  useEffect(() => {
    dispatch(getMyJobs());
  }, [dispatch]);



  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th className="table-heading">Date</th>
            <th className="table-heading">Job Description</th>
            <th className="table-heading">Price</th>
            <th className="table-heading">Actions</th>
          </tr>
        </thead>
        <tbody>
          {closedJobs.length > 0 ? (
            <>
              {myJobs?.map((myJob) => {
                return (
                  myJob.status === "closed" && (
                    <tr key={myJob._id}>
                      <td>
                        <p style={{ fontSize: "14px" }}>
                          {formatMessageTime(myJob.createdAt)}
                        </p>
                      </td>
                      <td style={{ minWidth: "180px" }} >
                        {myJob.description.length > 65
                          ? myJob.description.slice(0, 64) + "..."
                          : myJob.description}
                      </td>
                      <td>
                        <p style={{ minWidth: "80px" }}>
                          ${myJob.budgetMin} - ${myJob.budgetMax}
                        </p>
                      </td>
                      <td>
                        <Button
                          children="Resume"
                          className="offer-btn resume-btn px-2 py-1"
                          disabled={myJob.loading}
                          onClick={() =>
                            dispatch(resumeJob({ id: myJob._id, navigate }))
                          }
                        />
                      </td>
                    </tr>
                  )
                );
              })}
            </>
          ) : closedJobs.length === 0 && loading === false ? (
            <tr>
              <td></td>
              <td></td>
              <td>
                <p className="mt-4 grey-clr">No Paused Job</p>
              </td>
            </tr>
          ) : (
            loading === true && (
              <tr>
                <td></td>
                <td></td>
                <td>
                  <div class="spinner-border text-secondary" role="status">
                    <span class="sr-only d-none">Loading...</span>
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PausedJobs;
