import styled from "styled-components";

export const Styles = styled.div`
  .category {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .chevron {
    width: 12px;
  }
  h5 {
    margin: 0;
    font-size: 14px;
    cursor: pointer;
    text-transform: capitalize;
  }
  h3 {
    text-transform: capitalize;
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
  }
  .dropdowns {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 3px;
    position: relative;
  }
  .drop-box {
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 20px;
    background-color: white;
    z-index: 3;
  }
  .search-bar {
    display: flex;
    align-items: center;
    margin: 20px auto;
    max-width: 500px;
  }
  .search-btn {
    background-color: ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
    border: none;
    padding: 8px 20px;
    border-radius: 0 5px 5px 0;
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }

  @media only screen and (min-width: 768px) {
    .drop-box {
      height: 400px;
      width: 300px;
      position: absolute;
      top: 34px;
      left: 0;
      z-index: 3;
    }
  }
`;
