import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import InputField from "../../../components/common/InputField";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Button from "../../../components/common/Button";
import Error from "../../../components/common/Error";
import { openErrorMessage } from "../../../store/slices/toastSlice";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../../features/authentication";
import { useNavigate } from "react-router-dom";
import { geocodeByPlaceId } from "react-places-autocomplete";
import AppleAutoComplete from "../../../components/common/AppleAutoComplete";

export const validationSchema = Yup.object({
  name: Yup.string().required("First Name is required"),
  lastname: Yup.string().required("Last Name is required"),
  username: Yup.string().required("Username is required"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
  address: Yup.object().required("Location is Required"),
});

const initialValues = {
  name: "",
  lastname: "",
  username: "",
  email: "",
  password: "",
  address: null,
};

const SignUpForm = ({ onSuccess }) => {
  const [serverError, setServerError] = useState(null);
  const { signUp } = useAuthContext();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = { ...values };
        if (values.address?.value?.place_id) {
          const response = await geocodeByPlaceId(
            values.address?.value?.place_id
          );
          const {
            geometry: {
              location: { lat, lng },
            },
          } = response[0];
          payload.location = {
            latitude: lat(),
            longitude: lng(),
          };
        }
        const response = await signUp(payload);
        onSuccess && onSuccess(response.data.user);
      } catch (error) {
        dispatch(openErrorMessage(error.message));
        setServerError(error.message);
      }
    },
  });
  const onSelectPlace = (location) => {
    const {
      coordinate: { latitude, longitude },
      description,
    } = location.value;
    setFieldValue("address", {
      latitude,
      longitude,
      address: description,
    });
  };
  return (
    <>
      <div
        className="d-flex flex-column justify-content-between mb-1"
        style={{ minHeight: "120px" }}
      >
        <div
          style={{ width: 200, cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img
            className="img-fluid"
            src={"/logo-horizontal.png"}
            alt={"Logo"}
          />
        </div>
        <div className="d-flex flex-column align-items-start justify-content-start">
          <h2 className="text-xl font-semibold">Sign up</h2>
          <p className="m-0">
            Already Have an Account?{" "}
            <span onClick={() => navigate("/sign-in")}>Sign in</span> or{" "}
            <span onClick={() => navigate("/")}>Continue as Guest</span>
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {serverError ? (
          <div className="alert alert-danger mb-0 " role="alert">
            {serverError}
          </div>
        ) : null}
        <div>
          <div className="first-name-last-name">
            <div>
              <InputField
                className="signup-input"
                placeholder="First Name"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                autoComplete="off"
                onBlur={handleBlur}
                error={errors.name && touched.name ? errors.name : null}
              />
            </div>
            <div>
              <InputField
                className="signup-input"
                placeholder="Last Name"
                name="lastname"
                id="lastname"
                value={values.lastname}
                onChange={handleChange}
                autoComplete="off"
                onBlur={handleBlur}
                error={
                  errors.lastname && touched.lastname ? errors.lastname : null
                }
              />
            </div>
          </div>
          <InputField
            className="signup-input"
            placeholder="Username"
            name="username"
            id="username"
            value={values.username}
            onChange={handleChange}
            autoComplete="off"
            onBlur={handleBlur}
            error={errors.username && touched.username ? errors.username : null}
          />

          <InputField
            className="signup-input"
            type="email"
            placeholder="Email"
            name="email"
            id="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email ? errors.email : null}
          />

          <div style={{ marginTop: "15px" }}>
            <AppleAutoComplete
              onSelect={onSelectPlace}
              style={{ width: "100%", borderRadius: 8 }}
            />
            {errors.address && touched.address && (
              <Error text={errors.address} />
            )}
          </div>
          <InputField
            className="signup-input"
            type="password"
            placeholder="Password"
            name="password"
            id="password"
            value={values.password}
            onChange={handleChange}
            autoComplete="off"
            onBlur={handleBlur}
            error={errors.password && touched.password ? errors.password : null}
          />
          <Button
            type="submit"
            className="continue"
            children={
              isSubmitting ? (
                <div
                  className="spinner-border spinner-border-sm and spinner-grow-sm"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              ) : (
                <p>Agree and Continue</p>
              )
            }
          />
        </div>
      </form>
    </>
  );
};

export default SignUpForm;
