import styled from "styled-components";

export const Styles = styled.div`
  .rating {
    display: flex;
    align-items: center;
  }
  .rating p {
    margin-left: 5px;
  }
  .star-icon {
    fill: gold;
    width: 20px;
    height: 20px;
  }
  .rating span {
    color: ${({ colors }) => colors.color.darkGrey};
    margin-left: 5px;
  }
`;
