export const createSessionRequest = async (QBApp, { login, password }) => {
  return new Promise((resolve, reject) => {
    QBApp.createSession({ login, password }, (errorCreateSession, session) => {
      if (errorCreateSession) {
        reject(JSON.stringify(errorCreateSession));
      } else {
        resolve(session);
      }
    });
  });
};
export const deleteSessionRequest = async (QBApp) => {
  return new Promise((resolve, reject) => {
    QBApp.logout((errorDestroySession) => {
      if (errorDestroySession) {
        reject(JSON.stringify(errorDestroySession));
      } else {
        resolve();
      }
    });
  });
};

export const startSessionWithToken = async (QBApp, token) => {
  return new Promise((resolve, reject) => {
    QBApp.startSessionWithToken(token, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.session);
      }
    });
  });
};
