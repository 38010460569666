import Home from "../pages/Home";
import Paths from "../utils/Paths";
import PostJob from "../pages/Jobs/PostJob";
import SignUp from "../pages/Authentication/SignUp";
import SignIn from "../pages/Authentication/SignIn";
import GigDetail from "../pages/Gigs/GigDetail";
import ViewGigs from "../pages/Gigs/ViewGigs";
import SellerDashboard from "../pages/SellerDashboard/Dashboard";
import Profile from "../pages/Profile/Profile";
import GigCreation from "../pages/Gigs/GigCreation";
import Error404 from "../components/common/Error404";
import Settings from "../pages/Settings";
import UpdateJob from "../pages/Jobs/UpdateJob";
import ManageOrders from "../pages/Orders/ManageOrders";
import OrderDetail from "../pages/Orders/OrderDetail";
import Forgetpassword from "../pages/Authentication/ForgetPassword";
import IncomingOffers from "../pages/Offers/IncomingOffers";
import SentOffers from "../pages/Offers/SentOffers";
import ManageGigs from "../pages/Gigs/ManageGigs";
import Payment from "../pages/Payment";
import Earnings from "../pages/Earnings";
import UpdateGig from "../pages/Gigs/UpdateGig";
import BecomeSeller from "../pages/SellerDashboard/BecomeSeller";
import SellerOrderDetail from "../pages/Orders/SellerOrderDetails";
import JobDetail from "../pages/Jobs/JobDetail";
import Favourites from "../pages/Favourites";
import Inbox from "../pages/Inbox";
import ManageJobs from "../pages/ManageJobs";
import LegalNDP from "../pages/Legal/LegalNDP";
import LegalTOS from "../pages/Legal/LegalTOS";
import LegalPP from "../pages/Legal/LegalPP";
import LegalPTOS from "../pages/Legal/LegalPTOS";

export const PrivateRoutes = [
  { path: Paths.seller, component: <SellerDashboard /> },
  { path: Paths.settings, component: <Settings /> },
  { path: Paths.becomeSeller, component: <BecomeSeller /> },

  //[TODO:Remove this comment] Following are OK
  { path: Paths.inbox, component: <Inbox /> },
  { path: Paths.favourites, component: <Favourites /> },
  { path: Paths.manageJobs, component: <ManageJobs /> },
  { path: Paths.updateJob, component: <UpdateJob /> },
  { path: Paths.postJob, component: <PostJob /> },
  { path: Paths.incomingOffers, component: <IncomingOffers /> },
  { path: Paths.buyerOrders, component: <ManageOrders ordersType="Sent" /> },

  //Review Server Error
  { path: Paths.orderDetail, component: <OrderDetail /> },
];

export const SellerRoutes = [
  { path: Paths.payments, component: <Payment /> },
  { path: Paths.earnings, component: <Earnings /> },

  //[TODO:Remove this comment] Following are OK
  { path: Paths.manageGigs, component: <ManageGigs /> },
  { path: Paths.gigCreation, component: <GigCreation /> },
  { path: Paths.updateGig, component: <UpdateGig /> },
  { path: Paths.sentOffers, component: <SentOffers /> },
  {
    path: Paths.sellerOrders,
    component: <ManageOrders ordersType="Received" />,
  },

  //Review Server Error
  { path: Paths.sellerOrderDetail, component: <SellerOrderDetail /> },
];

export const PublicRoutes = [
  { path: Paths.signUp, component: <SignUp /> },

  //[TODO:Remove this comment] Following are OK
  { path: Paths.signIn, component: <SignIn /> },
  { path: Paths.forgetPassword, component: <Forgetpassword /> },
];
export const commonRoutes = [
  //[TODO:Remove this comment] Following are OK
  { path: Paths.home, component: <Home /> },
  { path: Paths.error, component: <Error404 /> },
  { path: Paths.profile, component: <Profile /> },
  { path: Paths.viewGigs, component: <ViewGigs /> },
  { path: Paths.gigDetail, component: <GigDetail /> },
  { path: Paths.jobDetail, component: <JobDetail /> },
  { path: Paths.legalTOS, component: <LegalTOS /> },
  { path: Paths.legalPP, component: <LegalPP /> },
  { path: Paths.legalPTOS, component: <LegalPTOS /> },
  { path: Paths.legalNDP, component: <LegalNDP /> },
];
