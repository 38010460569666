import { intervalToDuration, parseISO } from "date-fns";
import React from "react";
import Button from "../../../../../components/common/Button";
import useImperativeComponent from "../../../../../hooks/common/useImperativeComponent";
import CreateCustomOfferButton from "../../../../../components/CustomOffer/CreateCustomOfferButton";

const ChatHeader = ({ recipent }) => {

  const LastSeenAt = recipent.last_request_at
    ? intervalToDuration({
        start: parseISO(recipent.last_request_at),
        end: new Date(),
      })
    : null;
  const lastSeenFormated = LastSeenAt
    ? LastSeenAt.days
      ? LastSeenAt.days + " days ago"
      : LastSeenAt.hours
      ? LastSeenAt.hours + " hours ago"
      : LastSeenAt.minutes
      ? LastSeenAt.minutes + " minutes ago"
      : LastSeenAt.seconds
      ? LastSeenAt.seconds + " seconds ago"
      : "Just Now"
    : "Never";
  return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-2">
            <p style={{ fontSize: "1.2rem" }}>{recipent.full_name}</p>
          </div>
          <p className="text-muted" style={{ fontSize: "0.8rem" }}>
            {`Last Seen: ${lastSeenFormated}`}
          </p>
        </div>
        <CreateCustomOfferButton buyerId={recipent.login} />
      </div>
  );
};

export default ChatHeader;
