import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { updateUser } from "../slices/authSlice";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

/**
 * @typedef {object} UserProfileData
 * @property {string} _id
 * @property {string} username
 * @property {string} role
 * @property {boolean} isOnline
 * @property {Attachment} profileImg
 * @property {SellerStats} sellerStats
 * @property {string} qbId
 * @property {number} rating
 * @property {location} location
 */



export const getProfileData = createAsyncThunk(
  "profile/getProfileData",
  /**
   * @param {object} data
   * @param {string} data.userId
   */
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/users/profile-public/${data.userId}`);
      return response.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getBuyerProfile = createAsyncThunk(
  "profile/getBuyerprofile",
  /**
   * @param {object} data
   * @param {string} data.userId
   */
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/users/buyer/profile/public/${data.userId}`);
      return response.data.user;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const becomeSeller = createAsyncThunk(
  "profile/becomeSeller",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data, navigate } = payload;
      const { category, subcategories, description, languages, profileImg } =
        data;
      const subcategoryIds = subcategories.map((subcat) => subcat._id);
      const categoryId = category._id;

      const formData = new FormData();
      formData.append("attachment", profileImg);
      const imgResponse = await axios.post("attachments/one", formData);

      let image = {
        publicId: imgResponse.data.attachment.publicId,
        imgUrl: imgResponse.data.attachment.url,
      };

      const response = await axios.post("/users/become-seller", {
        category: categoryId,
        subcategories: subcategoryIds,
        profileImg: image,
        languages,
        description,
      });

      const user = response.data.user;

      dispatch(updateUser(user));
      dispatch(
        openSuccessMessage(
          "Congratulations! your account is now a seller account"
        )
      );
      navigate("/");
      return user;
    } catch (error) {
      dispatch(openErrorMessage("Sorry Something went wrong"));
      return rejectWithValue(error.message);
    }
  }
);
