import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";


export const useQueryReduxState = ({ actions, reducerName }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => {
    return state[reducerName];
  });
  const getStateById = useCallback((id) => {
    const stateId = typeof id === "string" ? id : JSON.stringify(id);
    return state[stateId];
  }, [state]);

  const setStateById = useCallback(
    (id, setState) => {
      const stateId = typeof id === "string" ? id : JSON.stringify(id);
      dispatch(
        actions.setStateForId({
          id: stateId,
          setState,
        })
      );
    },
    [dispatch, actions]
  );

  return { state,getStateById, setStateById };
};
export const useQueryRedux = ({ id, fetch, actions, reducerName }) => {
  const uniqueId = useMemo(() => JSON.stringify(id), [id]);
  const { getStateById, setStateById } = useQueryReduxState({
    actions,
    reducerName,
  });

  /**
   * @type {{status:"loading"|"success"|"error",error?:any,data?:any}}
   */
  const stateWithDefault = getStateById(id) || {
    data: undefined,
    status: "loading",
    error: undefined,
  };

  const setState = useCallback(
    (setState) => {
      const id = JSON.parse(uniqueId);
      setStateById(id, setState);
    },
    [uniqueId, setStateById]
  );

  const fetchCall = useCallback(async () => {
    const id = JSON.parse(uniqueId);
    try {
      setState((state) => ({
        ...state,
        status: "loading",
      }));
      const response = await fetch(id);
      setState((state) => ({
        ...state,
        data: response,
        error: null,
        status: "success",
      }));
    } catch (e) {
      setState((state) => ({ ...state, status: "error", error: e }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId, setState]);
  useEffect(() => {
    fetchCall();
  }, [fetchCall]);

  return {
    ...stateWithDefault,
    setState,
  };
};
