import { useSelector } from "react-redux";

/**
 * @typedef {Object} Attachment
 * @property {string} imgUrl
 * @property {string} pubicId
 *
 * @typedef {Object} Category
 * @property {string} _id
 * @property {string} name
 *
 * @typedef {Object} SellerStats
 * @property {'Rookie'} sellerLevel
 * @property {number} completedOrders
 *
 * @typedef {Object} User
 * @property {string} _id
 * @property {string} username
 * @property {boolean} isOnline
 * @property {Attachment} profileImg
 * @property {SellerStats} sellerStats
 *
 * @typedef {Object} Gig
 * @property {string} _id
 * @property {'online'} workType
 * @property {number} workDaysTo
 * @property {number} workDaysFrom
 * @property {string} updatedAt
 * @property {string} title
 * @property {'active'} status
 * @property {string} description
 * @property {string} priceFrom
 * @property {string} priceTo
 * @property {Category} category
 * @property {Category} subcategory
 * @property {string} createdAt
 * @property {User} createdBy
 * @property {number} rating
 * @property {number} completedOrderscount
 * @property {string} requirements
 * @property {Array} attachments
 * @property {number} latitude
 * @property {number} longitude
 * @property {string} address
 *
 */

/**
 *
 * @returns {{gigs: Array<Gig>, loading: boolean}}
 */
const useGigsSelector = () => {
  return useSelector((state) => state.gigs ?? []);
};

export default useGigsSelector;
