import { createSlice } from "@reduxjs/toolkit";
import {
  getJobs,
  postJob,
  getMyJobs,
  deleteMyJob,
  getJobOffers,
  rejectJobOffer,
  updateJob,
  filterJobs,
  getJobById,
  jobsByPaginate,
} from "../thunks/jobsThunk";
import { pauseJob, resumeJob } from "../thunks/jobActionsThunk";

const initialState = {
  loading: false,
  error: "",
  success: null,
  jobsData: [],
  myJobs: [],
  jobOffers: [],
  currentSection: 2,
  paymentModal: null,
  jobsByPaginate: {
    loading: false,
    error: "",
    hasMore: true,
    data: undefined,
  },
  getJobById: {
    data: undefined,
    error: null,
    loading: true,
  },
};

const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};
const handlefilterGigsFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.jobsData = action.payload;
};

const handleOfferRejectPending = (state, action) => {
  const paylaod = action.meta.arg || {};
  const offerId = paylaod._id;
  state.jobOffers = state.jobOffers.map((offer) => {
    if (offer._id === offerId) {
      return {
        ...offer,
        loading: true,
      };
    }
    return offer;
  });
};
const handleGetJobOffersPending = (state) => {
  state.loading = true;
  state.error = null;
  state.jobOffers = [];
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const handleGetJobsFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.jobsData = action.payload;
};
const handlePostJobFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
};
const handleUpdateJobFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
};
const handleGetMyJobsFulfilled = (state, action) => {
  state.loading = false;
  state.error = false;
  state.myJobs = action.payload;
};
const handleDeleteMyJobFulfilled = (state, action) => {
  state.loading = false;
  state.myJobs = state.myJobs.filter((job) => job._id !== action.payload._id);
  state.success = action.payload.message;
};
const handleGetJobOffersFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.jobOffers = action.payload;
};
const handleRejectJobOfferFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.jobOffers = state.jobOffers.filter(
    (offer) => offer._id !== action.payload._id
  );
  state.success = action.payload.message;
};
const handlePauseJobFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.myJobs = state.myJobs.filter((job) => job._id !== action.payload.id);
};
const handleResumeJobPending = (state, action) => {
  const payload = action.meta.arg || {};
  const jobId = payload.id;
  state.myJobs = state.myJobs.map((job) => {
    if (job._id === jobId) {
      return {
        ...job,
        loading: true,
      };
    }
    return job;
  });
};
const handleResumeJobFulfilled = (state, action) => {
  // state.myJobs = action.payload;
};
const handleResumeJobRejected = (state, action) => {
  state.myjobs = state.myJobs.map((job) => {
    return (job.loading = false);
  });
  state.error = action.payload;
};
const handlePendingGetJobById = (state, action) => {
  state.getJobById = {
    data: undefined,
    error: null,
    loading: true,
  };
};
const handleFulfilledGetJobById = (state, action) => {
  state.getJobById = {
    data: action.payload,
    error: null,
    loading: false,
  };
};
const handleRejectedGetJobById = (state, action) => {
  state.getJobById = {
    data: undefined,
    error: action.payload,
    loading: false,
  };
};

const handleJobsByPaginatePending = (state, action) => {
  state.jobsByPaginate.loading = true;
  state.jobsByPaginate.error = "";
};

const handleJobsByPaginateFulfilled = (state, action) => {
  state.jobsByPaginate.loading = false;
  state.jobsByPaginate.error = "";
  state.jobsByPaginate.page = action.payload.page;
  state.jobsByPaginate.hasMore = !!action.payload.jobs.length;
  if (action.payload.page === 1) {
    state.jobsByPaginate.data = action.payload.jobs;
  } else {
    state.jobsByPaginate.data = [
      ...state.jobsByPaginate.data,
      ...action.payload.jobs,
    ];
  }
};

const handleJobsByPaginateRejected = (state, action) => {
  state.jobsByPaginate.loading = false;
  state.jobsByPaginate.error = action.payload;
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setPaymentModal: (state, action) => {
      state.paymentModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobs.pending, handlePending)
      .addCase(getJobs.fulfilled, handleGetJobsFulfilled)
      .addCase(getJobs.rejected, handleRejected)
      .addCase(getJobById.pending, handlePendingGetJobById)
      .addCase(getJobById.fulfilled, handleFulfilledGetJobById)
      .addCase(getJobById.rejected, handleRejectedGetJobById)
      .addCase(filterJobs.pending, handlePending)
      .addCase(filterJobs.fulfilled, handlefilterGigsFulfilled)
      .addCase(filterJobs.rejected, handleRejected)
      .addCase(postJob.pending, handlePending)
      .addCase(postJob.fulfilled, handlePostJobFulfilled)
      .addCase(postJob.rejected, handleRejected)
      .addCase(updateJob.pending, handlePending)
      .addCase(updateJob.fulfilled, handleUpdateJobFulfilled)
      .addCase(updateJob.rejected, handleRejected)
      .addCase(getMyJobs.pending, handlePending)
      .addCase(getMyJobs.fulfilled, handleGetMyJobsFulfilled)
      .addCase(getMyJobs.rejected, handleRejected)
      .addCase(deleteMyJob.pending, handlePending)
      .addCase(deleteMyJob.fulfilled, handleDeleteMyJobFulfilled)
      .addCase(deleteMyJob.rejected, handleRejected)
      .addCase(getJobOffers.pending, handleGetJobOffersPending)
      .addCase(getJobOffers.fulfilled, handleGetJobOffersFulfilled)
      .addCase(getJobOffers.rejected, handleRejected)
      .addCase(rejectJobOffer.pending, handleOfferRejectPending)
      .addCase(rejectJobOffer.fulfilled, handleRejectJobOfferFulfilled)
      .addCase(rejectJobOffer.rejected, handleRejected)
      .addCase(pauseJob.pending, handlePending)
      .addCase(pauseJob.fulfilled, handlePauseJobFulfilled)
      .addCase(pauseJob.rejected, handleRejected)
      .addCase(resumeJob.pending, handleResumeJobPending)
      .addCase(resumeJob.fulfilled, handleResumeJobFulfilled)
      .addCase(resumeJob.rejected, handleResumeJobRejected)
      .addCase(jobsByPaginate.pending, handleJobsByPaginatePending)
      .addCase(jobsByPaginate.fulfilled, handleJobsByPaginateFulfilled)
      .addCase(jobsByPaginate.rejected, handleJobsByPaginateRejected);
  },
});

export const { setCurrentSection, setPaymentModal } = jobsSlice.actions;
export default jobsSlice.reducer;
