import React from "react";

import { Styles } from "./rating";
import { colors } from "../../../utils/Colors";

import { AiTwotoneStar } from "react-icons/ai";

/**
 * @typedef {Object} Props
 * @property {number} rating
 * @property {number} ordersCompleted
 * @property {'compact' | 'full' | undefined} size
 */
const Rating = (
  /** @type {Props} */ { rating, ordersCompleted, size = "full" }
) => {
  if (!rating) {
    return null;
  }
  if (size === "compact") {
    return (
      <div
        className="d-flex rounded-4 ps-2 pe-2 align-items-center"
        style={{ color: "white", fontSize:'12px', backgroundColor: "var(--color-pending)" }}
      >
        <p className = "m-0">{rating}</p>
        <AiTwotoneStar style={{marginLeft:"1px", marginBottom: "0.6px" }} />
      </div>
    );
  }

  return (
    <Styles colors={colors}>
      <div className="rating mb-0">
        <div className={rating ? "" : "d-none"}>
          <AiTwotoneStar className="star-icon" />
        </div>
        <div className={rating >= 2 ? "" : "d-none"}>
          <AiTwotoneStar className="star-icon" />
        </div>
        <div className={rating >= 3 ? "" : "d-none"}>
          <AiTwotoneStar className="star-icon" />
        </div>
        <div className={rating >= 4 ? "" : "d-none"}>
          <AiTwotoneStar className="star-icon" />
        </div>
        <div className={rating >= 5 ? "" : "d-none"}>
          <AiTwotoneStar className="star-icon" />
        </div>
        <p>
          {/* {rating} */}
          <span>{ordersCompleted > 0 && `( ${ordersCompleted} )`}</span>
        </p>
      </div>
    </Styles>
  );
};

export default Rating;
