import React from "react";
import { colors } from "../../utils/Colors";
import { Link, useNavigate } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import styled from "styled-components";
import Rating from "../common/Rating";
import Avatar from "../common/Avatar";
import RoutesPath from "../../utils/Paths";

/**
 * @typedef {import("../../types/server").JobDetailData} Props
 */
const JobCard = (/** @type {Props} **/ props) => {
    const navigate = useNavigate()
    console.log(props)
    return (
    <JobCard2Style
      colors={colors}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`${RoutesPath.manageJobs}/${props._id}/detail`, {state:props})
      }}
    >
      <div className="card-body">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex align-items-center gap-2">
            <Avatar
              imgSrc={props.createdBy?.profileImg?.imgUrl}
              name={props.createdBy?.username}
              width={40}
              height={40}
            />
            <div
              style={{ fontWeight: 600 }}
              className="overflow-hidden avatar-username"
            >
              <Link
                className="text-truncate username-txt"
                onClick={(e) => {
                  e.stopPropagation();                
                }}
                to={`/profile/${props.createdBy?._id}`}
              >
                {props.createdBy?.username}
              </Link>
              <div>
                {props.createdBy?.rating > 0 ? (
                  <Rating rating={props.createdBy?.rating} />
                ) : null}
              </div>
            </div>
          </div>
          <p>{props.description}</p>
        </div>
        <div
          className="d-flex gap-2 align-items-center"
          style={{ color: "gray", fontSize: "16px" }}
        >
          <div className="d-flex gap-2">
            <p>{props.workType === "isOnline" ? "Online" : "Onsite"}</p>
          </div>
          <div>
            <GoDotFill size={8} />
          </div>
          <div className="d-flex align-items-center gap-2">
            <p>
              ${props.budgetMin} - ${props.budgetMax}
            </p>
          </div>
        </div>
      </div>
    </JobCard2Style>
  );
};
const JobCard2Style = styled.div`
  position: relative;
  width: 280px;
  height: 260px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: #005b46;
  background-color: #e8f3f1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
  .username-txt {
    color: #005b46;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    .avatar-username {
      &:hover {
        .username-txt {
          text-decoration: underline !important;
        }
      }
    }
  }
`;

export default JobCard;
