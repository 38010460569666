// export const sellerDetails = [
//   {
//     _id: "zjcbhjsdknck",
//     title: "I will paint your house",
//     category: "Home Maintenance",
//     subcategory: "Exterior Painting",
//     workType: "on-site",
//     description:
//       "Looking to refresh the look of your home or increase its curb appeal? Look no further! I am a skilled professional who will paint the exterior or interior of your house, providing a high-quality, hassle-free service that will leave your home looking stunning.My process begins with a thorough assessment of your surfaces to be painted, followed by meticulous preparation that includes cleaning, scraping, sanding, and patching any damaged areas. I will then work with you to select the perfect paint type and color that best fits your preferences and the nature of the project.During the painting process, I take great care to protect surfaces that are not to be painted, such as windows and doors, and will clean up any drips or spills promptly. With my attention to detail and expertise, you can rest assured that the final result will be smooth, even, and visually striking.I pride myself on providing a reliable, efficient, and stress-free service that meets your specific needs and budget. Whether youre looking to add a fresh coat of paint to your homes exterior or transform the look of your interior spaces, I am here to help.So why wait? Let me help you bring new life to your home with my exceptional painting services. Contact me today to get started!",
//     priceMin: 100,
//     priceMax: 150,
//     duration: 3,
//     latitude: 31.3456789,
//     longitude: 75.7654321,
//     attachments: [{}],
//     rating: 4,
//   },
// ];

export const cardData = [
  {
    _id: "zjcbhjsdknck",
    profileName: "John Doe",
    title: "I will paint your house & Make it look new",
    level: "Expert",
    workType: "on-site",
    category: "Home Maintenance",
    subcategory: "Exterior Painting",
    requirements:
      "Please Provide complete details of your house, including number of bedrooms, washrooms and total area to be painted. Provide us with any additional information you want us to know.",
    description:
      "Looking to refresh the look of your home or increase its curb appeal? Look no further! I am a skilled professional who will paint the exterior or interior of your house, providing a high-quality, hassle-free service that will leave your home looking stunning.My process begins with a thorough assessment of your surfaces to be painted,",
    priceMin: 100,
    priceMax: 150,
    orders: 23,
    rating: 4.3,
    duration: 3,
    latitude: 31.3456789,
    longitude: 75.7654321,
    attachments: [{}],
    profileImg:
      "https://fiverr-res.cloudinary.com/t_profile_thumb,q_auto,f_auto/attachments/profile/photo/34c58e7730ecced2ff90b1f9a1bb459d-1559763744784/d832a555-e7d9-4934-a3d9-003b36405a5c.png",

    bgImg:
      "https://cdn.pixabay.com/photo/2019/04/24/17/18/space-4152623_960_720.jpg",
  },
  {
    _id: "gfgfcbnghfuvgh",
    category: "home Maintenance",
    subcategory: "exterior painting",
    requirements:
      "Please Provide complete details of your house, including number of bedrooms, washrooms and total area to be painted. Provide us with any additional information you want us to know.",
    description:
      "Looking to refresh the look of your home or increase its curb appeal? Look no further! I am a skilled professional who will paint the exterior or interior of your house, providing a high-quality, hassle-free service that will leave your home looking stunning.My process begins with a thorough assessment of your surfaces to be painted,",
    orders: 23,
    latitude: 31.3456789,
    longitude: 75.7654321,
    attachments: [{}],
    title: "Logo design for your business",
    level: "Intermediate",
    workType: "online",
    priceMin: 20,
    priceMax: 30,
    orders: 15,
    rating: 0,
    profileImg:
      "https://fiverr-res.cloudinary.com/t_profile_thumb,q_auto,f_auto/attachments/profile/photo/34c58e7730ecced2ff90b1f9a1bb459d-1559763744784/d832a555-e7d9-4934-a3d9-003b36405a5c.png",
    profileName: "Jane Smith",
    bgImg:
      "https://images.squarespace-cdn.com/content/v1/56a966539cadb678e0d07a04/1575760783351-MIXR7FHVNU4LL3IYP4M3/Spa+Bathroom.jpg",
  },
  {
    _id: "trtufyuoihbh",
    category: "home Maintenance",
    subcategory: "fence installation",
    requirements:
      "Please Provide complete details of your house, including number of bedrooms, washrooms and total area to be painted. Provide us with any additional information you want us to know.",
    description:
      "Looking to refresh the look of your home or increase its curb appeal? Look no further! I am a skilled professional who will paint the exterior or interior of your house, providing a high-quality, hassle-free service that will leave your home looking stunning.My process begins with a thorough assessment of your surfaces to be painted,",
    orders: 23,
    latitude: 31.3456789,
    longitude: 75.7654321,
    attachments: [{}],
    title: "Translation services for your documents",
    level: "Advanced",
    workType: "online",
    priceMin: 50,
    priceMax: 60,
    orders: 10,
    rating: 4.5,
    profileImg:
      "https://fiverr-res.cloudinary.com/t_profile_thumb,q_auto,f_auto/attachments/profile/photo/34c58e7730ecced2ff90b1f9a1bb459d-1559763744784/d832a555-e7d9-4934-a3d9-003b36405a5c.png",
    profileName: "David Johnson",
    bgImg:
      "https://www.homeadvisor.com/r/wp-content/uploads/2018/04/wood-fencing.jpeg",
  },
  {
    _id: "tryuioetr",
    category: "home Maintenance",
    subcategory: "hanging items",
    requirements:
      "Please Provide complete details of your house, including number of bedrooms, washrooms and total area to be painted. Provide us with any additional information you want us to know.",
    description:
      "Looking to refresh the look of your home or increase its curb appeal? Look no further! I am a skilled professional who will paint the exterior or interior of your house, providing a high-quality, hassle-free service that will leave your home looking stunning.My process begins with a thorough assessment of your surfaces to be painted,",
    orders: 23,
    latitude: 31.3456789,
    longitude: 75.7654321,
    attachments: [{}],
    title: "Professional voice-over for your project",
    level: "Expert",
    workType: "online",
    priceMin: 55,
    priceMax: 70,
    orders: 42,
    rating: 4.9,
    profileImg:
      "https://fiverr-res.cloudinary.com/t_profile_original,q_auto,f_auto/attachments/profile/photo/1d943221ab48c8a0be3f0c6312d3a1f7-45459661627970312924/JPEG_20210803_115830_2365722574100175430.jpg",
    profileName: "Sarah Lee",
    bgImg:
      "https://www.nipponpaint.co.in/wp-content/uploads/2019/03/Dark-exterior-wall-paint-colours-for-home-1024x640.jpg",
  },
  {
    _id: "gfgcvbnmiugyt",
    category: "Home Maintenance",
    subcategory: "Exterior Painting",
    requirements:
      "Please Provide complete details of your house, including number of bedrooms, washrooms and total area to be painted. Provide us with any additional information you want us to know.",
    description:
      "Looking to refresh the look of your home or increase its curb appeal? Look no further! I am a skilled professional who will paint the exterior or interior of your house, providing a high-quality, hassle-free service that will leave your home looking stunning.My process begins with a thorough assessment of your surfaces to be painted,",
    orders: 23,
    latitude: 31.3456789,
    longitude: 75.7654321,
    attachments: [{}],
    title: "Website development using React",
    level: "Intermediate",
    workType: "online",
    priceMin: 2000,
    priceMax: 2500,
    orders: 35,
    rating: 4.7,
    profileImg:
      "https://fiverr-res.cloudinary.com/t_profile_original,q_auto,f_auto/attachments/profile/photo/1d943221ab48c8a0be3f0c6312d3a1f7-45459661627970312924/JPEG_20210803_115830_2365722574100175430.jpg",
    profileName: "Michael Clark",
    bgImg:
      "https://www.onthemap.com/wp-content/uploads/2020/09/plumbing-working-plumber.jpg",
  },
  {
    _id: "kjbgnmfctgc",
    category: "Home Maintenance",
    subcategory: "Exterior Painting",
    requirements:
      "Please Provide complete details of your house, including number of bedrooms, washrooms and total area to be painted. Provide us with any additional information you want us to know.",
    description:
      "Looking to refresh the look of your home or increase its curb appeal? Look no further! I am a skilled professional who will paint the exterior or interior of your house, providing a high-quality, hassle-free service that will leave your home looking stunning.My process begins with a thorough assessment of your surfaces to be painted,",
    orders: 23,
    latitude: 31.3456789,
    longitude: 75.7654321,
    attachments: [{}],
    title: "Proofreading and editing for your book",
    level: "Advanced",
    workType: "online",
    priceMin: 100,
    priceMax: 150,
    orders: 18,
    rating: 4.6,
    profileImg:
      "https://fiverr-res.cloudinary.com/t_profile_original,q_auto,f_auto/attachments/profile/photo/1d943221ab48c8a0be3f0c6312d3a1f7-45459661627970312924/JPEG_20210803_115830_2365722574100175430.jpg",
    profileName: "Emily Davis",
    bgImg:
      "https://www.nipponpaint.co.in/wp-content/uploads/2019/06/Living-room-wall-painted-with-lime-green-colour-1024x640.jpg",
  },
  {
    _id: "htdrdggvyhg",
    category: "Home Maintenance",
    subcategory: "Exterior Painting",
    requirements:
      "Please Provide complete details of your house, including number of bedrooms, washrooms and total area to be painted. Provide us with any additional information you want us to know.",
    description:
      "Looking to refresh the look of your home or increase its curb appeal? Look no further! I am a skilled professional who will paint the exterior or interior of your house, providing a high-quality, hassle-free service that will leave your home looking stunning.My process begins with a thorough assessment of your surfaces to be painted,",
    orders: 23,
    latitude: 31.3456789,
    longitude: 75.7654321,
    attachments: [{}],
    title: "Photoshop editing for your photos",
    level: "Expert",
    workType: "online",
    priceMin: 100,
    priceMax: 150,
    orders: 27,
    rating: 4.9,
    profileImg:
      "https://fiverr-res.cloudinary.com/t_profile_original,q_auto,f_auto/attachments/profile/photo/1d943221ab48c8a0be3f0c6312d3a1f7-45459661627970312924/JPEG_20210803_115830_2365722574100175430.jpg",
    profileName: "Adam Wilson",
    bgImg:
      "https://media.istockphoto.com/photos/equipment-for-laying-tile-picture-id511324570?k=20&m=511324570&s=612x612&w=0&h=kqxDg0oYtKVcdh_png-mFPJtR263uwWqJ_24euus1xk=",
  },
  // {
  //   title: "Logo design for your business",
  //   level: "Intermediate",
  //   workType: "online",
  //   price: 50,
  //   orders: 15,
  //   rating: 4.8,
  //   profileImg:
  //     "https://images.unsplash.com/photo-1579469445882-2ab448ea59ca?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=150&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=150",
  //   profileName: "Jane Smith",
  //   bgImg:
  //     "https://images.unsplash.com/photo-1573497811942-0b7907e35bd5?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=250&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=500",
  // },
  // {
  //   title: "Translation services for your documents",
  //   level: "Advanced",
  //   workType: "online",
  //   price: 80,
  //   orders: 10,
  //   rating: 4.5,
  //   profileImg:
  //     "https://images.unsplash.com/photo-1602275945765-5ba5af5aeba9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=150&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=150",
  //   profileName: "David Johnson",
  //   bgImg:
  //     "https://images.unsplash.com/photo-1531302145928-7a8a847008b3?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=250&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=500",
  // },
];

export const categoryData = [
  {
    _id: {
      $oid: "635a5fa1c1a49e7a963ee919",
    },
    name: "home Maintenance",
    img: {
      imgUrl:
        "https://res.cloudinary.com/chirptech123/image/upload/v1666867105/gig-falcon/categories/wlwlp8ddrbpygybexfmv.jpg",
      publicId: "gig-falcon/categories/wlwlp8ddrbpygybexfmv",
    },
    subcategories: [
      {
        name: "bathroom remodeling",
        _id: {
          $oid: "635a7a4a22ca41e82e84f49d",
        },
      },
      {
        name: "fence installation",
        _id: {
          $oid: "635a7a9c22ca41e82e84f4a1",
        },
      },
      {
        name: "exterior painting",
        _id: {
          $oid: "635a7ad422ca41e82e84f4a6",
        },
      },
      {
        name: "furniture assembly",
        _id: {
          $oid: "635a7b0622ca41e82e84f4ac",
        },
      },
      {
        name: "kitchen remodeling",
        _id: {
          $oid: "635a7b3022ca41e82e84f4b3",
        },
      },
      {
        name: "hanging items",
        _id: {
          $oid: "635a7b5422ca41e82e84f4bb",
        },
      },
    ],
    __v: 0,
  },
  {
    _id: {
      $oid: "635a6036c1a49e7a963ee91b",
    },
    name: "graphic designing",
    img: {
      imgUrl:
        "https://res.cloudinary.com/chirptech123/image/upload/v1666867254/gig-falcon/categories/aejbgxexe9cxoxteskn6.webp",
      publicId: "gig-falcon/categories/aejbgxexe9cxoxteskn6",
    },
    subcategories: [
      {
        name: "logo design",
        _id: {
          $oid: "635a7bbd22ca41e82e84f4c4",
        },
      },
      {
        name: "brand style guides",
        _id: {
          $oid: "635a7bea22ca41e82e84f4c8",
        },
      },
      {
        name: "game art",
        _id: {
          $oid: "635a7c0b22ca41e82e84f4cd",
        },
      },
      {
        name: "graphic for streamers",
        _id: {
          $oid: "635a7c2e22ca41e82e84f4d3",
        },
      },
      {
        name: "busniess card & stationery",
        _id: {
          $oid: "635a7c7422ca41e82e84f4da",
        },
      },
      {
        name: "illustration",
        _id: {
          $oid: "635a7c9722ca41e82e84f4e2",
        },
      },
    ],
    __v: 0,
  },
  {
    _id: {
      $oid: "635a6166c1a49e7a963ee91d",
    },
    name: "digital marketing",
    img: {
      imgUrl:
        "https://res.cloudinary.com/chirptech123/image/upload/v1666867558/gig-falcon/categories/opgta6rjdpoidevgz0fw.webp",
      publicId: "gig-falcon/categories/opgta6rjdpoidevgz0fw",
    },
    subcategories: [
      {
        name: "search engine optimization (seo)",
        _id: {
          $oid: "635a7d3322ca41e82e84f4ef",
        },
      },
      {
        name: "social media advertising",
        _id: {
          $oid: "635a7d7122ca41e82e84f4f4",
        },
      },
      {
        name: "book & ebook marketing",
        _id: {
          $oid: "635a7d9022ca41e82e84f4fa",
        },
      },
      {
        name: "music promotion",
        _id: {
          $oid: "635a7dad22ca41e82e84f501",
        },
      },
      {
        name: "podcast marketing",
        _id: {
          $oid: "635a7dcb22ca41e82e84f509",
        },
      },
      {
        name: "mobile app marketing",
        _id: {
          $oid: "635a7dda22ca41e82e84f512",
        },
      },
    ],
    __v: 0,
  },
  {
    _id: {
      $oid: "635a63c5c1a49e7a963ee91f",
    },
    name: "writing & translation",
    img: {
      imgUrl:
        "https://res.cloudinary.com/chirptech123/image/upload/v1666868165/gig-falcon/categories/luwe75xlegfugagreyqr.webp",
      publicId: "gig-falcon/categories/luwe75xlegfugagreyqr",
    },
    subcategories: [
      {
        name: "articles & blog posts",
        _id: {
          $oid: "635a7e2322ca41e82e84f51c",
        },
      },
      {
        name: "proofreading & editing",
        _id: {
          $oid: "635a7e5322ca41e82e84f520",
        },
      },
      {
        name: "websites content",
        _id: {
          $oid: "635a7e6422ca41e82e84f525",
        },
      },
      {
        name: "book & ebook writing",
        _id: {
          $oid: "635a7e8622ca41e82e84f52b",
        },
      },
      {
        name: "creative writing",
        _id: {
          $oid: "635a7e9922ca41e82e84f532",
        },
      },
      {
        name: "beta writing",
        _id: {
          $oid: "635a7eab22ca41e82e84f53a",
        },
      },
    ],
    __v: 0,
  },
  {
    _id: {
      $oid: "635a641ec1a49e7a963ee921",
    },
    name: "programming & tech",
    img: {
      imgUrl:
        "https://res.cloudinary.com/chirptech123/image/upload/v1666868254/gig-falcon/categories/o9y88zohbcwaz40frohc.webp",
      publicId: "gig-falcon/categories/o9y88zohbcwaz40frohc",
    },
    subcategories: [
      {
        name: "wordpress",
        _id: {
          $oid: "635a7eea22ca41e82e84f543",
        },
      },
      {
        name: "website builder & cms",
        _id: {
          $oid: "635a7f0822ca41e82e84f547",
        },
      },
      {
        name: "e-commerce development",
        _id: {
          $oid: "635a7f4b22ca41e82e84f54c",
        },
      },
      {
        name: "game development",
        _id: {
          $oid: "635a7f5a22ca41e82e84f552",
        },
      },
      {
        name: "mobile apps",
        _id: {
          $oid: "635a7f6722ca41e82e84f559",
        },
      },
      {
        name: "electronics engineering",
        _id: {
          $oid: "635a7fb322ca41e82e84f561",
        },
      },
    ],
    __v: 0,
  },
  {
    _id: {
      $oid: "635a648ac1a49e7a963ee923",
    },
    name: "video & animation",
    img: {
      imgUrl:
        "https://res.cloudinary.com/chirptech123/image/upload/v1666868361/gig-falcon/categories/ydfy80hze38weooaiwn6.png",
      publicId: "gig-falcon/categories/ydfy80hze38weooaiwn6",
    },
    subcategories: [
      {
        name: "whiteboard & animated expplainers",
        _id: {
          $oid: "635a80c822ca41e82e84f56a",
        },
      },
      {
        name: "video editing",
        _id: {
          $oid: "635a80eb22ca41e82e84f56e",
        },
      },
      {
        name: "sort video ads",
        _id: {
          $oid: "635a811122ca41e82e84f573",
        },
      },
      {
        name: "character animation",
        _id: {
          $oid: "635a813122ca41e82e84f579",
        },
      },
      {
        name: "animated gifs",
        _id: {
          $oid: "635a814122ca41e82e84f580",
        },
      },
      {
        name: "logo animation",
        _id: {
          $oid: "635a816222ca41e82e84f588",
        },
      },
    ],
    __v: 0,
  },
];
