import { useState, useCallback } from "react";
import axios from "../../api/axios";
import { debounce } from "lodash";

/**
 * Params for Gigs query on Map
 * @typedef {object} params
 * @property {string} category
 * @property {string} searchText
 * @property {string} sortBy
 * @property {string} level
 * @property {string} minPrice
 * @property {string} maxPrice
 * @property {string} subcategory
 * @property {string} userId
 * @property {number} limit
 * @property {number} page
 * @property {number} neLat
 * @property {number} neLng
 * @property {number} swLat
 * @property {number} swLng
 * @property {number} latDelta
 * @property {number} lngDelta
 */

/**
 * @typedef {Object} result
 * @property {import("./useGigsSelector").Gig[]} gigs
 * @property {boolean} loading
 * @property {(params:params)=>void} fetchGigs
 */

/**
 * @returns {result}
 */
const useGigsSelectorOnMap = () => {
  const [gigs, setGigs] = useState([]);
  const [loading, setLoading] = useState(false);

  const debounceFetch = useCallback(
    debounce((query) => fetchGigs(query), 500),
    [],
  );

  /**
   * @param {params} params
   */
  const fetchGigs = async (params) => {
    const filters = {
      page: params.page || 1,
      limit: params.limit || 200,
      category: params.category || "",
      searchText: params.searchText || "",
      sortBy: params.sortBy || "",
      sellerLevel: params.level || "",
      minPrice: params.minPrice || "",
      maxPrice: params.maxPrice || "",
      subcategory: params.subcategory || "",
      userId: params.userId || "",
      neLat: params.neLat || null,
      neLng: params.neLng || null,
      swLat: params.swLat || null,
      swLng: params.swLng || null,
    };
    let queryString = "";
    for (let key in filters) {
      queryString = queryString + `${key}=${filters[key]}&`;
    }
    setLoading(true);
    try {
      const response = await axios.get(`/gigs/query/results?${queryString}`);
      const { gigs } = response.data;
      setGigs(gigs);
    } catch (err) {}
    setLoading(false);
  };

  return { gigs, fetchGigs: debounceFetch, loading };
};

export default useGigsSelectorOnMap;
