import axios from "../../../api/axios";

/**
 * Verify user's OTP and store authentication data in local storage.
 * @param {Object} user - User object containing OTP and other details.
 * @returns {Promise} - Promise object representing the response from the server.
 */
export const verifyOTP = async (data) => {
  const response = await axios.post("/users/verify-user-sign-in", data);
  return response;
};
