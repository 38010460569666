import React from "react";
import { colors } from "../../utils/Colors";
import Marker from "../Map/Marker";
import {
  CiLocationOff,
  CiLocationOn,
  CiMoneyBill,
  CiTimer,
} from "react-icons/ci";
import AvatarRatingCard from "../common/AvatarRatingCard";
import { getTimeDuration } from "../../utils/helperFunctions";
import AttachmentCard from "../common/AttachmentCard";
import GoogleMapReact from "google-map-react";

import { PinLocation } from "../../utils/Images";
import styled from "styled-components";
import CirclePlaceholder from "../common/Placeholders/CirclePlaceholder";
import RectanglePlaceholder from "../common/Placeholders/RectanglePlaceholder";
import JobAppleMap from "./JobAppleMap";

/**
 * @typedef {import('../../types/server'.JobDetailData)} Props
 */
const JobInfoSection = (/** @type {Props} **/ data) => {
  const coordinates = {
    center: {
      lat: data.location.latitude,
      lng: data.location.longitude,
    },
    zoom: 10,
  };
  return (
    <JobInfoStyled
      colors={colors}
      className="d-flex flex-md-row flex-column gap-2 flex-wrap align-items-start"
    >
      <section>
        <div>
          <JobAppleMap
            latitude={data.location.latitude}
            longitude={data.location.longitude}
          />

          <div className="d-flex align-items-center text-muted text-sm">
            <CiLocationOn size={16} className="m-1 d-none d" />
            <p className="text-capitalize">{data.location.address}</p>
          </div>
        </div>
      </section>
      <section className="d-flex flex-column gap-4">
        <div>
          <p className="text-xl">{data.description}</p>
          <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center mt-1">
            <AvatarRatingCard
              profileImg={data?.createdBy?.profileImg?.imgUrl}
              username={data?.createdBy?.username}
              rating={data?.createdBy?.rating}
              userId={data?.createdBy?._id}
              size={"small"}
            />
            <div className="d-flex gap-2 flex-wrap mb-2">
              <div className="d-flex align-items-center text-muted text-sm">
                <p>{"Posted " + getTimeDuration(data.createdAt)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-sm-between gap-4 flex-wrap">
          <div className="d-flex gap-2 align-items-center">
            <CiMoneyBill size={24} />
            <div>
              <p className="text-sm">{"Budget"}</p>
              <p className="text-md font-bold">
                {`$${data.budgetMin} - $${data.budgetMax}`}
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            {data.workType === "online" ? (
              <CiLocationOn size={24} />
            ) : (
              <CiLocationOff size={24} />
            )}
            <div>
              <p className="text-sm">{"Work Type"}</p>
              <p className="text-md font-bold text-capitalize">
                {`${data.workType}`}
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <CiTimer size={24} />
            <div>
              <p className="text-sm">{"Deadline"}</p>
              <p className="text-md font-bold text-capitalize">
                {`${data.deadline} days`}
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-4">
          {data?.attachments?.length > 0 && (
            <div className="d-flex flex-column gap-2">
              <p className="text-lg">{"Attachments"}</p>
              <div className="d-flex flex-wrap gap-2">
                {data?.attachments?.map((attachment, idx) => (
                  <AttachmentCard previewUrl={attachment.imgUrl} key={idx} />
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </JobInfoStyled>
  );
};

export const JobInfoPlaceholder = () => {
  return (
    <JobInfoStyled
      colors={colors}
      className="d-flex flex-md-row flex-column gap-2 flex-wrap align-items-start"
    >
      <section>
        <div>
          <div className="d-block map">
            <div className="map-placeholder placeholder-glow h-100 w-100">
              <div className="placeholder-grey placeholder w-100 h-100"></div>
            </div>
          </div>
          <div className="d-flex align-items-center text-muted text-sm">
            <p
              className="text-capitalize placeholder-glow w-25"
              style={{ height: 8 }}
            >
              <span className="placeholder-grey placeholder w-100 h-100"></span>
            </p>
          </div>
        </div>
      </section>
      <section className="d-flex flex-column gap-4">
        <div>
          <p className="text-xl placeholder-glow">
            <span className="placeholder-grey placeholder w-75 h-100"></span>
          </p>
          <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center mt-1">
            <div className="d-flex gap-2">
              <CirclePlaceholder className="flex-grow-1" size={30} />
              <p
                className="text-capitalize placeholder-glow flex-grow-1"
                style={{ height: 12, width: 120 }}
              >
                <span className="placeholder-grey placeholder w-100 h-100"></span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-sm-between gap-4 flex-wrap">
          <div className="d-flex gap-2 align-items-center">
            <RectanglePlaceholder size={24} />
            <div>
              <p
                className="text-sm placeholder-glow placeholder-sm"
                style={{ width: 80 }}
              >
                <span className="placeholder-grey placeholder w-100 h-100"></span>
              </p>
              <p
                className="text-md placeholder-glow placeholder-sm"
                style={{ width: 120 }}
              >
                <span className="placeholder-grey placeholder w-100 h-100"></span>
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <RectanglePlaceholder size={24} />
            <div>
              <p
                className="text-sm placeholder-glow placeholder-sm"
                style={{ width: 80 }}
              >
                <span className="placeholder-grey placeholder w-100 h-100"></span>
              </p>
              <p
                className="text-md placeholder-glow placeholder-sm"
                style={{ width: 120 }}
              >
                <span className="placeholder-grey placeholder w-100 h-100"></span>
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <RectanglePlaceholder size={24} />
            <div>
              <p
                className="text-sm placeholder-glow placeholder-sm"
                style={{ width: 80 }}
              >
                <span className="placeholder-grey placeholder w-100 h-100"></span>
              </p>
              <p
                className="text-md placeholder-glow placeholder-sm"
                style={{ width: 120 }}
              >
                <span className="placeholder-grey placeholder w-100 h-100"></span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-2">
            <p className="text-lg placeholder-glow">
              <span className="placeholder-grey placeholder w-25 h-100"></span>
            </p>
            <div className="d-flex flex-wrap gap-2">
              <div
                className="placeholder-glow"
                style={{ width: 180, height: 200 }}
              >
                <div className="placeholder-grey placeholder w-100 h-100"></div>
              </div>
              <div
                className="placeholder-glow"
                style={{ width: 180, height: 200 }}
              >
                <div className="placeholder-grey placeholder w-100 h-100"></div>
              </div>
              <div
                className="placeholder-glow"
                style={{ width: 180, height: 200 }}
              >
                <div className="placeholder-grey placeholder w-100 h-100"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </JobInfoStyled>
  );
};

const JobInfoStyled = styled.div`
  section {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
    padding: 20px;
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    section {
      width: 28%;
    }
    section:last-child {
      width: 70%;
      flex: 1;
    }
  }
`;

export default JobInfoSection;
