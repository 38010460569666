import React from "react";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import LegalNonDiscriminationPolicyJSX from "../../components/Legal/non-discrimination-policy";
const LegalNDP = () => {
  return (
    <>
      <Header />
      <div className="container p-5">
        <LegalNonDiscriminationPolicyJSX />
      </div>
      <Footer />
    </>
  );
};

export default LegalNDP;
