import React from "react";
import styled from "styled-components";
import { BsX } from "react-icons/bs";

/**
 * @typedef ErrorObj
 * @property {string} message
 * @param {{src: string, error: ErrorObj | undefined, fileName: string, fileSize: string, onCancel: () => void}} props
 */
const FilePreviewBox = (
  /**@type {props} */ { src, error, fileName, fileSize, onCancel }
) => {
  return (
    <StyledPreview>
      <BsX
        role="button"
        className="position-absolute top-0 end-0"
        style={{ width: 24, height: 24, zIndex: 9999, color: "white" }}
        onClick={onCancel}
      />
      <div className="info">
        <div
          className="d-flex gap-2 flex-column justify-content-center align-items-center h-100"
          style={{ fontSize: 12 }}
        >
          <p className="m-0">{fileName}</p>
          <p className="m-0">{fileSize}</p>
        </div>
      </div>
      {src && <img src={src} alt={fileName} />}
      {error && (
        <div
          className="error d-flex justify-content-center align-items-center text-center p-2"
          style={{ pointerEvents: "none" }}
        >
          <p
            style={{
              display: "-webkit-box",
              "-webkit-line-clamp": "3",
              "-webkit-box-orient": "vertical",
              "overflow": "hidden",
              "text-overflow": "ellipsis",
            }}
          >
            {error.message || "Unexpected Error!"}
          </p>
        </div>
      )}
    </StyledPreview>
  );
};
const StyledPreview = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  background-color: #eee;
  overflow: hidden;
  :hover {
    cursor: pointer;
    > div {
      opacity: 1;
    }
  }
  > div.info {
    opacity: 0;
    transition: opacity 0.5s;
    background-color: #000;
    color: #fff;
    z-index: 990;
  }
  > div.error {
    background-color: #ff3a36;
    color: #fff;
    opacity: 1;
    z-index: 999;
  }
  > div {
    position: absolute;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    font-size: 12px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default FilePreviewBox;
