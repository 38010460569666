import React from "react";

import { Styles } from "./postJob";
import { colors } from "../../../utils/Colors";
import Footer from "../../../components/common/Footer";
import Header from "../../../components/common/Header";
import PostJobForm from "../../../components/Jobs/PostJobForm";
import Search from "../../../assets/images/postjob-img.svg";
import Categories from "../../../components/common/Categories";
import { postJob } from "../../../store/thunks/jobsThunk";

import { useDispatch } from "react-redux";

const PostJob = () => {
  const dispatch = useDispatch();

  const handlePostJob = (values) => {
    dispatch(postJob(values));
  };

  return (
    <Styles colors={colors}>
      <Header />
      <Categories />
      <div className="post-job-section">
        <section>
          <h3>What Service Are You Looking For?</h3>
          <PostJobForm onSubmit={handlePostJob} />
        </section>
        <div className="image-section">
          <div className="search">
            <img src={Search} alt="search" />
          </div>
        </div>
      </div>
      <Footer />
    </Styles>
  );
};

export default PostJob;
