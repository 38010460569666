import React, { useState, useEffect } from "react";
import HomeMapView from "../components/Home/HomeMapView";
import LocationFilter from "../components/Home/LocationFilter";
import styled from "styled-components";
import { FaListUl } from "react-icons/fa";
import { FiMap } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { getGigs } from "../store/thunks/gigsThunk";
import Header from "../components/common/Header";
import Categories from "../components/common/Categories";
import { colors } from "../utils/Colors";
import Footer from "../components/common/Footer";
import JobsContainer from "../components/Home/JobsContainer";
import GigsContainer from "../components/Home/GigsContainer";
import useHomeFilters from "../store/hooks/useHomeFilters";
import MarketingPopup from "../components/Home/MarketingPopup";
import HomeAppleMapView from "../components/Home/HomeAppleMapView";

const Home = () => {
  const dispatch = useDispatch();
  const {
    filters: { sectionFilter },
  } = useHomeFilters();
  const [showMap, setShowMap] = useState(false);
  const [showGigs, setShowGigs] = useState(true);
  const handleShowMap = () => {
    setShowMap(!showMap);
    setShowGigs(!showGigs);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getGigs({ signal }));

    return () => {
      controller.abort();
    };
  }, [dispatch]);

  return (
    <>
      <MarketingPopup />
      <Header />
      <Categories />
      <HomeStyled className="mb-4" colors={colors}>
        <div className="d-block">
          <LocationFilter />
        </div>
        <div className="d-flex flex-row w-100">
          <div className={showMap ? "d-block" : "home-map-view d-none"}>
            {/* <HomeMapView /> */}
            <HomeAppleMapView />
          </div>
          <div className={showGigs ? "d-block w-100" : "home-gigs d-none"}>
            {sectionFilter === "jobs" ? <JobsContainer /> : <GigsContainer />}
          </div>
        </div>
        <div className="show-map" onClick={handleShowMap}>
          {showMap ? (
            <FaListUl className="list-icon" />
          ) : (
            <FiMap className="list-icon" />
          )}
        </div>
      </HomeStyled>
      <Footer />
    </>
  );
};
export default Home;

const HomeStyled = styled.div`
  .list {
    width: 100% !important;
    height: auto;
  }
  .home-dropdown {
    padding: 20px;
  }
  .list-header h2 {
    font-size: 30px;
    text-align: center;
  }
  .list-header span {
    color: ${({ colors }) => colors.color.secondary};
  }
  .search-input-wrapper {
  }
  .search-bar {
    display: flex;
    align-items: center;
    margin: 20px auto;
    max-width: 500px;
  }
  .search-btn {
    background-color: ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
    border: none;
    padding: 8px 20px;
    border-radius: 0 5px 5px 0;
  }
  .home-gigs-list {
    padding: 20px;
  }
  .show-map {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow:
      rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px,
      rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px,
      rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 50%;
    background-color: ${({ colors }) => colors.color.white};
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  // .show-map img {
  //   width: 25px;
  //   height: 25px;
  //   color: ${({ colors }) => colors.color.secondary} !important;
  // }
  .list-icon {
    width: 20px;
    height: 20px;
    color: ${({ colors }) => colors.color.secondary} !important;
  }
  h2,
  h2 span {
    font-family: "MS_SemiBold";
  }

  @media only screen and (min-width: 480px) {
    .list-header h2 {
      font-size: 40px;
    }
    .home-gigs-list {
      padding: 40px !important;
      width: 100%;
    }
  }

  @media only screen and (min-width: 769px) {
    .list {
      overflow-y: scroll;
      height: 80vh;
      width: 100% !important;
      box-shadow: inset 0 1px 3px ${({ colors }) => colors.color.veryLightGrey};
    }
    .list::-webkit-scrollbar {
      width: 10px !important;
    }
    .list::-webkit-scrollbar-track {
      background-color: ${({ colors }) => colors.color.lightGrey};
    }
    .list::-webkit-scrollbar-thumb {
      background-color: ${({ colors }) => colors.color.secondary};
      opacity: 0.7;
    }
    .show-map {
      display: none !important;
    }
    .home-map-view {
      display: block !important;
    }
    .home-gigs {
      display: flex !important;
    }

    @media only screen and (max-width: 479px) {
      .list::-webkit-scrollbar {
        width: 20px !important;
      }
    }
  }
`;
