import styled from "styled-components";

export const Styles = styled.div`
  .gig-card {
    height: 320px;
    width: 240px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.29);
    border-radius: 10px;
    overflow: hidden;
  }
  .gig-img {
    width: 100%;
    height: 170px;
    border-radius: 0 0 5px 5px;
    position: relative;
    overflow: hidden;
  }
  .gig-img img {
    width: 100%;
    height: 170px;
    object-fit: cover;
  }
  .options {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: ${({ colors }) => colors.color.white};
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.29);
  }
  .gig-rating {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.47);
    border-radius: 5px;
    padding: 2px 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .dropdown-menu {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.29) !important;
  }
  .gig-content {
    padding: 20px;
  }
  .gig-title {
    font-family: "NN_SemiBold";
    height: 60px;
    overflow: hidden;
    font-size: 19px;
    margin-bottom: 10px !important;
    cursor: pointer;
  }
  .gig-price {
    font-family: "NN_Regular";
    font-size: 14px;
  }
  .gig-price span {
    font-family: "NN_Regular";
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .fav-icon-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ colors }) => colors.color.white};
    width: 27px;
    height: 27px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .fav-icon {
    width: 20px;
    height: 20px;

    color: ${({ colors }) => colors.color.primary};
  }
  .filled {
    color: red;
    fill: red;
  }
`;
