import React from "react";

import { Styles } from "./gigInfo";
import { colors } from "../../../utils/Colors";

import { BiTimeFive } from "react-icons/bi";
import { ContactButton } from "../../../features/quickblox";
import { useAuthContext } from "../../../features/authentication";

const GigInfo = ({
  createdBy,
  priceFrom,
  priceTo,
  workDaysFrom,
  workDaysTo,
  requirements,
}) => {
  const { user } = useAuthContext();
  const isGigOwner =
    user &&
    typeof createdBy?.qbId !== "undefined" &&
    user.qbId === createdBy.qbId;
  return (
    <Styles colors={colors}>
      <div className="price-box">
        <p className="price">
          USD ${priceFrom} - ${priceTo}
        </p>
        <div className="time">
          <BiTimeFive className="time-icon" />
          <p>
            {workDaysFrom} - {workDaysTo} Days Delievey
          </p>
        </div>
        <div className="requirements">
          <h6>Requirements:</h6>
          <p>{requirements}</p>
        </div>
        {user ? (
          typeof createdBy?.qbId !== "undefined" &&
          !isGigOwner && (
            <ContactButton
              recipientName={createdBy?.username}
              recipentId={parseInt(createdBy?.qbId) || null}
            />
          )
        ) : (
          <button
            type="button"
            className="btn btn-lg btn-primary mt-4 w-full"
            onClick={() => {
              alert("please Login First");
            }}
          >
            {"Contact Me"}
          </button>
        )}
      </div>
    </Styles>
  );
};
export const GigInfoPlaceholder = () => {
  return (
    <Styles colors={colors}>
      <div className="price-box">
        <p className="price">
          <span class="placeholder placeholder-grey w-100"></span>
        </p>
        <div className="time">
          <p className="w-25">
            <span class="placeholder placeholder-grey w-100"></span>
          </p>
          <p>
            <span class="placeholder placeholder-grey w-100"></span>
          </p>
        </div>
        <div className="requirements">
          <p className="w-50">
            <span class="placeholder placeholder-grey w-100"></span>
          </p>
          <p>
            <span class="placeholder placeholder-grey w-100"></span>
          </p>
        </div>
      </div>
    </Styles>
  );
};

export default GigInfo;
