import React from "react";
import SendMessage from "./SendMessage";
import ChatHeader from "./ChatHeader";
import { ChatDialogMessages } from "./ChatDialogMessages";
import { useUserDialogsState } from "../../../api/queries/use-user-dialogs";
const ChatBox = ({ selectedDialogId }) => {
  const { getStateByParams } = useUserDialogsState();
  const { data } = getStateByParams(undefined) ?? { data: undefined };
  const selectedDialog = data?.find(
    (dialog) => dialog._id === selectedDialogId
  );
  if (!selectedDialog) {
    return (
      <div className="position-relative d-flex align-items-center justify-content-center overflow-auto w-100 h-100">
        <p>{"Please select a recipient to start chatting"}</p>
      </div>
    );
  }

  return (
    <div className="w-100 d-flex flex-column overflow-auto" style={{ flex: 2 }}>
      <div className="border border-bottom-50 ps-4 pe-4 pt-2 pb-2 w-100 shadow-sm">
        <ChatHeader recipent={selectedDialog.opponent} />
      </div>
      <ChatDialogMessages selectedDialog={selectedDialog} />
      <div className="border-start-0 border-end-0 border border-bottom-0 border-top-50 ps-4 pe-4 pt-2 pb-2 w-100 shadow-sm">
        <SendMessage
          dialogId={selectedDialog._id}
          recipentId={selectedDialog.opponent.id}
        />
      </div>
    </div>
  );
};

export default ChatBox;
