import React from "react";

import { Styles } from "./footer";
import { colors } from "../../../utils/Colors";

import RoutesPath from "../../../utils/Paths";
import { Link, useNavigate } from "react-router-dom";

import FalconLogo from "../../../assets/images/falcon-horizental.png";
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
  FaTwitter,
  FaTiktok,
  FaLinkedin,
} from "react-icons/fa";
import GooglePlayButton from "../../../assets/images/Google_Play.png";
import AppleStoreButton from "../../../assets/images/Apple_Store.png";
import GigFalconLogo from "../../../assets/images/LogoIcon.png";

const Footer = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const navigate = useNavigate();
  return (
    <Styles colors={colors}>
      <div className="footer-section">
        <div className="about">
          <div className="logo-text">
            <img
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              src={FalconLogo}
              alt="Logo"
              className="logo-icon"
            />
            <span className="below-text">Where Every Skill Counts!</span>
          </div>

          <div className="contactInfo">
            <div className="social-icons">
              <FaFacebook
                className="facebook-icon"
                onClick={() =>
                  openInNewTab(
                    "https://www.facebook.com/profile.php?id=61556146895847"
                  )
                }
              />
              <FaInstagram
                className="instagram-icon"
                onClick={() =>
                  openInNewTab("https://www.instagram.com/gigfalcon01/")
                }
              />
              <FaWhatsapp
                className="whatsapp-icon"
                onClick={() =>
                  openInNewTab(
                    "https://whatsapp.com/channel/0029VacpJyl3rZZhni2d5q0K"
                  )
                }
              />
              <FaYoutube className="youtube-icon" />
              <FaLinkedin className="linkedin-icon" />
              <FaTiktok className="tiktok-icon" />
            </div>
            {/* <span>Contact us:</span> */}
            {/* <span>support@gigfalcon.com</span> */}
          </div>
        </div>
        <div className="support">
          <Link to={RoutesPath.legalTOS}>{"Terms of Service"}</Link>
          <Link to={RoutesPath.legalPTOS}>{"Payments Terms of Service"}</Link>
          <Link to={RoutesPath.legalNDP}>{"Non-Discrimination Policy"}</Link>
          <Link to={RoutesPath.legalPP}>{"Privacy Policy"}</Link>
        </div>
        <div className="community">
          <a
            href="mailto:support@gigfalcon.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@gigfalcon.com
          </a>
        </div>
        <div className="newsletter">
          <div className="">
            <img
              onClick={() =>
                openInNewTab(
                  "https://play.google.com/store/apps/details?id=com.gigfalcon"
                )
              }
              style={{ cursor: "pointer" }}
              src={GooglePlayButton}
              alt="GooglePlay"
              className="play-icon"
            />
            <img
              onClick={() =>
                openInNewTab(
                  "https://apps.apple.com/us/app/gig-falcon/id6499023603"
                )
              }
              style={{ cursor: "pointer" }}
              src={AppleStoreButton}
              alt="AppleStore"
              className="apple-icon"
            />
          </div>
        </div>
      </div>
      <div className="borderTop"></div>
      <p className="copyright">GigFalcon &copy; All Rights Reserved</p>
    </Styles>
  );
};
export default Footer;
