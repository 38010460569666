import React from "react";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import TermsOfServiceJSX from "../../components/Legal/terms-of-service";
const LegalTOS = () => {
  return (
    <>
      <Header />
        <div className="container p-5">
          <TermsOfServiceJSX />
        </div>
      <Footer />
    </>
  );
};

export default LegalTOS;
