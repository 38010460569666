import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import axios1 from "axios";
import {
  setSectionFP,
  setSectionSignIn,
  setSectionSignUp,
} from "../slices/authSlice";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { user } = data;
      //here goes the api for username & email verification
      const response = await axios.get(
        `/users/availablity?username=${user.username}&email=${user.email}`
      );
      const availData = response.data;
      if (
        availData.username.availability === "NOT_AVAILABLE" &&
        availData.email.availability === "NOT_AVAILABLE"
      ) {
        throw new Error(
          "Username and Email already exist, Please Go to Sign in Page"
        );
      }
      if (availData.username.availability === "NOT_AVAILABLE") {
        throw new Error("Username already exist");
      }
      if (availData.email.availability === "NOT_AVAILABLE") {
        throw new Error("Email already exist, Please Go to Sign in Page");
      }

      // here goes the api call for OTP
      const res = await axios.post("/users/sign-up/app", {
        ...user,
      });
      dispatch(setSectionSignUp(2));
      return res.data.user;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const checkOTP = createAsyncThunk(
  "auth/checkOTP",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { user, navigate } = data;
      const response = await axios.post("/users/verify-user-sign-in", {
        ...user,
      });
      localStorage.setItem("auth", JSON.stringify(response.data.user));
      localStorage.setItem("x-access-token", response.data.token);
      dispatch(openSuccessMessage("Account Verified ! "));
      navigate("/");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",

  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { user, navigate, beforeNavigate } = data;
      const response = await axios.post("/users/sign-in/app", {
        email: user.email,
        password: user.password,
      });
      if (response.data.user.accountStatus === "pending") {
        // alert("Please verify your account before login.");
        await axios.post("/users/resend-verification-code", {
          userId: response.data.user._id,
        });
        dispatch(setSectionSignIn(2));
      } else {
        if (beforeNavigate) {
          await beforeNavigate();
        }
        navigate("/");
        dispatch(openSuccessMessage("Signed In Successfully"));
        localStorage.setItem("auth", JSON.stringify(response.data.user));
        localStorage.setItem("x-access-token", response.data.token);
      }
      return response.data.user;
    } catch (error) {
      dispatch(openErrorMessage(error.response.data.error));
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const verifyUserEmail = createAsyncThunk(
  "auth/verifyUserEmail",
  async (data, { rejectWithValue }) => {
    try {
    } catch (error) {
    }
  }
);

export const logOut = createAsyncThunk(
  "auth/logOut",

  async (
    { navigate, beforeNavigate, disableToast = false },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.get("/users/sign-out");
      localStorage.removeItem("auth");
      localStorage.removeItem("x-access-token");
      if (beforeNavigate) {
        await beforeNavigate();
      }
      navigate("/sign-in");
      if (!disableToast) {
        dispatch(openSuccessMessage("Logged Out Successfully"));
      }
      return response;
    } catch (err) {
      localStorage.removeItem("auth");
      localStorage.removeItem("x-access-token");
      navigate("/sign-in");
      return rejectWithValue(err.message);
    }
  }
);
//

export const googleSignIn = createAsyncThunk(
  "auth/googleSignIn",
  async (data, { rejectWithValue }) => {
    const { idToken } = data;
    try {
      // const response = await axios.post("/users/sign-in/google", {
      //   idToken: idToken,
      // });

      // const response = await fetch({
      //   url: "http://192.168.0.107:9002/v2/accounts/sign-in/google",
      //   method: "post",
      //   body: JSON.stringify({ idToken }),
      // });
      const response = await axios1.post(
        "http://192.168.0.101:9002/v2/accounts/sign-in/google",
        { idToken }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//"/users/sign-in/app", {idToken: idToken}

export const verifyForgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (data, { rejectWithValue, dispatch }) => {
    const { account } = data;
    try {
      const response = await axios.post("/users/confirm-account", { account });
      dispatch(setSectionFP(2));
      return response.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data, { rejectWithValue, dispatch }) => {
    const { user, navigate } = data;
    const { code, userId, password } = user;
    try {
      const response = await axios.post("/users/forgot-password", {
        code,
        userId,
        password,
      });
      navigate("/sign-in");
      dispatch(setSectionFP(1));
      dispatch(openSuccessMessage(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(setSectionFP(2));
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const makeFav = createAsyncThunk(
  "auth,makeFav",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post("/users/make-fav", payload);
    } catch (error) {
    }
  }
);
