/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import useHomeFilters from "../../../store/hooks/useHomeFilters";
import useGigsSelector from "../../../store/hooks/useGigsSelector";
import useJobsSelector from "../../../store/hooks/useJobsSelector";

import { Map, useMap } from "react-mapkit";
import "./style.css";
import { useNavigate } from "react-router-dom";
// import redFalcon from "../../../assets/icons/redIcon.png";
// import blueFalcon from "../../../assets/icons/blueIcon.png"
import { redFalcon, RedIconSVG, BlueIconSVG } from "../../../utils/Images";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import useGigsSelectorOnMap from "../../../store/hooks/useGigsSelectorOnMap";
import useJobsSelectorOnMap from "../../../store/hooks/useJobsSelectorOnMap";

const DEFAULT_LATITUDE_SPAN = 0.2;
const DEFAULT_LONGITUDE_SPAN = 0.2;

const roleTypes = {
  SELLER: "seller",
  BUYER: "buyer",
};

const getDesc = (description = "") => {
  const limit = 34;
  return description.length <= limit
    ? description
    : `${description.slice(0, limit)}...`;
};

function createCard(
  type,
  username,
  description,
  price,
  rating,
  profilePic,
  onPressDescription = () => {},
  onPressProfileName = () => {},
) {
  const card = document.createElement("div");
  card.className = "card";
  const header = document.createElement("div");
  header.className = "header";

  const profileImg = document.createElement("img");
  profileImg.src = profilePic;
  profileImg.className = "profile-img";

  const usernameElement = document.createElement("p");
  usernameElement.className = "username";
  usernameElement.innerText = username;
  usernameElement.onclick = onPressProfileName;
  header.appendChild(usernameElement);
  header.appendChild(profileImg);

  const ratingContainer = document.createElement("div");
  ratingContainer.className = "rating-container";
  const currentRating = rating;
  const ratingValueElement = document.createElement("span");
  if (currentRating > 0) {
    const ratingTextElement = document.createElement("span");
    ratingTextElement.innerText = "Rating: ";
    ratingContainer.appendChild(ratingTextElement);
    ratingValueElement.className = "rating-value";
  }
  ratingValueElement.innerText =
    currentRating > 0
      ? currentRating
      : type === roleTypes.BUYER
        ? "New Buyer"
        : "Rookie";
  ratingContainer.appendChild(ratingValueElement);
  const content = document.createElement("div");
  content.className = "card-content";
  content.textContent = getDesc(description);
  content.onclick = onPressDescription;

  const footer = document.createElement("div");
  footer.className = "footer-content d-flex justify-content-between";
  const priceContainer = document.createElement("div");
  const priceTextElement = document.createElement("span");
  priceTextElement.innerText = "Price:";
  const priceValueElement = document.createElement("span");
  priceValueElement.innerText = price;
  priceValueElement.className = "price";
  priceContainer.appendChild(priceTextElement);
  priceContainer.appendChild(priceValueElement);
  footer.appendChild(priceContainer);
  footer.appendChild(ratingContainer);
  card.appendChild(header);
  card.appendChild(content);
  card.appendChild(footer);

  return card;
}

const calloutElementForAnnotation = (annotation) => {
  const data = annotation.data;
  const card = createCard(
    data.type,
    data.username,
    data.description,
    `$${data.priceFrom}-$${data.priceTo}`,
    data.rating,
    data.profilePic,
    data.onPressDescription,
    data.onAvatarClick,
  );
  const div = document.createElement("div");
  div.appendChild(card);
  return div;
};

const HomeAppleMapView = () => {
  const navigate = useNavigate();
  const { fetchGigs, gigs, loading: gigsLoading } = useGigsSelectorOnMap();
  const {
    fetchJobs,
    jobs: jobsData,
    loading: jobsLoading,
  } = useJobsSelectorOnMap();
  const [rerender, setRerender] = useState(0);
  const { map, mapProps, mapkit, setRegion } = useMap({
    showsUserLocation: true,
    showsUserLocationControl: true,
  });

  const { filters } = useHomeFilters();

  const setLocation = (
    lat,
    lng,
    latitudeSpan = DEFAULT_LATITUDE_SPAN,
    longitudeSpan = DEFAULT_LONGITUDE_SPAN,
  ) => {
    setRegion(
      {
        latitude: lat,
        longitude: lng,
        latitudeSpan: latitudeSpan,
        longitudeSpan: longitudeSpan,
      },
      true,
    );
  };

  useEffect(() => {
    setLocation(
      filters.geometryFilter.data.lat,
      filters.geometryFilter.data.lng,
    );
  }, [filters.geometryFilter, map]);

  const onMapPointChange = useCallback(() => {
    const visibleMapRect = map.visibleMapRect;
    const { size, origin } = visibleMapRect;
    const halfHeight = size.height / 2;
    const halfWidth = size.width / 2;

    const northEastRect = new mapkit.MapRect(
      origin.x - halfWidth,
      origin.y - halfHeight,
      size.width,
      size.height,
    );
    const southWestRect = new mapkit.MapRect(
      origin.x + halfWidth,
      origin.y + halfHeight,
      size.width,
      size.height,
    );
    const northEastCoordinates = northEastRect.toCoordinateRegion();
    const southWestCoordinates = southWestRect.toCoordinateRegion();

    const neLat = northEastCoordinates.center.latitude;
    const neLng = northEastCoordinates.center.longitude;
    const swLat = southWestCoordinates.center.latitude;
    const swLng = southWestCoordinates.center.longitude;

    // Handling Page
    let page = 1;
    let limit = 200;

    if (filters.sectionFilter === "gigs" || filters.sectionFilter === "all") {
      fetchGigs({ neLat, neLng, swLat, swLng, page, limit });
    }

    if (filters.sectionFilter === "jobs" || filters.sectionFilter === "all") {
      fetchJobs({ neLat, neLng, swLat, swLng, page, limit });
    }
  }, [map, mapkit, fetchGigs, filters.sectionFilter]);

  useEffect(() => {
    if (map) {
      map.addEventListener("region-change-end", onMapPointChange);
      map.removeAnnotations(map.annotations);
      const markers = [];
      if (
        gigs &&
        gigs.length > 0 &&
        (filters.sectionFilter === "gigs" || filters.sectionFilter === "all")
      ) {
        gigs.forEach((gig) => {
          const rating = gig.rating;
          var pinOptions = {
            url: { 1: BlueIconSVG },
            callout: {
              calloutElementForAnnotation,
            },
            data: {
              id: gig._id,
              type: roleTypes.SELLER,
              userId: gig.createdBy._id,
              username: gig.createdBy.username,
              profilePic: gig.createdBy.profileImg?.imgUrl,
              description: gig.title,
              priceFrom: gig.priceFrom,
              priceTo: gig.priceTo,
              rating,
              onPressDescription: () => {
                navigate(`/gig/${gig._id}/detail`, {
                  state: gig,
                });
              },
              onAvatarClick: () => {
                navigate(`/profile/${gig.createdBy._id}`);
              },
            },
          };
          var coordinate = new mapkit.Coordinate(gig.latitude, gig.longitude);
          const factory = function (coordinate, options) {
            const img = document.createElement("img");
            img.style = "cursor:pointer";
            img.src = BlueIconSVG;
            return img;
          };
          const annotation = new mapkit.Annotation(
            coordinate,
            factory,
            pinOptions,
          );
          annotation.calloutOffset = new DOMPoint(0, 3);
          markers.push(annotation);
        });
      }
      if (
        jobsData &&
        jobsData.length > 0 &&
        (filters.sectionFilter === "jobs" || filters.sectionFilter === "all")
      )
        jobsData.forEach((job) => {
          const rating = job.createdBy.rating;
          var pinOptions = {
            url: { 1: RedIconSVG },
            callout: {
              calloutElementForAnnotation,
            },
            data: {
              id: job._id,
              type: roleTypes.BUYER,
              description: job.description,
              userId: job.createdBy._id,
              username: job.createdBy.username,
              profilePic: job.createdBy.profileImg?.imgUrl,
              priceFrom: job.budgetMin,
              priceTo: job.budgetMax,
              rating,
              onPressDescription: () => {
                navigate(`/jobs/${job._id}/detail`);
              },
              onAvatarClick: () => {
                navigate(`/profile/${job.createdBy._id}`);
              },
            },
          };
          var coordinate = new mapkit.Coordinate(
            job.location.latitude,
            job.location.longitude,
          );
          const factory = function (coordinate, options) {
            const img = document.createElement("img");
            img.style = "cursor:pointer";
            img.src = RedIconSVG;
            return img;
          };
          const annotation = new mapkit.Annotation(
            coordinate,
            factory,
            pinOptions,
          );
          annotation.calloutOffset = new DOMPoint(0, 7);

          markers.push(annotation);
        });
      map.addAnnotations(markers);

      return () => {
        map.removeAnnotations(map.annotations);
        map.removeOverlays(map.overlays);
        map.removeEventListener("region-change-end", onMapPointChange);
      };
    }
  }, [map, gigs, jobsData, filters.sectionFilter, rerender]);

  const handleZoomIn = () => {
    const newRegion = new mapkit.CoordinateRegion(
      map.region.center,
      new mapkit.CoordinateSpan(
        map.region.span.latitudeDelta * 0.5,
        map.region.span.longitudeDelta * 0.5,
      ),
    );
    map.setRegionAnimated(newRegion);
  };

  const handleZoomOut = () => {
    const newRegion = new mapkit.CoordinateRegion(
      map.region.center,
      new mapkit.CoordinateSpan(
        map.region.span.latitudeDelta * 2,
        map.region.span.longitudeDelta * 2,
      ),
    );
    map.setRegionAnimated(newRegion);
  };

  return (
    <StyledMapView>
      <div className="map position-relative">
        <Map {...mapProps} />
        <div style={{ position: "absolute", top: 50, right: 50 }}>
          <ZoomButton onClick={handleZoomIn}>+</ZoomButton>
          <ZoomButton onClick={handleZoomOut}>-</ZoomButton>
          {gigsLoading || jobsLoading ? <MapLoadingButton /> : null}
        </div>
      </div>
    </StyledMapView>
  );
};

/**
 * @typedef {Object} Coordinates
 * @property {Object} center
 * @property {number} center.lat
 * @property {number} center.lng
 * @property {number} zoom
 *
 *
 * @typedef Props
 * @property {Coordinates} coordinates
 * @property {(newCoordinate:Coordinates) => void} setCoordinates
 */

const ZoomButton = styled.div`
  display: block;
  background-color: rgb(245, 245, 245, 0.9);
  color: rgb(112, 115, 112);
  width: 24px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 8px 8px;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
`;

const MapLoadingButton = styled.div`
  margin-top: 16px;
  width: 24px;
  aspect-ratio: 1;
  border-radius: 50%;

  border: 8px solid #ddf9f5;
  border-right-color: #0fd3bb;
  animation: l2 1s infinite linear;
  @keyframes l2 {
    to {
      transform: rotate(1turn);
    }
  }
`;

const StyledMapView = styled.div`
  .map {
    display: block;
    height: 80vh;
    width: 100vw;
  }
  .map-zoom-buttons {
    position: absolute;
    top: 50;
    right: 7;
  }
  .map-zoom-button {
    width: 4px;
    height: 4px;
    background-color: white;
    border-radius: 10px;
  }
  @media only screen and (min-width: 769px) {
    .map {
      display: block;
      height: 80vh;
      width: 50vw;
    }
  }
`;

export default HomeAppleMapView;
