const Content = () => (
    <>
      <h1>
        Anti-Discrimination Policy
      </h1>
      <p>
        Updated on January 31, 2024
      </p>
      <p>
        <br />
      </p>
      <p>
        GigFalcon is committed to providing a work environment in which all
        individuals are treated with respect and dignity. Each individual has the
        right to work in a professional atmosphere that promotes equal employment
        opportunities and prohibits discriminatory practices, including
        harassment. Therefore, GigFalcon expects that all relationships among
        persons in the workplace will be business-like and free of bias,
        prejudice, and harassment.
      </p>
      <p>
        <br />
      </p>
      <p>
        GigFalcon is committed to preventing discrimination against employees,
        clients, and subcontractors based on race, color, religion, creed, sex,
        sexual orientation, gender identity, national origin, age, disability,
        marital status, or any other characteristic protected by applicable law.
      </p>
      <p>
        <br />
      </p>
      <p>
        To fulfill this commitment, GigFalcon will:
      </p>
      <p>
        <br />
      </p>
      <ul>
        <li>
          <p>
            Ensure equal employment opportunities for all qualified employees.
          </p>
        </li>
        <li>
          <p>
            Prohibit unlawful discrimination or harassment in any form.
          </p>
        </li>
        <li>
          <p>
            Take timely and appropriate action against any behavior that violates
            this policy.
          </p>
        </li>
        <li>
          <p>
            Investigate all complaints of discrimination or harassment and take
            appropriate corrective action as necessary.
          </p>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <p>
        GigFalcon will take steps to reasonably accommodate qualified individuals
        with disabilities, so long as the accommodation does not pose an undue
        hardship on the company.
      </p>
      <p>
        <br />
      </p>
      <p>
        This Anti-Discrimination Policy applies to all employees, including
        management and supervisors, and encompasses all aspects of employment,
        including hiring, training, promotion, and compensation.
      </p>
      <p>
        <br />
      </p>
      <p>
        Management at GigFalcon is responsible for implementing this policy, and
        employees are expected to cooperate in this regard. Any employee who
        violates this policy may be subject to disciplinary action, up to and
        including termination of employment.
      </p>
      <p>
        <br />
      </p>
      <p>
        This policy applies to all aspects of the work relationship, including all
        forms of communication, both within the workplace and externally.
      </p>
      <p>
        <br />
      </p>
      <p>
        Employees and subcontractors who believe they have been subjected to any
        type of discrimination or harassment are encouraged to report the
        incident(s) immediately. Appropriate investigative action will be taken
        upon such reports to ensure a prompt and fair resolution.
      </p>
    </>
  )
export default Content  