import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  success: "",
};

const toastSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    openSuccessMessage: (state, action) => {
      state.success = action.payload;
    },
    openErrorMessage: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { openErrorMessage, openSuccessMessage } = toastSlice.actions;
export default toastSlice.reducer;
