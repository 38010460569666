import { useEffect } from "react";
import { useQBContext } from "../../context/quickblox-provider";
import { useDialogMessagesState } from "../queries/use-dialog-messages";
import { formatISO, fromUnixTime } from "date-fns";
import { useAuthContext } from "../../../authentication";
import { useUserDialogsState } from "../queries/use-user-dialogs";

/**
 *
 * @typedef {object} Message
 * @property {string} body
 * @property {null | number} delay
 * @property {string} dialog_id
 * @property {object} extension
 * @property {number} extension.date_sent
 * @property {string} extension.message_id
 * @property {number} extension.dialog_id
 * @property {number} extension.save_to_history
 * @property {string} id
 * @property {number} markable
 * @property {null | number} recipient_id
 * @property {string} type
 */

export const useIncomingMessage = ({ onNewMessage }) => {
  const { QBApp } = useQBContext();
  const { user,isAuthenticated } = useAuthContext();
  const chatsMessages = useDialogMessagesState();
  const chatsList = useUserDialogsState();

  const currentUserId = isAuthenticated && user?.quickblox?.user_id;

  useEffect(() => {
    if (!currentUserId) return;
    const onMessageListener = (recipient_id, sender_id, message) => {
      const DateSent = formatISO(fromUnixTime(message.extension.date_sent));
      const attachments = message.extension.hasOwnProperty("attachments")
        ? message.extension.attachments.map((attachment) => {
            return {
              id: attachment.uid,
              url: QBApp.content.publicUrl(attachment.uid),
              type: attachment.type,
            };
          })
        : [];

      const newMessage = {
        all_read: false,
        attachments,
        chat_dialog_id: message.dialog_id,
        created_at: DateSent,
        date_sent: parseInt(message.extension.date_sent),
        delivered_ids: [sender_id, recipient_id],
        message: message.body,
        read: 0,
        read_ids: [sender_id],
        recipient_id,
        sender_id,
        _id: message.extension.message_id,
        updated_at: DateSent,
      };

      onNewMessage && onNewMessage(newMessage);
      // Load the message into it's appropriate dialog chat from the list of all loaded messages
      chatsMessages.setStateByParams(
        { dialogId: message.dialog_id },
        (state) => {
          // It might be useful in some cases to keep the limit of page in mind.
          // But as in this case, we are not displaying data in table/pages format,
          // we can just push the new message to the last page,
          // no need to ensure that it's size becomes more than the lmit
          if (!state) return { status: "success", data: [[newMessage]] };
          const pageWithMostRecentMessages =
            state.data && state.data.length ? state.data[0] : [];

          return {
            ...state,
            data: state.data
              ? [[newMessage, ...pageWithMostRecentMessages], ...state.data]
              : [[newMessage]],
          };
        }
      );

      // Update the last message of the appropritate dialog from the list of all dialogs
      chatsList.setStateByParams(undefined, (state) => {
        if (!state) return state;
        return {
          ...state,
          data: state.data?.map((dialog) => {
            if (dialog._id === message.dialog_id) {
              return {
                ...dialog,
                unread_messages_count: dialog.unread_messages_count + 1,
                last_message: newMessage.message,
                last_message_date_sent: newMessage.date_sent,
                last_message_id: newMessage._id,
                last_message_user_id: newMessage.sender_id,
              };
            }
            return dialog;
          }),
        };
      });
    };

    QBApp.chat.onMessageListener = (senderId, message) =>
      onMessageListener(currentUserId, senderId, message);
    return () => {
      QBApp.chat.onMessageListener = null;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QBApp, currentUserId]);
};
