import styled from "styled-components";

export const Styles = styled.div`
  .gig-creation {
    padding: 40px 20px;
  }
  .page1 {
    background-color: ${({ colors }) => colors.color.lightBackground};

    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
    padding: 10px 20px;
  }
  .page1 label {
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
  }
  textarea {
    width: 100%;
    height: 150px;
    padding: 5px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    resize: vertical;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
  }
  .selectors {
    margin-bottom: 20px;
  }
  .select {
    width: 200px;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .note {
    color: ${({ colors }) => colors.color.darkGrey};
    font-size: 14px;
  }
  .btns {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  .cancel-btn {
    background-color: ${({ colors }) => colors.color.white};
    color: ${({ colors }) => colors.color.black};
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${({ colors }) => colors.color.secondary};
  }
  .save-btn {
    border: none;
    background-color: ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  .location-wrapper {
    margin-bottom: 40px;
  }
  .location {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    background-color: ${({ colors }) => colors.color.white};
    color: ${({ colors }) => colors.color.darkGrey};
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
    cursor: pointer;
  }
  .gig-creation-img {
    display: none;
  }

  @media only screen and (min-width: 769px) {
    .gig-creation {
      padding: 40px;
    }
  }
  @media only screen and (min-width: 1025px) {
    .gig-creation {
      display: flex;
      gap: 40px;
    }
    .gig-creation-data {
      flex: 1;
    }
    .gig-creation-img {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -50px;
    }
    .gig-creation-img img {
      width: 100%;
    }
  }
`;
