import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/categories");

      return response.data.categories;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
