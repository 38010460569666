import styled from "styled-components";

export const Styles = styled.div`
  .price-box {
    margin: 30px 0;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 10px;
  }
  .price {
    font-size: 20px;
    font-family: "MS_SemiBold";
    padding-bottom: 10px;
  }
  .time {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  .time-icon {
    width: 18px;
    height: 18px;
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .time p {
    font-size: 14px;
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .requirements p {
    font-size: 14px;
  }
  .requirements h6 {
    font-family: "MS_SemiBold";
  }
`;
