import styled from "styled-components";

export const Styles = styled.div`
  .service-looking {
    background-color: ${({ colors }) => colors.color.lightBackground};
    padding: 20px 10px;
    margin: 0 10px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
  }
  .service-looking-question {
    margin-bottom: 5px;
  }
  .text-field {
    padding: 0 10px 10px 10px;
    background-color: ${({ colors }) => colors.color.white};
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
  }
  .text-field p {
    text-align: end;
  }
  textarea {
    width: 100%;
    height: 150px;
    padding: 5px;
    border: none;
    outline: none;
    resize: vertical;
  }
  .selector {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .select {
    
    font-size: 15px;
  }
  .work-type {
    margin-top: 20px;
  }
  .attachment-box {
    margin-top: 20px;
  }
  .attachment-box label {
    display: block;
    text-align: center;
    font-size: 18px;
  }
  .add {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  .add p {
    text-align: center;
    margin-top: 5px;
  }
  .deliever {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .duration {
    margin-top: 15px;
    display: flex;
  }
  .deliever p {
    text-align: center;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 5px 1em;
  }
  .budget {
    margin-top: 20px;
  }
  .budget-box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  .budget-min-max {
    display: flex;
    align-items: center;
    width: 200px;
    height: 45px;
    margin-top: 8px;
    background-color: ${({ colors }) => colors.color.white};
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 5px;
  }
  .budget label {
    font-size: 18px;
    display: block;
    text-align: center;
  }
  .budget p {
    margin-left: 5px;
    width: 100%;
  }
  .dollar {
  }
  .submit-job {
    text-align: center;
    margin: 20px 0;
  }

  .error {
    border: 1px solid red !important;
  }
  .add-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    width: 220px;
    background-color: ${({ colors }) => colors.color.addBox};
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: ${({ colors }) => colors.color.secondary};
  }
  .plus-icon {
    height: 35px;
    width: 35px;
    filter: brightness(0) invert(1);
  }
  .file-img {
    border-radius: 5px;
    margin-top: 20px;
  }
  .select-label span {
    color: ${({ colors }) => colors.color.darkGrey};
    font-size: 14px;
  }

  @media only screen and (min-width: 480px) {
    .budget-box {
      flex-direction: row;
      gap: 30px;
    }
    .inner {
      display: flex;
      justify-content: space-between;
    }
  }
`;
