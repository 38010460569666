import { format, fromUnixTime } from "date-fns";
import React from "react";
import styled from "styled-components";
import Avatar from "../../../../../components/common/Avatar";

const ChatsListItem = ({
  senderName,
  lastMessageDate,
  selected,
  lastMessageTxt,
  lastMessageId,
  unread_messages_count,
  onClick,
}) => {
  return (
    <StyledChatsListItem
      onClick={onClick}
      style={{ backgroundColor: selected ? "#f5f5f5" : "white" }}
    >
      <Avatar name={senderName} />
      <div
        style={{ display: "flex", flexDirection: "column", gap: 4, flex: 1 }}
      >
        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          <p>{senderName}</p>
          {lastMessageDate && (
            <p className="text-muted">
              {format(fromUnixTime(lastMessageDate), "hh:mm a")}
            </p>
          )}
        </div>
        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          {lastMessageTxt && (
            <>
              <p
                style={{
                  fontSize: "0.8rem",
                  color: unread_messages_count > 0 ? "black" : "#6c757d",
                  fontWeight: unread_messages_count > 0 ? "bolder" : "normal",
                }}
              >
                {lastMessageTxt}
              </p>
              {unread_messages_count > 0 && (
                <span
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    fontSize: 8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100%",
                    width: 16,
                    height: 16,
                    fontWeight: 600,
                  }}
                >
                  {unread_messages_count}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </StyledChatsListItem>
  );
};
const StyledChatsListItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.6rem 0.8rem;
  gap: 0.6rem;
  :hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: block;
    margin-right: 8px;
  }

  .text-muted {
    font-size: 0.8rem;
    color: #6c757d;
  }
  p {
    font-size: 1rem;
    margin: 0;
  }
`;

export default ChatsListItem;
