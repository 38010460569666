import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import useGigsSelectorByPagination from "../../store/hooks/useGigsSelectorByPagination";
import { gigsByPaginate } from "../../store/thunks/gigsThunk";
import FilterDropdowns from "../Gigs/FilterDropdowns";
import { colors } from "../../utils/Colors";
import GigPlaceholder from "../common/Placeholders/GigPlaceholder";
import GigCard from "../Gigs/GigCard";

const GigsContainer = () => {
  const {
    data: gigs,
    loading,
    hasMore,
    page,
    limit,
  } = useGigsSelectorByPagination();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const scrollViewRef = useRef(null);
  const dispatch = useDispatch();
  const { handleChange, handleSubmit, values, setFieldValue, errors } =
    useFormik({
      initialValues: {
        category: "",
        searchText: "",
        sortBy: "",
        level: "",
        minPrice: "",
        maxPrice: "",
        subcategory: "",
        userId: "",
      },
      onSubmit: (values) => {
        dispatch(gigsByPaginate({ ...values, page: 1 }));
      },
    });

  const handleScroll = useCallback(() => {
    if (!hasMore) {
      return;
    }
    if (isMobile) {
      const threshold = document.body.offsetHeight * 0.8;
      if (window.innerHeight + window.scrollY >= threshold && !loading) {
        dispatch(gigsByPaginate({ ...values, page: page + 1 }));
      }
    } else {
      if (scrollViewRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollViewRef.current;
        const threshold = scrollHeight * 0.9;
        if (scrollTop + clientHeight >= threshold && !loading) {
          dispatch(gigsByPaginate({ ...values, page: page + 1 }));
        }
      }
    }
  }, [isMobile, loading, dispatch, values, page, hasMore]);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    if (scrollViewRef.current) {
      scrollViewRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);

      if (scrollViewRef.current) {
        scrollViewRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isMobile, handleScroll]);

  return (
    <div className="list" ref={scrollViewRef}>
      <div className="home-dropdown">
        <div className="list-header">
          <h2 className="gigs-title">
            Find The{" "}
            <span>
              Services
              <br />
            </span>{" "}
            You Need
          </h2>
        </div>
        <FilterDropdowns
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      </div>
      <StyledGigList colors={colors}>
        {!!gigs?.length &&
          gigs?.map((gig, index) => <GigCard key={index} {...gig} />)}
        {loading ? (
          <GigPlaceholder placeholders={[1, 2, 3, 4, 5, 6]} />
        ) : (
          !loading &&
          !gigs?.length && (
            <div
              className="d-flex align-items-center justify-content-center mt-5"
              style={{ color: colors.color.darkGrey }}
            >
              <p>Sorry ... No Gig Found</p>
            </div>
          )
        )}
      </StyledGigList>
    </div>
  );
};
const StyledGigList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0;
`;

export default GigsContainer;
