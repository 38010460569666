import React from "react";
import { FaPaperclip } from "react-icons/fa";

import styled from "styled-components";
const AttachmentButton = ({...props}) => {
  return (
    <StyledAttachmentButton className="btn">
      <FaPaperclip style = {{width:'1.5rem', height:'1.5rem'}} {...props} />
    </StyledAttachmentButton>
  );
};

const StyledAttachmentButton = styled.button`
    cursor: pointer;
    color: GrayText;
    :hover{
        background-color: #e8e8e8;
    }
`;

export default AttachmentButton;
