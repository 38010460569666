import React, { useState } from "react";
import { Styles } from "./modalPayment";
import { colors } from "../../../utils/Colors";
import OfferDetails from "../../../assets/images/offer-detail.png";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as API from "../../../api";
/**
 * @typedef {Object} ModalPaymentProps
 * @property {boolean} isOpen
 * @property {function} onClose
 * @property {{coverLetter: string, price: number, _id: string}} offer
 * @property {number} buyerTax
 * @property {number} sellerTax
 *
 */
const ModalPayment = (/** ModalPaymentProps **/{ offer, isOpen, onClose, buyerTax, sellerTax }) => {
  const coverLetter = offer?.coverLetter;
  const price = offer?.price;
  const [loading, setLoading] = useState(false);
  const handleProceedToPay = async () => {
    setLoading(true);
    try {
      const response = await API.getJobOfferCheckoutSession(offer._id);
      console.log(response)
      window.location.href = response.data.session
    } catch (err) {      
      console.log(err);
    }
    setLoading(false);
  };

  const serviceFee = ((price / 100) * buyerTax).toFixed(1)

  return (
    <Modal isOpen={isOpen} toggle={onClose} className="modal-xl">
      <ModalHeader toggle={onClose}>Payments</ModalHeader>
      <ModalBody>
        <Styles colors={colors}>
          <div className="order-1 order-lg-2 offer-info">
            <h5 className="text-center my-3">
              Offer <span className="falcon-clr">Details</span>
            </h5>
            <div className="offer-detail-img">
              <img src={OfferDetails} alt={"offer details"} />
            </div>
            <div className="my-5">
              <p className="mb-3">
                {coverLetter?.length > 60
                  ? coverLetter.slice(0, 59) + "..."
                  : coverLetter}
              </p>
              <div className="d-flex justify-content-between">
                <p className="falcon-clr">Price:</p>
                <p>${price}</p>
              </div>
              <hr />
              <div className="d-flex justify-content-end gap-3">
                <p className="text-muted">Sub Total:</p>
                <p>${price}</p>
              </div>
              <div className="d-flex justify-content-end gap-3">
                <p className="text-muted">Service Fee:</p>
                <p>${serviceFee}</p>
              </div>
              <div className="d-flex justify-content-end gap-3">
                <p className="falcon-clr">Total:</p>
                <p>${parseInt(price) + parseInt(serviceFee)}</p>
              </div>
            </div>
          </div>
        </Styles>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          {"Close"}
        </button>
        <button
          type="button"
          className="d-flex align-items-center gap-2 btn"
          style={{
            backgroundColor: colors.color.secondary,
            color: colors.color.white,
            minWidth: "150px",
          }}
          onClick={handleProceedToPay}
        >
          {loading && (
            <div
              className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
              role="status"
            >
              <span className="sr-only"></span>
            </div>
          )}
          <p>{"Proceed to Pay"}</p>
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalPayment;
