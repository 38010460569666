import { useCallback, useEffect } from "react";
import { useQBContext } from "../../context/quickblox-provider";
import { useDialogMessagesState } from "../queries/use-dialog-messages";
import { useAuthContext } from "../../../authentication";
import { useUserDialogsState } from "../queries/use-user-dialogs";

/**
 *
 * @typedef {object} Message
 * @property {string} body
 * @property {null | number} delay
 * @property {string} dialog_id
 * @property {object} extension
 * @property {number} extension.date_sent
 * @property {string} extension.message_id
 * @property {number} extension.dialog_id
 * @property {number} extension.save_to_history
 * @property {string} id
 * @property {number} markable
 * @property {null | number} recipient_id
 * @property {string} type
 */

export const useReadMessageListener = () => {
  const { QBApp } = useQBContext();
  const { user } = useAuthContext();
  const chatsMessagesState = useDialogMessagesState();
  const chatsListState = useUserDialogsState();

  const onReadStatusListener = useCallback(
    (messageId, dialogId, userId) => {
      console.log("read", messageId, dialogId, userId);
      // Update the message read status in the list of all loaded messages
      chatsMessagesState.setStateByParams({ dialogId }, (prevState) => {
        if (!prevState) return prevState;

        return {
          ...prevState,
          data: prevState.data?.map((page) => {
            return page.map((message) => {
              const read_ids =
                message._id === messageId
                  ? [...message.read_ids, userId]
                  : [...message.read_ids];
              return {
                ...message,
                read_ids,
                all_read: read_ids.length >= 2 ? 1 : 0,
              };
            });
          }),
        };
      });

      // Update the unread messages of the list of all dialogs
      chatsListState.setStateByParams(undefined, (prevState) => {
        if (!prevState) return prevState;
        return {
          ...prevState,
          data: prevState.data?.map((dialog) => {
            if (dialog._id === dialogId) {
              return {
                ...dialog,
                unread_messages_count: 0,
              };
            }
            return dialog;
          }),
        };
      });
    },
    [chatsListState, chatsMessagesState]
  );

  useEffect(() => {
    if (!user?.quickblox) return;

    QBApp.chat.onReadStatusListener = (messageId, dialogId, userId) =>
      onReadStatusListener(messageId, dialogId, userId);
    return () => {
      QBApp.chat.onReadStatusListener = null;
    };
  }, [QBApp.chat, onReadStatusListener, user]);

  return {
    messages: [],
  };
};
