import React from "react";

import UserInfo from "../UserInfo";
import { Styles } from "./sellerInfo";
import { colors } from "../../../utils/Colors";
import { formatMessageTime } from "../../../utils/helperFunctions";

/**
 * @typedef {import("../../../types/server").UserProfileData} Props
 */
const Sellerinfo = (/** @type {Props} */props) => {
  const averageResponseTimeHours = props?.sellerStats?.averageResponseTimeHours;
  const avgResponseTime =
    averageResponseTimeHours > 1 ? averageResponseTimeHours + " Hours" : averageResponseTimeHours + " Hour";
  const createdOn = formatMessageTime(props.createdAt);

  return (
    <Styles colors={colors}>
      <div className="seller-section">
        <div>
          <h3 className="mb-3">
            About the <span>Seller</span>
          </h3>
          <div className="seller-info">
            <UserInfo
              {...props}
            />
          </div>
        </div>
        <div className="seller-details">
          <div className="d-flex justify-content-between">
            <div>
              <label>Average Response Time</label>
              <p>{avgResponseTime}</p>
            </div>
            <div>
              <label>Member Since</label>
              <p>{createdOn}</p>
            </div>
          </div>
          <hr />
          <div>
            <p className="seller-desrciption">{props.description}</p>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default Sellerinfo;
