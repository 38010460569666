import React, { useState } from "react";
import AttachmentButton from "./atoms/AttachmentButton";
import { useSendMessage } from "../api";
import FileUploadTrigger from "../../../components/common/FileUploadTrigger";
import useAttachmentsHandler from "../useAttachmentsHandler";
import FilesSelectedPreview from "./molecules/FilesSelectedPreview";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { openSuccessMessage } from "../../../store/slices/toastSlice";
import { useNavigate } from "react-router-dom";

// Todo: Replace this button with the one that is used now
// import Button from "../../../components/common/Button";
const ContactButton = ({
  label = "Contact Seller",
  recipientName,
  recipentId,
  className = "",
}) => {
  const { sendMessage, status } = useSendMessage();
  const dispatch = useDispatch();
  const [values, setValues] = useState({ message: "" });
  const { filesSelected, handleFilesChange, isUploading, removeFile } =
    useAttachmentsHandler();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const navigate = useNavigate();

  const handleSendingMessage = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!values.message) {
      return;
    }
    const attachments = filesSelected?.map((file) => file.serverFile);
    const newMessage = await sendMessage({
      message: values.message,
      recipentId,
      attachments,
    });
    navigate(`/inbox?dialogId=${newMessage.chat_dialog_id}`);
    dispatch(openSuccessMessage("Message sent successfully"));
    setModal(false);
  };

  return (
    <>
      <button
        type="button"
        className={`btn btn-lg btn-primary mt-4 ${className}`}
        onClick={toggle}
      >
        {label}
      </button>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          className="d-flex"
          close={
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={toggle}
            />
          }
        >
          {`Message ` + recipientName}
        </ModalHeader>
        <ModalBody className="p-0">
          <textarea
            className="form-control modal-body"
            id="message-text"
            name="message-text"
            placeholder="Type your message here..."
            value={values.message}
            onChange={(e) => setValues({ ...values, message: e.target.value })}
          />
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-2">
            <FileUploadTrigger
              name={"attachment"}
              onChange={(e) => {
                const files = e.target.files;
                handleFilesChange(files);
              }}
              multiple
              RenderComponent={({ onClick }) => 
                <AttachmentButton onClick={onClick} />
              }
            />
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isUploading || !values.message || status === "pending"}
              onClick={handleSendingMessage}
            >
              {status === "pending" || isUploading ? (
                <div className="d-flex gap-2 align-items-center">
                  <div
                    className="spinner-border spinner-border-sm and spinner-grow-sm"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                  <p style={{ color: "white" }}>
                    {isUploading ? "Uploading..." : "Sending..."}
                  </p>
                </div>
              ) : (
                <p style={{ color: "white" }}>Send Message</p>
              )}
            </button>
          </div>
          <div className="flex-fill w-100">
            {filesSelected && (
              <FilesSelectedPreview
                filesSelected={filesSelected}
                removeFile={removeFile}
              />
            )}
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ContactButton;
