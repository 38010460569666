import Button from "../../components/common/Button";
import React from "react";
import { BsChevronDown } from "react-icons/bs";
import styled from "styled-components";
import { colors } from "../../utils/Colors";

export const FilterDropdownTrigger = ({ children }) => {
  return (
    <StyledFilterDropdownTrigger
      colors={colors}
      className="dropdowns d-flex justify-content-between"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style={{ width: "150px", cursor: "pointer" }}
    >
      {children}
      <BsChevronDown className="chevron" style={{ marginLeft: "20px" }} />
    </StyledFilterDropdownTrigger>
  );
};
const StyledFilterDropdownTrigger = styled.div`
  border: 1px solid ${({ colors }) => colors.color.lightGrey};
  display: flex;
  align-items: center;
  width: 150px;
  gap: 5px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
  position: relative;
  hover {
    background-color: ${({ colors }) => colors.color.lightGrey};
  }
`;

export const FilterDropdownMenu = ({ children }) => {
  return (
    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      {children}
    </ul>
  );
};

export const FilterDropdown = ({ children }) => {
  return <div className="dropdown">{children}</div>;
};

export const FilterDropdownMenuItem = ({ children, ...props }) => {
  return (
    <div className="w-100" style={{ cursor: "pointer" }} {...props}>
      <div
        className="dropdown-item d-flex justify-content-between gap-3 mb-3"
      >
        {children}
      </div>
    </div>
  );
};
export const FilterDropdownClearButton = ({ children, ...props }) => {
  // .clear-filter {
  //     font-size: 12px;
  //     padding: 0 20px;
  //     color: grey;
  //     cursor: pointer;
  //   }
  return (
    <p
      style={{
        padding: "0 20px",
        fontSize: "12px",
        color: "gray",
        cursor: "pointer",
      }}
      {...props}
    >
      {children}
    </p>
  );
};

export const FilterDropdownApplyButton = ({ children, ...props }) => {
  return (
    <div className="mt-4 mx-3 mb-2">
      <Button type="submit" className={'apply-btn'} {...props}>
        {children}
      </Button>
    </div>
  );
};
