import { createSlice } from "@reduxjs/toolkit";
import {
  completeOrder,
  createJobOrder,
  deliverOrder,
  getBuyerOrders,
  getSellerOrders,
  rejectOrder,
} from "../thunks";

const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const handleCreateJobOrderFulfilled = (state, action) => {
  state.loading = false;
  state.success = action.payload;
};
const handleBuyerOrdersPending = (state) => {
  state.loading = true;
  state.error = null;
  state.orders = [];
};
const handleGetBuyerOrdersFulfilled = (state, action) => {
  state.loading = false;
  state.orders = action.payload;
};
const handleCompleteOrderPending = (state) => {
  state.completeLoading = true;
  state.error = null;
};
const handleCompleteOrderFulfilled = (state, action) => {
  state.completeLoading = false;
  state.success = action.payload;
};
const handleCompleteOrderRejected = (state, action) => {
  state.completeLoading = false;
  state.error = action.payload;
};
const handleRejectOrderPending = (state) => {
  state.rejectLoading = true;
  state.error = null;
};
const handleRejectOrderFulfilled = (state, action) => {
  state.rejectLoading = false;
  state.error = null;
  state.success = action.payload;
};
const handleRejectOrderRejected = (state, action) => {
  state.rejectLoading = false;
  state.error = action.payload;
};

const initialState = {
  loading: false,
  completeLoading: false,
  rejectLoading: false,
  error: null,
  success: null,
  orders: [],
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createJobOrder.pending, handlePending)
      .addCase(createJobOrder.fulfilled, handleCreateJobOrderFulfilled)
      .addCase(createJobOrder.rejected, handleRejected)
      .addCase(getBuyerOrders.pending, handleBuyerOrdersPending)
      .addCase(getBuyerOrders.fulfilled, handleGetBuyerOrdersFulfilled)
      .addCase(getBuyerOrders.rejected, handleRejected)
      .addCase(getSellerOrders.pending, handleBuyerOrdersPending)
      .addCase(getSellerOrders.fulfilled, handleGetBuyerOrdersFulfilled)
      .addCase(getSellerOrders.rejected, handleRejected)
      .addCase(completeOrder.pending, handleCompleteOrderPending)
      .addCase(completeOrder.fulfilled, handleCompleteOrderFulfilled)
      .addCase(completeOrder.rejected, handleCompleteOrderRejected)
      .addCase(rejectOrder.pending, handleRejectOrderPending)
      .addCase(rejectOrder.fulfilled, handleRejectOrderFulfilled)
      .addCase(rejectOrder.rejected, handleRejectOrderRejected)
      .addCase(deliverOrder.pending, handlePending)
      .addCase(deliverOrder.fulfilled, handleCompleteOrderFulfilled)
      .addCase(deliverOrder.rejected, handleRejected);
  },
});

export default ordersSlice.reducer;
