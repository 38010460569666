import React from "react";

/**
 * @typedef {object} Props
 * @property {number | undefined} size
 * @property {string | undefined} className
 */
const CirclePlaceholder = (/** @type {Props} */ { size = 50, className="" }) => {
  return (
    <div
      className={`placeholder-glow rounded-circle ${className}`}
      style={{ height: size, width: size }}
    >
      <span className="placeholder placeholder-grey w-100 h-100 rounded-circle"></span>
    </div>
  );
};

export default CirclePlaceholder;
