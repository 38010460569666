import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async () => {
    try {
      const response = await axios.get("/notifications");
      return response.data.notifications;
    } catch (error) {
      
    }
  }
);
