import {
  createSessionRequest,
  deleteSessionRequest,
  startSessionWithToken,
} from "./api";

const QuickbloxSessionHandlers = ({
  QBApp,
  onSessionCreated,
  onSessionDestroyed,
  onSessionInitalized,
}) => {
  const createQBSession = async (login, password) => {
    const session = await createSessionRequest(QBApp, { login, password });
    onSessionCreated && (await onSessionCreated(session));
    try {
      sessionStorage.setItem("QBSession", JSON.stringify(session));
    } catch (e) {}
    return session;
  };

  const destroyQBSession = async () => {
    // On Logout, even if error occurs, destroy session
    try {
      await deleteSessionRequest(QBApp);
    } catch (e) {
      console.log(e);
    }
    sessionStorage.removeItem("QBSession");
    onSessionDestroyed && (await onSessionDestroyed(null));
    return null;
  };

  const initializeQBSession = async () => {
    const session = JSON.parse(sessionStorage.getItem("QBSession"));
    const initalSession = await startSessionWithToken(QBApp, session.token);
    onSessionInitalized && (await onSessionInitalized(initalSession));
    return session;
  };

  QBApp.onSessionExpiredListener = async () => {
    onSessionDestroyed && (await onSessionDestroyed(null));
  };

  return {
    createQBSession,
    destroyQBSession,
    initializeQBSession,
  };
};

export default QuickbloxSessionHandlers;
