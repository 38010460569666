import { createSlice } from "@reduxjs/toolkit";
import { getCategories } from "../thunks/categoryThunk";

const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};
const handleRejected = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};

const handleGetCategoriesFulfilled = (state, action) => {
  state.categories = action.payload;
  state.loading = false;
};

const initialState = {
  loading: false,
  error: "",
  categories: [],
};

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, handlePending)
      .addCase(getCategories.fulfilled, handleGetCategoriesFulfilled)
      .addCase(getCategories.rejected, handleRejected);
  },
});

export default categorySlice.reducer;
