import styled from "styled-components";

export const Styles = styled.div`
  .fav-heading {
    font-family: "NN_SemiBold";
  }
  .gigs-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    place-items: center;
    grid-gap: 25px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
`;
