import { createSlice } from "@reduxjs/toolkit";
import {
  setGeometryFilterToCurrentLocation,
  setGooglePlaceFilterWithPlaceId,
} from "../thunks/homeFiltersThunk";

/**
 * @typedef {Object} Geometry
 * @property {number} lat
 * @property {number} lng
 *
 * @typedef {Object} GooglePlaceSelectedOption
 * @property {string} label
 * @property {Object} value
 *
 *
 * @typedef {Object} FilterLocation
 * @property {GooglePlaceSelectedOption | undefined} googlePlaceSelectedOption
 * @property {Geometry} geometry
 *
 * @typedef {'jobs' | 'gigs' | 'all'} FilterSection
 *
 *
 *
 * @typedef {Object} FiltersParams
 * @property {FilterSection} sectionFilter
 * @property {GooglePlaceSelectedOption} googlePlaceFilter
 * @property {Geometry} geometryFilter
 *
 */

export const defaultState = {
  /** @type {FilterSection} */
  sectionFilter: "all",
  googlePlaceFilter: {
    /** @type {GooglePlaceSelectedOption | undefined} */
    data: undefined,
    loading: false,
    error: null,
  },
  geometryFilter: {
    data: {
      lat: 37.7583458,
      lng: -122.4707466,
    },
    loading: false,
    error: null,
  },
};
/**
 * @typedef {typeof defaultState} HomeFilterState
 */
const initialState = defaultState;

const homeFiltersSlice = createSlice({
  name: "homeFilters",
  initialState,
  reducers: {
    /**
     * @param {any} state
     * @param {Object} action
     * @param {Partial<FiltersParams>} action.payload
     *
     * @returns {void}
     */
    updateFilters(state, action) {
      if (action.payload.sectionFilter) {
        state.sectionFilter = action.payload.sectionFilter;
      }
      if (action.payload.googlePlaceFilter) {
        state.googlePlaceFilter.data = action.payload.googlePlaceFilter;
      }
      if (action.payload.geometryFilter) {
        state.geometryFilter.data = action.payload.geometryFilter;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      setGeometryFilterToCurrentLocation.pending,
      (state, action) => {
        state.geometryFilter.loading = true;
        state.geometryFilter.error = null;
      }
    );
    builder.addCase(
      setGeometryFilterToCurrentLocation.fulfilled,
      (state, action) => {
        state.geometryFilter.loading = false;
        state.geometryFilter.error = null;
        state.geometryFilter.data = action.payload;
      }
    );
    builder.addCase(
      setGeometryFilterToCurrentLocation.rejected,
      (state, action) => {
        state.geometryFilter.loading = false;
        state.geometryFilter.error = action.payload;
      }
    );
    builder.addCase(
      setGooglePlaceFilterWithPlaceId.pending,
      (state, action) => {
        state.googlePlaceFilter.loading = true;
        state.googlePlaceFilter.error = null;
      }
    );
    builder.addCase(
      setGooglePlaceFilterWithPlaceId.fulfilled,
      (state, action) => {
        state.googlePlaceFilter.loading = false;
        state.googlePlaceFilter.error = null;
        state.googlePlaceFilter.data = action.payload;
      }
    );
    builder.addCase(
      setGooglePlaceFilterWithPlaceId.rejected,
      (state, action) => {
        state.googlePlaceFilter.loading = false;
        state.googlePlaceFilter.error = action.payload;
      }
    );
  },
});

export const { updateFilters } = homeFiltersSlice.actions;
export default homeFiltersSlice.reducer;
