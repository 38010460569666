import React from "react";

import Error from "../../../common/Error";
import Button from "../../../common/Button";

import { useFormik } from "formik";
import { RequirementsSchema } from "../../../../utils/PostJobSchema";

const Requirements = ({ formNo, setFormNo, handleFormData, gigValues }) => {
  const initialValues = {
    requirements: gigValues?.requirements || "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: RequirementsSchema,
      onSubmit: (values) => {
        handleFormData({ ...values });
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Add Requirement</label>
        <textarea
          name="requirements"
          id="requirements"
          value={values.requirements}
          onChange={handleChange}
          autoComplete="off"
          onBlur={handleBlur}
          placeholder="Hello! All I need is some basic information to get started."
        ></textarea>
        {errors.requirements && touched.requirements && (
          <Error text={errors.requirements}></Error>
        )}
      </div>
      <div className="btns">
        <Button
          type="button"
          children="Back"
          className={formNo === 1 ? "d-none" : "cancel-btn"}
          onClick={() =>
            setFormNo(formNo > 1 && formNo < 5 ? formNo - 1 : formNo - 2)
          }
        />
        <div></div>
        <Button
          children={formNo === 4 ? "Submit" : "Next"}
          className="save-btn"
          type="submit"
        />
      </div>
    </form>
  );
};

export default Requirements;
