import React, { useMemo, useState } from "react";

import { useMap } from "react-mapkit";
import AsyncSelect from "react-select/async";

import { colors } from "../../../utils/Colors";
import useAppleReverseGeocoding from "../../../hooks/common/useAppleReverseGeocoding";

function AppleAutoComplete({
  onSelect,
  style = { width: "240px", borderRadius: 50 },
  limitCountries = "",
  coordinate = null,
  placeholder = "Search location...",
}) {
  const [isFocused, setIsFocused] = useState(false);
  const { mapkit } = useMap();
  const { address } = useAppleReverseGeocoding({
    coordinate,
    placeholder,
  });

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      const options = {
        getsUserLocation: true,
        includeQueries: true,
        includePointsOfInterest: true,
        limitToCountries: limitCountries,
      };
      const search = new mapkit.Search(options);
      search.autocomplete(inputValue, (error, data) => {
        if (error) {
          console.error(error);
          return;
        }
        const { results } = data;
        resolve(
          results.map((result) => {
            return {
              label: result.displayLines[0],
              value: {
                description: result.displayLines[0],
                coordinate: result.coordinate,
              },
            };
          })
        );
      });
    });

  const focusedStyles = {
    outline: "none",
    border: `2px solid ${colors.color.secondary}`,
    backgroundColor: "white",
    boxShadow: `0 0 1px ${colors.color.secondary}`,
  };

  const defaultStyles = {};

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      width: style.width,
      borderRadius: style.borderRadius,
      ...(isFocused ? focusedStyles : defaultStyles),
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "rgb(64, 65, 69)",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  return (
    <AsyncSelect
      styles={selectStyles}
      placeholder={address}
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      getOptionValue={(option) => option.value}
      onChange={(value) => onSelect(value)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  );
}

export default AppleAutoComplete;
