import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  location: null,
  // {
  // latitude: 95.7129,
  // longitude: 37.0902,
  // },
};

const locationSlice = createSlice({
  name: "loc",
  initialState,
  reducers: {
    getLocation: (state, action) => {
      state.location = action.payload;
    },
  },
});

export const { getLocation } = locationSlice.actions;
export default locationSlice.reducer;
