import styled from "styled-components";

export const Styles = styled.div`
  .manage-orders {
    margin: 60px auto;
    min-height: 50vh;
  }
  .manage-orders h2 {
    font-weight: 700;
    margin: 40px 0px;
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }
  .manage-status-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-left: 0;
    list-style: none;
  }
  .manage-status {
    color: ${({ colors }) => colors.color.status};
    font-weight: 800;
    font-size: 10px;
    cursor: pointer;
  }
  .status-bar {
    padding: 1px;
    background-color: ${({ colors }) => colors.color.status};
    position: relative;
    margin-bottom: 10px;
  }
  .status-bar-sphere {
    width: 40px;
    height: 5px;
    background-color: ${({ colors }) => colors.color.secondary};
    position: absolute;
    top: -1.5px;
    border-radius: 2px;
  }
  .priority-order {
    left: 0;
  }
  .active-order {
    left: 0px;
  }
  .pause-order {
    left: 45px;
  }
  .pending-order {
    left: 153px;
  }
  .unapprove-order {
    left: 220px;
  }
  .orders-box {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    margin: 20px 0;
  }
  .orders-details {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .order-details-headings {
    width: 70px;
  }
  .orders-details img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .orders-box hr {
    margin: 0;
  }
  .order-status {
    padding: 5px 20px;
    background-color: ${({ colors }) => colors.color.darkGrey};
    color: ${({ colors }) => colors.color.white};
    border-radius: 5px;
  }
  .revision {
    background-color: ${({ colors }) => colors.color.red} !important;
  }
  .falcon-offers {
    background-color: ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
    padding: 5px 20px;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .review-offers {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 5px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .active-table {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 10px 0px;
    border-radius: 10px;
    overflow-x: scroll !important;
  }
  table {
    width: 100%;
  }
  td,
  th {
    text-align: left;
    padding: 10px;
    font-size: 12px;
  }

  tr:nth-child(even) {
    background-color: #efeeee;
  }
  .offer-btn {
    border: none;
    background-color: ${({ colors }) => colors.color.secondary};
    color: ${({ colors }) => colors.color.white};
    border-radius: 5px;
    font-size: 14px;
  }
  .active-table::-webkit-scrollbar {
    height: 5px;
  }
  .active-table::-webkit-scrollbar-track {
    color: ${({ colors }) => colors.color.lightGrey};
    background: ;
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }

  .active-table::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.color.secondary};
    opacity: 0.7;
  }
  h2,
  h2 span {
    font-family: "MS_SemiBold";
  }
  .table-heading {
    font-family: "MS_SemiBold";
  }

  @media only screen and (min-width: 450px) {
    .manage-status {
      font-size: 14px;
    }
    .status-bar-sphere {
      width: 55px;
    }
    .priority-order {
      left: 0;
    }
    .active-order {
      left: 0px;
    }
    .pause-order {
      left: 60px;
    }
    .pending-order {
      left: 205px;
    }
    .unapprove-order {
      left: 300px;
    }
    td,
    th {
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 768px) {
    .manage-status-list {
      gap: 30px;
    }
    .manage-status {
      font-size: 16px;
    }
    .status-bar-sphere {
      width: 70px;
    }
    .priority-order {
      left: 0;
    }
    .active-order {
      left: 0px;
    }
    .pause-order {
      left: 90px;
    }
    .pending-order {
      left: 285px;
    }
    .unapprove-order {
      left: 400px;
    }
    .orders-box {
      display: flex;
      gap: 10px;
    }
    .orders-details {
      margin: 0;
      padding: 10px 10px;
      flex-direction: column;
      gap: 20px;
      align-items: start !important;
    }
    .orders-details div {
      margin-left: 0 !important;
    }
    .order-details-headings {
      width: 100%;
    }
    .falcon-offers {
      margin-bottom: 0;
    }
    .active-table {
      padding: 20px 10px;
    }

    td,
    th {
      padding: 15px;
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 992px) {
    .manage-orders {
      max-width: 1100px !important;
    }
    .orders-box {
      gap: 20px;
      padding: 10px;
    }
    .orders-details {
    }
  }
`;
