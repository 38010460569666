import React from "react";

const Error = ({ text }) => {
  return (
    <div
      className="p-1"
      style={{ fontSize: "12px", color: "#cc0000", height: "25px" }}
    >
      {text}
    </div>
  );
};

export default Error;
