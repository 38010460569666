// Docs: https://docs.quickblox.com/reference/content
/**
 *
 * @param {QuickbloxApp} QBApp
 * @param {File} file
 * @returns {Promise<{id: number, url: string, type: string}>}
 */
export const uploadFile = async (QBApp, file) =>
  new Promise((resolve, reject) => {
    const fileParams = {
      name: file.name,
      file: file,
      type: file.type,
      size: file.size,
      public: true,
    };
    QBApp.content.createAndUpload(fileParams, function (error, result) {
      if (error) {
        reject(error);
      } else {
        resolve({
          id: result.uid,
          url: QBApp.content.publicUrl(result.uid),
          type: file.type,
        });
      }
    });
  });
