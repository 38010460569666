import React, { useState } from "react";
import InputField from "../../../components/common/InputField";
import Button from "../../../components/common/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuthContext } from "../../../features/authentication";
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Email is Required"),
});

const initialValues = {
  email: "",
};
export const VerifyEmailAccountForm = ({ onSuccess }) => {
  const [serverError, setServerError] = useState(null);
  const {confirmAccount} = useAuthContext()
  const {
    errors,
    values,
    handleChange,
    isSubmitting,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const user = await confirmAccount({ account: values.email })
        onSuccess && onSuccess(user);
      } catch (e) {
        setServerError(e.response.data.error);
      }
    },
  });
  return (
    <form onSubmit={handleSubmit} className="main-section">
      <div className="main-content">
        <h2 className="mb-3">Verify Your Account</h2>
        {serverError && (
          <div className="alert alert-danger mb-1 " role="alert">
            {serverError}
          </div>
        )}
        <InputField
          id="email"
          name="email"
          className="signup-input"
          placeholder="Enter Email or Username"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email}
        />
        <Button
          type="submit"
          className="continue"
          children={
            isSubmitting ? (
              <div
                className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                role="status"
              >
                <span className="sr-only"></span>
              </div>
            ) : (
              <p style={{ color: "white", fontSize: "15px" }}>Send</p>
            )
          }
        />
      </div>
    </form>
  );
};
