import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../../components/common/InputField";
import Button from "../../../components/common/Button";
import { openErrorMessage } from "../../../store/slices/toastSlice";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../../features/authentication";
import RoutesPath from "../../../utils/Paths";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Email is Required"),
  password: Yup.string().required("Password is Required"),
});

const initialValues = {
  email: "",
  password: "",
};

export const SignInForm = ({ onSuccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState(null);
  const { signIn } = useAuthContext();
  const {
    errors,
    values,
    handleChange,
    isSubmitting,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await signIn({ ...values });
        onSuccess && onSuccess(response.data.user);
      } catch (error) {
        dispatch(openErrorMessage(error.response.data.error));
        setServerError(error.response.data.error);
      }
    },
  });
  return (
    <>
      <div
        className="d-flex flex-column justify-content-between mb-2"
        style={{ minHeight: "160px" }}
      >
        <div style={{ width: 200, cursor:'pointer' }} onClick={() => navigate("/")}>
          <img
            className="img-fluid"
            src={"/logo-horizontal.png"}
            alt={"Logo"}
          />
        </div>
        <div className="d-flex flex-column align-items-start justify-content-start">
          <h2 className="text-2xl font-semibold">Sign in</h2>
          <p className="m-0">
            Don't have an account?{" "}
            <span onClick={() => navigate("/sign-up")}>Sign up</span> or{" "}
            <span onClick={() => navigate("/")}>Continue as Guest</span>
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {serverError && (
          <div className="alert alert-danger mb-0 mt-2 " role="alert">
            {serverError}
          </div>
        )}
        <div>
          <InputField
            id="email"
            name="email"
            className="signup-input"
            type="email"
            placeholder="Enter your email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email}
          />
          <InputField
            id="password"
            name="password"
            className="signup-input"
            type="password"
            placeholder="Enter your password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password}
          />
          <div>
            <p
              className="text-end forget-password"
              onClick={() => navigate("/forget-password")}
            >
              Forgot Password ?
            </p>
          </div>
          <div className="d-flex flex-column gap-2">
            <Button
              type="submit"
              className="continue"
              children={
                isSubmitting ? (
                  <div
                    className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  <p style={{ color: "white", fontSize: "15px" }}>Submit</p>
                )
              }
            />
          </div>
        </div>
      </form>
      <p className="m-0 mt-2">
        By selecting Submit, I agree to GigFalcon's{" "}
        <Link to={RoutesPath.legalTOS}>{"Terms of Service"}</Link>,{" "}
        <Link to={RoutesPath.legalPTOS}>{"Payments Terms of Service"}</Link> and{" "}
        <Link to={RoutesPath.legalNDP}>{"Non-Discrimination Policy"}</Link> and
        acknowledge the <Link to={RoutesPath.legalPP}>{"Privacy Policy"}</Link>.
      </p>
    </>
  );
};
