import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";

export const getEarnings = createAsyncThunk(
  "earnings/getEarnings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/users/earnings");
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
