import React from "react";
import FilePreviewBox from "../../../../components/common/FilePreviewBox";

const FilesSelectedPreview = ({ filesSelected, removeFile }) => {
  return (
    <div className="pt-2 pb-3">
      <p
        className="text-muted pb-1 border-bottom border-1 mb-2"
        style={{ fontSize: 10 }}
      >
        {`${filesSelected.length} File${filesSelected > 1 ? "s" : ""}`}
      </p>
      <div className="d-flex flex-wrap gap-2 overflow-auto">
        {filesSelected.map((file, index) => {
          return (
            <FilePreviewBox
              src={file.serverFile?.url}
              key={index}
              error={file.error && {
                message: file.error,
              }}
              fileName={file.clientFile.name}

              fileSize={file.clientFile.size}
              onCancel={() => removeFile(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilesSelectedPreview;
