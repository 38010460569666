import axios from "../../../api/axios";

export const changePassword = async (data) => {
  const { code, userId, password } = data;
  return await axios.post("/users/forgot-password", {
    code,
    userId,
    password,
  });
};
