const Content = () => (
  <>
    <h1>Privacy Policy</h1>
    <p>Updated on January 31, 2024</p>
    <p>
      <br />
    </p>
    <p>
      At GigFalcon, we are committed to protecting the privacy and security of
      our users. This Privacy Policy outlines how we collect, use, and safeguard
      your personal information when you use our platform.
    </p>
    <p>
      <br />
    </p>
    <p>Information We Collect</p>
    <p>
      When you register for an account or use our services, we may collect
      personal information such as your name, email address, contact details,
      payment information, and any other information you choose to provide.
    </p>
    <p>
      <br />
    </p>
    <h4>How We Use Your Information</h4>
    <p>We may use the information we collect to:</p>
    <p>
      <br />
    </p>
    <ul>
      <li>
        <p>Provide and personalize our services</p>
      </li>
      <li>
        <p>Process transactions and payments</p>
      </li>
      <li>
        <p>Location for relevancy and result optimization</p>
      </li>
      <li>
        <p>Communicate with you about our services</p>
      </li>
      <li>
        <p>Improve our platform and user experience</p>
      </li>
      <li>
        <p>Comply with legal requirements</p>
      </li>
      <li>
        <p>Protect the security and integrity of our platform</p>
      </li>
    </ul>
    <p>
      <br />
    </p>
    <h4>Cookies</h4>
    <p>
      We use cookies to enhance your experience while using our website and
      services. Cookies are small pieces of data stored on your device that help
      us improve your user experience. You can choose to accept or decline
      cookies. If you decline cookies, some features of our website may not
      function properly.
    </p>
    <p>
      <br />
    </p>
    <h4>Location Tracking</h4>
    <p>
      We may also collect your location information to provide you with relevant
      content or services based on your geographic location. Your location data
      is used solely for the purpose of enhancing your experience and providing
      personalized services.
    </p>
    <p>
      <br />
    </p>
    <h4>Information Sharing and Disclosure</h4>
    <p>
      We may share your personal information with third-party service providers
      to facilitate our services, and we may also disclose information when
      required by law or to protect our rights.
    </p>
    <p>
      <br />
    </p>
    <h4>Your Choices and Rights</h4>
    <p>
      You have the right to access, update, and delete your personal
      information, and you may also opt out of receiving certain communications
      from us.
    </p>
    <p>
      <br />
    </p>
    <h4>Security</h4>
    <p>
      We take the security of your personal information seriously and implement
      measures to protect it from unauthorized access, alteration, disclosure,
      or destruction.
    </p>
    <p>
      <br />
    </p>
    <h4>Changes to this Policy</h4>
    <p>
      We may update this Privacy Policy from time to time, and we will notify
      you of any material changes.
    </p>
    <p>
      <br />
    </p>
    <h4>Contact Us</h4>
    <p>
      If you have any questions or concerns about our Privacy Policy or the
      handling of your personal information, please contact us at
      support@gigfalcon.com.
    </p>
    <p>
      <br />
    </p>
    <p>
      By using our website or services, you consent to the use of cookies and
      location tracking as described in this privacy policy.
    </p>
  </>
);
export default Content;
