import React from "react";
import { StyledButton } from "./button";
import { colors } from "../../../utils/Colors";

const Button = ({
  onClick,
  styles = {},
  children = "",
  className,
  type = "button",
  disabled = false,
}) => {
  return (
    <StyledButton colors={colors}>
      <button
        type={type}
        className={className}
        onClick={onClick}
        disabled={disabled}
        styles={styles}
      >
        {children}
      </button>
    </StyledButton>
  );
};

export default Button;
