import React from "react";

import { Styles } from "./inputField";
import { colors } from "../../../utils/Colors";
import Error from "../Error";

const InputField = ({
  value,
  label,
  placeholder = "",
  type = "text",
  error,
  className,
  ...props
}) => (
  <Styles colors={colors}>
    <div>
      {label && <label htmlFor="input-field">{label}</label>}
      <input
        className={`input-field ${className}`}
        type={type}
        value={value}
        placeholder={placeholder}
        {...props}
      />
      {error && (
        <div style={{ textAlign: "start" }}>
          <Error text={error} />
        </div>
      )}
    </div>
  </Styles>
);

export default InputField;
