export const colors = {
  color: {
    primary: "#404145",
    status: "rgb(98,98,98)",
    secondary: "#0fd3bb",
    lightFalcon: "rgba(15, 211, 187, 0.3)",
    lightGrey: "#dadbdd",
    lightBackground: "#fcfcf9",
    green: "#00a67e",
    veryLightGrey: "#d3d3d3 ",
    addBox: "#e7e5e5",
    mediumGrey: "#c2c2c2",
    darkGrey: "#757575",
    white: "#fff",
    black: "#000",
    github: "#171515",
    google: "#186DEE",
    facebook: "#3b5998",
    blue: "#446EE7",
    red: "#FF0000",
    danger: "#FF424C",
    bgPending: " #FFF3CE",
    pending: "#DCA500",
    bgRejected: "#FFE8E8",
    rejected: "#FF0000",
    bgCancel: "#EBE7FF",
    cancel: "#3300FF",
  },
};
