import { createSlice } from "@reduxjs/toolkit";
import { getEarnings } from "../thunks/earningsThunk";

const initialState = {
  loading: false,
  error: "",
  earnings: [],
};

const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};
const handleRejected = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};
const handleGetEarningsFulfilled = (state, action) => {
  state.earnings = action.payload;
  state.loading = false;
};

const earningsSlice = createSlice({
  name: "earnings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEarnings.pending, handlePending)
      .addCase(getEarnings.fulfilled, handleGetEarningsFulfilled)
      .addCase(getEarnings.rejected, handleRejected);
  },
});

export default earningsSlice.reducer;
