import styled from "styled-components";

export const Styles = styled.div`
  /* .about-profile {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
  } */
  .about-info {
    padding: 10px;
  }
  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .status li {
    padding: 0 10px;
    color: ${({ colors }) => colors.color.secondary};
    border-radius: 50px;
    border: 1px solid ${({ colors }) => colors.color.secondary};
  }
  .offline li {
    color: ${({ colors }) => colors.color.darkGrey} !important;
    border: 1px solid ${({ colors }) => colors.color.darkGrey} !important;
  }
  .about-info-lower-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .avatar {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .avatar img {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  .profile-name {
    font-size: 18px;
    font-weight: bold;
  }
  .profile-bio {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .profile-bio p {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .edit-profile {
    color: ${({ colors }) => colors.color.darkGrey};
  }

  hr {
    margin: 0;
  }
  .profile-info {
    padding: 20px 10px 10px 10px;
  }
  .profile-info-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .question {
    color: ${({ colors }) => colors.color.darkGrey};
  }
`;
