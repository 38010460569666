import styled from "styled-components";

export const Styles = styled.div`
  .location-filter {
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
  }
  .custom-select__control {
    background-color: red !important;
    border-color: #ccc;
    border-radius: 4px;
    border-width: 1px;
    padding: 8px;
  }
  .locations-wrapper {
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    padding: 5px 20px;
    box-shadow: 0 0 5px 2px rgba(233, 229, 229, 0.9);
    border-radius: 20px;
  }
  .search-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ colors }) => colors.color.secondary};
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  .search-icon {
    width: 20px;
    height: 20px;
    color: white;
    fill: white;
  }
  .search-close {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ colors }) => colors.color.secondary};
    height: 30px;
    width: 30px !important;
    border-radius: 50%;
    cursor: pointer;
  }
  .search-location {
    width: 300px;
  }
  .form-check-input:checked {
    background-color: ${({ colors }) => colors.color.secondary} !important;
    border-color: ${({ colors }) => colors.color.secondary} !important;
  }
  .form-check-input {
    border-color: ${({ colors }) => colors.color.lightGrey} !important;
    height: 20px !important;
    width: 40px !important;
  }
  .form-check-input:focus {
    border-color: none !important;
    background-img: none !important;
    box-shadow: none !important;
  }
`;
