import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import {isFulfilled} from '@reduxjs/toolkit'
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import Error from "../common/Error";
import InputField from "../common/InputField";
import Button from "../common/Button";
import useGetConfigurations from "../../hooks/api/useGetConfigurations";
import * as Yup from "yup";
import { getMyGigs } from "../../store/thunks/gigsThunk";
import { createCustomJobOffer } from "../../store/thunks/offersThunk";
import RoutesPath from "../../utils/Paths";
import { useNavigate } from "react-router-dom";
const SendOfferSchema = Yup.object({
  coverLetter: Yup.string().required("Cover Letter is Required"),
  selectedGig: Yup.object().required("Gig is Required"),
  price: Yup.number().required("Price is Required"),
  deliveryTime: Yup.number()
    .required("Delivery Time is Required")
    .min(1, "Delivery Time must be greater than 0"),
});

/**
 * @typedef {Object} CreateCustomOfferModalProps
 * @property {boolean} isOpen
 * @property {function} onClose
 * @property {string} buyerId
 */

const CreateCustomOfferModal = (
  /** @type {CreateCustomOfferModalProps} */ { isOpen, onClose,buyerId }
) => {
  const { data: gigs, loading } = useSelector((state) => state.gigs.getMyGigs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const configurations = useGetConfigurations();

  useEffect(() => {
    dispatch(getMyGigs());
  }, [dispatch]);
  return (
    <Modal isOpen={isOpen} toggle={onClose} className="modal-md">
      <ModalHeader toggle={onClose}>Create Custom Offer</ModalHeader>
      <Formik
        initialValues={{
          coverLetter: "",
          selectedGig: "",
          price: "",
          deliveryTime: 0,
        }}
        validationSchema={SendOfferSchema}
        onSubmit={async (values) => {
          const action = await dispatch(createCustomJobOffer({
            coverLetter: values.coverLetter,
            price: values.price,
            deliveryTime: values.deliveryTime,
            buyerId,
            gig:values.selectedGig.id,

          }))
          if (isFulfilled(action)) {
            navigate(RoutesPath.sentOffers + '?tab=customOffers');
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form className="d-flex flex-column gap-2 justify-content-center w-100">
            <ModalBody>
              <div className="mb-3">
                <label className="d-block regular" htmlFor="gig">
                  Select Gig
                </label>
                <Select
                  className="select"
                  id="selectedGig"
                  name="selectedGig"
                  options={gigs?.map((gig) => ({
                    label: gig.title,
                    id: gig._id,
                  }))}
                  isLoading={loading}
                  getOptionLabel={(option) => (
                    <div className="text-capitalize">{option.label}</div>
                  )}
                  getOptionValue={(option) => option.id}
                  onChange={(selectedOption) =>
                    setFieldValue("selectedGig", selectedOption)
                  }
                  value={values.selectedGig}
                />
                {errors.selectedGig && touched.selectedGig && (
                  <Error text={errors.selectedGig} />
                )}
              </div>
              <div className="mb-3">
                <label className="d-block regular" htmlFor="coverLetter">
                  {"Cover Letter"}
                </label>
                <textarea
                  placeholder="I can..."
                  name="coverLetter"
                  id="coverLetter"
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    border: "1px solid grey",
                    padding: "8px 12px",
                  }}
                  value={values.coverLetter}
                  onChange={handleChange}
                  autoComplete="off"
                  rows={4}
                />
                {errors.coverLetter && touched.coverLetter && (
                  <Error text={errors.coverLetter} />
                )}
              </div>
              <div className="mb-3">
                <label className="d-block regular" htmlFor="price">
                  Price
                </label>
                <div
                  className="d-flex align-items-center px-2 py-1"
                  style={{ borderRadius: "8px", border: "1px solid grey" }}
                >
                  <p className="heading" style={{ minWidth: "25px" }}>
                    $ -{" "}
                  </p>
                  <InputField
                    className={"budget-input"}
                    type="number"
                    name="price"
                    id="price"
                    value={values.price}
                    onChange={handleChange}
                    autoComplete="off"
                    onBlur={handleBlur}
                  />
                </div>
                {values?.price && (
                  <p className="text-sm mt-1 mb-2">
                    {"Amount you will recieve after service fees - "}
                    <strong>
                      {configurations.loading
                        ? "Loading..."
                        : `$${(
                            values.price -
                            (values.price * configurations.data.sellerTax) / 100
                          ).toFixed(2)}`}
                    </strong>
                  </p>
                )}
                {errors.price && touched.price && <Error text={errors.price} />}
              </div>
              <div>
                <label className="d-block regular" htmlFor="price">
                  Delivery Time (in days)
                </label>
                <div style={{ borderRadius: "8px", border: "1px solid grey" }}>
                  <InputField
                    className="budget-input"
                    type="number"
                    name="deliveryTime"
                    id="deliveryTime"
                    value={values.deliveryTime}
                    onChange={handleChange}
                    autoComplete="off"
                    onBlur={handleBlur}
                  />
                </div>

                {errors.deliveryTime && touched.deliveryTime ? (
                  <Error text={errors.deliveryTime}></Error>
                ) : (
                  <Error text="" />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-center gap-2">
                <Button
                  type="button"
                  className="reject-btn"
                  disabled={loading}
                  onClick={onClose}
                >
                  {"Cancel"}
                </Button>
                <Button type="submit" className="accept-btn" disabled={loading}>
                  {isSubmitting ? (
                    <div
                      className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    "Send Offer"
                  )}
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateCustomOfferModal;
