import { useQBContext } from "../../context/quickblox-provider";
import {
  createPrivateDialogWithOccupants,
  getDialogOfOccupants,
  getUserIdFromUserName,
  sendMessageToDialog,
} from "../services";
import {
  createSliceWithStateIds,
  useMutationRedux,
} from "../../../react-query-redux";
import { useDialogMessagesState } from "../queries/use-dialog-messages";
import { formatISO, getUnixTime } from "date-fns";
import { useAuthContext } from "../../../authentication";
import { useUserDialogsState } from "../queries/use-user-dialogs";
import { useQBListenersContext } from "../../context/quickblox-listeners-provider";

const stateKey = "qb-send-messages";
export const useSendMessageSlice = createSliceWithStateIds(stateKey);
const { reducerName, actions } = useSendMessageSlice;

/**
 * @typedef {Object} SendMessageParams
 * @property {string} dialogId
 * @property {string} message
 * @property {string | undefined} recipentUserName
 * @property {string | undefined} recipentId
 * @property {Array<{id: number, url: string, type: string}> | undefined} attachments
 */

/**
 * @param {SendMessageParams} params
 * @returns {Promise<number>}
 */

/**
 *
 * @returns {{sendMessage: (params: SendMessageParams) => Promise<number>, error: any, data: number, status: string}}
 */
export const useSendMessage = () => {
  const { QBApp } = useQBContext();
  const { user } = useAuthContext({ required: true });
  const chatsMessagesState = useDialogMessagesState();
  const chatsListState = useUserDialogsState();
  const {setNewMessage} = useQBListenersContext()

  const state = useMutationRedux({
    id: [stateKey],
    actions,
    reducerName,
    mutationFn: async ({
      dialogId,
      message,
      recipentUserName,
      attachments,
      recipentId,
    }) => {
      const SenderId = user.quickblox.user_id;
      if (!recipentId) {
        if (!recipentUserName) {
          throw new Error({ message: "Recipient username or id is required" });
        }
        recipentId = await getUserIdFromUserName(QBApp, recipentUserName);
      }
      if (!dialogId) {
        let dialog = await getDialogOfOccupants(QBApp, [SenderId, recipentId]);
        // Sending a message to a new user
        if (!dialog) {
          dialog = await createPrivateDialogWithOccupants(QBApp, [
            SenderId,
            recipentId,
          ]);
        }
        dialogId = dialog._id;
      }
      const newMessageId = await sendMessageToDialog(
        QBApp,
        dialogId,
        recipentId,
        message,
        attachments
      );
      const currentDate = new Date();
      const currentDateISO = formatISO(currentDate);
      const newMessage = {
        all_read: false,
        attachments,
        chat_dialog_id: dialogId,
        created_at: currentDateISO,
        date_sent: getUnixTime(currentDate),
        delivered_ids: [SenderId],
        message: message,
        read: 1,
        read_ids: [SenderId],
        recipient_id: recipentId,
        sender_id: SenderId,
        _id: newMessageId,
        updated_at: currentDateISO,
      };
      setNewMessage(newMessage)
      // Loading the send message into it's appropriate chat box from the list of all loaded messages
      chatsMessagesState.setStateByParams({ dialogId }, (state) => {
        // It might be useful in some cases to keep the limit of page in mind.
        // But as in this case, we are not displaying data in table/pages format,
        // we can just push the new message to the last page,
        // no need to ensure that it's size becomes more than the lmit
        
        if (!state) return { status: "success", data: [[newMessage]] };
        const pageWithMostRecentMessages =
          state.data && state.data.length
            ? state.data[0]
            : [];

        return {
          ...state,
          data: state.data
            ? [[newMessage,...pageWithMostRecentMessages], ...state.data.slice(1)]
            : [[newMessage]],
        };
      });

      // Updating the last message of the appropriate dialog from the list of all dialogs
      chatsListState.setStateByParams(undefined, (state) => {
        if (!state) return state;
        return {
          ...state,
          data:state.data?.map((dialog) => {
            if (dialog._id !== dialogId) return dialog;
            return {
              ...dialog,
              last_message: newMessage.message,
              last_message_date_sent: newMessage.date_sent,
              last_message_id: newMessage._id,
              last_message_user_id: newMessage.sender_id,
            };
          }),
        };
      });

      return newMessage;
    },
  });
  return {
    sendMessage: state.mutate,
    error: state.error,
    data: state.data,
    status: state.status,
  };
};
