import React, { useEffect, useState } from "react";

import { Styles } from "./incomingOffers";
import { colors } from "../../../utils/Colors";
import Header from "../../../components/common/Header";
import Footer from "../../../components/common/Footer";
import ModalPayment from "../../../components/payment/ModalPayment";
import { getRecievedOffers } from "../../../store/thunks/offersThunk";
import { useDispatch, useSelector } from "react-redux";
import JobOffer from "../../../components/Offers/IncomingOffers/JobOffer";
import IncomingCustomOffers from "../../../components/Offers/IncomingOffers/IncomingCustomOffers";
import useGetConfigurations from "../../../hooks/api/useGetConfigurations";
import { useSearchParams } from "react-router-dom";

const IncomingOffers = () => {
  const dispatch = useDispatch();
  const { data: offers, loading } = useSelector(
    (state) => state.offers.getRecievedOffers
  );
  const { data: configurations, loading: loadingConfigurations } =
    useGetConfigurations();
  const [params, setParams] = useSearchParams();
  const currentTab = /** @type {('jobOffers' | 'customOffers')} */ (
    params.get("tab") || "jobOffers"
  );

  const [PaymentModalState, setPaymentModal] = useState(null);

  const handleAcceptJobOffer = async (offer) => {
    setPaymentModal({ ...offer, type: "job" });
  };

  useEffect(() => {
    dispatch(getRecievedOffers());
  }, [dispatch]);

  return (
    <Styles colors={colors}>
      <Header />
      <div className="container offers-page">
        <h3 className="mb-3">
          <span className="falcon-clr">Incoming</span> Offers
        </h3>
        <div className="status-bar">
          <div
            className={
              currentTab === "jobOffers"
                ? "job-offer status-bar-sphere"
                : currentTab === "customOffers" &&
                  "custom-offer status-bar-sphere"
            }
          ></div>
        </div>
        <ul className="manage-status-list">
          <li
            className="manage-status"
            onClick={() =>
              setParams((params) => {
                params.set("tab", "jobOffers");
                return params;
              })
            }
          >
            {"Job Offers"}
          </li>
          <li
            className="manage-status"
            onClick={() =>
              setParams((params) => {
                params.set("tab", "customOffers")
                return params;
              })
            }
          >
            {"Custom Offers"}
          </li>
        </ul>
        <div className="offers-wrapper">
          {currentTab === "jobOffers" ? (
            loading || loadingConfigurations ? (
              <p className="grey-clr text-center p-5">Loading...</p>
            ) : offers?.length === 0 ? (
              <p className="grey-clr text-center p-5">No Offer to Show</p>
            ) : (
              offers?.map((offer, idx) => {
                return (
                  <JobOffer
                    offer={offer}
                    handleAccept={handleAcceptJobOffer}
                    key={idx}
                  />
                );
              })
            )
          ) : (
            <IncomingCustomOffers onAccept={handleAcceptJobOffer} />
          )}
        </div>
        <ModalPayment
          offer={PaymentModalState}
          isOpen={Boolean(PaymentModalState)}
          buyerTax={configurations?.buyerTax}
          sellerTax={configurations?.sellerTax}
          onClose={() => {
            setPaymentModal(null);
          }}
        />
      </div>
      <Footer />
    </Styles>
  );
};

export default IncomingOffers;
