import React from "react";

import { Styles } from "./gigReview";
import { colors } from "../../../utils/Colors";
import { formatMessageTime } from "../../../utils/helperFunctions";
import Rating from "../../common/Rating";
import Avatar from "../../common/Avatar";

/**
 * @typedef {import("../../../types/server").GigReview} Props
 */
const GigReview = (/** @type {Props} */props) => {
  return (
    <Styles colors={colors}>
      <div className="gig-review">
        <div>
          <Avatar 
            imgSrc={props.createdBy.profileImg?.imgUrl}
            alt = {"Profile"}
            width = {50}
            height = {50}
            name={props.createdBy.username}
          />
        </div>
        <div>
          <h4 className="review-name mb-0 mt-2">{props.createdBy.username}</h4>
          <div className="d-flex gap-2 align-items-center mt-1">
            <Rating 
              rating={props.rating}
              ordersCompleted={props?.createdBy?.sellerStats?.completedOrders}
            />
          </div>
          <p className="mt-2">{props.comment}</p>
          <p className="country">Published on {formatMessageTime(props.createdAt)}</p>
        </div>
      </div>
    </Styles>
  );
};

export default GigReview;
