import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

export const getPostedOffers = createAsyncThunk(
  "offers/getPostedOffers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("jobs/offers/posted");
      return response.data.offers;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCustomPostedOffers = createAsyncThunk(
  "offers/getCustomPostedOffers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("jobs/offers/custom/posted");
      return response.data.offers;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getRecievedOffers = createAsyncThunk(
  "offers/getRecievedOffers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("jobs/offers/recieved");
      return response.data.offers;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCustomRecievedOffers = createAsyncThunk(
  "offers/getCustomRecievedOffers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("jobs/offers/custom/recieved");
      return response.data.offers;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const rejectRecievedOffer = createAsyncThunk(
  "offers/rejectRecievedOffer",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { _id } = data;
      const response = await axios.put(`jobs/offers/${_id}/reject`);
      dispatch(openSuccessMessage(response.data.message));
      return {
        message: response.data.message,
        _id: _id,
      };
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  }
);

export const cancelPostedOffer = createAsyncThunk(
  "offers/cancelPostedOffer",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { _id } = data;
      const response = await axios.put(`/jobs/offers/${_id}/cancel`);
      dispatch(openSuccessMessage(response.data.message));
      return {
        message: response.data.message,
        _id: _id,
      };
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  }
);

export const createJobOffer = createAsyncThunk(
  "/jobs/makeOffer",
  /**
   * @param {{selectedGig: string, jobId: string, uid: string, coverLetter: string, price: string, attachments: FileList}} data
   */
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      const response = await axios.post("jobs/makeOffer", formData);
      dispatch(openSuccessMessage(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  }
);
export const createCustomJobOffer = createAsyncThunk(
  "/jobs/makeCustomOffer",
  /**
   * @param {{gig: string, buyerId: string, coverLetter: string, price:number,deliveryTime:number}} data
   */
  async (data, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.post("jobs/offers/custom", data);
      dispatch(openSuccessMessage(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  }
);
