import React, { useState, useEffect } from "react";
import { Styles } from "./locationFilter";
import { colors } from "../../../utils/Colors";
import { getJobs } from "../../../store/thunks/jobsThunk";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import useHomeFilters from "../../../store/hooks/useHomeFilters";
import AppleAutoComplete from "../../common/AppleAutoComplete";

const LocationFilter = () => {
  const {
    filters: { sectionFilter, googlePlaceFilter },
    handleUpdatingFilters,
  } = useHomeFilters();
  const [openSearch, toggleSearch] = useState(
    googlePlaceFilter.isPlaceIdDefined
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobs());
  }, [dispatch]);

  const onSelectPlaces = (result) => {
    handleUpdatingFilters({
      applePlaceFilter: result.value,
    });
  };

  return (
    <Styles colors={colors}>
      <div className="location-filter d-flex justify-content-center py-2">
        <div className="locations-wrapper">
          {!openSearch && (
            <div
              className="search-icon-wrapper"
              onClick={() => toggleSearch(true)}
            >
              <FaSearch className="search-icon" />
            </div>
          )}
          {openSearch && (
            <form>
              <div className="search-location d-flex align-items-center gap-2">
                <div
                  className="search-close"
                  onClick={() => toggleSearch(false)}
                >
                  <MdClose className="search-icon" />
                </div>
                <div style={{ minWidth: "150px !important" }}>
                  <AppleAutoComplete onSelect={onSelectPlaces} />
                </div>
              </div>
            </form>
          )}

          <div className="d-flex align-items-center locations-wrapper py-2">
            <label>Gigs</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault1"
                style={{ cursor: "pointer" }}
                onChange={(e) => {
                  e.target.checked
                    ? handleUpdatingFilters(
                        sectionFilter === "jobs"
                          ? { sectionFilter: "all" }
                          : { sectionFilter: "gigs" }
                      )
                    : handleUpdatingFilters({ sectionFilter: "jobs" });
                }}
                checked={sectionFilter === "gigs" || sectionFilter === "all"}
              />
            </div>
          </div>
          <div className="d-flex align-items-center locations-wrapper py-2">
            <label>Jobs </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault2"
                style={{ cursor: "pointer" }}
                onChange={(e) => {
                  e.target.checked
                    ? handleUpdatingFilters(
                        sectionFilter === "gigs"
                          ? { sectionFilter: "all" }
                          : { sectionFilter: "jobs" }
                      )
                    : handleUpdatingFilters({ sectionFilter: "gigs" });
                }}
                checked={sectionFilter === "jobs" || sectionFilter === "all"}
              />
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default LocationFilter;
