export const sendMessageToDialog = (
  QBApp,
  dialogId,
  recipentId,
  messageTxt,
  attachments
) => {
  return new Promise((resolve, reject) => {
    const params = {
      type: "chat",
      body: messageTxt,
      markable: 1,
      extension: {
        save_to_history: 1,
        dialog_id: dialogId,
        ...(attachments ? {attachments: attachments}:{})
      },
    };
    try {
      const message = QBApp.chat.send(parseInt(recipentId), params);
      resolve(message);
    } catch (e) {
      reject(e);
    }
  });
};
export const connectChat = async (QBApp, QBSession) => {
  const userId = QBSession.user_id;
  const password = QBSession.token;
  return new Promise((resolve, reject) => {
    QBApp.chat.connect({ userId: userId, password: password }, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};
export const sendReadMessageStatus = (QBApp, dialogId, messageId, userId) => {
  return new Promise((resolve, reject) => {
    try {
      QBApp.chat.sendReadStatus({
        messageId: messageId,
        userId: userId,
        dialogId: dialogId,
      });
      resolve();
    }catch(e){
      console.log(e)
      reject(e)
    }

  });
}