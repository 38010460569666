import React from "react";
import InputField from "../../../components/common/InputField";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/common/Button";
import { useAuthContext } from "../../../features/authentication";
import { useDispatch } from "react-redux";
import { openErrorMessage } from "../../../store/slices/toastSlice";
const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required("Password is Required")
    .min(6, "Password must be atleast 6 characters"),
  confirmPassword: Yup.string()
    .required("Password is Required")
    .min(6, "Password must be atleast 6 characters")
    .oneOf([Yup.ref("newPassword"), null], "Passwords don't match"),
});

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};
const ChangePasswordForm = ({ onSuccess, verifyCode, userId }) => {
  const { changePassword } = useAuthContext();
  const dispatch = useDispatch();
  const {
    errors,
    values,
    handleChange,
    isSubmitting,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        let reqData = {
          code: verifyCode,
          userId: userId,
          password: values.confirmPassword,
        };
        const response = await changePassword(reqData);
        onSuccess && onSuccess(response.data.message);
      } catch (e) {
        dispatch(openErrorMessage(e.response.data.error));
      }
    },
  });
  return (
    <form method={"post"} className="main-section" onSubmit={handleSubmit}>
      <div className="main-content">
        <h2 className="mb-3">Change Your Password</h2>
        <InputField
          type="password"
          id="newPassword"
          name="newPassword"
          className="signup-input"
          placeholder="Enter new password"
          value={values.newPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.newPassword}
        />
        <InputField
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          className="signup-input"
          placeholder="Confirm password"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.confirmPassword}
        />
        <Button
          className="continue"
          type="submit"
          children={
            isSubmitting ? (
              <div
                className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                role="status"
              >
                <span className="sr-only"></span>
              </div>
            ) : (
              <p style={{ color: "white", fontSize: "15px" }}>Send</p>
            )
          }
        />
      </div>
    </form>
  );
};

export default ChangePasswordForm;
