import React, { useState } from "react";

import InputField from "../../../common/InputField";
import Error from "../../../common/Error";
import Button from "../../../common/Button";
import { DetailSchema } from "../../../../utils/PostJobSchema";

import Select from "react-select";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import AppleAutoComplete from "../../../../components/common/AppleAutoComplete";

const Details = ({ formNo, setFormNo, handleFormData, gigValues }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [subCategoryList, setsubCategoryList] = useState([]);
  const { categories } = useSelector((state) => state.categories);

  const work = [
    { value: "on-site", label: "On-Site" },
    { value: "online", label: "Online" },
  ];

  const handleWork = (workType) => {
    if (workType === "on-site") {
      return { value: "on-site", label: "On-Site" };
    }
    if (workType === "online") {
      return { value: "online", label: "Online" };
    }
  };

  const handleSelectChange = (selected) => {
    setFieldValue("category", selected);
    setFieldValue("subcategory", "");
    setsubCategoryList(selected.subcategories);
    selected.subcategories ? setSelectedOption(true) : setSelectedOption(false);
  };

  const onSelectPlace = (location) => {
    const {
      coordinate: { latitude, longitude },
      description,
    } = location.value;
    setFieldValue("location", {
      latitude,
      longitude,
      address: description,
    });
  };

  const gigLocation = {
    latitude: gigValues?.latitude,
    longitude: gigValues?.longitude,
    address: gigValues?.address,
  };

  const initialValues = {
    category: gigValues?.category || "",
    subcategory: gigValues?.subcategory || "",
    title: gigValues?.title || "",
    location: gigLocation || "",
    workType: gigValues?.workType || "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: DetailSchema,
    onSubmit: (values) => {
      handleFormData({ ...values });
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Gig Title</label>
        <InputField
          className="gig-creation-input"
          placeholder="I will do..."
          type="text"
          name="title"
          id="title"
          value={values.title}
          onChange={handleChange}
          autoComplete="off"
          onBlur={handleBlur}
        ></InputField>
        {errors.title && touched.title ? (
          <Error text={errors.title}></Error>
        ) : (
          <Error text="" />
        )}
        <div className=" d-flex flex-wrap justify-content-between gap-2">
          <div className="select">
            <label className="mb-1">Category</label>
            <Select
              id="category"
              name="category"
              placeholder="Select Category"
              options={categories}
              getOptionLabel={(option) => {
                return <div className="text-capitalize">{option.name}</div>;
              }}
              getOptionValue={(option) => option}
              onChange={handleSelectChange}
              value={values.category}
            />
            {errors.category && touched.category ? (
              <Error text={errors.category}></Error>
            ) : (
              <Error text="" />
            )}
          </div>
          <div className="select">
            <label className="mb-1">Subcategory</label>
            <Select
              id="subcategory"
              name="subcategory"
              placeholder="Select Subcat..."
              options={subCategoryList}
              getOptionLabel={(option) => {
                return <div className="text-capitalize">{option.name}</div>;
              }}
              getOptionValue={(option) => option}
              isDisabled={!selectedOption}
              onChange={(value) => setFieldValue("subcategory", value)}
              value={values.subcategory}
            />
            {errors.subcategory && touched.subcategory ? (
              <Error text={errors.subcategory}></Error>
            ) : (
              <Error text="" />
            )}
          </div>
          <div className="select">
            <label className="mb-1">Work Type</label>
            <Select
              id="workType"
              name="workType"
              className="select"
              onChange={(selectedOption) => {
                setFieldValue("workType", selectedOption.value);
              }}
              options={work}
              getOptionLabel={(option) => {
                return <div>{option.label}</div>;
              }}
              getOptionValue={(option) => option}
              value={handleWork(values.workType)}
            />
            {errors.workType && touched.workType && (
              <Error text={errors.workType} />
            )}
          </div>
        </div>
        <div style={{ width: "140px !important" }}>
          <label className="select-label">Location</label>
          <AppleAutoComplete
            style={{ width: "100%", borderRadius: 4 }}
            onSelect={onSelectPlace}
            coordinate={{
              latitude: parseFloat(values.location.latitude),
              longitude: parseFloat(values.location.longitude),
            }}
          />
          {errors.location && touched.location ? (
            <Error text={errors.location} />
          ) : (
            <Error text="" />
          )}
        </div>
      </div>
      <div></div>
      <div>
        <p className="note">
          Note: Some Categories requires that Seller verify their skills
        </p>
        <div className="btns">
          <Button
            type="button"
            children="Back"
            className={formNo === 1 ? "d-none" : "cancel-btn"}
            onClick={() =>
              setFormNo(formNo > 1 && formNo < 5 ? formNo - 1 : formNo - 2)
            }
          />
          <div></div>
          <Button
            children={formNo === 4 ? "Submit" : "Next"}
            className="save-btn"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default Details;
