import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/Colors";
import Avatar from "../common/Avatar";

const Marker = ({
  imageSrc,
  onAvatarClick,
  onHoverModalClick,
  name,
  description,
  profilePic,
  footer,
  openModal = false,
  height = "40px",
  width = "40px",
}) => {
  return (
    <MarkerStyled>
      <div className="marker" style={{ width, height }}>
        {!!(name && description) && (
          <div
            className={`marker-gig ${openModal ? `marker-gig-display` : ""}`}
          >
            <div
              className="d-flex flex-column gap-2 bg-white text-black p-2 border border-2 rounded-2"
              style={{ width: "240px", fontSize: "12px" }}
            >
              <div className="d-flex justify-content-between">
                <p
                  className="marker-avatar-username"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAvatarClick && onAvatarClick();
                  }}
                >
                  {name}
                </p>
                <Avatar
                  imgSrc={profilePic}
                  name={name}
                  width={24}
                  height={24}
                />
              </div>
              <p
                className="marker-description text-nowrap overflow-hidden"
                style={{
                  color: colors.color.secondary,
                  textOverflow: "ellipsis",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onHoverModalClick && onHoverModalClick();
                }}
              >
                {description}
              </p>
              {footer}
            </div>
          </div>
        )}
        <img
          src={imageSrc}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            bottom: height,
            left: "-50%",
          }}
          alt={"marker"}
        />
      </div>
    </MarkerStyled>
  );
};

const MarkerStyled = styled.div`
  .marker {
    position: relative;
    cursor: default;
  }
  .marker-gig {
    display: block;
    visibility: hidden;
    position: absolute;
    left: -100px;
    z-index: 2;
    top: -2000px;
    right: 0px;
    opacity: 0;
    transition: all 0.8s;
  }
  .marker-avatar-username {
    font-weight: semi-bold;
    cursor: pointer;
  }
  .marker-avatar-username:hover {
    text-decoration: underline;
    cursor: pointer;
    font-weight: semi-bold;
    transition: all 0.3s;
  }
  .marker-description:hover {
    text-decoration: underline;
    cursor: pointer;
    font-weight: semi-bold;
    transition: all 0.3s;
  }
  .marker-gig-display {
    top: -130px;
    opacity: 1;
    visibility: visible;
  }
`;

export default Marker;
