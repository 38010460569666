export const getUserIdFromUserName = async (QBApp, username) => {
  return new Promise((resolve, reject) => {
    QBApp.users.get({ login: username }, (error, response) => {
      if (error) {
        reject(error);
      } else {
        if (!response?.id) {
          reject("No user found");
        } else {
          resolve(response.id);
        }
      }
    });
  });
};
export const getUserFromId = async (QBApp, userId) => {
  return new Promise((resolve, reject) => {
    const searchParams = {
      filter: { field: "id", param: "in", value: [userId] },
    };
    QBApp.users.listUsers(searchParams, (error, response) => {
      if (error) {
        reject(error);
      } else {
        if (!response?.items || !response?.items.length) {
          reject("No user found");
        } else {
          resolve(response.items[0].user);
        }
      }
    });
  });
};
