import { debounce } from "lodash";
import { useState } from "react";
import { useCallback } from "react";
import axios from "../../api/axios";

/**
 * Params for Query Jobs on Map
 * @typedef {Object} params
 * @property {string} category
 * @property {string} searchText
 * @property {string} sortBy
 * @property {string} workType
 * @property {string} minPrice
 * @property {string} maxPrice
 * @property {string} subcategory
 * @property {string} userId
 * @property {number} limit
 * @property {number} page
 * @property {number} neLat
 * @property {number} neLng
 * @property {number} swLat
 * @property {number} swLng
 * @property {number} latDelta
 * @property {number} lngDelta
 */

/**
 * @typedef {Object} result
 * @property {import("./useJobsSelector").Job[]} jobs
 * @property {boolean} loading
 * @property {(params:params)=>void} fetchJobs
 */

function useJobsSelectorOnMap() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  const debounceFetchJobs = useCallback(
    debounce((query) => fetchJobs(query), 500),
    [],
  );

  /**
   * @param {params}
   */
  const fetchJobs = async (params) => {
    const filters = {
      category: params.category || "",
      searchText: params.searchText || "",
      sortBy: params.sortBy || "",
      workType: params.workType || "",
      minPrice: params.minPrice || "",
      maxPrice: params.maxPrice || "",
      subcategory: params.subcategory || "",
      userId: params.userId || "",
      limit: params.limit || 200,
      page: params.page || 1,
      neLat: params.neLat || null,
      neLng: params.neLng || null,
      swLat: params.swLat || null,
      swLng: params.swLng || null,
      latDelta: params.latDelta || null,
      lngDelta: params.lngDelta || null,
    };
    let queryString = "";
    for (let key in filters) {
      queryString = queryString + `${key}=${filters[key]}&`;
    }

    setLoading(true);
    try {
      const response = await axios.get(`/jobs/query/results?${queryString}`);
      const { jobs } = response.data;
      setJobs(jobs);
    } catch (err) {}
    setLoading(false);
  };

  return {
    jobs,
    fetchJobs: debounceFetchJobs,
    loading,
  };
}

export default useJobsSelectorOnMap;
