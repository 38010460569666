import React from "react";
import {
  commonRoutes,
  PrivateRoutes,
  PublicRoutes,
  SellerRoutes,
} from "./Routes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QBListenersContextProvider } from "../features/quickblox";
import { useAuthContext } from "../features/authentication";

const IndexRoutes = () => {
  const { status, isAuthenticated, user } = useAuthContext();

  if (status === "loading") {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ flex: 1 }}
      >
        Loading...
      </div>
    );
  }

  return (
    <QBListenersContextProvider>
      <BrowserRouter>
        <Routes>
          {isAuthenticated ? (
            <>
              {commonRoutes.map((route, i) => (
                <Route path={route.path} key={i} element={route.component} />
              ))}
              {PrivateRoutes.map((route, i) => (
                <Route path={route.path} key={i} element={route.component} />
              ))}
              {user.role === "seller" &&
                SellerRoutes.map((route, i) => (
                  <Route path={route.path} key={i} element={route.component} />
                ))}
            </>
          ) : (
            <>
              {commonRoutes.map((route, i) => (
                <Route path={route.path} key={i} element={route.component} />
              ))}
              {PublicRoutes.map((route, i) => (
                <Route path={route.path} key={i} element={route.component} />
              ))}
            </>
          )}
        </Routes>
      </BrowserRouter>
    </QBListenersContextProvider>
  );
};
export default IndexRoutes;
