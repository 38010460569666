import { format, fromUnixTime } from "date-fns";
import React from "react";
import { BsCheck } from "react-icons/bs";
import { BsCheckAll } from "react-icons/bs";
import { PiDownloadSimple } from "react-icons/pi";
import styled from "styled-components";
const SingleMessage = ({
  position = "left",
  id,
  to,
  from,
  date,
  deliveredIds,
  readIds,
  dialogId,
  attachments,
  messageBody,
}) => {
  const FromName = from.name || from.email || "You";
  const isDelivered = deliveredIds.includes(to.id);
  const isRead = readIds.includes(to.id);
  return (
    <StyledSingleMessage right={position === "right"}>
      <div className="d-flex flex-column">
        <div
          className="d-flex align-items-baseline justify-content-between gap-2"
          style={{ fontSize: "0.7rem", minWidth: "160px" }}
        >
          <div className="d-flex gap-1 align-items-center">
            <p>{FromName}</p>
            {isDelivered ? (
              isRead ? (
                <BsCheckAll style={{ width: 16, height: 16, color: "blue" }} />
              ) : (
                <BsCheck style={{ width: 16, height: 16, color: "gray" }} />
              )
            ) : (
              ""
            )}
          </div>
          <p className="m-0 text-muted">
            {format(fromUnixTime(date), "HH:MM")}
          </p>
        </div>
        <p className="message-body">{messageBody}</p>
      </div>
      {attachments && attachments.length ? (
        <div
          className="d-flex gap-2 flex-wrap"
          style={{ maxWidth: "max-content" }}
        >
          {attachments.map((attachment) => {
            return (
              <div
                className="d-flex flex-column gap-2"
                style={{ width:80, height: 80 }}
                key = {attachment.id}
              >
                <img
                  src={attachment.url}
                  alt={attachment.id}
                  className="w-100 h-100"
                />
                <div>
                  <a
                    href={attachment.url}
                    download={attachment.id}
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex gap-1 align-items-center"
                  >
                    <p className="text-truncate" style={{ fontSize: 12, flex:1 }}>{attachment.id}</p>
                    <PiDownloadSimple style={{ width: 16, height: 16, flex:1, maxWidth:16 }} />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </StyledSingleMessage>
  );
};

const StyledSingleMessage = styled.div`
  padding: 0.5rem 0.5rem;
  display: flex;
  gap:12px;
  flex-direction: column;
  font-size: 0.9rem;
  ${(props) => props.right ? "justify-content:flex-end; align-items:flex-end;" : "align-items:flex-start;"}
  > div {
    padding: 0rem 0.8rem;
    gap: 0.2rem;
  }

  .message-body {
    padding: 0.6rem 1rem;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    cursor: pointer;
    ${(props) =>
      props.right
        ? `
    border-bottom-left-radius: 20px;
    background-color:rgb(15, 211, 187);
    color:white;
    `
        : `
    border-bottom-right-radius: 20px;
    background-color: white;
    color: black;
    `}

    display: block;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    margin: 0;
  }
`;

export default SingleMessage;
