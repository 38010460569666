import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { jobsByPaginate } from "../thunks/jobsThunk";

/**
 * @typedef {Object} Job
 * @property {string} _id
 * @property {string} title
 * @property {string} description
 * @property {string} budgetMax
 * @property {string} budgetMin
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {string} status
 * @property {string} workType
 * @property {Object} location
 * @property {number} location.longitude
 * @property {number} location.latitude
 * @property {Object} createdBy
 * @property {string} createdBy._id
 * @property {string} createdBy.username
 * @property {string} createdBy.email
 * @property {string} createdBy.createdAt
 * @property {string} createdBy.rating
 * @property {boolean} createdBy.isOnline
 * @property {Object} category
 * @property {string} category._id
 * @property {string} category.name
 * @property {Object} subcategory
 * @property {string} subcategory._id
 * @property {string} subcategory.name
 * @property {number} deadline
 *
 *
 * @returns {{data: Array<Job> | undefined, loading: boolean, page:number, limit:number, hasMore:boolean}}
 * */
const useJobsSelectorByPagination = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.jobs.jobsByPaginate);

  useEffect(() => {
    if (!state.data && !state.loading) {
      dispatch(jobsByPaginate());
    }
  }, []);

  return state;
};

export default useJobsSelectorByPagination;
