import React, { useEffect, useState } from "react";
import {
  FilterDropdown,
  FilterDropdownApplyButton,
  FilterDropdownClearButton,
  FilterDropdownMenu,
  FilterDropdownMenuItem,
  FilterDropdownTrigger,
} from "./FilterDropdown";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../utils/Colors";
const RangeFilter = ({ maxParamId, minParamId, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = useState({
    max: searchParams.get(maxParamId) || "",
    min: searchParams.get(minParamId) || "",
  });
  console.log(values)

  const handleRangeSubmit = (e) => {
    e.preventDefault();
    setSearchParams((prev) => {
      if (values.min?.length) {
        prev.set(minParamId, values.min);
      } else {
        prev.delete(minParamId);
      }
      if (values.max?.length) {
        prev.set(maxParamId, values.max);
      } else {
        prev.delete(maxParamId);
      }
      return prev;
    });
  };
  useEffect(() => {
    setValues({
      max: searchParams.get(maxParamId) || "",
      min: searchParams.get(minParamId) || "",
    });
  }, [searchParams, maxParamId, minParamId]);

  return (
    <form method={"POST"} onSubmit={handleRangeSubmit}>
      <FilterDropdown>
        <FilterDropdownTrigger>{children}</FilterDropdownTrigger>
        <FilterDropdownMenu>
          <FilterDropdownMenuItem>
            <label className="d-block">Min</label>
            <StyleRangeFilterInput
              colors={colors}
              type="number"
              label="Min"
              name={minParamId}
              value={values.min}
              onChange={(e) => {
                setValues({ ...values, min: e.target.value });
              }}
            />
          </FilterDropdownMenuItem>
          <FilterDropdownMenuItem>
            <label className="d-block">Max</label>
            <StyleRangeFilterInput
              colors={colors}
              type="number"
              label="Max"
              name={maxParamId}
              value={values.max}
              onChange={(e) => {
                setValues({ ...values, max: e.target.value });
              }}
            />
          </FilterDropdownMenuItem>
          <FilterDropdownClearButton
            onClick={() => {
              setSearchParams((prevParams) => {
                prevParams.delete(maxParamId);
                prevParams.delete(minParamId);
                return prevParams;
              });
            }}
          >
            {"Clear"}
          </FilterDropdownClearButton>
          <FilterDropdownApplyButton type={"submit"}>
            {"Apply"}
          </FilterDropdownApplyButton>
        </FilterDropdownMenu>
      </FilterDropdown>
    </form>
  );
};
const StyleRangeFilterInput = styled.input`
  max-width: 150px;
  padding: 5px 10px;
  outline: none;
  border: 1px solid ${({ colors }) => colors.color.lightGrey};
  border-radius: 7px;
`;
export default RangeFilter;
