export const getDialogOfOccupants = async (QBApp, occupants_ids) => {
  return new Promise((resolve, reject) => {
    const params = {
      type: {
        in: [3],
      },
      all:occupants_ids,
    };
    QBApp.chat.dialog.list(params, (error, dialogs) => {
      if (error) {
        reject(error);
      } else {
        if (!dialogs || !dialogs.items.length) {
          resolve(null);
        } else {
          const findDialogWithBothOccupants = dialogs.items.find((dialog) => {
            const occupantIds = dialog.occupants_ids;
            return occupantIds.includes(occupants_ids[0]) && occupantIds.includes(occupants_ids[1])
          })
          resolve(findDialogWithBothOccupants || null);
        }
      }
    });
  });
};
export const createPrivateDialogWithOccupants = (QBApp, occupants_ids) => {
  return new Promise((resolve, reject) => {
    // Create a new Dialog
    const params = {
      type: 3,
      occupants_ids,
    };
    QBApp.chat.dialog.create(params, function (error, dialog) {
      if (error) {
        reject(error);
        // dispatch(
        //   actions.setStateForId({
        //     id: initalStateId,
        //     setState: (state) => ({ ...state, status: "error" }),
        //   })
        // );
      } else {
        resolve(dialog);
        // dialog_id = dialog._id;
      }
    });
  });
};
export const listPrivateDialogsOfUser = async (
  QBApp,
  userId,
  { skip = 0, limit = 10 }
) => {
  return new Promise((resolve, reject) => {
    const params = {
      type: {
        in: [3],
      },
      occupants_ids: {
        all: [userId],
      },
      sort_desc: "created_at",
      skip,
      limit,
    };
    QBApp.chat.dialog.list(params, (error, result) => {
      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
    });
  });
};
export const listDialogChatMessages = async (QBApp, dialogId, pageParams = {skip:0}) => {
  return new Promise((resolve, reject) => {
    const params = {
      chat_dialog_id: dialogId,
      sort_desc: 'date_sent',
      limit:20,
      mark_as_read: 0,
      ...pageParams,
    };
    QBApp.chat.message.list(params, (error, messages) => {
      if (error) {
        reject(error);
      } else {
        console.log(messages)
        resolve(messages);
      }
    });
  });
}