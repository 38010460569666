import axios from "../../../api/axios";

/**
 * @param {Object} param0
 * @param {string} param0.categoryId
 * @param {string[]} param0.subcategoryIds
 * @param {File} param0.profileImg
 * @param {string[]} param0.languages
 * @param {string} param0.description
 * @returns {Promise<Object>}
 */
export const becomeSeller = async ({
  categoryId,
  subcategoryIds,
  profileImg,
  languages,
  description,
}) => {
    try {
        const formData = new FormData();
        formData.append("attachment", profileImg);
        const imgResponse = await axios.post("attachments/one", formData);
      
        let image = {
          publicId: imgResponse.data.attachment.publicId,
          imgUrl: imgResponse.data.attachment.url,
        };
        const response = await axios.post("/users/become-seller", {
          category: categoryId,
          subcategories: subcategoryIds,
          profileImg: image,
          languages,
          description,
        });
        return response.data.user
    }catch(e) {
        throw new Error(e)
    }
};
