import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Button from "../common/Button";
import { useEffect } from "react";

const MarketingPopup = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (sessionStorage.getItem("visited")) {
      setModal(false);
    } else {
      setModal(true);
    }
    sessionStorage.setItem("visited", true);
  }, []);
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalBody
        className="d-flex flex-column align-items-center"
        style={{ padding: "32px 16px", width: "90%", margin: "auto" }}
      >
        <div
          className="falcon-clr text-xl text-center font-semibold"
          style={{ marginBottom: "14px" }}
        >
          {"Welcome to GigFalcon!"}
        </div>
        <div className="d-flex flex-column gap-2">
          {/* <p>{`A Marketplace where every skill counts, and dreams take flight! 🚀`}</p>
          <p>{`Join our bustling community as we are actively onboarding Sellers worldwide and Buyers from every corner of the US.`}</p>
          <p>{`Excited to take off? Dive into the action now by creating your Buyer/Seller account & Jobs/Gigs respectively!`}</p>
          <p>{`Buckle up, because transactions are gearing up for launch! 💥`}</p> */}
          <p>{`🌟 Important Reminder for Our New Users! 🌟`}</p>
          <p>{`If you're eagerly waiting for your sign-up code and haven't received it yet, please take a moment to check your spam or junk folder. Sometimes, our emails can mistakenly land there.`}</p>
          <p>{`We want to ensure you have a smooth experience, so if you still don't see it, feel free to reach out to our support team for assistance.`}</p>
          <p>{`Thank you for joining us, and welcome to the community! 😊`}</p>
          <p>{`#GigFalcon #CheckYourSpam #Welcome`}</p>
        </div>
        <Button
          type="submit"
          className="continue px-3 py-2 mt-3"
          onClick={toggle}
        >
          {"Let's Go!"}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default MarketingPopup;
