import React, { useState } from "react";
import { FaPaperclip } from "react-icons/fa";
import { useSendMessage } from "../../../api";
import FileUploadTrigger from "../../../../../components/common/FileUploadTrigger";
import useAttachmentsHandler from "../../../useAttachmentsHandler";
import FilesSelectedPreview from "../../molecules/FilesSelectedPreview";
const SendMessage = ({ dialogId, recipentId }) => {
  const { status, sendMessage } = useSendMessage();
  const [message, setMessage] = useState("");
  const { filesSelected, isUploading, removeFile,removeAll, handleFilesChange } =
    useAttachmentsHandler();
  const filesUploadingError = filesSelected && filesSelected.length && filesSelected.some((file) => file.status === 'error');

  return (
    <div style={{ maxHeight: "600px" }}>
      <div
        className="d-flex align-items-center gap-4"
        style={{ minHeight: "48px", padding: "0.5rem 0rem" }}
      >
        <div className="d-flex gap-2">
          <FileUploadTrigger
            name={"attachment"}
            onChange={(e) => {
              const files = e.target.files;
              handleFilesChange(files);
            }}
            multiple
            RenderComponent={({ onClick }) => {
              return (
                <FaPaperclip
                  role="button"
                  onClick={onClick}
                  style={{
                    cursor: "pointer",
                    width: "1.5rem",
                    height: "1.5rem",
                    color: "GrayText",
                  }}
                />
              );
            }}
          />
        </div>
        <div className="input-group input-group-md">
          <input
            type="text"
            className="form-control"
            aria-label="Sizing example input"
            placeholder="Type a message"
            aria-describedby="inputGroup-sizing-lg"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn btn-secondary"
            style ={{minWidth:"100px"}}
            disabled={status === "pending" || message.length === 0 || isUploading || filesUploadingError}
            onClick={() => {
              const attachments =
                filesSelected && filesSelected.length && filesSelected.every(file => file.serverFile)
                  ? filesSelected.map((files) => files.serverFile)
                  : undefined;
              sendMessage({ dialogId, recipentId, message, attachments });
              setMessage("");
              removeAll();
            }}
          >
            {isUploading
              ? "Uploading..."
              : status === "pending"
              ? "Sending"
              : "Send"}
          </button>
        </div>
      </div>
      {filesSelected && (
        <FilesSelectedPreview
          filesSelected={filesSelected}
          removeFile={removeFile}
        />
      )}
    </div>
  );
};

export default SendMessage;
