import React from "react";

import { formateDate, getTimeDuration } from "../../utils/helperFunctions";

import { useNavigate } from "react-router-dom";

/**
 * @description This is a functional component used to display the list of orders
 * @typedef Props
 * @property {Array} orders - This is an array of orders
 * @property {Boolean} loading - This is a boolean value to show loading
 * @property {('completed' | 'active' | 'revision')} listType - This is a string value to show the type of list
 * @property {('Sent' | 'Received')} ordersType - This is a string value to show the type of orders
 *
 *
 */
const OrdersList = (
  /** @type {Props} **/ {
    orders,
    loading,
    listType = "active",
    ordersType = "Received",
  }
) => {
  const navigate = useNavigate();
  return (
    <div className="active-table">
      <table className="">
        <thead>
          <tr>
            <th className="main-heading text-capitalize">Orders</th>
          </tr>
          <tr>
            <th className="table-heading">
              {ordersType === "Received" ? "Buyer" : "Seller"}
            </th>
            <th className="table-heading">JOB</th>
            <th className="table-heading">DUE ON</th>
            {listType === "completed" && (
              <th className="table-heading">DELIVERED AT</th>
            )}
            <th className="table-heading">TOTAL</th>
            <th className="table-heading">STATUS</th>
          </tr>
        </thead>
        <tbody>
          {orders?.length > 0 ? (
            orders.map((order) => {
              const User =
                ordersType === "Received" ? order?.createdBy : order?.createdTo;
              return (
                <tr
                  key={order._id}
                  onClick={() =>
                    navigate(`/order/${order._id}/${ordersType === 'Received'? 'seller/':''}detail`, {
                      state: order,
                    })
                  }
                  role="button"
                >
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div className="avatar">
                        <img src={User?.profileImg?.imgUrl} alt={"Profile"} />
                      </div>
                      <p>{User?.username}</p>
                    </div>
                  </td>
                  <td>
                    <p style={{ minWidth: "180px" }}>
                      {(order?.job?.description.length > 50
                        ? order?.job?.description.slice(0, 49) + "..."
                        : order?.job?.description) || "Custom Order"}
                    </p>
                  </td>
                  <td>
                    <p>{formateDate(order.deliveryDate)}</p>
                  </td>
                  {listType === "completed" && (
                    <td>
                      <p className = 'text-center'>{formateDate(order?.submissionDate)}</p>
                    </td>
                  )}
                  <td>
                    <p>${order?.price}</p>
                  </td>
                  <td>
                    <div className={`order-status ${order.status}`}>
                      {order?.status}
                    </div>
                  </td>
                </tr>
              );
            })
          ) : orders.length === 0 && loading === false ? (
            <tr>
              <td></td>
              <td></td>
              <td>
                <p className="grey-clr my-5">No Order to show</p>
              </td>
              <td></td>
              <td></td>
            </tr>
          ) : (
            loading === true && (
              <tr>
                <td></td>
                <td></td>
                <td>
                  <p className="grey-clr my-5">Loading...</p>
                </td>
                <td></td>
                <td></td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersList;
