import styled from "styled-components";

export const Styles = styled.div`
  .signup {
    padding: 0 15px;
    height: 100vh;
    font-family: "MS_SemiBold";
  }
  .react-google-autocomplete input[type="text"] {
    border-radius: 10px !important; /* Set the desired border-radius value */
  }
  .person-section {
    display: none;
  }
  form {
    display: flex;
    flex-direction: column;
  }
  p {
    text-align: center;
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .agree {
    font-size: 11px;
    padding: 15px 0;
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
  }
  button {
    padding: 8px 3px !important;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    color: #fff;
    margin-top: 15px;
    width: 100%;
  }
  .continue {
    background-color: ${({ colors }) => colors.color.secondary};
  }
  .continue p {
    font-size: 15px !important;
    color: ${({ colors }) => colors.color.white};
  }
  .member {
    color: ${({ colors }) => colors.color.black};
    margin-top: 15px;
  }
  span {
    color: ${({ colors }) => colors.color.secondary};
    cursor: pointer;
  }
  .first-name-last-name {
    width: 100%;
    display: flex;
    column-gap: 10px;
  }
  .first-name-last-name div {
    flex: 1;
  }
  @media only screen and (min-width: 375px) {
    p:last-child {
      font-size: 12.8px;
    }
    button {
      font-size: 15px;
    }
  }
  @media only screen and (min-width: 768px) {
    .signup {
      display: flex;
      padding-right: 20px;
      padding-left: 0;
    }
    .person-section {
      display: block;
      width: 350px;
    }
    @keyframes slideInLeft {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
    .person-section img {
      height: 100vh;
      animation: 1s ease-out 0s 1 slideInLeft;
    }
  }
  @media only screen and (min-height: 1024px) {
    .person-section {
      display: none;
    }
  }
  @media only screen and (min-width: 1024px) {
    .signup {
      display: flex;
      padding: 0 50px;
    }
    .person-section {
      display: block;
      flex: 1;
    }
    .person-section img {
      height: 100vh;
    }
  }
`;
