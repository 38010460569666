import React, { useState, useEffect } from "react";

import Button from "../../common/Button";
import { formatMessageTime } from "../../../utils/helperFunctions";
import { deleteMyJob } from "../../../store/thunks";
import { pauseJob } from "../../../store/thunks/jobActionsThunk";
import { colors } from "../../../utils/Colors";
import { getMyJobs } from "../../../store/thunks";

import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { GiPauseButton } from "react-icons/gi";
import { MdEdit } from "react-icons/md";
import RoutesPath from "../../../utils/Paths";

const ActiveJobs = () => {
  const [onConfirm, setOnConfirm] = useState(false);
  const { myJobs, loading } = useSelector((state) => state.jobs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeJobs = myJobs?.filter((job) => {
    if (job.status === "active") {
      return true;
    }
    return false;
  });
  const handleDeleteJob = (job) => {
    dispatch(deleteMyJob(job));
  };

  const handleJobOffer = (job) => {
    navigate(`${RoutesPath.manageJobs}/${job._id}/detail`, {
      state: job,
    });
  };

  useEffect(() => {
    dispatch(getMyJobs());
  }, [dispatch]);

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th className="table-heading">Date</th>
            <th className="table-heading">Job Description</th>
            <th className="table-heading">Price</th>
            <th className="table-heading">Offers</th>
            <th className="table-heading">Actions</th>
          </tr>
        </thead>
        <tbody>
          {activeJobs.length > 0 ? (
            myJobs?.map((myJob) => {
              return (
                myJob.status === "active" && (
                  <tr key={myJob._id}>
                    <td>
                      <p style={{ fontSize: "14px" }}>
                        {formatMessageTime(myJob.createdAt)}
                      </p>
                    </td>
                    <td
                      style={{ minWidth: "180px" }}
                      className="hover-underlined"
                      onClick={() => handleJobOffer(myJob)}
                    >
                      {myJob.description.length > 65
                        ? myJob.description.slice(0, 64) + "..."
                        : myJob.description}
                    </td>
                    <td>
                      <p style={{ minWidth: "80px" }}>
                        ${myJob.budgetMin} - ${myJob.budgetMax}
                      </p>
                    </td>
                    <td>
                      <Button
                        children="Offers"
                        className="offer-btn px-2 py-1"
                        onClick={() => handleJobOffer(myJob)}
                      />
                    </td>
                    <td className="text-center">
                      <BsThreeDots
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ cursor: "pointer" }}
                      />
                      <ul
                        className="dropdown-menu dropdown-menu-left"
                        aria-labelledby="dropdownMenuLink"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <li>
                          <div
                            className="dropdown-item d-flex align-items-center gap-3 mb-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(`/jobs/${myJob._id}/update`, {
                                state: myJob,
                              });
                            }}
                          >
                            <MdEdit color={`${colors.color.secondary}`} />
                            <p>Edit</p>
                          </div>
                        </li>
                        <li>
                          <div
                            className="dropdown-item d-flex align-items-center gap-3 mb-1 cursor-pointer"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              dispatch(pauseJob({ id: myJob._id }))
                            }
                          >
                            {loading ? (
                              <p className="m-auto">
                                <div
                                  className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                              </p>
                            ) : (
                              <>
                                <GiPauseButton />
                                <p>Pause</p>
                              </>
                            )}
                          </div>
                        </li>
                      </ul>
                    </td>
                    {onConfirm && (
                      <SweetAlert
                        danger
                        title={"Are you Sure you want to delete this Job ?"}
                        confirmBtnText="Yes, delete it!"
                        confirmBtnBsStyle="danger"
                        showCancel
                        focusCancelBtn
                        onConfirm={() => {
                          setOnConfirm(false);
                          handleDeleteJob(myJob);
                        }}
                        onCancel={() => setOnConfirm(false)}
                      ></SweetAlert>
                    )}
                  </tr>
                )
              );
            })
          ) : activeJobs.length === 0 && loading === false ? (
            <tr>
              <td></td>
              <td></td>
              <td>
                <p className="mt-4 grey-clr">No Active Job</p>
              </td>
            </tr>
          ) : (
            loading === true && (
              <tr>
                <td></td>
                <td></td>
                <td>
                  <div class="spinner-border text-secondary" role="status">
                    <span class="sr-only d-none">Loading...</span>
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveJobs;
