import React, { useState } from "react";

import { Styles } from "./jobOffer";
import { colors } from "../../../../utils/Colors";
// import { formatMessageTime } from "../../../../utils/helperFunctions";
import Button from "../../../common/Button";
import Rating from "../../../common/Rating";

import { ImAttachment } from "react-icons/im";
import { Link } from "react-router-dom";

const JobOffer = ({ data, onReject, onAccept }) => {
  const [more, setMore] = useState(false);

  const profileImg = data?.uid?.profileImg?.imgUrl;
  const username = data?.uid?.username;
  const sellerLevel = data?.uid?.sellerStats?.sellerLevel;
  // const completedOrders = data?.uid?.sellerStats?.completedOrders;
  // const createdAt = formatMessageTime(data?.createdAt);
  const coverLetter = data?.coverLetter;
  const attachments = data?.attachments;
  const price = data?.price;
  // const workingDays = data?.workingDays;
  const loading = data?.loading;
  const gigImg = data?.selectedGig?.attachments?.[0].imgUrl;
  const gigRating = data?.selectedGig?.rating;

  return (
    <Styles colors={colors}>
      <div className="job-offer">
        <div className="offer-img">
          <img src={gigImg} alt = {"gigImage"} />
          <div className="offer-avatar">
            <img src={profileImg} alt = {"avatar"} />
          </div>
        </div>
        <div className="p-3">
          <div className="mt-2">
            <h5 className="text-center pt-2 mb-0 heading">{username}</h5>
            <p className="text-center grey-clr" style={{ fontSize: "12px" }}>
              {sellerLevel}
            </p>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <Rating rating={gigRating} />
            </div>
          </div>
          <hr />
          <div className="offer-content w-100">
            <p className="text-center heading offer-cover">
              {coverLetter?.length > 65 && more === false ? (
                <span className="mb-2">
                  {coverLetter.slice(0, 64)}{" "}
                  <span className="see-more" onClick={() => setMore(true)}>
                    ...see more
                  </span>
                </span>
              ) : (
                <>
                  <span className="mb-2">{coverLetter}</span>
                  {more === true && (
                    <span
                      className="grey-clr mb-2"
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      onClick={() => setMore(false)}
                    >
                      see less
                    </span>
                  )}
                </>
              )}
            </p>
            <p className="text-center heading">
              Price: <span className="grey-clr">${price}</span>
            </p>
            <div style={{ height: "20px" }}>
              {attachments.length > 0 && (
                <div className="mb-1 d-flex align-items-center justify-content-center gap-1">
                  <ImAttachment className="file-icon" />
                  <Link
                    className="attachments"
                    to={attachments[0]?.url}
                    download="myFile"
                    target="_blank"
                  >
                    <p>attachments...</p>
                  </Link>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center gap-3 py-2">
              <div data-bs-toggle="modal" data-bs-target="#exampleModal">
                <Button
                  className="accept-btn"
                  children="Accept"
                  onClick={() => onAccept(data)}
                  disabled={loading}
                />
              </div>
              <Button
                children={
                  loading ? (
                    <div
                      className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    "Reject"
                  )
                }
                className="reject-btn"
                onClick={() => onReject(data)}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="job-offer">
        <div className="d-flex align-items-start gap-3">
          <div className="avatar">
            <img src={profileImg} />
          </div>
          <div>
            <p>{username}</p>
            <div className="d-flex align-items-center gap-1">
              <p className="grey-clr" style={{ fontSize: "12px" }}>
                {sellerLevel}
              </p>

              <p className="grey-clr " style={{ fontSize: "12px" }}>
                {completedOrders > 0 && <span>({completedOrders})</span>}
              </p>
              <p className="grey-clr " style={{ fontSize: "10px" }}>
                {createdAt}
              </p>
            </div>
          </div>
        </div>
        <div className="offer-content">
          {coverLetter?.length > 65 && more == false ? (
            <p className="mb-2">
              {coverLetter.slice(0, 64)}{" "}
              <span className="see-more" onClick={() => setMore(true)}>
                ...see more
              </span>
            </p>
          ) : (
            <>
              <p className="mb-2">{coverLetter}</p>
              {more == true && (
                <p
                  className="grey-clr mb-2"
                  style={{ fontSize: "12px", cursor: "pointer" }}
                  onClick={() => setMore(false)}
                >
                  see less
                </p>
              )}
            </>
          )}
          {attachments.length > 0 && (
            <div className="mb-1 d-flex align-items-center gap-1">
              <ImAttachment className="file-icon" />
              <Link
                className="attachments"
                to={attachments[0]?.url}
                download="myFile"
                target="_blank"
              >
                <p>attachments...</p>
              </Link>
            </div>
          )}
          <p>
            <span className="falcon-clr">Offered Price: </span>${price}
          </p>
          <p>
            <span className="falcon-clr">Work Duration: </span>
            {workingDays} {workingDays > 1 ? "Days" : "Day"}
          </p>
        </div>
        <div className="d-flex justify-content-end gap-2 py-2">
          <Button
            children={
              loading ? (
                <div
                  className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              ) : (
                "Reject"
              )
            }
            className="reject-btn"
            onClick={() => onReject(data)}
            disabled={loading}
          />
          <div data-bs-toggle="modal" data-bs-target="#exampleModal">
            <Button
              className="accept-btn"
              children="Accept"
              onClick={() => onAccept(data)}
              disabled={loading}
            />
          </div>
        </div>
      </div> */}
    </Styles>
  );
};

export default JobOffer;
