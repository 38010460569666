import styled from "styled-components";

export const Styles = styled.div`
  .profile {
    padding: 20px;
  }
  .about-profile-container {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
  }
  .description-profile-container {
    margin-top: 20px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
  }
  .profile-gigs {
    margin-top: 20px;
    padding: 0 20px;
  }
  .profile-gigs h3 {
    font-size: 23px;
  }
  .profile-gigs h3 span {
    color: ${({ colors }) => colors.color.secondary};
  }
  .profile-gigs-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  .add-box {
    height: 250px;
    width: 260px;
    margin-top: 0;
    border-radius: 10px;
  }
  .create-new-gig {
    color: ${({ colors }) => colors.color.darkGrey};
  }

  @media only screen and (min-width: 769px) {
    .profile {
      padding: 0px;
      display: flex;
    }
    .description-profile-container {
      margin-top: 0;
    }
    .profile-gigs {
      padding: 10px 0px;
    }
    .profile-gigs-list {
      justify-content: start;
    }
  }
`;
