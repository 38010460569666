import React from "react";
import ChatsList from "./ChatsList";
import ChatBox from "./ChatBox";
import { useSearchParams } from "react-router-dom";

const InboxSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDialogId = searchParams.get("dialogId");
  return (
    <>
      <div
        className="border-end-50 border border-top-0 flex-1"
        style={{ minWidth: "320px" }}
      >
        <ChatsList
          selectedDialogId={selectedDialogId}
          onSelectDialogId={(id) => {
            setSearchParams({ dialogId: id });
          }}
        />
      </div>
      <ChatBox selectedDialogId={selectedDialogId} />
    </>
  );
};

export default InboxSection;
