import React, { useEffect, useRef } from "react";
import SingleMessage from "./SingleMessage";
import { useDialogMessages, useReadMessageStatus } from "../../../api";
import { useAuthContext } from "../../../../authentication";
import {
  format,
  fromUnixTime,
  isSameDay,
  isToday,
  isYesterday,
} from "date-fns";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQBListenersContext } from "../../../context/quickblox-listeners-provider";

export const ChatDialogMessages = ({ selectedDialog }) => {
  const { user } = useAuthContext();
  const containerRef = useRef(null);
  const {
    messages: messagesByPages,
    status,
    fetchMore,
    hasMore,
  } = useDialogMessages({
    dialogId: selectedDialog._id,
  });
  console.log(messagesByPages);
  const messages = messagesByPages
    ?.flat()
    //.sort((a, b) => compareDesc(a.date_sent, b.date_sent))
    .reverse();

  const opponent = {
    id: selectedDialog.opponent.id,
    name: selectedDialog.opponent.full_name,
    email: selectedDialog.opponent.email,
  };
  const currentUser = {
    id: user?.quickblox.user_id,
    name: user?.quickblox.full_name,
    email: user?.quickblox.email,
  };
  const messagesGroupedByDate = messages?.reduce((prev, message) => {
    const currentMessageDate = fromUnixTime(message.date_sent);
    if (prev.length === 0) {
      return prev.concat([{ date: currentMessageDate, messages: [message] }]);
    }
    const lastGroupDate = prev[prev.length - 1].date;
    const lastGroupMessages = prev[prev.length - 1].messages;
    if (isSameDay(lastGroupDate, currentMessageDate)) {
      lastGroupMessages.push(message);
      return prev;
    }
    return prev.concat([{ date: currentMessageDate, messages: [message] }]);
  }, []);

  useSendingMessageStatus({
    messages: messages,
    currentUserId: currentUser.id,
    opponentId: opponent.id,
  });

  if (status === "loading") {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ flex: 1 }}
      >
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only d-none">Loading...</span>
        </div>
      </div>
    );
  }
  if (status === "error") {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ flex: 1 }}
      >
        {"Something went wrong"}
      </div>
    );
  }
  return (
    <div
      className="d-flex flex-column-reverse overflow-auto flex-grow-1"
      ref={containerRef}
      id="scrollable"
    >
      <InfiniteScroll
        aria-label="messages"
        dataLength={messages?.length || 0}
        hasMore={hasMore}
        next={fetchMore}
        scrollableTarget={"scrollable"}
        inverse={true}
        initialScrollY={containerRef.current?.scrollHeight}
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          padding: 10,
        }}
      >
        <NewMessageAnchor />
        <div className="d-flex flex-column gap-5">
          {messagesGroupedByDate.map((group, idx) => {
            return (
              <div
                className="d-flex flex-column justify-content-end align-items-center"
                style={{ flex: "1 0 auto" }}
                key={idx}
              >
                <p
                  className="pt-1 pb-1 ps-3 pe-3 bg-light d-block rounded-pill"
                  style={{ fontSize: 12 }}
                >
                  {GetTimePeriods(group.date)}
                </p>
                <div className="w-100">
                  {group.messages.map((message, idx) => (
                    <SingleMessage
                      key={idx}
                      id={message._id}
                      dialogId={message.chat_dialog_id}
                      date={message.date_sent}
                      position={
                        message.sender_id === currentUser.id ? "right" : "left"
                      }
                      to={
                        message.sender_id === currentUser.id
                          ? opponent
                          : currentUser
                      }
                      from={
                        message.sender_id !== currentUser.id
                          ? opponent
                          : currentUser
                      }
                      messageBody={message.message}
                      deliveredIds={message.delivered_ids}
                      readIds={message.read_ids}
                      attachments={message.attachments}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};
const GetTimePeriods = (date, formatString = "dd/MM/yyyy") => {
  if (isToday(date)) {
    return "Today";
  }
  if (isYesterday(date)) {
    return "Yesterday";
  }
  return format(date, formatString);
};

// Reading Messages on load
const useSendingMessageStatus = ({ messages, currentUserId, opponentId }) => {
  const { sendReadMessageStatus } = useReadMessageStatus();

  const readMessagesRef = useRef([]);
  useEffect(() => {
    if (!messages) return;
    for (const message of messages) {
      if (
        !readMessagesRef.current.includes(message._id) &&
        !message.read_ids.includes(currentUserId)
      ) {
        console.log("sending read status", message._id);
        sendReadMessageStatus({
          dialogId: message.chat_dialog_id,
          messageId: message._id,
          recieverId: opponentId,
          senderId: currentUserId,
        });
        readMessagesRef.current.push(message._id);
      }
    }
  }, [currentUserId, opponentId, messages, sendReadMessageStatus]);
};
// Scrolling to new messages
const NewMessageAnchor = () => {
  const { newMessage } = useQBListenersContext();
  const autoScrollAnchorRef = useRef(null);
  useEffect(() => {
    const target = autoScrollAnchorRef.current;
    if (target) {
      if (target.getBoundingClientRect().bottom > window.innerHeight) {
        target.scrollIntoView(false);
      }
      if (target.getBoundingClientRect().top < 0) {
        target.scrollIntoView({ behaviour: "smooth" });
      }
    }
  }, [newMessage]);

  return <div id="autoscroll-anchor" ref={autoScrollAnchorRef}></div>;
};
