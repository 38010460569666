import React, { useState } from "react";

import { Styles } from "./gigCard3";
import { colors } from "../../../utils/Colors";
import Rating from "../../common/Rating";

import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import { deleteMyGig } from "../../../store/thunks/gigsThunk";

/**
 * @typedef {import("../../../types/server").GigDetailData} Props
 */
const GigCard3 = (/** @type {Props} */gig) => {
  const [onConfirm, setOnConfirm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDeleteGig = (gig) => {
    dispatch(deleteMyGig(gig));
  };

  const bgImg = gig?.attachments?.[0].imgUrl;
  const id = gig?._id;
  const title = gig?.title;
  const rating = gig?.rating;
  const ordersCompleted = gig?.completedOrdersCount;
  const priceFrom = gig?.priceFrom;
  const priceTo = gig?.priceTo;

  return (
    <Styles colors={colors}>
      <div className="gig-card">
        <div className="gig-img">
          <img className="bg-img" src={bgImg} alt="Background" />
          <div className="options d-flex align-items-center justify-content-center">
            <BsThreeDotsVertical
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={(e) => e.stopPropagation()}
            />
            <ul
              className="dropdown-menu dropdown-menu-left"
              aria-labelledby="dropdownMenuLink"
            >
              <li>
                <div
                  className="dropdown-item d-flex align-items-center gap-3 mb-1"
                  style={{ cursor: "pointer" }} 
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/update-gig/${gig._id}`,{ state: gig })
                  }}
                >
                  Edit
                </div>
              </li>
              <li>
                <div
                  className="dropdown-item d-flex align-items-center gap-3 mb-1"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOnConfirm(true);
                  }}
                >
                  <p>Delete</p>
                </div>
              </li>
            </ul>
          </div>
          {rating > 0 && (
            <div className="gig-rating">
              <Rating rating={rating} ordersCompleted={ordersCompleted} />
            </div>
          )}
        </div>
        <div className="gig-content">
          <p
            className="gig-title"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/gig/${id}/detail`, {
                state: gig,
              });
            }}
          >
            {title}
          </p>
          <p className="gig-price">
            Price:{" "}
            <span>
              ${priceFrom} - ${priceTo}
            </span>
          </p>
        </div>
        {onConfirm && (
          <SweetAlert
            danger
            title={"Are you Sure you want to delete this Gig ?"}
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            showCancel
            focusCancelBtn
            onConfirm={() => {
              setOnConfirm(false);
              handleDeleteGig(gig);
            }}
            onCancel={() => setOnConfirm(false)}
          ></SweetAlert>
        )}
      </div>
    </Styles>
  );
};


export const GigCard3Placeholder = () => {
  return (
    <Styles colors={colors}>
      <div className="gig-card">
        <div className="gig-img placeholder-glow" style = {{height:170}}>
          <div className="bg-img placeholder placeholder-grey h-100 w-100"></div>
        </div>
        <div className="gig-content">
          <div className="gig-title">
            <div className="placeholder-glow w-100" style = {{height:40}}>
              <span class="placeholder placeholder-grey w-100 h-100"></span>
            </div>
          </div>
          <div className="gig-price">
            <div className="placeholder-glow w-75">
              <span class="placeholder placeholder-grey w-100"></span>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}
export default GigCard3;
