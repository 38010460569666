import React, { useEffect, useState } from "react";

import { useMap } from "react-mapkit";

function UseAppleReverseGeocoding({ coordinate, placeholder }) {
  const { mapkit } = useMap();
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (coordinate && coordinate.latitude && coordinate.longitude && mapkit) {
      const getAddressFromLatLng = (latitude, longitude) =>
        new Promise((resolve, reject) => {
          const geocoder = new mapkit.Geocoder();
          const mapKitcoordinate = new mapkit.Coordinate(
            coordinate.latitude,
            coordinate.longitude
          );
          geocoder.reverseLookup(mapKitcoordinate, (error, data) => {
            if (error) {
              console.error("Reverse geocoding failed:", error);
              resolve(placeholder);
            }

            if (data && data.results && data.results.length > 0) {
              const firstResult = data.results[0];
              const address = firstResult.formattedAddress;
              resolve(address);
            } else {
              resolve(placeholder);
            }
          });
        });
      (async () => {
        const address = await getAddressFromLatLng(
          coordinate.latitude,
          coordinate.longitude
        );
        setAddress(address);
      })();
    } else {
      placeholder && setAddress(placeholder);
    }
  }, [coordinate, mapkit]);

  return {
    address,
  };
}

export default UseAppleReverseGeocoding;
