import styled from "styled-components";

export const Styles = styled.div`
  .offers-page {
    min-height: 50vh;
    padding: 10px;
    margin: 40px auto;
  }
  h3,
  span {
    font-family: "NN_Bold";
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .status-bar {
    padding: 1px;
    background-color: ${({ colors }) => colors.color.status};
    position: relative;
    margin-bottom: 10px;
  }
  .status-bar-sphere {
    width: 50px;
    height: 5px;
    background-color: ${({ colors }) => colors.color.secondary};
    position: absolute;
    top: -1.5px;
    border-radius: 2px;
  }
  .manage-status-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-left: 0;
    list-style: none;
  }
  .manage-status {
    color: ${({ colors }) => colors.color.status};
    font-weight: 800;
    font-size: 10px;
    cursor: pointer;
  }
  .job-offer {
  }
  .custom-offer {
    left: 70px;
  }
  .offers-wrapper {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 0px 20px;
    border-radius: 10px;
  }
  .offer {
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 30px 0;
  }
  .offer:last-child {
    border-bottom: none;
  }
  .offer-img {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
  }
  .offer-img img {
    width: 100%;
    min-height: 120px;
    object-fit: cover;
  }
  .offer-desc {
    font-family: "NN_SemiBold";
    font-size: 18px;
    height: 80px;
    max-width: 350px;
    overflow: hidden;
  }
  .custom-offer-desc {
    font-family: "NN_SemiBold";
    font-size: 18px;
  }
  .offer-price {
    font-family: "NN_SemiBold";
  }
  .offer-price span {
    font-family: "NN_Regular";
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .attachments {
    font-family: "NN_Light";
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .offer-status {
    padding: 2px 10px;
    border-radius: 5px;
    color: ${({ colors }) => colors.color.white};
    background-color: ${({ colors }) => colors.color.darkGrey};
  }
  .offer-status p {
    font-family: "NN_Regular";
  }
  .rejected {
    color: ${({ colors }) => colors.color.rejected};
    background-color: ${({ colors }) => colors.color.bgRejected};
  }
  .pending {
    color: ${({ colors }) => colors.color.pending};
    background-color: ${({ colors }) => colors.color.bgPending};
  }
  .cancel {
    color: ${({ colors }) => colors.color.cancel};
    background-color: ${({ colors }) => colors.color.bgCancel};
    cursor: pointer;
  }
  .offer-info {
    // max-width: 350px;
    // overflow: hidden;
  }

  @media only screen and (min-width: 450px) {
    .manage-status {
      font-size: 14px;
    }
    .status-bar-sphere {
      width: 70px;
    }
    .job-offer {
    }
    .custom-offer {
      left: 100px;
    }
  }
  @media only screen and (min-width: 587px) {
    .offer-img {
      max-width: 180px;
      max-height: 120px;
    }
    .offer-img img {
      max-width: 180px;
      height: 120px;
    }
  }

  @media only screen and (min-width: 768px) {
    .offers-page {
      padding: 10px 40px;
    }
    .manage-status-list {
      gap: 20px;
    }
  }
  @media only screen and (min-width: 992px) {
    .offers-page {
      max-width: 1100px !important;
    }
  }
`;
