import React from "react";

import { Styles } from "./dashboard";
import { colors } from "../../../utils/Colors";
import Header from "../../../components/common/Header";
import Footer from "../../../components/common/Footer";
import Speaker from "../../../assets/images/megaphone.png";
import { cardData } from "../../../utils/FakeBackend";
import GigCard from "../../../components/Gigs/GigCard";
import InputImage1 from "../../../components/common/InputImage1";
import AboutSeller from "../../../components/Seller/AboutSeller";

import { useNavigate } from "react-router-dom";
import DescriptionProfile from "../../Profile/DesriptionProfile";

const SellerDashboard = () => {
  const navigate = useNavigate();

  return (
    <Styles colors={colors}>
      <Header />
      <section className="seller-dashboard">
        <div className="row">
          <div className="col-sm-12 col-lg-10 m-auto">
            <div className="row">
              <div className="d-lg-flex">
                <div className="about-seller-wrapper col-12 col-lg-5">
                  <AboutSeller />
                </div>
                <div className="seller-desc dash-box mt-4 col-12 col-lg-7">
                  <DescriptionProfile />
                </div>
              </div>
            </div>

            <div className="active-orders dash-box mt-4 p-3">
              <h5>
                Active Orders <span className="grey-clr">- 0($0)</span>
              </h5>
            </div>
            <div className="steps-top-seller dash-box mt-4 p-3">
              <h3 className="text-center mt-3">
                Take these steps to become a Top seller on falcon
              </h3>
              <div className="d-flex justify-content-center mt-5 mb-3">
                <div className="speaker">
                  <img src={Speaker} alt="" />
                </div>
              </div>
              <h5 className="text-center">Get noticed</h5>
              <p className="notice-text text-center grey-clr mb-5">
                Tap into the power of social media by sharing your Gig, and get
                expert help to grow your impact.
              </p>
              <div className="d-flex justify-content-center mb-4">
                <button
                  type="button"
                  className="share-gigs-btn"
                  onClick={() => navigate("/profile")}
                >
                  Share Your Gigs
                </button>
              </div>
            </div>
            <div>
              <div className="seller-dash-gigs my-5">
                <h3 className="mb-3 text-center text-lg-start">
                  Quoterze <span className="falcon-clr">Gigs</span>
                </h3>
                <div className="seller-dash-gigs-list d-flex justify-content-center justify-content-lg-start gap-4 flex-wrap">
                  {cardData.map((gig, index) => {
                    return index < 3 ? (
                      <GigCard {...gig} />
                    ) : null;
                  })}
                  <div onClick={() => navigate("/gig-creation")}>
                    <InputImage1 text="Create A new Gig" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Styles>
  );
};

export default SellerDashboard;
