import axios from "../../api/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";
// import axios1 from "axios";

export const createJobOrder = createAsyncThunk(
  "orders/createJobOrder",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { user, navigate } = data;
      const { pmId, offerId } = user;

      const response = await axios.post("/orders/job", {
        pmId,
        offer: offerId,
      });
      
      navigate("/buyer-orders");
      dispatch(openSuccessMessage(response.data.message));
      return response.data.message;
    } catch (error) {
      
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getBuyerOrders = createAsyncThunk(
  "orders/getBuyerOrders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/orders/buyer");
      return response.data.orders;
    } catch (error) {
      
      return rejectWithValue(error.message);
    }
  }
);

export const completeOrder = createAsyncThunk(
  "orders/completeOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { orderId } = payload;
      const response = await axios.post(`/orders/buyer/${orderId}/complete`);
      dispatch(openSuccessMessage(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  }
);

export const rejectOrder = createAsyncThunk(
  "orders/rejectOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data, navigate } = payload;
      const response = await axios.post("/orders/buyer/decline", data);
      navigate("/buyer-orders");
      // return response.data.
      dispatch(openSuccessMessage(response.data.message));
    } catch (error) {
      
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  }
);

export const getSellerOrders = createAsyncThunk(
  "orders/getSellerOrders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/orders/seller");
      return response.data.orders;
    } catch (error) {
      
      return rejectWithValue(error.message);
    }
  }
);

export const deliverOrder = createAsyncThunk(
  "orders/deliverOrders",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data, navigate } = payload;
      //const { orderId, deliveryText } = data;
      await axios.post("/orders/seller/delivery", data);
      navigate("/seller-orders");
      dispatch(openSuccessMessage("Order Delieverd!"));
      
    } catch (error) {
      
      dispatch(openErrorMessage("Something went wrong"));
      return rejectWithValue("Something went wrong!");
    }
  }
);
