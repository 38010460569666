import React from "react";

const ReviewPlaceholder = ({ placeholders }) => {
  return (
    <div className="d-flex flex-column gap-3 mt-4">
      {placeholders.map((placeholder, index) => {
        return (
          <div className="d-flex gap-4" key = {index}>
            <div
              className="placeholder-glow rounded-circle"
              style={{ height: 50, width: 50 }}
            >
              <span className="placeholder placeholder-grey w-100 h-100 rounded-circle"></span>
            </div>
            <div className = "flex-grow-1">
              <h4 className="placeholder-glow w-25 mb-0 mt-2">
                <span className="placeholder placeholder-grey w-100"></span>
              </h4>
              <p className="mt-2">
                <span className="placeholder placeholder-grey w-100"></span>
              </p>
              <p className="mt-2">
                <span className="placeholder placeholder-grey w-100"></span>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReviewPlaceholder;
