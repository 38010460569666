import axios from "../../../api/axios";

export const signUp = async (user) => {
  //here goes the api for username & email verification
  const response = await axios.get(
    `/users/availablity?username=${user.username}&email=${user.email}`
  );
  const availData = response.data;
  if (
    availData.username.availability === "NOT_AVAILABLE" &&
    availData.email.availability === "NOT_AVAILABLE"
  ) {
    throw new Error("Username and Email already exist");
  }
  if (availData.username.availability === "NOT_AVAILABLE") {
    throw new Error("Username already exist");
  }
  if (availData.email.availability === "NOT_AVAILABLE") {
    throw new Error("Email already exist");
  }

  // here goes the api call for OTP
  try {
    const res = await axios.post("/users/sign-up/app", {
      ...user,
    });
    return res;
  } catch (err) {
    const msg =
      err?.response?.data?.error ||
      err?.response?.data?.message ||
      "Something went wrong";
    throw new Error(msg);
  }
};
