import React from "react";
const messagesFilter = [
  "All Messages",
  "Unread",
  // "Important",
  // "Drafts",
  // "Spam",
];

export const MessageStateFilter = ({ chats, render }) => {
  const [selectedFilter, setCurrentMessageFilter] = React.useState(
    messagesFilter[0]
  );
  const filteredChats = chats.filter((chat) => {
    if (selectedFilter === "All Messages") return true;
    if (selectedFilter === "Unread") return chat.unread_messages_count > 0;
    return false;
  });
  return (
    <>
      <select
        className="form-select"
        onChange={(e) => setCurrentMessageFilter(e.target.value)}
        aria-label="Default select example"
      >
        {messagesFilter.map((item) => (
          <option
            //selected={item === currentMessagesFilter}
            value={item}
            key={item}
          >
            {item}
          </option>
        ))}
      </select>
      {render({ chats: filteredChats })}
    </>
  );
};
