import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../thunks/notificationsThunk";

const initialState = {
  notifications: [],
  loading: false,
  error: null,
};

const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};
const handleRejected = (state) => {
  state.loading = false;
  state.error = "Something went wrong.";
};
const handleGetNotifications = (state, action) => {
  state.loading = false;
  state.notifications = action.payload;
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, handlePending)
      .addCase(getNotifications.fulfilled, handleGetNotifications)
      .addCase(getNotifications.rejected, handleRejected);
  },
});

export default notificationsSlice.reducer;
