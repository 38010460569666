import React, { useEffect, useState } from "react";

import { Styles } from "./earnings";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import { colors } from "../../utils/Colors";
import Button from "../../components/common/Button";
import { getEarnings } from "../../store/thunks/earningsThunk";

import Chart from "react-apexcharts";
import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getYear } from "date-fns";
import PendingPaymentsSection from "./components/PendingPaymentsSection";

const Earnings = () => {
  const [showDetails, setShowDetails] = useState(true);
  const { earnings } = useSelector((state) => state.earnings);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentBalance = earnings?.user?.currentBalance || 0;
  const platformCharges = earnings?.platformCharges || 0;
  const totalOrders = earnings?.orders?.total || 0;
  const completedOrders = earnings?.orders?.completed || 0;
  const pendingOrders = earnings?.orders?.pending || 0;
  const revisionOrders = earnings?.orders?.revision || 0;
  const activeOrders = earnings?.orders?.active || 0;
  const responseTime =
    earnings?.user?.sellerStats?.averageResponseTimeHours || 0;

  const graph = {
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories:
          earnings?.completedOrderList?.map((order) =>
            getYear(new Date(order.deliveryDate))
          ) || [],
      },
      //   stroke: {
      //     curve: "smooth",
      //   },
      theme: {
        monochrome: {
          enabled: true,
          color: `${colors.color.secondary}`,
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
    },

    series: [
      {
        name: "series-1",
        data:
          earnings?.completedOrderList?.map(
            (order) => order.price - platformCharges
          ) || [],
      },
    ],
  };

  useEffect(() => {
    dispatch(getEarnings());
  }, [dispatch]);
  return (
    <>
      <Header />
      <Styles colors={colors}>
        <div className="earnings-page">
          <h3 className="falcon-clr mb-4">Earnings</h3>
          <div className="earnings-wrapper">
            <div className="earnings-head-section d-flex flex-column flex-md-row gap-3">
              <div className="earnings-graph">
                <Chart
                  options={graph.options}
                  series={graph.series}
                  type="line"
                  height={320}
                />
              </div>
              <div className="earnings-balance">
                <div className="d-flex flex-column justify-content-between h-100 gap-2">
                  <div>
                    <h5>Total Balance</h5>
                    <hr />
                    <p className="grey-clr  dark">Total Wallet Balance</p>
                    <div className="ms-4">
                      <p className="dollar">$</p>
                      <h4 className="total-income">{currentBalance}</h4>
                    </div>
                  </div>
                  <div>
                    <Button
                      children="Withdraw"
                      className="withdraw-btn"
                      onClick={() => navigate("/payments")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="earnings-foot-section"
                onClick={() => setShowDetails(!showDetails)}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Overview</h5>
                  <FiChevronDown />
                </div>
              </div>
              {showDetails && (
                <div className="earnings-foot-content d-sm-flex justify-content-between">
                  <div>
                    <p className="light mt-1">
                      Total Orders:{" "}
                      <span className="falcon-clr">{totalOrders}</span>
                    </p>
                    <p className="light mt-1">
                      Completed Orders:{" "}
                      <span className="falcon-clr"> {completedOrders}</span>
                    </p>
                    <p className="light mt-1">
                      Revision Orders:{" "}
                      <span className="falcon-clr"> {revisionOrders}</span>
                    </p>
                    <p className="light mt-1">
                      Pending Orders:{" "}
                      <span className="falcon-clr"> {pendingOrders}</span>
                    </p>
                    <p className="light mt-1">
                      Active Orders:{" "}
                      <span className="falcon-clr"> {activeOrders}</span>
                    </p>
                  </div>
                  <div>
                    <p className="light mt-1">
                      Current Balance:{" "}
                      <span className="falcon-clr"> ${currentBalance}</span>
                    </p>
                    {/* <p className="light mt-1">
                      Platform Charges:{" "}
                      <span className="falcon-clr"> ${platformCharges}</span>
                    </p> */}
                    <p className="light mt-1">
                      Response Time:{" "}
                      <span className="falcon-clr"> {responseTime} hr</span>
                    </p>
                    {/* <p className="light mt-1">
                      Total Withdraw: <span className="falcon-clr"> $39</span>
                    </p>
                    <p className="light mt-1">
                      Last Withdraw: <span className="falcon-clr"> $10</span>
                    </p> */}
                  </div>
                </div>
              )}
            </div>
            <PendingPaymentsSection />
          </div>
        </div>
      </Styles>
      <Footer />
    </>
  );
};

export default Earnings;
