import React, { useEffect } from "react";

import { Styles } from "./manageGigs";
import { colors } from "../../../utils/Colors";
import Header from "../../../components/common/Header";
import Footer from "../../../components/common/Footer";
import { getMyGigs } from "../../../store/thunks/gigsThunk";

import { useDispatch, useSelector } from "react-redux";
import GigCard3, {
  GigCard3Placeholder,
} from "../../../components/Gigs/GigCard3";

const ManageGigs = () => {
  const {data:gigs, loading} = useSelector((state) => state.gigs.getMyGigs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyGigs());
  }, [dispatch]);


  return (
    <>
      <Header />
      <Styles colors={colors}>
        <div className="manage-gigs-page">
          <h3 className="mb-3">
            <span className="falcon-clr">Manage</span> Gigs
          </h3>
          <div className="gigs-list w-100">
            {loading ? (
              <>
                <GigCard3Placeholder />
                <GigCard3Placeholder />
                <GigCard3Placeholder />
              </>
            ) : gigs?.length > 0 ? (
              gigs?.map((gig) => <GigCard3 {...gig} key={gig._id} />)
            ) : (
              <p className="text-center p-5 grey-clr heading">No Gig to show</p>
            )}
          </div>
        </div>
      </Styles>
      <Footer />
    </>
  );
};

export default ManageGigs;
