import styled from "styled-components";

export const Styles = styled.div`
  .description-profile {
    padding: 10px;
    margin-top: 20px;
    
  }
  .heading {
    font-size: 20px;
  }
  .answer {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .langauges div {
    display: flex;
  }

  @media only screen and (min-width: 769px) {
    .description-profile {
      margin-top: 0;
    }
  }
`;
