import { useQBContext } from "../../context/quickblox-provider";
import { sendReadMessageStatus } from "../services";
import {
  createSliceWithStateIds,
  useMutationRedux,
} from "../../../react-query-redux";
import { useDialogMessagesState } from "../queries/use-dialog-messages";
import { useAuthContext } from "../../../authentication";
import { useUserDialogsState } from "../queries/use-user-dialogs";

const stateKey = "qb-read-messages";
export const useReadMessageStatusSlice = createSliceWithStateIds(stateKey);
const { reducerName, actions } = useReadMessageStatusSlice;
export const useReadMessageStatus = () => {
  useAuthContext({ required: true });
  const { QBApp } = useQBContext();
  const chatsMessagesState = useDialogMessagesState();
  const chatsListState = useUserDialogsState();

  const state = useMutationRedux({
    id: [stateKey],
    actions,
    reducerName,
    mutationFn: async ({ dialogId, messageId, senderId, recieverId }) => {
      console.log("sending-read-status", messageId, dialogId, recieverId);
      await sendReadMessageStatus(QBApp, dialogId, messageId, recieverId);

      // Updating the message read status in the list of all loaded messages
      chatsMessagesState.setStateByParams({ dialogId }, (prevState) => {
        if (!prevState) return undefined;
        return {
          ...prevState,
          data: prevState.data?.map((page) => {
            return page.map((message) => {
              const read_ids =
                message._id === messageId
                  ? [...message.read_ids, senderId]
                  : [...message.read_ids];
              return {
                ...message,
                all_read: read_ids.length === 2 ? 1 : 0,
                read_ids,
              };
            });
          }),
        };
      });

      // Updating the unread messages of the list of all dialogs
      chatsListState.setStateByParams(undefined, (prevState) => {
        if (!prevState) return prevState;
        return {
          ...prevState,
          data: prevState.data?.map((dialog) => {
            if (dialog._id === dialogId) {
              return {
                ...dialog,
                unread_messages_count: 0,
              };
            }
            return dialog;
          }),
        };
      });
    },
  });

  return {
    sendReadMessageStatus: state.mutate,
    error: state.error,
    data: state.data,
    status: state.status,
  };
};
