import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { call } from "../../api/axios";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

/**
 * @typedef {Object} Attachment
 * @property {string} imgUrl
 * @property {string} pubicId
 *
 * @typedef {Object} Category
 * @property {string} _id
 * @property {string} name
 *
 * @typedef {Object} SellerStats
 * @property {'Rookie'} sellerLevel
 * @property {number} completedOrders
 *
 * @typedef {Object} User
 * @property {string} _id
 * @property {string} username
 * @property {boolean} isOnline
 * @property {Attachment} profileImg
 * @property {SellerStats} sellerStats
 *
 * @typedef {Object} Gig
 * @property {string} _id
 * @property {'online'} workType
 * @property {number} workDaysTo
 * @property {number} workDaysFrom
 * @property {string} updatedAt
 * @property {string} title
 * @property {'active'} status
 * @property {string} description
 * @property {string} priceFrom
 * @property {string} priceTo
 * @property {Category} category
 * @property {Category} subcategory
 * @property {string} createdAt
 * @property {User} createdBy
 * @property {number} rating
 * @property {number} completedOrderscount
 * @property {string} requirements
 * @property {Array} attachments
 * @property {number} latitude
 * @property {number} longitude
 * @property {string} address
 *
 *
 * @typedef {object} GigDetail
 * @property {string} _id
 * @property {string} title
 * @property {object} category
 * @property {string} category._id
 * @property {string} category.name
 * @property {object} subcategory
 * @property {string} subcategory._id
 * @property {string} subcategory.name
 * @property {string} workType
 * @property {string} description
 * @property {number} priceFrom
 * @property {number} priceTo
 * @property {number} workDaysFrom
 * @property {number} workDaysTo
 * @property {string} requirements
 * @property {number} latitude
 * @property {number} longitude
 * @property {object[]} attachments
 * @property {string} attachments[].imgUrl
 * @property {string} attachments[].publicId
 * @property {object} createdBy
 * @property {object} createdBy.profileImg
 * @property {string} createdBy.profileImg.imgUrl
 * @property {string} createdBy.profileImg.publicId
 * @property {string} createdBy._id
 * @property {string} createdBy.username
 * @property {boolean} createdBy.isOnline
 * @property {string} createdBy.qbId
 * @property {string} createdBy.createdAt
 * @property {object} createdBy.sellerStats
 * @property {string} createdBy.sellerStats._id
 * @property {number} createdBy.sellerStats.averageResponseTimeHours
 * @property {string} createdBy.sellerStats.description
 * @property {string} createdBy.sellerStats.sellerLevel
 * @property {number} createdBy.sellerStats.rating
 * @property {boolean} createdBy.sellerStats.isProVerified
 * @property {number} createdBy.sellerStats.completedOrders
 * @property {string[]} createdBy.sellerStats.languages
 * @property {number} rating
 * @property {string} status
 * @property {string[]} searchTags
 * @property {number} completedOrdersCount
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} __v
 */

export const getGigs = createAsyncThunk(
  "gigs/getGigs",
  /**
   * @returns {Promise<Array<Gig>>}
   */
  async (data, { rejectWithValue, signal }) => {
    try {
      const response = await call("/gigs", { signal });
      return response.data.gigs;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const filterGigs = createAsyncThunk(
  "gigs/filterGigs",
  /**
   * @param {object} data
   * @param {string} data.category
   * @param {string} data.searchText
   * @param {string} data.sortBy
   * @param {string} data.level
   * @param {string} data.minPrice
   * @param {string} data.maxPrice
   * @param {string} data.subcategory
   * @param {string} data.userId
   *
   * @returns {Promise<Array<Gig>>}
   */
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const {
        category,
        searchText,
        sortBy,
        level,
        minPrice,
        maxPrice,
        subcategory,
        userId,
      } = data;

      const filters = {
        category: category || "",
        searchText: searchText || "",
        sortBy: sortBy || "",
        sellerLevel: level || "",
        minPrice: minPrice || "",
        maxPrice: maxPrice || "",
        subcategory: subcategory || "",
        userId: userId || "",
      };

      let queryString = "";
      for (let key in filters) {
        queryString = queryString + `${key}=${filters[key]}&`;
      }

      const response = await axios.get(`/gigs/query?${queryString}`);
      return response.data.gigs;
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  },
);

export const getGigDetail = createAsyncThunk(
  "gigs/getGigDetail",
  /**
   * @param {object} data
   * @param {string} data.gigId
   * @returns {Promise<GigDetail>}
   */
  async (data, { rejectWithValue }) => {
    try {
      const { gigId } = data;
      const response = await axios.get(`/gigs/${gigId}/details`);
      return response.data.gig;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createGig = createAsyncThunk(
  "gigs/createGig",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data, navigate } = payload;
      const {
        // description,
        // requirements,
        category,
        subcategory,
        // workDaysFrom,
        // workDaysTo,
        // title,
        location,
        // priceTo,
        // priceFrom,
        attachments,
        // workType,
      } = data;
      const categoryId = category._id;
      const subcategoryId = subcategory._id;
      const { latitude, longitude } = location;

      const formData = new FormData();
      for (let key in data) {
        if (key === "attachments") {
          for (let i = 0; i < attachments.length; i++) {
            formData.append("attachments", attachments[i]);
          }
        } else if (key === "location") {
          formData.append("latitude", latitude);
          formData.append("longitude", longitude);
        } else if (key === "category") {
          formData.append("category", categoryId);
        } else if (key === "subcategory") {
          formData.append("subcategory", subcategoryId);
        } else {
          formData.append(key, data[key]);
        }
      }

      const response = await axios.post("/gigs", formData);
      dispatch(openSuccessMessage(response.data.message));
      navigate("/");
      return response.data.message;
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  },
);

export const updateGig = createAsyncThunk(
  "gigs/updateGig",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data, navigate } = payload;
      const {
        _id,
        description,
        requirements,
        category,
        subcategory,
        workDaysFrom,
        workDaysTo,
        title,
        location,
        priceTo,
        priceFrom,
        attachments,
        workType,
      } = data;
      const categoryId = category._id;
      const subcategoryId = subcategory._id;
      const { latitude, longitude } = location;

      let images = [];
      for (let i = 0; i < attachments.length; i++) {
        const formData = new FormData();
        if (!("imgUrl" in attachments[i])) {
          formData.append("attachment", attachments[i]);
          const response = await axios.post("attachments/one", formData);
          images.push({
            publicId: response.data.attachment.publicId,
            imgUrl: response.data.attachment.url,
          });
        }
        if ("imgUrl" in data.attachments[i]) {
          images.push(data.attachments[i]);
        }
      }
      const gigData = {
        _id,
        description,
        requirements,
        category: categoryId,
        subcategory: subcategoryId,
        workDaysFrom,
        workDaysTo,
        title,
        latitude: latitude,
        longitude: longitude,
        priceTo,
        priceFrom,
        attachments: images,
        workType,
      };

      const response = await axios.put("/gigs", gigData);

      navigate("/manage-gigs");
      dispatch(openSuccessMessage(response.data.message));
      return response.data.message;
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error);
    }
  },
);

export const getMyGigs = createAsyncThunk(
  "gigs/getMyGigs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/gigs/my");
      return response.data.gigs;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMyGig = createAsyncThunk(
  "gigs/deleteMyGig",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { _id } = data;
      const response = await axios.delete(`/gigs/${_id}`);
      dispatch(openSuccessMessage(response.data.message));
      return {
        message: response.data.message,
        _id: _id,
      };
    } catch (error) {
      dispatch(openErrorMessage(error.response.data.error));
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const gigsByPaginate = createAsyncThunk(
  "gigs/gigs-paginate",
  /**
   * @param {object} data
   * @param {number} data.page
   * @param {number} data.limit
   * @param {string} data.category
   * @param {string} data.searchText
   * @param {string} data.sortBy
   * @param {string} data.level
   * @param {string} data.minPrice
   * @param {string} data.maxPrice
   * @param {string} data.subcategory
   * @param {string} data.userId
   *
   * @returns {Promise<{gigs: Array<Gig>, page:number, limit:number}>}
   */
  async (data = {}, { rejectWithValue, dispatch }) => {
    try {
      const {
        page,
        limit,
        category,
        searchText,
        sortBy,
        level,
        minPrice,
        maxPrice,
        subcategory,
        userId,
      } = data;
      const filters = {
        page: page || 1,
        limit: limit || 15,
        category: category || "",
        searchText: searchText || "",
        sortBy: sortBy || "",
        sellerLevel: level || "",
        minPrice: minPrice || "",
        maxPrice: maxPrice || "",
        subcategory: subcategory || "",
        userId: userId || "",
      };
      let queryString = "";
      for (let key in filters) {
        queryString = queryString + `${key}=${filters[key]}&`;
      }
      const response = await axios.get(`/gigs/query/results?${queryString}`);
      return {
        page: filters.page,
        limit: filters.limit,
        gigs: response.data.gigs,
      };
    } catch (error) {
      const msg = error?.message;
      dispatch(openErrorMessage(msg));
      return rejectWithValue(error);
    }
  },
);
