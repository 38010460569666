import React from "react";
import Header from "../../components/common/Header";
import {InboxSection} from "../../features/quickblox";

const Inbox = () => {
  return (
    <>
      <Header />
      <div className="border-top-50 border d-flex overflow-auto" style = {{height:"calc(100vh - 70px)"}}>
        <InboxSection />
      </div>
    </>
  );
};

export default Inbox;
