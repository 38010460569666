import { createSlice } from "@reduxjs/toolkit";
import {
  getCustomPostedOffers,
  getPostedOffers,
  getCustomRecievedOffers,
  getRecievedOffers,
  rejectRecievedOffer,
  cancelPostedOffer,
  createJobOffer,
} from "../thunks/offersThunk";

const initialState = {
  getPostedOffers: {
    data: undefined,
    loading: false,
    error: null,
  },
  getCustomPostedOffers: {
    data: undefined,
    loading: false,
    error: null,
  },
  getRecievedOffers: {
    data: undefined,
    loading: false,
    error: null,
  },
  getCustomRecievedOffers: {
    data: undefined,
    loading: false,
    error: null,
  },
  createJobOfferMutation: {
    loading: false,
    error: null,
    data: undefined,
  },
  rejectRecievedOfferMutation:{
    loading: false,
    error: null,
    data: undefined,
  },
  cancelPostedOfferMutation:{
    loading: false,
    error: null,
    data: undefined,
  },
};

const handlePending_ = (key) => (state, action) => {
  state[key].loading = true;
  state[key].error = null;
  state[key].data = undefined;
};
const handleFulfilled_ = (key) => (state, action) => {
  state[key].loading = false;
  state[key].error = null;
  state[key].data = action.payload;
};
const handleRejected_ = (key) => (state, action) => {
  state[key].loading = false;
  state[key].error = action.payload;
  state[key].data = undefined;
};

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cancelPostedOffer.pending, handlePending_("cancelPostedOfferMutation"))
      .addCase(cancelPostedOffer.fulfilled, (state,action) => {
        handleFulfilled_("cancelPostedOfferMutation")(state,action);
        state.getPostedOffers.data = state.getPostedOffers.data?.map(
          (offer) => offer._id === action.payload._id ? ({...offer, status: "rejected"}): offer
        );
        state.getCustomPostedOffers.data = state.getCustomPostedOffers.data?.map(
          (offer) => offer._id === action.payload._id ? ({...offer, status: "rejected"}): offer
        );
      })
      .addCase(cancelPostedOffer.rejected, handleRejected_("cancelPostedOfferMutation"))

      .addCase(rejectRecievedOffer.pending, handlePending_("rejectRecievedOfferMutation"))
      .addCase(rejectRecievedOffer.fulfilled, (state,action) => {
        handleFulfilled_("rejectRecievedOfferMutation")(state,action);
        console.log(action)
        state.getRecievedOffers.data = state.getRecievedOffers.data?.map(
          (offer) => offer._id === action.payload._id ? ({...offer, status: "rejected"}): offer
        );
        state.getCustomRecievedOffers.data = state.getCustomRecievedOffers.data?.map(
          (offer) => offer._id === action.payload._id ? ({...offer, status: "rejected"}): offer
        );
      })
      .addCase(rejectRecievedOffer.rejected,handleRejected_("rejectRecievedOfferMutation"))


      .addCase(createJobOffer.pending, handlePending_("createJobOfferMutation"))
      .addCase(
        createJobOffer.fulfilled,
        handleFulfilled_("createJobOfferMutation")
      )
      .addCase(
        createJobOffer.rejected,
        handleRejected_("createJobOfferMutation")
      )

      .addCase(getPostedOffers.pending, handlePending_("getPostedOffers"))
      .addCase(getPostedOffers.fulfilled, handleFulfilled_("getPostedOffers"))
      .addCase(getPostedOffers.rejected, handleRejected_("getPostedOffers"))

      .addCase(
        getCustomPostedOffers.pending,
        handlePending_("getCustomPostedOffers")
      )
      .addCase(
        getCustomPostedOffers.fulfilled,
        handleFulfilled_("getCustomPostedOffers")
      )
      .addCase(
        getCustomPostedOffers.rejected,
        handleRejected_("getCustomPostedOffers")
      )

      .addCase(getRecievedOffers.pending, handlePending_("getRecievedOffers"))
      .addCase(
        getRecievedOffers.fulfilled,
        handleFulfilled_("getRecievedOffers")
      )
      .addCase(
        getRecievedOffers.rejected,
        handleRejected_("getRecievedOffers")
      )

      .addCase(
        getCustomRecievedOffers.pending,
        handlePending_("getCustomRecievedOffers")
      )
      .addCase(
        getCustomRecievedOffers.fulfilled,
        handleFulfilled_("getCustomRecievedOffers")
      )
      .addCase(
        getCustomRecievedOffers.rejected,
        handleRejected_("getCustomRecievedOffers")
      )

  },
});

export default offersSlice.reducer;
