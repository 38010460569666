import { createSlice } from "@reduxjs/toolkit";
import {
  signUp,
  logOut,
  checkOTP,
  signIn,
  verifyForgetPassword,
  changePassword,
  googleSignIn,
} from "../thunks";

const handlePending = (state) => {
  state.loading = true;
  state.error = null;
  state.success = null;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const handleSignInRejected = (state, action) => {
  state.loading = false;
  state.signInError = action.payload;
};
const handleSignUpRejected = (state, action) => {
  state.loading = false;
  state.signUpError = action.payload;
};
const handleSignUpFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.user = action.payload;
  state.verifyOTP = true;
};
const handleLogOutFulfilled = (state) => {
  state.loading = false;
  state.error = null;
  state.user = {};
  state.isLogin = false;
};
const handlecheckOTPFulfilled = (state) => {
  state.loading = false;
  state.isLogin = true;
};
const handleSignInFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  if (action.payload.accountStatus !== "pending") {
    state.user = action.payload;
    state.isLogin = true;
  } else {
    state.verifyEmail = action.payload;
  }
};
const handleVerifyFPFulfilled = (state, action) => {
  state.loading = false;
  state.verifyFP = action.payload;
};
const handleVerifyFPRejected = (state, action) => {
  state.loading = false;
  state.forgetPasswordError = action.payload;
};

const handleChangePasswordFulfilled = (state, action) => {
  state.loading = false;
  state.success = action.payload;
};
const handleChangePasswordRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};

const initialState = {
  loading: false,
  signUpError: "",
  signInError: "",
  forgetPasswordError: null,
  success: null,
  user: null,
  isLogin: false,
  isLoading: true,
  verifyOTP: false,
  error: null,
  verifyFP: null,
  sectionFP: 1,
  sectionSignUp: 1,
  sectionSignIn: 1,
  verifyEmail: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signInSuccess: (state, action) => {
      state.isLogin = true;
      state.user = action.payload;
      state.isLoading = false; 
    },
    updateUser: (state, action) => {
      localStorage.setItem("auth", JSON.stringify(action.payload));
      state.user = action.payload;
    },
    setSectionFP: (state, action) => {
      state.sectionFP = action.payload;
    },
    setSectionSignUp: (state, action) => {
      state.sectionSignUp = action.payload;
    },
    setSectionSignIn: (state, action) => {
      state.sectionSignIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, handlePending)
      .addCase(signUp.fulfilled, handleSignUpFulfilled)
      .addCase(signUp.rejected, handleSignUpRejected)
      .addCase(checkOTP.pending, handlePending)
      .addCase(checkOTP.fulfilled, handlecheckOTPFulfilled)
      .addCase(checkOTP.rejected, handleRejected)
      .addCase(signIn.pending, handlePending)
      .addCase(signIn.fulfilled, handleSignInFulfilled)
      .addCase(signIn.rejected, handleSignInRejected)
      .addCase(logOut.pending, handlePending)
      .addCase(logOut.fulfilled, handleLogOutFulfilled)
      .addCase(logOut.rejected, handleLogOutFulfilled)
      .addCase(verifyForgetPassword.pending, handlePending)
      .addCase(verifyForgetPassword.fulfilled, handleVerifyFPFulfilled)
      .addCase(verifyForgetPassword.rejected, handleVerifyFPRejected)
      .addCase(changePassword.pending, handlePending)
      .addCase(changePassword.fulfilled, handleChangePasswordFulfilled)
      .addCase(changePassword.rejected, handleChangePasswordRejected)
      .addCase(googleSignIn.pending, handlePending)
      .addCase(googleSignIn.fulfilled, handleChangePasswordFulfilled)
      .addCase(googleSignIn.rejected, handleChangePasswordRejected);
  },
});

export const {
  signInSuccess,
  updateUser,
  setSectionFP,
  setSectionSignUp,
  setSectionSignIn,
} = authSlice.actions;
export default authSlice.reducer;
