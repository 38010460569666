import React, { useRef } from "react";

import { TiPlus } from "react-icons/ti";
import { RiDeleteBin6Line } from "react-icons/ri";

const PicturePicker = ({ setImage, index, image }) => {
  const fileRef = useRef(null);
  return (
    <div>
      <input
        // name={`file${index}`}
        // id={`file${index}`}
        hidden
        ref={fileRef}
        accept="image/*"
        type="file"
        onChange={(e) => {
          setImage(e.target.files[0], index);
        }}
      />
      <div
        onClick={() => {
          fileRef.current.click();
        }}
      >
        {image ? (
          <div style={{ position: "relative" }}>
            <img
              className="file-img"
              src={
                typeof image == "string" ? image : URL.createObjectURL(image)
              }
              alt="Preview"
              width="220"
              height="160"
            />
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                position: "absolute",
                top: "30px",
                right: "10px",
                backgroundColor: "rgba(255,255,255,0.7)",
                padding: "4px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setImage(undefined, index);
              }}
            >
              <RiDeleteBin6Line />
            </div>
          </div>
        ) : (
          <div className="add-box">
            <div className="plus">
              <TiPlus className="plus-icon" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PicturePicker;
