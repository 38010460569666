import React from "react";
import AvatarRatingCard from "../common/AvatarRatingCard";
import { Badge } from "reactstrap";
/**
 * @typedef {import("../../types/server").UserProfileData} UserData
 *
 */
const UserInfo = (/**@type {UserData} */ props) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <AvatarRatingCard
        profileImg={props.profileImg.imgUrl}
        username={props.username}
        rating={props.rating}
        userId={props._id}
      />
      {props.sellerStats?.isProVerified ? (
        <Badge className="mb-1">{"Pro"}</Badge>
      ) : (
        <p className="text-md text-muted mb-1">
          {props.sellerStats?.sellerLevel + " Seller"}
        </p>
      )}
    </div>
  );
};
export const UserInfoPlaceholder = () => {
  return (
    <div className="d-flex gap-2">
      <div
        className="placeholder-glow rounded-circle"
        style={{ height: 50, width: 50 }}
      >
        <span className="placeholder placeholder-grey w-100 h-100 rounded-circle"></span>
      </div>
      <div className="d-flex flex-column flex-grow-1">
        <p>
          <span className="placeholder placeholder-grey w-25"></span>
        </p>
        <p className="d-block" style={{ height: 8 }}>
          <span
            className="placeholder placeholder-grey w-25 h-100"
            style={{ minHeight: "auto" }}
          ></span>
        </p>
      </div>
    </div>
  );
};
export default UserInfo;
