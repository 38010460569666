const Content = () => (
  <>
    <h1>
      Payment Terms <br />
    </h1>
    <p>
      Updated on January 31, 2024 <br /> <br />
      The payment terms outlined below serve as a framework for conducting
      financial <br />
      transactions on the platform. <br />
      GigFalcon reserves the right to modify, update, or adjust the payment
      terms as deemed <br />
      necessary to ensure the efficiency and integrity of the payment process.{" "}
      <br />
      Sellers and buyers are encouraged to review the specific payment terms
      associated <br />
      with each transaction or service, as they may vary based on the nature of
      the job, <br />
      project, or purchase. <br />
      While GigFalcon endeavors to maintain clear and consistent payment terms,
      users are <br />
      advised to remain aware of any updates or revisions to the payment terms
      that may be <br />
      communicated through official channels. <br />
      Please thoroughly review the payment terms and conditions outlined below
      before <br />
      engaging in any financial transactions on this platform. By making or
      receiving <br />
      payments through our services, users acknowledge that they have read and
      understood <br />
      the payment terms and agree to abide by the conditions set forth herein.
      <br /> <br />
      <h4>Receiving and Withdrawing Funds</h4>{" "}
    </p>
    <p>
      By using GigFalcon platform, you agree to authorize GigFalcon to receive
      and <br />
      send payments on your behalf for the services performed. <br />
    </p>
    <p>
      <br />
      GigFalcon will charge a 5% order fee to all buyers for using its platform.{" "}
      <br />
    </p>
    <p>
      <br />
      GigFalcon will charge a 15% order fee to all sellers for using its
      platform. <br />
    </p>
    <p>
      <br />
      Sellers are responsible for remitting any applicable taxes, including but
      not <br />
    </p>
    <p>
      limited to GST, VAT, or income taxes. <br />
    </p>
    <p>
      GigFalcon utilizes different payment gateways to collect funds from buyers
      or to <br />
      transfer funds to sellers and also holding payments when necessary. <br />
    </p>
    <p>
      <br />
      GigFalcon will not be responsible or liable for any payment arrangements
      made <br />
    </p>
    <p>
      outside the platform. <br />
    </p>
    <p>
      In order to withdraw money from GigFalcon, sellers are required to link
      their bank <br />
      account through the platform. <br />
    </p>
    <p>
      <br />
      GigFalcon will hold money for a period of 7 to 14 days after order is
      completed <br />
    </p>
    <p>
      and accepted by buyer. However, established sellers may have shorter
      holding <br />
      time frames based on their track record and performance.{" "}
    </p>
    <p>
      {" "}
      <br />
      Once a withdrawal is initiated, it cannot be reversed. It's important for
      sellers to <br />
    </p>
    <p>
      consider their withdrawal requests carefully before initiating the
      process. <br /> <br />
      <h4>Disputes</h4>
    </p>
    <p>
      In the event of a dispute between the buyer and the seller, both parties
      are <br />
      encouraged to first attempt to resolve the issue amicably. <br />
    </p>
    <p>
      If a resolution cannot be reached, either party may escalate the matter to{" "}
      <br />
      GigFalcon for mediation or intervention by reaching out to <br />
      support@gigfalcon.com. <br />
    </p>
    <p>
      GigFalcon will review the dispute and make a fair determination based on
      the <br />
      evidence provided by both parties.
    </p>
    <br /> <br />
    <p>
      <h4>Cancellation</h4>
    </p>
    <p>
      In the event that a cancellation is necessary, sellers and buyers are
      required to <br />
      communicate promptly. <br />
    </p>
    <p>
      There is 24-hour notice is required to avoid any fees or penalties. <br />
      GigFalcon reserves the right to intervene in cancellations that do not
      adhere to <br />
    </p>
    <p>
      the agreed-upon terms and may take appropriate action to resolve any
      disputes <br />
      arising from cancellations.
    </p>
    <br /> <br />
    <p>
      <h4>
        Refund Policy
      </h4>
    </p>
    <p>
      GigFalcon recognizes the importance of customer satisfaction and aims to{" "}
      <br />
      provide a fair and transparent refund process. <br />
    </p>
    <p>
      If Buyers are not satisfied, they will have 5 days to file a claim and
      request for a <br />
      refund for the service received. <br />
    </p>
    <p>
      Refund requests will be reviewed and processed in accordance with the
      specific <br />
      circumstances of each case. <br />
    </p>
    <p>
      GigFalcon reserves the right to approve or deny refund requests based on
      the <br />
      merit of the case and any relevant evidence provided by the buyer and
      seller. <br />
    </p>
    <br /> <br />
    <p>
      <h4>
        Refund Process
      </h4>
    </p>
    <p>
      Upon approval of a refund request, GigFalcon will initiate the refund to
      the <br />
      buyer's original payment method. <br />
    </p>
    <p>
      Sellers will be notified of the refund and any applicable fees or
      deductions as per <br />
      the platform's refund policy. <br />
    </p>
  </>
);
export default Content;
