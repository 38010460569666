import React, { useEffect } from "react";
import ReviewPlaceholder from "../../../components/common/Placeholders/ReviewPlaceholder";
import GigReview from "../../../components/Gigs/GigReview";
import { useDispatch, useSelector } from "react-redux";
import { getReviews } from "../../../store/thunks";
/**
 * @typedef {Object} Props
 * @property {string} gigId
 * @property {string | undefined} className
 */
const GigReviews = (/** @type {Props} */ { gigId, className = "" }) => {
  const dispatch = useDispatch();
  const { data: userReviews, loading: userReviewsLoading } = useSelector(
    (state) => state.reviews.getReviews
  );
  useEffect(() => {
    dispatch(getReviews({ gig: gigId }));
  }, [dispatch, gigId]);

  return (
    <div className={className}>
      <h3 className="mb-3 review-heading">Reviews:</h3>
      {userReviewsLoading ? (
        <ReviewPlaceholder placeholders={[1, 2, 3]} />
      ) : userReviews?.length > 0 ? (
        userReviews?.map((review, idx) => {
          return <GigReview key={idx} {...review} />;
        })
      ) : (
        <p>No Review to show</p>
      )}
    </div>
  );
};

export default GigReviews;
