import { connectChat } from "./api";
import QuickbloxSessionHandlers from "./QuickbloxSessionHandlers";

export const QuickbloxSessionHandlersWithChat = ({ QBApp, ...props }) => {
  return QuickbloxSessionHandlers({
    QBApp,
    onSessionCreated: async (session) => {
      await connectChat(QBApp, session);
      props.onSessionCreated && (await props.onSessionCreated(session));
    },
    onSessionDestroyed: async (session) => {
      QBApp.chat.disconnect();
      props.onSessionDestroyed && (await props.onSessionDestroyed(session));
    },
    onSessionInitalized: async (session) => {
      if (session) {
        await connectChat(QBApp, session);
      }
      props.onSessionInitalized && (await props.onSessionInitalized(session));
    },
  });
};
