import { createSlice } from "@reduxjs/toolkit";
import {
  getGigs,
  filterGigs,
  getGigDetail,
  createGig,
  getMyGigs,
  deleteMyGig,
  updateGig,
  gigsByPaginate,
} from "../thunks/gigsThunk";

const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};
const handleGetGigs = (state) => {
  state.loading = true;
  state.error = "";
  state.gigs = [];
};
const handleGetGigDetail = (state) => {
  state.loading = true;
  state.error = "";
  state.gigDetail = undefined;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};
const handleGetGigsFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.gigs = action.payload;
};
const handlefilterGigsFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.gigs = action.payload;
};
const handleGigDetailFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.gigDetail = action.payload;
};
const handleCreateGigFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.success = action.payload;
};
const handleDeleteGigFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.gigs = state.gigs.filter((gig) => gig._id !== action.payload._id);
};
const handleUpadteGigFulfilled = (state, action) => {
  state.loading = false;
  state.error = null;
  state.success = action.payload;
};

const handlePending_ = (key) => (state, action) => {
  state[key].loading = true;
  state[key].error = null;
  state[key].data = undefined;
};
const handleFulfilled_ = (key) => (state, action) => {
  state[key].loading = false;
  state[key].error = null;
  state[key].data = action.payload;
};
const handleRejected_ = (key) => (state, action) => {
  state[key].loading = false;
  state[key].error = action.payload;
};

const handleGigsByPaginatePending = (state, action) => {
  state.gigsByPaginate.loading = true;
  state.gigsByPaginate.error = "";
};
const handleGigsByPaginateFulfilled = (state, action) => {
  state.gigsByPaginate.loading = false;
  state.gigsByPaginate.error = "";
  state.gigsByPaginate.page = action.payload.page;
  state.gigsByPaginate.limit = action.payload.limit;
  state.gigsByPaginate.hasMore = !!action.payload.gigs.length;
  if (action.payload.page === 1) {
    state.gigsByPaginate.data = action.payload.gigs;
  } else {
    state.gigsByPaginate.data = [
      ...state.gigsByPaginate.data,
      ...action.payload.gigs,
    ];
  }
};
const handleGigsByPaginateRejected = (state, action) => {
  state.gigsByPaginate.loading = false;
  state.gigsByPaginate.error = action.payload;
};

const initialState = {
  loading: false,
  error: "",
  success: null,
  gigs: undefined,
  gigsByPaginate: {
    loading: false,
    error: "",
    page: 1,
    limit: 10,
    hasMore: true,
    /**
     * @type {import("../thunks/gigsThunk").GigDetail[] | undefined}
     */
    data: undefined,
  },
  /**
   * @type {import("../thunks/gigsThunk").GigDetail | undefined}
   */
  gigDetail: undefined,
  favGigs: [],
  getMyGigs: {
    loading: false,
    error: "",
    /**
     * @type {import("../thunks/gigsThunk").GigDetail[] | undefined}
     */
    data: undefined,
  },
};

const gigsSlice = createSlice({
  name: "gigs",
  initialState,
  reducers: {
    setGigsList: (state, action) => {
      state.gigs = state.gigs.filter((gig) => gig._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGigs.pending, handlePending)
      .addCase(getGigs.fulfilled, handleGetGigsFulfilled)
      .addCase(getGigs.rejected, handleRejected)
      .addCase(filterGigs.pending, handleGetGigs)
      .addCase(filterGigs.fulfilled, handlefilterGigsFulfilled)
      .addCase(filterGigs.rejected, handleRejected)
      .addCase(getGigDetail.pending, handleGetGigDetail)
      .addCase(getGigDetail.fulfilled, handleGigDetailFulfilled)
      .addCase(getGigDetail.rejected, handleRejected)
      .addCase(createGig.pending, handlePending)
      .addCase(createGig.fulfilled, handleCreateGigFulfilled)
      .addCase(createGig.rejected, handleRejected)

      .addCase(deleteMyGig.pending, handlePending)
      .addCase(deleteMyGig.fulfilled, handleDeleteGigFulfilled)
      .addCase(deleteMyGig.rejected, handleRejected)
      .addCase(updateGig.pending, handlePending)
      .addCase(updateGig.fulfilled, handleUpadteGigFulfilled)
      .addCase(updateGig.rejected, handleRejected)
      // Trying to convert everything to the new format
      .addCase(getMyGigs.pending, handlePending_("getMyGigs"))
      .addCase(getMyGigs.fulfilled, handleFulfilled_("getMyGigs"))
      .addCase(getMyGigs.rejected, handleRejected_("getMyGigs"))

      // Get Gigs By Paginate
      .addCase(gigsByPaginate.pending, handleGigsByPaginatePending)
      .addCase(gigsByPaginate.fulfilled, handleGigsByPaginateFulfilled)
      .addCase(gigsByPaginate.rejected, handleGigsByPaginateRejected);
  },
});

export const { setGigsList } = gigsSlice.actions;
export default gigsSlice.reducer;
