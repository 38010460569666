import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  z-index: 10;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .dropdown-trigger {
    font-size: 17px;
    color: ${({ colors }) => colors.color.primary};
    box-shadow: 0 0 5px 2px rgb(233 229 229 / 90%);
    padding: 5px;
    border-radius: 20px;
    margin: 5px 0;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
  }
  .dropdown-item:hover {
    color: ${({ colors }) => colors.color.primary};
    background-color: ${({ colors }) => colors.color.lightFalcon};
  }

  // Swiper Navigation Styles
  :root {
    --swiper-navigation-size: 24px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: 2px solid rgb(15, 211, 187);
    color: rgb(15, 211, 187);
    background-color:white;
    font-weight: 800;
    border-radius: 50%;
    margin-bottom:8px;
    padding:16px;
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-button-next.swiper-button-hidden,
  .swiper-button-prev.swiper-button-hidden {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-navigation-disabled .swiper-button-next,
  .swiper-navigation-disabled .swiper-button-prev {
    display: none !important;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 16px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 10px);
    right: auto;
  }
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
    margin-right:2px;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 10px);
    left: auto;
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
    margin-left:2px;
  }
  .swiper-button-lock {
    display: none;
  }
`;
