// Production
// // Using Shahid-Google-Account
// export const QBConfig = {
//   credentials: {
//     appId: 102034,
//     authKey: "ak_H5RC37ecgpcDYRv",
//     authSecret: "as_6UsCW97E9Z5RQs3",
//     accountKey: "ack_WXFHfb6DdBmpKEFsGiAj",
//   },
//   appConfig: {
//     chatProtocol: {
//       Active: 2,
//     },
//     debug: false,
//     endpoints: {
//       apiEndpoint: "https://api.quickblox.com",
//       chatEndpoint: "chat.quickblox.com",
//     },
//     streamManagement: {
//       Enable: true,
//     },
//   },
//   // appConfig: {
//   //   chatProtocol: {
//   //     active: 2,
//   //   },
//   //   streamManagement: {
//   //     enable: true,
//   //   },
//   //   debug: {
//   //     mode: 0,
//   //     file: null,
//   //   },
//   // },
// };
// Development
// Using Chirp-Google-Account
export const QBConfig = {
  credentials: {
    appId: process.env.REACT_APP_QB_APP_ID,
    authKey: process.env.REACT_APP_QB_AUTH_KEY,
    authSecret: process.env.REACT_APP_QB_AUTH_SECRET,
    accountKey:process.env.REACT_APP_QB_ACCOUNT_KEY,
  },
  appConfig: {
    chatProtocol: {
      Active: 2,
    },
    debug: false,
    endpoints: {
      apiEndpoint: "https://api.quickblox.com",
      chatEndpoint: "chat.quickblox.com",
    },
    streamManagement: {
      Enable: true,
    },
  },
  // appConfig: {
  //   chatProtocol: {
  //     active: 2,
  //   },
  //   streamManagement: {
  //     enable: true,
  //   },
  //   debug: {
  //     mode: 0,
  //     file: null,
  //   },
  // },
};
export default QBConfig