import React, { useEffect, useState } from "react";

import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import { colors } from "../../utils/Colors";

import { useSelector, useDispatch } from "react-redux";
import { getBuyerOrders, getSellerOrders } from "../../store/thunks";
import styled from "styled-components";
import OrdersList from "../../components/Orders/OrdersList";

/**
 * 
 * @typedef Props
 * @property {('Sent' | 'Received')} ordersType - This is a string value to show the type of orders
 */
const ManageOrders = (/** @type {Props} **/{ordersType = 'Sent'}) => {
  const [section, setSection] = useState(1);
  const dispatch = useDispatch();
  const { orders = [], loading } = useSelector((state) => state.orders);
  
  useEffect(() => {
    if (ordersType === 'Received') {
      dispatch(getSellerOrders());
    } else {
      dispatch(getBuyerOrders());
    }
    
  }, [dispatch, ordersType]);


  return (
    <div>
      <Header />
      <ManageOrdersStyledDiv colors={colors}>
        <div className="orders-page container">
          <h3 className="mb-4 ">
            Manage <span className="falcon-clr">{ordersType}</span> Orders
          </h3>
          <div className="status-bar">
            <div
              className={
                section === 1
                  ? "active-order status-bar-sphere"
                  : section === 2
                  ? "revision-order status-bar-sphere"
                  : section === 3 && "completed-order status-bar-sphere"
              }
            ></div>
          </div>
          <div className="mb-3">
            <ul className="manage-status-list">
              <li className="manage-status" onClick={() => setSection(1)}>
                Active
              </li>
              <li className="manage-status" onClick={() => setSection(2)}>
                Revision
              </li>
              <li className="manage-status" onClick={() => setSection(3)}>
                Completed
              </li>
            </ul>
          </div>
          {section === 1 ? (
            <OrdersList
              orders={orders.filter((order) => order?.status === "active")}
              loading={loading}
              ordersType={ordersType}
            />
          ) : section === 2 ? (
            <OrdersList
              orders={orders.filter((order) => order?.status === "revision")}
              loading={loading}
              ordersType={ordersType}
            />
          ) : (
            section === 3 && (
              <OrdersList
                orders={orders.filter((order) => order?.status === "completed")}
                loading={loading}
                listType="completed"
                ordersType={ordersType}
              />
            )
          )}
        </div>
      </ManageOrdersStyledDiv>
      <Footer />
    </div>
  );
};


const ManageOrdersStyledDiv = styled.div`
  .orders-page {
    min-height: 50vh;
    padding: 10px;
    margin: 40px auto;
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  h3,
  h3 span {
    font-family: "NN_Bold";
  }
  .manage-status-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-left: 0;
    list-style: none;
  }
  .manage-status {
    color: ${({ colors }) => colors.color.status};
    font-weight: 800;
    font-size: 10px;
    cursor: pointer;
  }
  .completed-orders-field {
    min-width: 300px;
  }
  .text-small {
    font-size: 12px !important;
  }
  .status-bar {
    padding: 1px;
    background-color: ${({ colors }) => colors.color.status};
    position: relative;
    margin-bottom: 10px;
  }
  .status-bar-sphere {
    width: 40px;
    height: 5px;
    background-color: ${({ colors }) => colors.color.secondary};
    position: absolute;
    top: -1.5px;
    border-radius: 2px;
  }
  .active-order {
    left: 0;
  }
  .revision-order {
    left: 43px;
  }
  .completed-order {
    left: 100px;
  }
  .active-table {
    padding: 0px 0px;
    border-radius: 10px;
    overflow-x: scroll !important;
  }

  p {
    font-size: 12px !important;
  }
  .order {
    border-radius: 10px;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .order:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .order-wrapper {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px !important;
    border-radius: 10px;
    position: relative;
  }
  .status {
    background-color: ${({ colors }) => colors.color.lightFalcon};
    border-radius: 7px;
    position: absolute;
    right: 7px;
    top: 7px;
    padding: 2px 5px;
  }
  .status p {
    font-size: 12px !important;
    color: ${({ colors }) => colors.color.secondary};
  }
  .status-completed {
    background-color: rgba(122, 34, 133, 0.5);
    color: ${({ colors }) => colors.color.white};
    border-radius: 7px;
    position: absolute;
    right: 7px;
    top: 7px;
    padding: 2px 10px;
  }
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }
  .avatar img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
  }
  .active-table::-webkit-scrollbar {
    height: 5px;
  }
  .active-table::-webkit-scrollbar-track {
    color: ${({ colors }) => colors.color.lightGrey};
  }
  .active-table::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.color.secondary};
    opacity: 0.7;
  }
  h2,
  h2 span,
  .heading {
    font-family: "MS_SemiBold";
  }
  .active-table {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    // padding: 10px 0px;
    border-radius: 10px;
    width: 100%;
    overflow-x: scroll !important;
  }
  table {
    width: 100%;
  }
  td,
  th {
    text-align: left;
    padding: 20px 10px;
    font-size: 12px;
  }
  td {
    font-family: "MS_Regular";
  }
  .table-heading {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .main-heading {
    font-family: "MS_SemiBold";
  }
  tr {
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
  }
  .order-status {
    background-color: ${({ colors }) => colors.color.darkGrey};
    color: ${({ colors }) => colors.color.white};
    font-size: 12px;
    padding: 3px;
    border-radius: 5px;
    text-align: center;
  }
  .active {
    background-color: ${({ colors }) => colors.color.green};
  }
  .completed {
    background-color: ${({ colors }) => colors.color.pending};
  }
  .revision {
    background-color: ${({ colors }) => colors.color.danger};
  }

  @media only screen and (min-width: 450px) {
    .manage-status {
      font-size: 14px;
    }
    .status-bar-sphere {
      width: 50px;
    }
    .active-order {
      left: 0;
    }
    .revision-order {
      left: 60px;
    }
    .completed-order {
      left: 138px;
    }
    td,
    th {
      font-size: 14px;
    }
    p {
      font-size: 14px !important;
    }
  }
  @media only screen and (min-width: 768px) {
    .orders-page {
      padding: 10px 40px;
    }
    .manage-status-list {
      gap: 30px;
    }
    .manage-status {
      font-size: 16px;
    }
    .status-bar-sphere {
      width: 65px;
    }
    .active-order {
      left: 0;
    }
    .revision-order {
      left: 85px;
    }
    .completed-order {
      left: 190px;
    }
    .active-table::-webkit-scrollbar {
      display: none;
    }
    td,
    th {
      padding: 20px 15px;
      font-size: 16px;
    }
    p {
      font-size: 16px !important;
    }
    .order-wrapper {
      padding: 40px !important;
    }
    .status {
      border-radius: 10px;
      position: absolute;
      right: 15px;
      top: 15px;
      padding: 3px 10px;
    }
    .status p {
      font-size: 14px !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .manage-orders {
      max-width: 1100px !important;
    }
    .orders-box {
      gap: 20px;
      padding: 10px;
    }
    .orders-page {
      padding: 10px 100px;
    }
  }
`;

export default ManageOrders;
