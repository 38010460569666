import React, { useState } from "react";

import { Styles } from "../GigCreation/gigCreation";
import { colors } from "../../../utils/Colors";
import Header from "../../../components/common/Header";
import Footer from "../../../components/common/Footer";
import UnderConstruct from "../../../assets/images/gig-creation.svg";
import ModalLocation from "../../../components/Seller/ModalLocation";
import GigCreationForm from "../../../components/Seller/GigCreationForm";

import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { updateGig } from "../../../store/thunks/gigsThunk";

const UpdateGig = () => {
  const [formNo, setFormNo] = useState(1);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gigData = useLocation();
  const data = gigData.state;

  const handleForm = (details) => {
    setValues({ ...values, ...details });
    setFormNo(formNo <= 4 ? formNo + 1 : formNo);
  };

  const handleSubmit = (details) => {
    dispatch(
      updateGig({ data: { ...values, ...details, _id: data._id }, navigate })
    );
  };

  return (
    <Styles colors={colors}>
      <Header />
      <div className="gig-creation">
        <div className="gig-creation-data">
          <GigCreationForm
            gigValues={data}
            handleSubmit={handleSubmit}
            handleForm={handleForm}
            formNo={formNo}
            setFormNo={setFormNo}
            title={
              <>
                Update a <span className="falcon-clr">Gig</span>
              </>
            }
          />
        </div>
        <div className="gig-creation-img">
          <img src={UnderConstruct} alt={"under-construct"} />
        </div>
      </div>
      <ModalLocation />
      <Footer />
    </Styles>
  );
};

export default UpdateGig;
