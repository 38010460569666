import React, { useEffect } from "react";
import { Map, useMap } from "react-mapkit";

import { RedIconSVG } from "../../utils/Images";

const DEFAULT_LATITUDE_SPAN = 0.2;
const DEFAULT_LONGITUDE_SPAN = 0.2;

/**
 * @param {number} latitude
 * @param {number} longitude
 */
const JobAppleMap = ({ latitude, longitude }) => {
  const { map, mapProps, mapkit, setRegion } = useMap();
  useEffect(() => {
    if (map) {
      map.removeAnnotations(map.annotations);
      var pinOptions = {
        url: { 1: RedIconSVG },
      };
      var coordinate = new mapkit.Coordinate(latitude, longitude);
      var pinAnnotation = new mapkit.ImageAnnotation(coordinate, pinOptions);
      pinAnnotation.calloutOffset = new DOMPoint(0, 7);
      map.addAnnotation(pinAnnotation);
      setRegion(
        {
          latitude,
          longitude,
          latitudeSpan: DEFAULT_LATITUDE_SPAN,
          longitudeSpan: DEFAULT_LONGITUDE_SPAN,
        },
        true
      );
      return () => {
        map.removeAnnotations(map.annotations);
        map.removeOverlays(map.overlays);
      };
    }
  }, [map]);

  return (
    <div className="d-block map">
      <Map {...mapProps} />
    </div>
  );
};

export default JobAppleMap;
