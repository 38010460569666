import styled from "styled-components";

export const Styles = styled.div`
  .offer-content {
    word-wrap: break-word;
  }
  .job-offer {
    width: 275px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
  }
  .offer-img {
    width: 275px;
    height: 180px;
    position: relative;
  }
  .offer-img img {
    object-fit: cover;
    width: 275px;
    height: 180px;
  }
  .offer-avatar {
    position: absolute;
    z-index: 2;
    bottom: -25px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid ${({ colors }) => colors.color.white};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    overflow: hidden;
  }
  .offer-avatar img {
    width: 47px;
    height: 47px;
    object-fit: cover;
  }
  .offer-cover {
    height: 75px;
    overflow: hidden;
  }

  @media only screen and (min-width: 587px) {
    .job-offer {
      // padding: 30px 40px;
    }
  }
`;
