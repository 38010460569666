import * as Yup from "yup";

//POST JOB SCHEMA
export const PostJobSchema = Yup.object({
  budgetMin: Yup.number().min(5).required("Please Enter Minimum Budget"),
  budgetMax: Yup.number()
    .moreThan(Yup.ref("budgetMin"), "Invalid Max budget")
    .required("Please Enter Maximum Budget"),
  description: Yup.string()
    //.min(40)
    .max(600)
    .required("Description is Required."),
  category: Yup.object().required("Category is Required."),
  subcategory: Yup.object().required("Subcategory is Required."),
  workType: Yup.string().required("Work Type is Required."),
  deadline: Yup.number().required("Delivery Time is Required."),
  attachments: Yup.array().min(1),
  location: Yup.object()
    .test("has-longitude-and-latitude", "Location is Required", (obj) => {
      return (
        obj &&
        typeof obj.longitude === "number" &&
        typeof obj.latitude === "number"
      );
    })
    .required("Location is Required"),
});

//PAYMENT CARD SCHEMA
export const paymentCardSchema = Yup.object({
  cardNumber: Yup.number()
    .required("Please Enter Your Card Number")
    .test("length", "Invalid Card Number", (value) => {
      if (!value) return false; // Return false if the value is empty or undefined
      const stringValue = String(value);
      return stringValue.length < 17;
    }),
  cvc: Yup.number()
    .required("Please Enter CVC. ")
    .test("length", "Invalid CVC", (value) => {
      if (!value) return false; // Return false if the value is empty or undefined
      const stringValue = String(value);
      return stringValue.length === 3;
    }),
  expiryMonth: Yup.number()
    .min(1, "Invalid month")
    .max(12, "Invalid month")
    .required("Month is required"),
  expiryYear: Yup.number()
    // .min(new Date().getFullYear(), "Card has expired")
    .required("Year is required")
    .test("expiry-date", "Card has expired", function (value) {
      const { expiryMonth } = this.parent;
      const currentYear = new Date().getFullYear().toString().substr(-2);
      const currentMonth = new Date().getMonth() + 1;

      if (value > currentYear) {
        return true;
      } else if (value === currentYear && expiryMonth >= currentMonth) {
        return true;
      }

      return false;
    }),
  // expiryYear: Yup.number()
  //   .min(new Date().getFullYear().toString().substring(-2), "Invalid year")
  //   .required("Year is required"),
});

// GIG CREATION SCHEMA
// export const GigCreationSchema = Yup.object({
//   category: Yup.object().required("Category is required"),
//   subcategory: Yup.object().required("Subcategory is required"),
//   gigTitle: Yup.string().required("Title is required"),
//   description: Yup.string().required("Description is required"),
//   requirement: Yup.string().required("Requirement is required"),
//   priceMax: Yup.number().required("Maximum Price is required"),
//   priceMin: Yup.number().required("Minimum Price is required"),
//   daysMin: Yup.number().required("Minimum Days is required"),
//   daysMax: Yup.number().required("Maximum Days is required"),
// });

//SIGN UP SCHEMA
export const SignupSchema = Yup.object({
  name: Yup.string().required("First Name is required"),
  lastname: Yup.string().required("Last Name is required"),
  username: Yup.string().required("Username is required"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
  address: Yup.object().required("Location is Required"),
});

//Gig Creation Schema
export const DetailSchema = Yup.object({
  category: Yup.object().required("Category is Required."),
  subcategory: Yup.object().required("Subcategory is Required."),
  title: Yup.string().min(10).max(60).required("Gig Title is Required."),
  location: Yup.object()
    .test("has-longitude-and-latitude", "Location is Required", (obj) => {
      return (
        obj &&
        typeof obj.longitude === "number" &&
        typeof obj.latitude === "number"
      );
    })
    .required("Location is Required"),
});

export const DescriptionSchema = Yup.object({
  description: Yup.string()
    .min(40)
    .max(300)
    .required("Description is Required."),
  priceTo: Yup.number()
    .moreThan(Yup.ref("priceFrom"), "Invalid Max price")
    .required("Please Enter Maximum Price"),
  priceFrom: Yup.number().min(5).required("Please Enter Minimum Price"),
  workDaysTo: Yup.number()
    .moreThan(Yup.ref("workDaysFrom"), "Invalid Max days")
    .required("Please Enter Maximum Days"),
  workDaysFrom: Yup.number().required("Minimum Days is Required."),
});

export const RequirementsSchema = Yup.object({
  requirements: Yup.string()
    .min(10)
    .max(300)
    .required("Above Field is Required."),
});

export const AttachmentSchema = Yup.object({
  attachments: Yup.array().min(1),
});

export const BecomeSellerSchema = Yup.object({
  category: Yup.object().required("Category is Required."),
  subcategories: Yup.array().min(1).required("Select atlest 1 subcategory."),
  description: Yup.string()
    .min(20)
    .max(600)
    .required("Description is Required."),
  profileImg: Yup.mixed().required("Profile Picture is required"),
  languages: Yup.array().min(1),
});

export const BankAccountSchema = Yup.object({
  accountHolderName: Yup.string().required("Account Holder Name is Required"),
  bankAccountNumber: Yup.string().required("Bank Account Number is Required"),
  routingNumber: Yup.string().required("Routing Number is Required"),
  country: Yup.object().required("Country is Required"),
});

export const SendOfferSchema = Yup.object({
  coverLetter: Yup.string().required("Cover Letter is Required"),
  selectedGig: Yup.object().required("Gig is Required"),
  price: Yup.number().required("Price is Required"),
});
