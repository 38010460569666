import { createSlice } from "@reduxjs/toolkit";

export const createSliceWithStateIds = (reducerName) => {
  const initialState = {};
  const slice = createSlice({
    name: reducerName,
    initialState,
    reducers: {
      setStateForId: (state, action) => {
        state[action.payload.id] =
          action.payload.setState(state[action.payload.id]) || undefined;
      },
    },
  });
  return {
    reducerName,
    actions: slice.actions,
    reducer: slice.reducer,
  };
};
