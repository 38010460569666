import { useCallback, useState } from "react";
import { uploadFile } from "./api";
import { useQBContext } from "./context/quickblox-provider";

const useAttachmentsHandler = () => {
  const { QBApp } = useQBContext();

  /**
   * @typedef {Object} FileSelected
   * @property {File} clientFile
   * @property {{id: number, url: string, type: string} | undefined}
   * @property {"pending" | "success" | "error"} status
   * @property {{id: number, url: string, type: string} | undefined} serverFile
   * @property {string | undefined} error
   */
  const [filesSelected, setFilesSelected] = useState(
    /** @type {Array.<FileSelected> | undefined} **/ (undefined)
  );

  const isUploading =
    filesSelected && filesSelected.length
      ? filesSelected.some((file) => file.status === "pending")
      : false;

  const handleFilesChange = useCallback(
    /**
     * @param {FileList} files
     */
    async (files) => {
      const totalFiles = files.length;
      setFilesSelected((prev) => {
        const prevFiles = prev ? prev : [];
        const newFiles = [...prevFiles];
        for (let i = 0; i < totalFiles; i++) {
          newFiles.push({
            clientFile: files[i],
            serverFile: undefined,
            status: "pending",
          });
        }
        return newFiles;
      });

      // Uploading Files
      for (let i = 0; i < totalFiles; i++) {
        const file = files[i];
        try {
          const { id, url, type } = await uploadFile(QBApp, file);
          setFilesSelected((prev) => {
            const prevFiles = prev ? prev : [];
            const newFiles = [...prevFiles];
            const index = newFiles.findIndex(
              (file) => file.clientFile === files[i]
            );
            newFiles[index] = {
              clientFile: files[i],
              serverFile: {
                id,
                url,
                type,
              },
              status: "success",
            };
            return newFiles;
          });
        } catch (e) {
          setFilesSelected((prev) => {
            const prevFiles = prev ? prev : [];
            const newFiles = [...prevFiles];
            const index = newFiles.findIndex(
              (file) => file.clientFile === files[i]
            );
            console.log(e)
            // Parsing the error
            let errorMsg = 
              "Unexpected error! Some error occured while uploading the file";
            if (typeof e?.message?.errors === "object") {
              const ErrorEntry = Object.entries(e.message.errors).pop();
              if (ErrorEntry) {
                errorMsg = `${ErrorEntry[0]} ${ErrorEntry[1]}`;
              }
            } else if (typeof e.message === "string") {
              const parseMessage = JSON.parse(e.message)
              if (parseMessage.errors) {
                const ErrorEntry = Object.entries(parseMessage.errors).pop();
                if (ErrorEntry) {
                  errorMsg = `${ErrorEntry[1]}`;
                }
              }
            }
            newFiles[index] = {
              clientFile: files[i],
              serverFile: undefined,
              status: "error",
              error: errorMsg,
            };
            return newFiles;
          });
        }
      }
    },
    [QBApp]
  );

  /**
   *
   * @param {number} index
   */
  const removeFile = (index) => {
    setFilesSelected((prev) => {
      const prevFiles = prev ? prev : [];
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles.length === 0 ? undefined : newFiles;
    });
  };

  const removeAll = () => {
    setFilesSelected(undefined);
  };

  return {
    handleFilesChange,
    filesSelected,
    isUploading,
    removeFile,
    removeAll,
  };
};

export default useAttachmentsHandler;
