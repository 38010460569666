import styled from "styled-components";

export const Styles = styled.div`
  .payment-page {
    min-height: 50vh;
    padding: 10px;
    margin: 40px auto;
  }
  h5,
  h3,
  h3 span,
  .dark {
    font-family: "NN_SemiBold";
  }
  .light {
    font-family: "NN_Light";
  }
  hr {
    margin-top: 0;
  }
  .falcon-clr {
    color: ${({ colors }) => colors.color.secondary};
  }
  .grey-clr {
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .payment-wrapper {
    margin-top: 20px;
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
    border-radius: 10px;
  }
  .payment-sidebar {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
    border-radius: 10px;
    flex: 1;
    max-height: 278px;
  }
  .box {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    width: 120px;
    height: 90px;
    padding: 20px 5px;
    border-radius: 10px;
    cursor: pointer;
  }
  .box:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
  }
  .box p {
    font-size: 14px;
  }
  .selected {
    border: 4px solid ${({ colors }) => colors.color.secondary};
    box-shadow: none !important;
  }
  .paypal-icon {
    width: 40px;
    height: 40px;
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .bank-icon {
    width: 30px;
    height: 30px;
  }
  .paypal-text {
    font-family: "NN_Bold";
  }
  .payment-main-section {
    flex: 4;
  }
  .bank-details-section {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    border-radius: 10px;
  }
  .bank-details {
    padding: 20px;
    flex: 4;
  }
  .bank-name {
    font-family: "NN_Light";
    font-size: 14px;
    color: ${({ colors }) => colors.color.darkGrey};
  }
  .username {
    font-family: "NN_SemiBold";
  }
  .total-payment {
    flex: 1;
    padding: 20px 40px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    min-width: 200px;
    height: 100%;
  }
  .dollar {
    color: rgb(255, 193, 7);
    margin-top: 10px;
  }
  .total-income {
    font-family: "MS_Bold";
    font-size: 28px;
    margin-left: 10px;
    margin-top: -10px;
  }
  .previous-section {
    border: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 20px;
    border-radius: 10px;
  }
  .transaction {
    border-bottom: 1px solid ${({ colors }) => colors.color.lightGrey};
    padding: 10px 0;
  }
  .transaction:last-child {
    border: none;
    padding-bottom: 0;
  }

  @media only screen and (min-width: 768px) {
    .payment-page {
      padding: 10px 40px;
    }
    .payment-wrapper {
      padding: 40px;
    }
    .payment-sidebar {
      padding: 40px;
    }
  }
  @media only screen and (min-width: 992px) {
    .payment-page {
      max-width: 1100px !important;
    }
  }
`;
