import React, { useEffect } from "react";
import { colors } from "../../../utils/Colors";
import styled from "styled-components";
import { Map, useMap } from "react-mapkit";

import { BlueIconSVG } from "../../../utils/Images";
/**
 * @typedef {import("../../../types/server").GigDetailData} Props
 *
 */
const DEFAULT_LATITUDE_SPAN = 0.2;
const DEFAULT_LONGITUDE_SPAN = 0.2;

const GigAppleMap = (/** @type {Props} */ data) => {
  const { map, mapProps, mapkit, setRegion } = useMap();
  const latitude = data.latitude;
  const longitude = data.longitude;
  useEffect(() => {
    if (map) {
      map.removeAnnotations(map.annotations);
      var pinOptions = {
        url: { 1: BlueIconSVG },
      };
      var coordinate = new mapkit.Coordinate(latitude, longitude);
      var pinAnnotation = new mapkit.ImageAnnotation(coordinate, pinOptions);
      pinAnnotation.calloutOffset = new DOMPoint(0, 7);
      map.addAnnotation(pinAnnotation);
      setRegion(
        {
          latitude,
          longitude,
          latitudeSpan: DEFAULT_LATITUDE_SPAN,
          longitudeSpan: DEFAULT_LONGITUDE_SPAN,
        },
        true
      );
      return () => {
        map.removeAnnotations(map.annotations);
        map.removeOverlays(map.overlays);
      };
    }
  }, [map]);

  return (
    <GigMapStyle colors={colors}>
      <div className="map">
        <Map {...mapProps} />
      </div>
    </GigMapStyle>
  );
};

const GigMapStyle = styled.div`
  padding: 10px;
  border: 1px solid ${({ colors }) => colors.color.lightGrey};
  .map {
    height: 300px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  @media only screen and (min-width: 768px) {
    .map {
      height: 400px;
    }
  }
`;

export default GigAppleMap;
