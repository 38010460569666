import { createAsyncThunk } from "@reduxjs/toolkit";
import { geocodeByPlaceId } from "react-places-autocomplete";


export const setGeometryFilterToCurrentLocation = createAsyncThunk(
  "homeFilters/setGeometryFilterToCurrentLocation",
  /**
   * @param {any} _
   * @return {import('../slices/homeFiltersSlice.js').Geometry} _
   */
  async (_, { rejectWithValue }) => {
    try {
      return await new Promise((resolve, reject) => {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setGooglePlaceFilterWithPlaceId = createAsyncThunk(
  "homeFilters/setGooglePlaceFilterWithPlaceId",
  /**
   * @param {string} googlePlaceId
   * @return {Promise<import('../slices/homeFiltersSlice.js').GooglePlaceSelectedOption>}
   */
  async (googlePlaceId, { rejectWithValue }) => {
    try {
      const results = await geocodeByPlaceId(googlePlaceId);
      const googleLocation = results.pop();
      return {
        label: googleLocation.formatted_address,
        value: googleLocation,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
